import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
    selector: 'app-dropdown-item',
    templateUrl: './dropdown-item.component.html',
    styleUrls: ['./dropdown-item.component.scss']
})
export class DropdownItemComponent {
    @Output() deleteItemEvent = new EventEmitter<any>();
    @Output() saveItemEvent = new EventEmitter<any>();

    inputTypeValue: any[] = [];
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DropdownItemComponent>) {
        this.inputTypeValue = data.arryObject;
    }
    closeDialog(): void {
        this.dialogRef.close();
    }

    saveItems() {
        if (this.inputTypeValue.length > 0) {

            this.saveItemEvent.emit(this.inputTypeValue);
        }
        this.dialogRef.close();
    }

    removeItem(item: number, inputTypeValueId: number) {
        this.inputTypeValue?.splice(item, 1);
        this.deleteItemEvent.emit(inputTypeValueId);

    }

    addValue(item: any) {
        if (this.inputTypeValue.length == 0) {
            this.inputTypeValue = [
                {
                    id: 0,
                    descriptionId: 0,
                    value: ""
                }
            ];
        }
        item.id = 0;
        this.inputTypeValue = [...this.inputTypeValue];
        this.inputTypeValue.push({
            id: -1,
            descriptionId: 0,
            value: ""
        })
    }
}

import {Component} from '@angular/core';

@Component({
    selector: 'app-nav-unit-description',
    templateUrl: './nav-unit-description.component.html',
    styleUrls: ['./nav-unit-description.component.scss']
})
export class NavUnitDescriptionComponent {

}

import { Component, ViewChild, ElementRef, OnInit, ViewEncapsulation, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { arWebDesigner } from '@grapecity/ar-designer';
import { JSViewer, createViewer } from '@grapecity/ar-viewer';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
    selector: 'app-active-report',
    templateUrl: './active-report.component.html',
    styleUrls: [
        './active-report.component.css',
        '../../../../node_modules/@grapecity/ar-designer/dist/web-designer.css',
        '../../../../node_modules/@grapecity/ar-viewer/dist/jsViewer.min.css'
    ],
    encapsulation: ViewEncapsulation.None,
})
export class ActiveReportComponent implements OnInit {
    private viewer: JSViewer | null = null;

    constructor(private route: ActivatedRoute, private ngZone: NgZone, private configurations: ConfigurationService) {
    }

    ngOnInit() {
        arWebDesigner.create('#ar-web-designer', {
            server: {
                url: 'gt/api/'
            },
            rpx: { enabled: true },
            appBar: { openButton: { visible: true } },
            data: { dataSets: { visible: true, canModify: true }, dataSources: { canModify: true } },
            language: "fr",
            preview: {
                openViewer: (options: any) => {
                    if (this.viewer) {
                        this.viewer.openReport(options.documentInfo.id);
                        return;
                    }

                    this.viewer = createViewer({
                        element: '#' + options.element,
                        renderFormat: 'svg',
                        reportService: {
                            url: 'gt/api/reporting/'
                        },
                        reportID: options.documentInfo.id,
                        parametersPanel: {
                            location: 'default',
                            open: 'always'
                        }
                    });
                }
            }
        });
    }

    ngOnDestroy() {
        this.viewer?.destroy();
        arWebDesigner.destroy('#ar-web-designer');
    }
}

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Unit Details</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i> Dashboard
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Unit Details</li>
    </ol>
</div>
<ng-container *ngIf="loadingService.isLoading$ | async; else loadedContent">
    <app-loading></app-loading>
</ng-container>
<ng-template #loadedContent>
    <mat-card class="mb-25 tagus-card">
        <div class="container border-bottom">
            <div class="row mb-5">
                <div class="col-lg-4 text-start my-2">
                    <ng-container *ngFor="let field of ['name', 'rfId1', 'rfId2']">
                        <h5 class="mb-0 d-inline">
                            <span *ngIf="!isEditing || (isEditing && editingField !== field)">
                                {{ descriptionValue[field] }}<span *ngIf="field !== 'rfId2'">-</span>
                            </span>
                            <ng-container *ngIf="isEditing && editingField === field">
                                <input [(ngModel)]="descriptionValue[field]" [placeholder]="getFieldPlaceholder(field)"
                                       (blur)="finishUnitEditing(field)" />
                            </ng-container>
                        </h5>
                    </ng-container>
                </div>

                <div class="col-lg-4 text-center d-flex align-items-center my-0">
                    <mat-progress-bar class="example-margin" [color]="getProgressBarColor(descriptionValue.progress)"
                                      mode="determinate" [value]="descriptionValue.progress" bufferValue="bufferValue"></mat-progress-bar>
                    <span class="progress-value">{{ descriptionValue.progress }}%</span>
                </div>
                <div class="col-lg-4 text-end">
                    <button mat-flat-button class="default-btn tagus" color="warn" (click)="removeUnit()" title="Remove Unit" [disabled]="!canManageUnits">
                        {{ 'Remove' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <mat-card-content>
            <div class="timeline">
                <ng-container *ngFor="let descriptionVal of descriptionValue.descriptionValues; let i = index">
                    <div class="event my-4">
                        <div class="inner" [ngClass]="{'icon-left': i % 2 !== 0, 'icon-right': i % 2 === 0}">

                            <ng-container *ngIf="canManageUnits && (descriptionVal.description.inputType.name !='UnitReference' && descriptionVal.description.inputType.name !='MouldNumber' && descriptionVal.description.inputType.name !='Hall')">
                                <div class="remove-icon">
                                    <button mat-icon-button color="warn" title="Remove description value"
                                            (click)="deleteDescriptionValue(i,descriptionVal.description.name,descriptionVal.description.id,descriptionVal.id)">
                                        <mat-icon matSuffix>delete</mat-icon>
                                    </button>
                                    <button *ngIf="!(descriptionVal.description.inputType.name === 'ImageUpload'
                    || descriptionVal.description.inputType.name === 'Handwriting'
                    || descriptionVal.description.inputType.name === 'Signature')" class="p-0" mat-icon-button
                                            color="primary" (click)="startEditingUnitValue(i,inputElement)" title="Edit description value">
                                        <mat-icon color="primary" title="Edit description value" matSuffix>edit</mat-icon>
                                    </button>
                                </div>
                            </ng-container>

                            <div class="content ">
                                <span class="date fw-semibold">{{ descriptionValue.createdDate | dateDayFormat }}</span>
                                <h5 class="mb-0">{{ descriptionVal.description.name }}</h5>
                                <span class="d-block muted-color fs-14">
                                    {{
 descriptionVal.updatedBy ?
                (descriptionValue.updatedDate | timeFormat) + ' By ' + ((descriptionVal.updatedBy | titlecase) ||
                'N/A'):
                (descriptionValue.createdDate | timeFormat) + ' By ' + ((descriptionVal.createdBy | titlecase) ||
                'N/A')
                                    }}
                                </span>
                                <div [ngClass]="{'col-lg-4 text-end mb-2': isEditing && editingIndex === i}">
                                    <div *ngIf="!isEditing || (isEditing && editingIndex !== i)"
                                         (click)="startEditingUnitValue(i,inputElement)">
                                        <p *ngIf="!isEditing || (isEditing && editingIndex !== i)">
                                            <ng-container *ngIf="descriptionVal.description.inputType.name === 'Date' ; else otherContent">
                                                <span id="formElement{{i}}">
                                                    {{
 getDisplayValue(descriptionVal, i) | dateTime24HoursFormat
                                                    }}
                                                </span>
                                            </ng-container>
                                            <ng-template #otherContent>
                                                <span id="formElement{{i}}">{{ getDisplayValue(descriptionVal, i) }}</span>
                                            </ng-template>
                                        </p>
                                    </div>

                                    <ng-template #editMode let-id="id">
                                        <div [ngClass]="{'input-container': i % 2 === 0}">
                                            <input formControlName="value{{i}}" class="form-control"
                                                   (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                   (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                   (focusout)="finishEditingUnitValue()" />
                                        </div>
                                    </ng-template>
                                </div>
                                <div [ngSwitch]="descriptionVal.description.inputType.name">
                                    <ng-container *ngSwitchCase="'Name'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill"
                                                            [readonly]="(!canManageUnits || !canViewUnits) || (!canManageUnits && canViewUnits)">
                                                <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                                <input #inputElement matInput [readonly]="!canManageUnits"
                                                       (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       [formControl]="unitForm.get('value' + i)" class="form-control" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Text'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill" [readonly]="!canManageUnits">
                                                <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                                <input #inputElement matInput type="text" [readonly]="!canManageUnits"
                                                       (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       [formControl]="unitForm.get('value' + i)" class="form-control" />
                                            </mat-form-field>

                                            <ng-template [ngIf]="descriptionVal.description.name === 'RFID 1'">
                                                <div class="alert alert-danger alert-sm mt-1 custom-alert" role="alert">
                                                    rfid number will be updated through all the application data.
                                                </div>
                                            </ng-template>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'UnitReference'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill" [readonly]="!canManageUnits" #inputElement>
                                                <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                                <input matInput type="text" [readonly]="!canManageUnits"
                                                       (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       [formControl]="unitForm.get('value' + i)" class="form-control" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Number'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill" [readonly]="!canManageUnits">
                                                <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                                <input #inputElement matInput type="number" [readonly]="!canManageUnits"
                                                       (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       [formControl]="unitForm.get('value' + i)" class="form-control" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'DropDown'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill">
                                                <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                                <mat-select #selectElement2 [formControl]="unitForm.get('value' + i)"
                                                            [disabled]="isDropdownDisabled(i)"
                                                            (selectionChange)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)">
                                                    <mat-option *ngFor="let item of descriptionVal.description.inputTypeValues; trackBy: trackByFn"
                                                                [value]="item.id">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Date'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i">
                                            <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i">
                                                <mat-form-field appearance="fill">
                                                    <input #inputElement matInput [readonly]="!canManageUnits"
                                                           (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                           (dateChange)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                           [ngxMatDatetimePicker]="picker" [formControl]="unitForm.get('value' + i)"
                                                           class="form-control" />
                                                    <ngx-mat-datepicker-toggle matSuffix [for]="picker"
                                                                               [disabled]="!canManageUnits"></ngx-mat-datepicker-toggle>
                                                    <ngx-mat-datetime-picker #picker (closed)="finishEditingUnitValue()"
                                                                             (selectedChanged)="onDateSelected($event, i, descriptionVal.id, descriptionVal.description.id)">
                                                        <ngx-mat-datepicker-actions>
                                                            <button mat-button ngxMatDatepickerCancel>Cancel {{descriptionVal.name}}</button>
                                                            <button mat-raised-button color="primary" ngxMatDatepickerApply
                                                                    (click)="onApplyDate(descriptionVal.description.inputType.name)">
                                                                Apply
                                                            </button>
                                                        </ngx-mat-datepicker-actions>
                                                    </ngx-mat-datetime-picker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Time'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i">
                                            <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i">
                                                <mat-form-field appearance="fill">
                                                    <input #inputElement matInput [readonly]="!canManageUnits" format="24"
                                                           placeholder="Choose a time"
                                                           (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                           (dateChange)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                           [ngxMatTimepicker]="timepicker" [formControl]="unitForm.get('value' + i)"
                                                           class="form-control" />
                                                    <ngx-mat-timepicker-toggle matSuffix [for]="timepicker"
                                                                               [disabled]="!canManageUnits"></ngx-mat-timepicker-toggle>
                                                    <ngx-mat-timepicker #timepicker (closed)="finishEditingUnitValue()"
                                                                        [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
                                                                        [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                                                                        [enableMeridian]="enableMeridian" [hideTime]="hideTime"
                                                                        (timeSet)="onDateSelected($event, i, descriptionVal.id, descriptionVal.description.id)">

                                                    </ngx-mat-timepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'ImageUpload'">
                                        <img [src]="descriptionVal.value || 'path/to/empty/placeholder.jpg'"
                                             (click)="openImagePreview(descriptionVal.value,i, descriptionVal.id, descriptionVal.description.id)"
                                             class="border-radius me-10" width="50" alt="descriptionVal.description.inputType.name" />

                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i">
                                            <mat-form-field appearance="fill">
                                                <input #inputElement matInput [readonly]="!canManageUnits"
                                                       [formControl]="unitForm.get('value' + i)" class="form-control" />
                                                <!-- Add an ngIf condition to display the upload button only for 'ImageUpload' type -->
                                                <button *ngIf="descriptionVal.description.inputType.name === 'ImageUpload'" mat-button
                                                        (click)="openImageUploadModal(i)">
                                                    Upload Image
                                                </button>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'ToggleButton'">
                                        <div class="tagus-form-group d-flex align-items-center toogle-btn"
                                             *ngIf="isEditing && editingIndex === i">
                                            <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                            {{ unitForm.get('value' + i).value }}
                                            <mat-slide-toggle [formControl]="unitForm.get('value' + i)" [disabled]="!canManageUnits"
                                                              (change)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id)"
                                                              (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id)">
                                            </mat-slide-toggle>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'PartialValidation'">
                                        <div class="tagus-form-group d-flex align-items-center toogle-btn"
                                             *ngIf="isEditing && editingIndex === i">
                                            <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                            <span class="px-1">{{ unitForm.get('value' + i)?.value ? 'Active' : 'In-Active' }}</span>
                                            <mat-slide-toggle [formControl]="unitForm.get('value' + i)" [disabled]="!canManageUnits"
                                                              (change)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id)"
                                                              [checked]="unitForm.get('value' + i)?.value"
                                                              (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id)">
                                            </mat-slide-toggle>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Handwriting'">
                                        <img [src]="descriptionVal.value || 'path/to/empty/placeholder.jpg'"
                                             (click)="openImagePreview(descriptionVal.value,i, descriptionVal.id, descriptionVal.description.id)"
                                             class="border-radius me-10" width="50" alt="descriptionVal.description.inputType.name" />
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Hall'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill">
                                                <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                                <input #inputElement matInput
                                                       (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       [formControl]="unitForm.get('value' + i)" class="form-control" [readonly]="!canManageUnits" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'MouldNumber'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill">
                                                <i [class]="getIconClassName(descriptionVal.description.inputType.name)"></i>
                                                <input #inputElement matInput
                                                       (blur)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       (keyup.enter)="updateUnitValue(i, descriptionVal.id, descriptionVal.description.id, descriptionVal.description.name)"
                                                       [formControl]="unitForm.get('value' + i)" class="form-control" [readonly]="!canManageUnits" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Signature'">
                                        <img [src]="descriptionVal.value || 'path/to/empty/placeholder.jpg'" (click)="openImagePreview(descriptionVal.value,i, descriptionVal.id, descriptionVal.description.id)"
                                             class="border-radius me-10" width="50" alt="descriptionVal.description.inputType.name" />
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <input [formControl]="unitForm.get('value' + i)" class="form-control" />
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </mat-card-content>
        <mat-card-content>
            <div class="timeline">
                <ng-container *ngIf="descriptionWithoutValues.length > 0">
                    <ng-container *ngFor="let descriptionVal1 of descriptionWithoutValues; let i = index">
                        <div class="event my-4">
                            <div class="inner">
                                <span class="date fw-semibold">{{ descriptionValue.createdDate | dateDayFormat }}</span>

                                <h5 class="mb-0 text-danger d-inline" (click)="startEditingUnitValue1(i, inputElement)">
                                    {{
                  descriptionVal1.name
                                    }}
                                    <button class="p-0 text-success" mat-icon-button color="primary" title="Add description value">
                                        <mat-icon class="green-icon" title="Edit description value" matSuffix>add</mat-icon>
                                    </button>
                                </h5>

                                <span class="d-block muted-color fs-14">
                                    {{
 descriptionVal1.updatedBy ?
                  (descriptionValue.updatedDate | timeFormat) + ' By ' + ((descriptionVal1.updatedBy | titlecase) ||
                  'N/A'):
                  (descriptionValue.createdDate | timeFormat) + ' By ' + ((descriptionVal1.createdBy | titlecase) ||
                  'N/A')
                                    }}
                                </span>
                                <div [ngClass]="{'col-lg-4 text-end mb-2': isEditing && editingIndex === i}">
                                    <div *ngIf="!isEditing || (isEditing && editingIndex !== i)">
                                        <p *ngIf="!isEditing || (isEditing && editingIndex !== i)">
                                            <ng-container *ngIf="descriptionVal1.inputTypeName === 'Date' ; else otherContent">
                                                <span id="formElement{{i}}">
                                                    {{
 getDisplayValue(descriptionVal1, i) | dateTime24HoursFormat
                                                    }}
                                                </span>
                                            </ng-container>
                                            <ng-template #otherContent>
                                                <span id="formElement{{i}}">{{ getDisplayValue(descriptionVal1, i) }}</span>
                                            </ng-template>
                                        </p>
                                    </div>

                                    <ng-template #editMode let-id="id">
                                        <div [ngClass]="{'input-container': i % 2 === 0}">
                                            <input formControlName="value{{i}}" class="form-control"
                                                   (blur)="updateUnitValue(i, descriptionVal1.id, descriptionVal1.id, descriptionVal.description.name)"
                                                   (keyup.enter)="updateUnitValue(i, descriptionVal1.id, descriptionVal1.id, descriptionVal.description.name)"
                                                   (focusout)="finishEditingUnitValue()" />
                                        </div>
                                    </ng-template>
                                </div>
                                <div [ngSwitch]="descriptionVal1.inputTypeName">
                                    <ng-container *ngSwitchCase="'Name'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill"
                                                            [readonly]="(!canManageUnits || !canViewUnits) || (!canManageUnits && canViewUnits)">
                                                <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                                <input #inputElement matInput [readonly]="!canManageUnits"
                                                       (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                       (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>


                                    <ng-container *ngSwitchCase="'Text'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill" [readonly]="!canManageUnits">
                                                <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                                <input #inputElement matInput type="text" [readonly]="!canManageUnits"
                                                       (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                       (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'UnitReference'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill" [readonly]="!canManageUnits">
                                                <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                                <input matInput type="text" [readonly]="!canManageUnits"
                                                       (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                       (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Number'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill" [readonly]="!canManageUnits">
                                                <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                                <input #inputElement1 matInput type="number" [readonly]="!canManageUnits"
                                                       (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                       (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'DropDown'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill">
                                                <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                                <mat-select #selectElement2 [formControl]="unitForm1.get('value' + i)"
                                                            (selectionChange)="addDescriptionValue(i, descriptionVal1.id)">
                                                    <mat-option *ngFor="let item of descriptionVal1.inputTypeValues; trackBy: trackByFn"
                                                                [value]="item.id">
                                                        {{ item.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>


                                    <ng-container *ngSwitchCase="'Date'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i">
                                            <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i">
                                                <mat-form-field appearance="fill">
                                                    <input #inputElement1 matInput [readonly]="!canManageUnits"
                                                           [value]="getLiveTime(i)"
                                                           (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                           (dateChange)="addDescriptionValue(i, descriptionVal1.id)" [ngxMatDatetimePicker]="picker"
                                                           [formControl]="unitForm1.get('value' + i)" class="form-control" />
                                                    <ngx-mat-datepicker-toggle matSuffix [for]="picker"
                                                                               [disabled]="!canManageUnits"></ngx-mat-datepicker-toggle>
                                                    <ngx-mat-datetime-picker #picker (closed)="finishEditingUnitValue()"
                                                                             [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                                                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                                                                             [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime"
                                                                             (selectedChanged)="onDateSelected($event, i, descriptionVal1?.id, descriptionVal1?.description?.id)">
                                                        <ngx-mat-datepicker-actions>
                                                            <button mat-button ngxMatDatepickerCancel>Cancel {{descriptionVal1?.name}}</button>
                                                            <button mat-raised-button color="primary" ngxMatDatepickerApply
                                                                    (click)="onApplyDate(descriptionVal1.inputTypeName)">
                                                                Apply
                                                            </button>
                                                        </ngx-mat-datepicker-actions>
                                                    </ngx-mat-datetime-picker>
                                                </mat-form-field>
                                            </div>



                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Time'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i">
                                            <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i">
                                                <mat-form-field appearance="fill">
                                                    <input #inputElement1 matInput [readonly]="!canManageUnits" format="24"
                                                           placeholder="Choose a time" (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                           (dateChange)="addDescriptionValue(i, descriptionVal1.id)" [ngxMatTimepicker]="timepicker"
                                                           [formControl]="unitForm1.get('value' + i)" class="form-control" />
                                                    <ngx-mat-timepicker-toggle matSuffix [for]="timepicker"
                                                                               [disabled]="!canManageUnits"></ngx-mat-timepicker-toggle>
                                                    <ngx-mat-timepicker #timepicker (closed)="finishEditingUnitValue()"
                                                                        [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
                                                                        [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                                                                        [enableMeridian]="enableMeridian" [hideTime]="hideTime"
                                                                        (timeSet)="addOnDateSelected($event, i, descriptionVal1.id)">

                                                    </ngx-mat-timepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </ng-container>





                                    <ng-container *ngSwitchCase="'ImageUpload'">


                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i">
                                            <mat-form-field appearance="fill">
                                                <input #inputElement matInput [readonly]="!canManageUnits"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control" />

                                                <mat-icon matSuffix (click)="fileInput.click()" class="ri-image-add-line"
                                                          style="margin-bottom: 3px;"></mat-icon>
                                                <mat-icon matSuffix (click)="openCamera(descriptionVal1.id,i)" class="ri-camera-3-line"
                                                          style="margin-bottom: 5px;margin-left: -20px;"></mat-icon>

                                                <input hidden (change)="selectFile($event,descriptionVal1.id,i)" #fileInput type="file"
                                                       id="file">

                                            </mat-form-field>
                                        </div>


                                    </ng-container>

                                    <ng-container *ngSwitchCase="'ToggleButton'">
                                        <div class="tagus-form-group d-flex align-items-center toogle-btn"
                                             *ngIf="isEditing && editingIndex1 === i">
                                            <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                            {{unitForm1.get('value' + i).value}}
                                            <mat-slide-toggle #toggleElement1 [(ngModel)]="unitForm1.get('value' + i).value"
                                                              [disabled]="!canManageUnits" (change)="addDescriptionValue(i, descriptionVal1.id)"
                                                              (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)">
                                            </mat-slide-toggle>
                                        </div>
                                    </ng-container>


                                    <ng-container *ngSwitchCase="'PartialValidation'">
                                        <div class="tagus-form-group d-flex align-items-center toogle-btn"
                                             *ngIf="isEditing && editingIndex1 === i">
                                            <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                            <mat-slide-toggle #toggleElement11 [(ngModel)]="unitForm1.get('value' + i).value"
                                                              [disabled]="!canManageUnits" (change)="addDescriptionValue(i, descriptionVal1.id)"
                                                              (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)">
                                            </mat-slide-toggle>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Handwriting'">

                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i">
                                            <mat-form-field appearance="fill"
                                                            (click)="signaturePadPopup(descriptionVal1.id,i,descriptionVal1.inputTypeName)">
                                                <mat-icon matSuffix class="ri-chat-upload-fill"></mat-icon>

                                                <input #inputElement1 matInput [readonly]="!canManageUnits"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control" />

                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Signature'">

                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i">
                                            <mat-form-field appearance="fill"
                                                            (click)="signaturePadPopup(descriptionVal1.id,i,descriptionVal1.inputTypeName)">
                                                <mat-icon matSuffix class="ri-chat-upload-fill"></mat-icon>

                                                <input #inputElement1 matInput [readonly]="!canManageUnits"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control" />

                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Hall'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill">
                                                <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                                <input #inputElement1 matInput (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                       (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control"
                                                       [readonly]="!canManageUnits" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'MouldNumber'">
                                        <div class="tagus-form-group" *ngIf="isEditing && editingIndex1 === i"
                                             (focusout)="finishEditingUnitValue()">
                                            <mat-form-field appearance="fill">
                                                <i [class]="getIconClassName(descriptionVal1.inputTypeName)"></i>
                                                <input #inputElement1 matInput (blur)="addDescriptionValue(i, descriptionVal1.id)"
                                                       (keyup.enter)="addDescriptionValue(i, descriptionVal1.id)"
                                                       [formControl]="unitForm1.get('value' + i)" class="form-control"
                                                       [readonly]="!canManageUnits" />
                                            </mat-form-field>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <input [formControl]="unitForm1.get('value' + i)" class="form-control" />
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<button type="button" class="d-none" id="cameraOpen" data-bs-toggle="modal" data-bs-target="#staticCamera">
</button>

<div class="modal fade" id="staticCamera" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Image Capture</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeCamera()"
                        aria-label="Close"></button>
            </div>
            <ng-container>
                <div style="text-align:center">
                    <div *ngIf="isOpenCamera && !webcamImage">
                        <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)" width="798" height="600"
                                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                                [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
                                (initError)="handleInitError($event)"></webcam>
                    </div>
                    <div class="snapshot" *ngIf="webcamImage">
                        <img [src]="webcamImage.imageAsDataUrl" width="798" height="600" />
                    </div>
                </div>
            </ng-container>

            <div class="modal-footer">
                <button type="button" class="btn cancleButtonColor" (click)="closeCamera()"
                        data-bs-dismiss="modal">
                    Close
                </button>
                <button type="button" class="btn saveButtonColor" (click)="takePicture()">Take Picture</button>
                <button type="button" class="btn saveButtonColor" data-bs-dismiss="modal" (click)="savePicture()">Save</button>
            </div>
        </div>
    </div>
</div>
<button type="button" class="d-none" id="menuModal" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
</button>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">{{popName}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <ng-container>
                <signature-pad #signaturePad [options]="signaturePadOptions" (drawStart)="drawStart($event)"
                               (drawEnd)="drawComplete($event)"></signature-pad>
            </ng-container>

            <div class="modal-footer">
                <button type="button" class="btn cancleButtonColor" data-bs-dismiss="modal">Close</button>
                <button type="button" (click)="saveSignatureAsImage()" class="btn saveButtonColor"
                        data-bs-dismiss="modal">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import { FactoryService } from '../services/factory.service';
import { EntityType } from '../models/enums';
import { DescriptionValueService } from '../services/description-value.service';

@Injectable({ providedIn: 'root' })
export class CustomValidators {
    constructor(private factoryService: FactoryService) {
    }

    // Custom async validator function to check for duplicate names
    static validateNameDuplicate(factoryService: FactoryService, entityType: EntityType, factoryId: number): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            const name = control.value;

            // Return null if the control value is empty
            if (!name) {
                return of(null);
            }

            // Use debounceTime to avoid making too many requests in a short time
            return of(name).pipe(
                debounceTime(300), // Adjust debounceTime as needed
                switchMap((value) =>
                    factoryService.getFactoryDuplicateStatus(factoryId, entityType, value).pipe(
                        map((isDuplicate) => {
                            return isDuplicate ? { nameDuplicate: true } : null;
                        }),
                        catchError(() => null) // Handle errors gracefully
                    )
                )
            );
        };
    }

    static validateConfirmPassword = (passwordControl: AbstractControl): ValidatorFn => {
        return (confirmationControl: AbstractControl): { [key: string]: boolean } | null => {
            const confirmValue = confirmationControl.value;
            const passwordValue = passwordControl.value;
            if (confirmValue === '') {
                return null;
            }

            if (confirmValue !== passwordValue) {
                return { mustMatch: true }
            }

            return null;
        };
    }
}
export function ForbiddenNameValidators(factoryService: FactoryService, entityType: EntityType, factoryId: number, workplaceId?: number): AsyncValidatorFn {
    return (control: FormGroup): Observable<ValidationErrors | null> => {

        const name = control.value;

        // Return null if the control value is empty
        if (!name) {
            return of(null);
        }

        // Use debounceTime to avoid making too many requests in a short time
        return of(name).pipe(
            debounceTime(300), // Adjust debounceTime as needed
            switchMap((value) =>
                factoryService.getFactoryDuplicateStatus(factoryId, entityType, value, workplaceId).pipe(
                    map((isDuplicate) => {
                        return isDuplicate ? { nameDuplicate: true } : null;
                    }),
                    catchError(() => null) // Handle errors gracefully
                )
            )
        );
    };
}

export function DescriptionValueDuplicate(descriptionValueService: DescriptionValueService, value: string, controlName: string, unitId: number): AsyncValidatorFn {
    return (control: FormGroup): Observable<ValidationErrors | null> => {

        const name = control.value;

        // Return null if the control value is empty
        if (!name) {
            return of(null);
        }

        // Use debounceTime to avoid making too many requests in a short time
        return of(name).pipe(
            debounceTime(300), // Adjust debounceTime as needed
            switchMap((value) =>
                descriptionValueService.getDuplicateDescriptionValue(value, controlName.split('_')[0], unitId).pipe(
                    map((isDuplicate) => {

                        return isDuplicate ? { nameDuplicate: true } : null;
                    }),
                    catchError(() => null) // Handle errors gracefully
                )
            )
        );


    };

}

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Units</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Units</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>

        <mat-card-header class="d-block">
            <div class="row control-box justify-content-between">
                <div class="col-lg-3">
                    <div class="search-box">
                        <app-search-box (searchChange)="onSearchChanged($event)"
                                        placeholder="{{'search for name' | translate}}"></app-search-box>
                    </div>
                </div>

                <div class="col-lg-3 text-end">
                    <!-- <button *ngIf="canManageUsers" mat-flat-button class="default-btn tagus" (click)="filter()">
                        <i class="ri-filter-fill"></i>{{'Filter' | translate}}
                    </button> -->
                    <button *ngIf="canManageUsers" mat-flat-button matBadge="{{filterCount}}" matBadgePosition="before"
                            matBadgeColor="accent" class="default-btn tagus" [matMenuTriggerFor]="filterMenu">
                        <i class="ri-filter-fill"></i> {{ 'Filter' | translate }}
                    </button>
                    <mat-menu #filterMenu="matMenu">
                        <div style="overflow-x: hidden;">
                            <app-units-filter [moulTypesList]="moulTypesList"
                                              [orignalMoulTypesList]="orignalMoulTypesList"
                                              (applyFilterChanged)="applyFilter($event)">
                            </app-units-filter>
                        </div>


                    </mat-menu>


                </div>
            </div>
        </mat-card-header>

        <div class="myTasksTable mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource">



                <!-- Task Name Column -->
                Assigned Column
                <!-- <ng-container matColumnDef="assigned">
                    <th mat-header-cell *matHeaderCellDef>Assigned</th>
                    <td mat-cell *matCellDef="let element">
                        <img [src]="element.assigned.img" matTooltip="{{element.assigned.name}}" class="rounded-circle"
                            alt="icon">
                    </td>
                </ng-container> -->
                <!-- Deadline Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <a class="text-primary cursor-pointer" [routerLink]="['/view-unit']"
                           [queryParams]="{ id: element.id }">{{ element.name }}</a>
                    </td>
                </ng-container>
                <!-- Deadline Column -->
                <ng-container matColumnDef="rfId1">
                    <th mat-header-cell *matHeaderCellDef>{{ 'RFID 1' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge completed fw-medium">{{element.rfId1}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="rfId2">
                    <th mat-header-cell *matHeaderCellDef>{{ 'RFID 2' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge completed fw-medium">{{element.rfId2}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="createdat">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Created At' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge completed fw-medium">{{element.createdDate | date:'yyyy-MM-dd'}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="progress">
                    <th mat-header-cell *matHeaderCellDef>{{ 'progress' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <mat-progress-bar class="example-margin"
                                          [color]="getProgressBarColor(element.progress)"
                                          [mode]="determinate"
                                          [value]="element.progress"
                                          [bufferValue]="bufferValue">

                        </mat-progress-bar>{{element.progress}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingIndicator"></mat-progress-bar>

            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                           [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {UnitRequest} from 'src/app/models/unit-request.model';
import {Unit} from 'src/app/models/unit.model';
import {FactoryService} from 'src/app/services/factory.service';
import {MouldService} from 'src/app/services/mouldServices/mould.service';
import {UnitService} from 'src/app/services/unit.service';

@Component({
    selector: 'app-add-edit-unit',
    templateUrl: './add-edit-unit.component.html',
    styleUrls: ['./add-edit-unit.component.scss']
})
export class AddEditUnitComponent {
    mouldTypeForm: FormGroup;
    isEditMode = false;
    unitData: Unit;
    unitRequest: UnitRequest = new UnitRequest();
    mouldTypeId: number = 0;
    isLoading: boolean;
    inProgress: boolean;
    mouldTypeEnum = [
        {id: 1, name: '1'},
        {id: 2, name: '2'}
    ]

    constructor(
        private fb: FormBuilder,
        private unitService: UnitService,
        private factoryService: FactoryService,
        private mouldService: MouldService,
        private route: ActivatedRoute,
        private router: Router,
        private toasterService: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams) => {
            if (queryParams['id']) {
                this.mouldTypeId = queryParams['id'];
                this.isEditMode = true;
                this.loadUnit(this.mouldTypeId.toString());
            }
        });
        this.createForm();
    }

    // getMouldsList() {
    //   this.mouldService.getMoulds().subscribe((data: any) => {
    //     this.workplacesList = data;
    //   })

    save(): void {
        this.isLoading = true;

        if (this.mouldTypeForm.valid) {
            if (this.inProgress) {
                return;
            }
            this.inProgress = true;
            const formData = this.mouldTypeForm.value as Unit;
            this.unitRequest.model = {...this.mouldTypeForm.value};
            this.unitRequest.model.id = this.mouldTypeId;
            if (this.isEditMode) {
                // Update an existing factory
                this.unitService.updateUnit(this.unitRequest, this.mouldTypeId).subscribe(() => {
                    this.isLoading = false;
                    this.inProgress = false;
                    // Handle success or navigate to a different page
                    this.toasterService.success(`Mould type ${this.unitRequest.model.name} has been updated.`, 'Factroy Updated',);
                    this.router.navigate(['/moulds-type']);
                });
            } else {
                // Create a new factory
                this.unitService.createUnit(this.unitRequest).subscribe(() => {
                    this.isLoading = false;
                    this.inProgress = false;
                    this.toasterService.success(`Mould type ${this.unitRequest.model.name} has been created.`, 'Factroy Created');
                    // Handle success or navigate to a different page
                    this.router.navigate(['/moulds-type']);
                });
            }
        } else {
            this.isLoading = false;
            this.inProgress = false;
            this.toasterService.error(`Kindly fill the Mould type form correctly.`, 'Mould type Failed');
        }

    }

    cancel() {
        this.router.navigate(['/moulds-type']);
    }

    // }
    private createForm(): void {
        this.mouldTypeForm = this.fb.group({
            name: ['', [Validators.required]],
            rfId1: ['', [Validators.required]],
            rfId2: ['', [Validators.required]],
            mouldId: [null, [Validators.required]],
        });
    }

    private loadUnit(id: string): void {
        this.unitService.getUnit(id).subscribe((factory) => {
            this.unitData = factory;
            this.mouldTypeForm.patchValue(factory);
        });
    }
}

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
  <h5 class="mb-0">Test Parameters</h5>
  <ol class="breadcrumb mb-0 ps-0">
      <li class="breadcrumb-item">
          <a routerLink="/">
              <i class="ri-home-8-line"></i>
              Dashboard
          </a>
      </li>
      <li class="breadcrumb-item">
        <a routerLink="/tests">
            <i class="ri-home-8-line"></i>
            Tests
        </a>
    </li>

      <li class="breadcrumb-item fw-semibold">Parameters</li>
  </ol>
</div>
<mat-card class="mb-25 tagus-card">
  <mat-card-content>
    <form [formGroup]="parameterForm">
      <div *ngIf="parameters && parameters.length > 0">
          <div formArrayName="parameters">
              <div class="row">

              <div class="col-lg-6 col-md-6 col-sm-6 posRelative" *ngFor="let parameter of parameters.controls; let i = index " [formGroupName]="i">
                <div class="tagus-form-group" (mouseenter)="showButtons(i)" (mouseleave)="hideButtons(i)">
                  <label  class="d-block mb-12 fw-semibold gray-color">Parameter<span *ngIf="checkboxStatus[i]">*</span></label>
                  <mat-form-field appearance="fill" class="input-container">
                      <i [class]="getIconClass(parameter.get('columnType').value)"></i>
                      <input
                      matInput
                      class="fullWidth"
                      formControlName="columnName"
                      placeholder="Add text label of input type"
                     
                      />
                       <mat-error *ngIf="parameter.get('columnName').errors?.uniqueColumnName">
                         Parameter name must be unique and not empty.
                      </mat-error>

                      <div style="display: inline-block; text-align: right; position: absolute; right: 0%">
                      <mat-select formControlName="columnType" >
                          <mat-option value="Text">Text</mat-option>
                          <mat-option value="Signature">Signature</mat-option>
                          <mat-option value="Image">Image Upload</mat-option>
                          <mat-option value="Interval">Interval</mat-option>
                          <mat-option value="SelectList">DropDown</mat-option>
                          <mat-option value="Numeric">Numeric</mat-option>
                          <mat-option value="SearchUnitId">Unit Reference</mat-option>
                          <mat-option value="Date">Date</mat-option>
                          <mat-option value="DateDifference">Date Difference</mat-option>
                          <mat-option value="HandWriting">Hand Writing</mat-option>
                          <mat-option value="Math">Math</mat-option> 
                          <mat-option value="Checkbox">Checkbox</mat-option>
                          
                          <mat-option value="Time">Time</mat-option>
                      </mat-select>
                      <!-- <mat-icon *ngIf="parameter.get('columnName').errors?.uniqueColumnName" class="invalid-icon">cancel</mat-icon> -->
                     
                      </div>
                      
                              <!-- <div *ngIf="parameter.get('columnType')?.value ===  'SelectList' && (parameter.get('isEditMode')?.value || i === lastActiveParameterIndex)">  -->
                                

                  </mat-form-field>
                  <div *ngIf="parameter.get('columnType')?.value === 'SelectList' || parameter.get('columnType')?.value === 'Checkbox'" > 
                    <button
                        
                        (click)="openPopup(i)"
                        class="bg-yellow extraValuesTabPosDescription"
                        [disabled]="parameter.invalid"
                        *ngIf="parameter.get('isEditMode')?.value || !parameter.disabled && hoveredParameterIndex === i"
                    >
                          <i class="ri-list-check clr-white AdjustPosDelete"></i>
                    </button>
                    </div>

                    <div *ngIf="parameter.get('columnType')?.value === 'Interval'">
                    <button
                        
                        (click)="openPopup(i)"
                        class="bg-yellow extraValuesTabPosDescription" 
                        *ngIf="parameter.get('isEditMode')?.value || !parameter.disabled && hoveredParameterIndex === i"
                        [disabled]="parameter.invalid"
                    > 
                    <i class="ri-expand-left-right-line clr-white AdjustPosDelete"></i>
                        
                    </button>
                    </div>
                 
                          <button *ngIf="hoveredParameterIndex === i && !isEditing(i) && !isNewParameter(i) && canManageUsers" (click)="removeParameter(i)" class="bg-red dltTabPosDescription"> 
                              <i class="ri-delete-bin-line clr-white AdjustPosDelete"></i>
                          </button>
                          <button *ngIf="hoveredParameterIndex === i && isEditing(i) && !isNewParameter(i) && canManageUsers" (click)="updateParameterOnKeyDown(i)" class="bg-yellow dltTabPosDescription"> 
                            <i class="ri-save-line clr-white AdjustPosEdit"></i>
                        </button>

                          <input *ngIf="hoveredParameterIndex === i && !isEditing(i) && canManageUsers" 
                          class="form-check-input bg-secondary checkBoxPosDescription"
                          (click)="toggleMandatory(i)" 
                          [checked]="parameters.at(i).get('mandatory').value" 
                          type="checkbox" 
                          id="checkbox{{i}}">

                          <button *ngIf="hoveredParameterIndex === i && !isEditing(i) && !isNewParameter(i)  && canManageUsers" (click)="editParameter(i) "  class="bg-primary UpdtTabPosDescription"> 
                            <i class="ri-pencil-line clr-white AdjustPosUpdate"></i>
                        </button>
                  

                  </div>
              </div>
              </div>
           </div>

      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div style="text-align: left;" class="mb-3 example-buttons">
            <button 
              mat-stroked-button
              (click)="addParameter()"
              class="gray text-primary"
              style="width:50%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white;"
              *ngIf="!parameters.length || parameters.at(parameters.length - 1).get('columnType').value && canManageUsers"
              [disabled]="parameters.length && parameters.at(parameters.length - 1).invalid"
            >
              <i class="ri-add-line" style="font-size: 12px;"></i> Add
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>

import { Component, EventEmitter, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Description } from 'src/app/models/description.model';
import { DescriptionService } from 'src/app/services/description.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styleUrls: ['./delete-confirmation-dialog.component.scss']
})
export class DeleteConfirmationDialogComponent {
    public deleteChanged = new EventEmitter<boolean>();
    @Input() row: Description;
    myFormTab: any;

    constructor(public activeModal: NgbActiveModal,private descriptionService: DescriptionService,
        private toastr: ToastrService,
      ) { }
      confirm() {
          this.deleteFactoryHelper(this.row);
        }

      get items() {
        return this.myFormTab.get('items') as FormArray;
      }

      deleteFactoryHelper(row: Description) {
        this.toastr.success('Deleting...');
        this.descriptionService.deleteDescription(row.id).subscribe({
            next: (_) => {
                this.toastr.success('Delete Description Type', `Description Type ${row.name} has been deleted successfully.`);
                this.deleteChanged.emit(true);
            },
            error: (error) => {
                this.toastr.error(
                    'Delete Error',
                    `An error occurred whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(
                        error
                    )}"`
                );
            },
        });
    }

      decline() {
        this.activeModal.close();
      }


}

export class User {
    public id: string;
    public userName: string;
    public fullName: string;
    public email: string;
    public jobTitle: string;
    public phoneNumber: string;
    public isEnabled: boolean;
    public isLockedOut: boolean;
    public roles: string[];

    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor(id?: string | any, userName?: string | any, fullName?: string | any, email?: string | any, jobTitle?: string | any, phoneNumber?: string | any, roles?: string[] | any) {

        this.id = id;
        this.userName = userName;
        this.fullName = fullName;
        this.email = email;
        this.jobTitle = jobTitle;
        this.phoneNumber = phoneNumber;
        this.roles = roles;
    }

    get friendlyName(): string {
        let name = this.fullName || this.userName;

        if (this.jobTitle) {
            name = this.jobTitle + ' ' + name;
        }

        return name;
    }
}

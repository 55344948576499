import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'src/app/models/permission.model';
import { Unit } from 'src/app/models/unit.model';
import { AccountService } from 'src/app/services/account.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { UnitService } from 'src/app/services/unit.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { MouldTypeService } from 'src/app/services/mould-type.service';
import { MouldTypeList } from 'src/app/models/mould-type-list';
import { TestResultsService } from 'src/app/services/testResultsServices/test-results.service';
import { DeleteTestResultComponent } from '../delete-test-result/delete-test-result.component';
import { TestResultsFilterComponent } from '../test-results-filter/test-results-filter.component';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';

@Component({
    selector: 'app-test-results-list',
    templateUrl: './test-results-list.component.html',
    styleUrls: ['./test-results-list.component.scss']
})
export class TestResultsListComponent implements OnInit {
    loadingIndicator: boolean;
    allUnits: Unit[] = [];
    totalRows = 0;
    testId: number;
    pageSize = 10;
    currentPage = 0;
    defaultPageSize = 10;
    azureBlobImageAdress = "";
    pageSizeOptions: number[] = [5, 10, 25, 100];
    displayedColumns: string[] = [];
    dataSource = new MatTableDataSource<any>([]);

    editingUnitName: null;
    sourceUser: null;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    searchValue: string = "";
    searchName: string = "";

    // MatChips properties
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    fruits: Fruit[] = [];

    range: FormGroup; // Declare a FormGroup for the date range picker
    addOnBlur = true;
    moulTypesList: MouldTypeList[] = [];
    showFilter: boolean;
    filterCount: number;
    orignalMoulTypesList: MouldTypeList[];

    isSearchLoading: boolean = false;
    isSearchValueLoading: boolean = false;

    constructor(
        public dialog: MatDialog,
        private alertService: AlertService,
        private accountService: AccountService,
        private unitService: UnitService,
        private router: Router,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private mouldTypeService: MouldTypeService,
        private testResultsService: TestResultsService,
    ) {
        this.range = this.formBuilder.group({
            start: null, // Initialize with null values
            end: null,
        });
    }

    ngOnInit(): void {
        this.azureBlobImageAdress = "https://satebagreentunnelstorqa.blob.core.windows.net/images/";
        this.testId = +this.route.snapshot.queryParams['id'];
        this.loadTestResults(this.testId);
    }

    isCreationDateColumn(columnName: string): boolean {
        return columnName === 'CreationDate';
    }
    newTestResult() {
        this.router.navigate(['/add-testresults'], { queryParams: { id: this.testId } });
    }


    loadTestResults(testId: number): void {
        const startDate = this.range.get('start').value;
        const endDate = this.range.get('end').value;

        // Convert the start and end dates to string format
        const start = this.datepipe.transform(startDate, 'YYYY-MM-dd');
        const end = this.datepipe.transform(endDate, 'YYYY-MM-dd');

        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        this.dataSource.data = [];
        this.testResultsService.getTestResults(testId, this.currentPage + 1, this.pageSize, this.searchValue, this.searchName, 'name', 'desc', start, end)
            .subscribe({
                next: (data) => {
                    if (data.items && data.items.length > 0) {
                        // Assuming the first item has all the keys for columns
                        this.displayedColumns = ['creationDate', 'rfid'];

                        // Add the rest of the columns from the testParameterValues of the first item
                        this.displayedColumns.push(...Object.keys(data.items[0].testParameterValues));

                        // Add 'actions' column at the end
                        this.displayedColumns.push('actions');
                        this.dataSource.data = data.items.map(item => ({
                            creationDate: item.creationDate,
                            rfid: item.rfid,
                            testResultId: item.testResultId,
                            ...item.testParameterValues
                        }));
                    }
                    this.onDataLoadSuccessful(data);
                    this.isSearchLoading = false;
                    this.isSearchValueLoading = false;
                },
                error: (error) => {
                    console.error("Error loading test results:", error);
                    this.onDataLoadFailed(error);
                }
            });
    }

    deleteTestResult(testResultId: number) {
        const modalRef = this.modalService.open(
            DeleteTestResultComponent,
            {
                size: 'lg',
                backdrop: 'static'
            }
        );
        modalRef.componentInstance.testResultId = testResultId;
        modalRef.componentInstance.deleteChanged.subscribe((data) => {
            if (data) {
                this.modalService.dismissAll();
                this.loadTestResults(this.testId);
            }
        })
    }

    editTestResult(testResultId: number) {
        const url = this.router.serializeUrl(this.router.createUrlTree(['/edit-test-result-values'], { queryParams: { id: testResultId, testId: this.testId } }));
        window.open('/#' + url, '_blank');    
    }

    calculateFilterCount(filterSettings: any) {
        this.filterCount = (
            (filterSettings.range && filterSettings.range.start && filterSettings.range.end) ? 1 : 0
        );// + (filterSettings.moulTypesList && filterSettings.moulTypesList.length > 0 ? 1 : 0);
    }

    pageChanged(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadTestResults(this.testId);
    }

    onDataLoadSuccessful(factories: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        // this.dataSource.data = factories.items;
        this.totalRows = factories.totalCount;
    }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
            'Load Error',
            `Unable to retrieve users from the server.\r\nErrors: "${error}"`,
            MessageSeverity.error,
            error
        );
    }

    filter() {
        const modalRef = this.modalService.open(TestResultsFilterComponent, {
            size: 'lg',
            backdrop: 'static'
        });
        //modalRef.componentInstance.moulTypesList = this.moulTypesList;
        // Assuming you have a selectedMoultypesList variable that contains the selected moultypes list.
        modalRef.componentInstance.initialFilterData = {
            range: {
                start: this.range.get('start').value, // selected start date
                end: this.range.get('end').value // selected end date
            },
            //  moulTypesList: this.moulTypesList // selected moultypes list
        };

        modalRef.componentInstance.applyFilterChanged.subscribe((data) => {
            if (data) {
                debugger
                this.modalService.dismissAll();
                this.range = data.range;
                // this.moulTypesList = data.mouldsTypes;
                this.filterCount = (
                    (data.range && data.range.get('start').value && data.range.get('end').value) ? 1 : 0
                );//+ (data.moulTypesList && data.moulTypesList.length > 0 ? 1 : 0);

                this.loadTestResults(this.testId);
            }
        })
        // this.showFilter = !this.showFilter;

    }

    editUnit(id: number) {
        this.router.navigate(['/add-edit-moulds-type'], { queryParams: { id: id } });
    }

    onSearchChanged(value: string) {
        //this.searchValue = value;
        this.isSearchValueLoading = true;
        this.searchName = "";
        this.currentPage = 0;
        this.loadTestResults(this.testId);
    }

    onSearchNameChanged(value: string) {
        //this.searchName = value;
        this.isSearchLoading = true;
        this.searchValue = "";
        this.currentPage = 0;
        this.loadTestResults(this.testId);
    }

    openFilterPanel(): void {
        this.showFilter = true;
    }

    applyFilter(data: any): void {
        this.range = data.range;
        // this.moulTypesList = data.mouldsTypes;
        this.filterCount = (
            (data.range && data.range.get('start').value && data.range.get('end').value) ? 1 : 0
        );// + (data.mouldsTypes && data.mouldsTypes.length > 0 ? 1 : 0);

        this.loadTestResults(this.testId);

        this.showFilter = false; // Hide the filter component after applying filters
    }

    get canAssignRoles() {
        return this.accountService.userHasPermission(Permission.assignRolesPermission);
    }

    get canViewRoles() {
        return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

    get canManageUsers() {
        return this.accountService.userHasPermission(Permission.manageConfigurationsPermission);
    }

    get canAddTests() {
        return this.accountService.userHasPermission(
            Permission.addTestsPermission
        );
    }
    get canEditTests() {
        return this.accountService.userHasPermission(
            Permission.editTestsPermission
        );
    }
    get canViewTests() {
        return this.accountService.userHasPermission(
            Permission.viewTestsPermission
        );
    }

    get canManageTests() {
        return this.accountService.userHasPermission(
            Permission.manageTestsPermission
        );
    }
    isImageUrl(value: string): boolean {
        return /\.(jpg|jpeg|png|gif)$/i.test(value);
    }
    openImagePreview(imageUrl: string) {
        const modalRef = this.modalService.open(ImagePreviewComponent,
            {
                size: 'lg',
                backdrop: 'static'
            }
        );
        modalRef.componentInstance.imageUrl = imageUrl;
    }

}
export interface Fruit {
    id: number;
    name: string;
}

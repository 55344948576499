<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Add Factory</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/factories">
                <i class="ri-home-8-line"></i>
                Factories
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Add Factory</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>
        <form [formGroup]="factoryForm" class="mb-25">
            <div class="row">
                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Name <span>*</span></label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Enter name</mat-label>
                            <input matInput formControlName="name">
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Name <span>*</span></label>
                        <mat-form-field appearance="fill" class="input-container">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Enter name</mat-label>
                            <input formControlName="name" id="nameInput" matInput>
                            <mat-icon *ngIf="factoryForm.get('name').valid && !factoryForm.get('name').hasError('nameDuplicate')"
                                      class="valid-icon">
                                check_circle
                            </mat-icon>
                            <mat-icon *ngIf="factoryForm.get('name').hasError('nameDuplicate')" class="invalid-icon">
                                cancel
                            </mat-icon>
                            <div *ngIf="factoryForm.get('name').pending && factoryForm.get('name').touched"
                                 class="mat-spinner">
                                <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
                            </div>
                        </mat-form-field>
                        <mat-error *ngIf="factoryForm.get('name').hasError('nameDuplicate')">Factory name already
                            exists.
                        </mat-error>
                        <mat-error
                            *ngIf="factoryForm.get('name').hasError('required') && factoryForm.get('name').touched">
                            Factory name require.
                        </mat-error>
                    </div>

                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Email <span>*</span></label>
                        <mat-form-field appearance="fill">
                            <i class="ri-mail-line"></i>
                            <mat-label>Enter email</mat-label>
                            <input formControlName="email" matInput>
                        </mat-form-field>
                        <mat-error
                            *ngIf="factoryForm.get('email').hasError('required') && factoryForm.get('email').touched">
                            Factory email require.
                        </mat-error>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-map-pin-line"></i>
                            <mat-label>Enter address</mat-label>
                            <input formControlName="address" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Description</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter description</mat-label>
                            <input formControlName="description" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Mobile</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter mobile</mat-label>
                            <input formControlName="mobile" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter phone</mat-label>
                            <input formControlName="phone" matInput>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Support</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter Support</mat-label>
                            <input formControlName="support" matInput>
                        </mat-form-field>
                    </div>
                </div>


            </div>
        </form>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 example-buttons" style="text-align: right;">
                    <button (click)="save()" [disabled]="inProgress || isLoading || factoryForm.invalid" class="tagus"
                            mat-flat-button>
                        <span *ngIf="inProgress" aria-hidden="true" class="spinner-border spinner-border-sm me-1"
                              role="status"></span>
                        <span *ngIf="inProgress; else saveText">Saving...</span>
                        <ng-template #saveText>Save</ng-template>
                    </button>
                    <span class="mx-2"></span>
                    <button (click)="cancel()" class="gray text-primary" mat-flat-button>Cancel</button>
                </div>

            </div>
        </div>

    </mat-card-content>
</mat-card>

<div>
    <div class="row">
        <label class="col-sm-3 col-form-label"
               for="refreshButton">{{'preferences.ReloadPreferences' | translate}} </label>
        <div class="col-sm-4">
            <button (click)="reloadFromServer()" class="btn btn-outline-secondary" id="refreshButton">
                <i class="fa fa-refresh"></i>
            </button>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.ReloadPreferencesHint' | translate}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <hr class="hr-separator info-separator"/>
        </div>
    </div>

    <div class="row">
        <label class="col-sm-3 col-form-label" for="language">{{'preferences.Language' | translate}} </label>
        <div class="col-sm-4">
            <ng-select [(ngModel)]="configurations.language" [clearable]="false" [searchable]="false" class="form-control"
                       id="language">
                <ng-option [value]="'en'">{{'preferences.English' | translate}} <small
                    class="text-muted"><i>({{'preferences.Default' | translate}})</i></small></ng-option>
                <ng-option [value]="'fr'">{{'preferences.French' | translate}}</ng-option>
                <ng-option [value]="'de'">{{'preferences.German' | translate}}</ng-option>
                <ng-option [value]="'pt'">{{'preferences.Portuguese' | translate}}</ng-option>
                <ng-option [value]="'ar'">{{'preferences.Arabic' | translate}}</ng-option>
                <ng-option [value]="'ko'">{{'preferences.Korean' | translate}}</ng-option>
            </ng-select>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.LanguageHint' | translate}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <hr class="hr-separator info-separator"/>
        </div>
    </div>

    <div class="row">
        <label class="col-sm-3 col-form-label" for="homePage">{{'preferences.HomePage' | translate}} </label>
        <div class="col-sm-4">
            <ng-select [(ngModel)]="configurations.homeUrl" [clearable]="false" [searchable]="false" class="form-control"
                       id="homePage">
                <ng-option [value]="'/'"><i class=" fa fa-tachometer"></i> {{'preferences.Dashboard' | translate}}
                    <small class="text-muted"><i>({{'preferences.Default' | translate}})</i></small></ng-option>
                <ng-option *ngIf="canViewCustomers" [value]="'/customers'"><i
                    class=" fa fa-handshake-o"></i> {{'preferences.Customers' | translate}}</ng-option>
                <ng-option *ngIf="canViewProducts" [value]="'/products'"><i
                    class=" fa fa-truck"></i> {{'preferences.Products' | translate}}</ng-option>
                <ng-option *ngIf="canViewOrders" [value]="'/orders'"><i
                    class=" fa fa-shopping-cart"></i> {{'preferences.Orders' | translate}}</ng-option>
                <ng-option [value]="'/about'"><i class=" fa fa-info-circle"></i> {{'preferences.About' | translate}}
                </ng-option>
                <ng-option [value]="'/settings'"><i class=" fa fa-cog"></i> {{'preferences.Settings' | translate}}
                </ng-option>
            </ng-select>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.HomePageHint' | translate}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <hr class="hr-separator info-separator"/>
        </div>
    </div>

    <div class="row">
        <label class="col-sm-3 col-form-label" for="defaultTheme">{{'preferences.Theme' | translate}} </label>
        <div class="col-sm-4">
            <ng-select [(ngModel)]="configurations.themeId" [clearable]="false" [searchable]="false" class="form-control theme-picker"
                       id="defaultTheme">
                <ng-option *ngFor="let theme of themeManager.themes" [value]="theme.id">
                    <div class="theme-item" style="background-color:{{theme.background}}; color: {{theme.color}}">
                        {{'preferences.' + theme.name + 'Theme' | translate}} <span
                        class="small">{{theme.isDark ? '(Dark)' : ''}}</span>
                    </div>
                </ng-option>
            </ng-select>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.ThemeHint' | translate}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <hr class="hr-separator info-separator"/>
        </div>
    </div>

    <div class="row">
        <label class="col-sm-3 col-form-label"
               for="dashboardStatistics">{{'preferences.DashboardStatistics' | translate}} </label>
        <div class="col-sm-4">
            <div class="form-check form-switch fs-5 pt-sm-1">
                <input [(ngModel)]="configurations.showDashboardStatistics" class="form-check-input"
                       id="dashboardStatistics" name="dashboardStatistics" type="checkbox">
            </div>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.DashboardStatisticsHint' | translate}}</p>
        </div>
    </div>
    <!--  -->

    <div class="row">
        <div class="col-md-12">
            <hr class="hr-sub-separator"/>
        </div>
    </div>

    <div class="row">
        <label class="col-sm-3 col-form-label"
               for="dashboardNotifications">{{'preferences.DashboardNotifications' | translate}} </label>
        <div class="col-sm-4">
            <div class="form-check form-switch fs-5 pt-sm-1">
                <input [(ngModel)]="configurations.showDashboardNotifications" class="form-check-input"
                       id="dashboardNotifications" name="dashboardNotifications" type="checkbox">
            </div>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.DashboardNotificationsHint' | translate}}</p>
        </div>
    </div>
    <!--  -->

    <div class="row">
        <div class="col-md-12">
            <hr class="hr-sub-separator"/>
        </div>
    </div>

    <div class="row">
        <label class="col-sm-3 col-form-label" for="dashboardTodo">{{'preferences.DashboardTodo' | translate}} </label>
        <div class="col-sm-4">
            <div class="form-check form-switch fs-5 pt-sm-1">
                <input [(ngModel)]="configurations.showDashboardTodo" class="form-check-input" id="dashboardTodo"
                       name="dashboardTodo" type="checkbox">
            </div>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.DashboardTodoHint' | translate}}</p>
        </div>
    </div>
    <!--  -->


    <div class="row">
        <div class="col-md-12">
            <hr class="hr-sub-separator"/>
        </div>
    </div>

    <div class="row">
        <label class="col-sm-3 col-form-label"
               for="dashboardBanner">{{'preferences.DashboardBanner' | translate}}</label>
        <div class="col-sm-4">
            <div class="form-check form-switch fs-5 pt-sm-1">
                <input [(ngModel)]="configurations.showDashboardBanner" class="form-check-input" id="dashboardBanner"
                       name="dashboardBanner" type="checkbox">
            </div>
        </div>
        <div class="col-sm-5">
            <p class="form-control-plaintext text-muted small">{{'preferences.DashboardBannerHint' | translate}}</p>
        </div>
    </div>
    <!--  -->


    <div class="row">
        <div class="col-md-12">
            <hr class="hr-separator hr-last-separator"/>
        </div>
    </div>
    <div class="row">
        <div class="offset-sm-3 col-sm-9">
            <div class="d-flex flex-row flex-wrap">
                <button (click)="resetDefault()" class="btn btn-success ms-auto ms-sm-0 me-2 mb-2" type="button">
                    <i class='fa fa-circle-o-notch'></i> {{'preferences.ResetDefault' | translate}}
                </button>
                <button (click)="setAsDefault()" class="btn btn-primary mb-2" type="button">
                    <i class='fa fa-hdd-o'></i> {{'preferences.SetDefault' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

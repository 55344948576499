import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TestResultsService } from '../../../services/testResultsServices/test-results.service';

@Component({
    selector: 'app-remove-test-results-values',
    templateUrl: './remove-test-results-values.component.html',
    styleUrls: ['./remove-test-results-values.component.scss']
})
export class RemoveTestResultsValues {

    parameterId: number;
    testResultId: number;
    parameterName: string;

    constructor(
        private testResultsService: TestResultsService,
        public activeModal: NgbActiveModal,
        private toastr: ToastrService) { }

    closeModal(): void {
        this.activeModal.dismiss();
    }

    confirm() {
        this.testResultsService
            .getDeleteTestResultValueEndpoint<any>(this.testResultId, this.parameterId)
            .subscribe((res) => {
                this.toastr.success(`${this.parameterName} has been deleted successfully.`, 'Remove test value');
                this.activeModal.close(true);
            }, (err) => {
                this.toastr.error(`Error while deleting ${this.parameterName}.`, 'Remove test value');
                this.activeModal.close(false);
            });
    }

    decline() {
        this.activeModal.close(false);
    }
}

import { Injectable } from '@angular/core';
import { MouldTypeEndpointService } from './mould-type-endpoint.service';
import { MouldType } from '../models/mould-type';
import { MouldTypeList } from '../models/mould-type-list';
import { MouldTypeRequest } from '../models/mould-type-request.model';
import { Observable } from 'rxjs';
import { EntityType } from '../models/enums';

@Injectable({
  providedIn: 'root'
})
export class MouldTypeService {

  constructor(private mouldTypeEndPointService:MouldTypeEndpointService) { }
  getMouldTypes(page?: number, pageSize?: number,searchTerm?:string,sortColumn?:string,sortOrder?:string) {
    return this.mouldTypeEndPointService.getMouldTypesEndpoint<MouldType[]>(page, pageSize,searchTerm,sortColumn,sortOrder);
}
getMouldTypesList():Observable<MouldTypeList[]> {
    return this.mouldTypeEndPointService.getMouldTypesListEndpoint<MouldTypeList[]>();
}
createMouldType(mouldType: MouldTypeRequest) {
    return this.mouldTypeEndPointService.getNewMouldTypeEndpoint<MouldType>(mouldType);
}
updateMouldType(mouldType: MouldTypeRequest,id:number) {
    return this.mouldTypeEndPointService.getUpdateMouldTypeEndpoint<MouldType>(mouldType,id);
}
getMouldType(mouldTypeId?: string) {
    return this.mouldTypeEndPointService.getMouldTypeEndpoint<MouldType>(mouldTypeId);
}
deleteMouldType(mouldTypeOrMouldTypeId: string | MouldType): Observable<MouldType> {
    if (typeof mouldTypeOrMouldTypeId === 'string' || mouldTypeOrMouldTypeId instanceof String) {
        return this.mouldTypeEndPointService.getDeleteMouldTypeEndpoint<MouldType>(mouldTypeOrMouldTypeId as string);
    } else {
        if (mouldTypeOrMouldTypeId.id) {
            return this.deleteMouldType(mouldTypeOrMouldTypeId.id);
        } else {
            throw new Error("Invalid mouldTypeOrMouldTypeId"); // Add this line to handle the case where id does not exist
        }
    }
}
getMouldTypeDuplicateStatus(mouldTypeId:number,entityType:EntityType,factoryName?: string) {
    return this.mouldTypeEndPointService.getMouldTypeDuplicateStatusEndpoint<boolean>(mouldTypeId, entityType,factoryName);
}
}


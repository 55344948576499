<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Add Test Type</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/testTypes">
                <i class="ri-home-8-line"></i>
                Test Types
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Add Test Type</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>
        <form [formGroup]="testTypeForm" class="mb-25">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Name <span>*</span></label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Enter name</mat-label>
                            <input matInput formControlName="name" id="nameInput">
                            <mat-icon class="valid-icon" *ngIf="testTypeForm.get('name').valid && !testTypeForm.get('name').hasError('nameDuplicate')">check_circle</mat-icon>
                            <mat-icon class="invalid-icon" *ngIf="testTypeForm.get('name').hasError('nameDuplicate')">cancel</mat-icon>
                            <div class="mat-spinner" *ngIf="testTypeForm.get('name').pending && testTypeForm.get('name').touched">
                              <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
                            </div>

                        </mat-form-field>
                        <mat-error *ngIf="testTypeForm.get('name').hasError('nameDuplicate')">Test Type name already exists.</mat-error>
                        <mat-error *ngIf="testTypeForm.get('name').hasError('required') && testTypeForm.get('name').touched">Test Type name require.</mat-error>

                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Email <span>*</span></label>
                        <mat-form-field appearance="fill">
                            <i class="ri-mail-line"></i>
                            <mat-label>Enter email</mat-label>
                            <input matInput formControlName="email">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-map-pin-line"></i>
                            <mat-label>Enter address</mat-label>
                            <input matInput formControlName="address">
                        </mat-form-field>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Description</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter description</mat-label>
                            <input matInput formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Mobile</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter mobile</mat-label>
                            <input matInput formControlName="mobile">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter phone</mat-label>
                            <input matInput formControlName="phone">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Support</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter Support</mat-label>
                            <input matInput formControlName="support">
                        </mat-form-field>
                    </div>
                </div> -->


            </div>
        </form>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div style="text-align: right;" class="mb-3 example-buttons">
                    <button mat-flat-button class="tagus" (click)="save()" [disabled]="inProgress || isLoading || testTypeForm.invalid">
                        <span *ngIf="inProgress" class="spinner-border spinner-border-sm me-1" role="status"
                            aria-hidden="true"></span>
                        <span *ngIf="inProgress; else saveText">Saving...</span>
                        <ng-template #saveText>Save</ng-template>
                    </button>
                    <span class="mx-2"></span>
                    <button mat-flat-button class="gray text-primary" (click)="cancel()">Cancel</button>
               </div>

            </div>
        </div>

    </mat-card-content>
</mat-card>
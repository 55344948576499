import { Injectable } from '@angular/core';
import { TestParametersEndpointService } from './test-parameters-endpoint.service';
import { Observable } from 'rxjs';
import { TestParameterRequest } from 'src/app/models/TestParameterRequest.model';
import { TestParameter } from 'src/app/models/testParameter.model';
import { TestParameterUpdateRequest } from 'src/app/models/testParameterUpdateRequest.model';
import { Mandatory } from 'src/app/models/mandatory.model';
import { IntervalData } from 'src/app/models/interval-data.model';

@Injectable({
  providedIn: 'root'
})
export class TestParametersService {
  testParametersEndpointService : TestParametersEndpointService;

  constructor(private testParametersEndpoint : TestParametersEndpointService) { }

  getTestParameters (){

    return null; 
  }
  getColumnTypes (){ return null; }
  addTestParameter(columnData: any) { }

  removeTestParameterItem(testparameterId : number ,itemValue : string): Observable<any>
  {
    return this.testParametersEndpoint.getDeleteTestParameterItemEndpoint <any> (testparameterId,itemValue);
  }
  removeTestParameter(testParameterId :number) : Observable<any> {
    return this.testParametersEndpoint.getDeleteTestParametersEndpoint <any> (testParameterId);

   }


  getExistingTestParameters (testId : number) : Observable<TestParameter[]> {
    return this.testParametersEndpoint.getExistingTestParametersEndpoint<TestParameter[]>(testId);
    
  }

  fetchIntervalDataForUnit(unitName:string , testId: number) : Observable<IntervalData[]> {
    return this.testParametersEndpoint.fetchIntervalDataForUnitEndpoint<IntervalData[]>(unitName,testId);
    
  }

  addParameter(parameterData: TestParameterRequest): Observable<any> {
    // Replace '/parameters' with the correct endpoint for adding a parameter
   
    return this.testParametersEndpoint.getAddTestParametersEndpoint <any> (parameterData) ;
    //http.post(`${this.apiUrl}/parameters`, parameterData);
  }
  updateParameter(parameterData: TestParameterUpdateRequest): Observable<any> {
    // Replace '/parameters' with the correct endpoint for adding a parameter
   
    return this.testParametersEndpoint.getUpdateTestParametersEndpoint <any> (parameterData) ;
    //http.post(`${this.apiUrl}/parameters`, parameterData);
  }
  getupdateParameterMandatoryStatus(toggleMandatory : Mandatory): Observable<any> {

    return this.testParametersEndpoint.getupdateParameterMandatoryStatusEndpoint <any> (toggleMandatory) ;

                                                    }
}

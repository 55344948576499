<div *ngIf="!isSubView" class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Moulds</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Moulds</li>
    </ol>
</div>
<mat-card [ngClass]="{'px-0': isSubView}" class="mb-25 tagus-card">
    <mat-card-content>

        <mat-card-header class="d-block">
            <div class="row control-box justify-content-between">
                <div class="col-lg-5">
                    <div class="search-box">
                        <app-search-box (searchChange)="onSearchChanged($event)"
                                        placeholder="{{'search for mould' | translate}}"></app-search-box>
                    </div>
                </div>
                <div class="col-lg-7 text-end">
                    <button (click)="newMould()" *ngIf="canManageUsers" class="default-btn tagus" mat-flat-button>
                        <i class="ri-add-fill"></i>{{'Add Mould' | translate}}
                    </button>
                </div>
            </div>
        </mat-card-header>

        <div class="myTasksTable mat-elevation-z8 tagus-mat-table">
            <table [dataSource]="dataSource" mat-table>

                <!-- Task Name Column -->
                Assigned Column
                <!-- <ng-container matColumnDef="assigned">
                    <th mat-header-cell *matHeaderCellDef>Assigned</th>
                    <td mat-cell *matCellDef="let element">
                        <img [src]="element.assigned.img" matTooltip="{{element.assigned.name}}" class="rounded-circle"
                            alt="icon">
                    </td>
                </ng-container> -->
                <!-- Deadline Column -->
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Name' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>
                        <a (click)="detailMould(element.id)" class="text-primary cursor-pointer">{{ element.name }}</a>
                    </td>
                </ng-container>
                <!-- Deadline Column -->
                <ng-container matColumnDef="type">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Type' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.mouldTypeId}}</td>
                </ng-container>
                <!-- Deadline Column -->
                <ng-container matColumnDef="status">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Status' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>
                        <p [style.backgroundColor]="getBackgroundColor(element.statusValue)"
                           [style.color]="getStatusTextColor(element.statusValue)" style="border-radius: 12px;">{{element.statusValue}}</p>
                    </td>
                </ng-container>
                <!-- Action Column -->
                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell>Action</th>
                    <td *matCellDef="let element" mat-cell>
                        <button [matMenuTriggerFor]="actionMenu" class="position-relative dot-btn p-0 bg-transparent border-none"
                                mat-button
                                type="button">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">

                            <button (click)="editMould(element)" class="bg-transparent border-none position-relative" mat-menu-item
                                    type="button">
                                <i class="ri-edit-line"></i> Edit
                            </button>
                            <button (click)="deleteUser(element)" class="bg-transparent border-none position-relative" mat-menu-item
                                    type="button">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

            </table>
            <mat-progress-bar *ngIf="loadingIndicator" mode="indeterminate"></mat-progress-bar>
            <mat-paginator #paginator (page)="pageChanged($event)" [length]="totalRows" [pageIndex]="currentPage"
                           [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" aria-label="Select page">
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
<ng-template #editorModal let-modal>
    <div class="modal-header">
        <h4 *ngIf="!editingUserName" class="modal-title">
            <i class="fa fa-user-plus"></i> {{
'users.management.NewUser' |
            translate
            }}
        </h4>
        <h4 *ngIf="editingUserName" class="modal-title">
            <i class="fa fa-user-circle-o"></i>
            {{'users.management.EditUser' | translate:editingUserName}}
        </h4>
        <button (click)="modal.close()" class="btn-close fs-sm" tabindex="-1" title="Close" type="button"></button>
    </div>
    <div class="modal-body">
        <app-user-info #userEditor (afterOnInit)="setUserEditorComponent($event)"
                       [isGeneralEditor]="true"></app-user-info>
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { Observable, catchError } from 'rxjs';


@Injectable()

export class TestParametersEndpointService extends EndpointBase {

  get testParametersUrl() { return this.configurations.baseUrl + '/api/testParameter'; }
  constructor(private configurations: ConfigurationService, http: HttpClient,authService: AuthService) { 
    super(http, authService)
  }

  getExistingTestParametersEndpoint<T>(testId?: number): Observable<T> {

    const endpointUrl = `${this.testParametersUrl}/${testId}`;
    
    
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError<T, Observable<T>>(error => {
        console.log("what's the error ",error);
        return this.handleError(error, () => this.getExistingTestParametersEndpoint(testId));
      })
    );
  }

  fetchIntervalDataForUnitEndpoint<T>(UnitNumber : string, testId: number): Observable<T> {

    const endpointUrl = `${this.testParametersUrl}/${testId}/${UnitNumber}`;
    
    
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError<T, Observable<T>>(error => {
        console.log("what's the error ",error);
        return this.handleError(error, () => this.fetchIntervalDataForUnitEndpoint(UnitNumber,testId));
      })
    );
  }



  getupdateParameterMandatoryStatusEndpoint<T>( toggleMandatory ?: any): Observable<T> {
    
   // console.log ("for is the isMandatory number",isMandatory);

    const endpointUrl = `${this.testParametersUrl}/updateMandatoryValueForTestParameter`;

    return this.http.put<T>(endpointUrl, JSON.stringify(toggleMandatory),this.requestHeaders).pipe<T>(
      catchError<T, Observable<T>>(error => {
        return this.handleError(error, () => this.getupdateParameterMandatoryStatusEndpoint(toggleMandatory));
      }));
  }

  getDeleteTestParametersEndpoint<T> (testParameterId :number) : Observable <T> {
    const endpointUrl = `${this.testParametersUrl}/${testParameterId}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError<T, Observable<T>>(error => {
        return this.handleError(error, () => this.getDeleteTestParametersEndpoint(testParameterId));
      }));
  }
  getDeleteTestParameterItemEndpoint <T> (testParameterId:number, Item : string) :Observable <T> {
    console.log("got to end point ");
    console.log("test parameter id is :",testParameterId);
    console.log("item   is :",Item);
    
    const endpointUrl = `${this.testParametersUrl}/${testParameterId}/${Item}`;

    return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError<T, Observable<T>>(error => {
        return this.handleError(error, () => this.getDeleteTestParameterItemEndpoint(testParameterId,Item));
      }));

  }
  getAddTestParametersEndpoint <T>(testParametersObject?: any): Observable<T> {  
    return this.http.post<T>(this.testParametersUrl , JSON.stringify(testParametersObject), this.requestHeaders).pipe<T>(
      catchError<T, Observable<T>>(error => {
        return this.handleError(error, () => this.getAddTestParametersEndpoint(testParametersObject));
      }));
}

getUpdateTestParametersEndpoint <T>(testParametersObject?: any): Observable<T> {
 console.log('JSON.stringify(testParametersObject)',JSON.stringify(testParametersObject));
  return this.http.put<T>(this.testParametersUrl , JSON.stringify(testParametersObject), this.requestHeaders).pipe<T>(
    catchError<T, Observable<T>>(error => {
      return this.handleError(error, () => this.getAddTestParametersEndpoint(testParametersObject));
    }));
}


}

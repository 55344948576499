import { Injectable } from '@angular/core';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FileEndpointService extends EndpointBase {

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get downloadImageUrl() {
        return this.configurations.baseUrl + '/api/FileUpload/GetFileUrl';
    }

    get uploadImageUrl() {
        return this.configurations.baseUrl + '/api/FileUpload/UploadFile';

    }

    getDownloadImageEndpoint<T>(imageName?: string): Observable<T> {
        const endpointUrl = `${this.downloadImageUrl}?fileName=${imageName}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDownloadImageEndpoint(imageName));
            }));
    }

    uploadImage<T>(file: File): Observable<T> {
        const formData: FormData = new FormData();
        formData.append('files', file, file.name);

        let header = this.requestHeaders;
        header['responseType'] = 'json';

        return this.http.post<T>(this.uploadImageUrl, formData, { responseType : 'json' }).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.uploadImage(file));
            })
        );
    }

}


<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Roles</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Roles</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>

        <mat-card-header class="d-block">
            <div class="row control-box justify-content-between">
                <div class="col-lg-5">
                    <div class="search-box">
                        <app-search-box (searchChange)="onSearchChanged($event)"
                                        placeholder="{{'roles.management.Search' | translate}}"></app-search-box>
                    </div>
                </div>
                <div class="col-lg-7 text-end">
                    <button (click)="newRole()" *ngIf="canManageRoles" class="default-btn tagus" mat-flat-button>
                        <i class="ri-add-fill"></i>{{'roles.management.NewRole' | translate}}
                    </button>
                </div>
            </div>
        </mat-card-header>

        <div class="myTasksTable mat-elevation-z8 tagus-mat-table">
            <table [dataSource]="dataSource" mat-table>
                <!-- Deadline Column -->
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell>{{ 'roles.management.Name' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.name}}</td>
                </ng-container>
                <!-- Deadline Column -->
                <ng-container matColumnDef="description">
                    <th *matHeaderCellDef mat-header-cell>{{ 'roles.management.Description' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.description}}</td>
                </ng-container>

                <!-- Completion Column -->
                <ng-container matColumnDef="usersCount">
                    <th *matHeaderCellDef mat-header-cell>{{ 'roles.management.Users' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.usersCount}}</td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell>Action</th>
                    <td *matCellDef="let element" mat-cell>
                        <div *ngIf="canManageRoles">
                            <button [matMenuTriggerFor]="actionMenu" class="position-relative dot-btn p-0 bg-transparent border-none"
                                    mat-button
                                    type="button">
                                <i class="ri-more-fill"></i>
                            </button>
                            <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">

                                <button (click)="editRole(element)" class="bg-transparent border-none position-relative" mat-menu-item
                                        type="button">
                                    <i class="ri-edit-line"></i> Edit
                                </button>
                                <button (click)="deleteRole(element)" class="bg-transparent border-none position-relative" mat-menu-item
                                        type="button">
                                    <i class="ri-delete-bin-line"></i> Remove
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

            </table>
            <mat-progress-bar *ngIf="loadingIndicator" mode="indeterminate"></mat-progress-bar>
            <mat-paginator #paginator (page)="pageChanged($event)" [length]="totalRows" [pageIndex]="currentPage"
                           [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" aria-label="Select page">
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

<!-- Define other ng-templates for other columns -->
<ng-template #actionsTemplate let-i="index" let-row="row" let-value="value">
    <a (click)="editRole(row)" *ngIf="canManageRoles" class="btn btn-link text-link btn-sm" href="javascript:">
        <i aria-hidden="true" class="fa fa-pencil-square-o"></i> {{'roles.management.Edit' | translate}}
    </a>
    <a (click)="editRole(row)" *ngIf="!canManageRoles" class="btn btn-link text-link btn-sm" href="javascript:">
        <i aria-hidden="true" class="fa fa-eye"></i> {{'roles.management.Details' | translate}}
    </a>
    {{canManageRoles ? '|' : ''}}
    <a (click)="deleteRole(row)" *ngIf="canManageRoles" class="btn btn-link text-link btn-sm" href="javascript:">
        <i aria-hidden="true" class="fa fa-trash-o"></i> {{'roles.management.Delete' | translate}}
    </a>
</ng-template>

<ng-template #editorModal let-modal>
    <div class="modal-header">
        <h4 *ngIf="!canManageRoles" class="modal-title">
            <i class="fa fa-shield"></i> {{
'roles.management.RoleDetails' |
            translate:editingRoleName
            }}
        </h4>
        <h4 *ngIf="canManageRoles" class="modal-title">
            <i class="fa fa-shield"></i> {{
editingRoleName ?
            ('roles.management.EditRole' | translate:editingRoleName) : ('roles.management.NewRole' | translate)
            }}
        </h4>
        <button (click)="modal.close()" class="btn-close fs-sm" tabindex="-1" title="Close" type="button"></button>
    </div>
    <div class="modal-body">
        <app-role-editor #roleEditor (afterOnInit)="setRoleEditorComponent($event)"></app-role-editor>
    </div>
</ng-template>

<!-- Test-details.component.html -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <!-- Breadcrumb and page title -->
    <h5 class="mb-0">Test Details</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/tests">
                <i class="ri-home-8-line"></i>
                Tests
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">
            Test Details
        </li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card" *ngIf="testData != null">
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Details"><br>
              <!-- Test details content -->
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Name</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Name</mat-label>
                            <input matInput [value]="testData?.name" readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Test Type</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Test Type Name</mat-label>
                            <input matInput [value]="testData?.testTypeName" readonly>
                        </mat-form-field>
                    </div>
                </div>


                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Description</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Description</mat-label>
                            <input matInput [value]="testData?.description" readonly>
                        </mat-form-field>
                    </div>
                </div>
 <!--
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Factory</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Factory</mat-label>
                            <input matInput [value]="factoryData?.factoryName" readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Email</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-mail-line"></i>
                            <mat-label>Email</mat-label>
                            <input matInput [value]="factoryData?.email" readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Address</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-map-pin-line"></i>
                            <mat-label>Address</mat-label>
                            <input matInput [value]="factoryData?.address" readonly>
                        </mat-form-field>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Mobile</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Mobile</mat-label>
                            <input matInput [value]="factoryData?.mobile" readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Phone</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Phone</mat-label>
                            <input matInput [value]="factoryData?.phone" readonly>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Support</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Support</mat-label>
                            <input matInput [value]="factoryData?.support" readonly>
                        </mat-form-field>
                    </div>
                </div>
            -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- Remove the button, as this is a read-only details page -->
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                   <div style="text-align: right;" class="mb-3 example-buttons"> 
                        <button mat-flat-button class="default-btn tagus" (click)="goBack()">
                            Back
                        </button>
                    </div>
               </div> 
            </div>
            </mat-tab>
            <mat-tab label="Test Types"><br>
                <app-testTypeForTest-list [testTypeId]="testTypeId"></app-testTypeForTest-list>
            </mat-tab>
        </mat-tab-group>
      
    </mat-card-content>
</mat-card>

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CustomizerSettingsService } from '../customizer-settings/customizer-settings.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveStatusEvent, TestParameterContentComponent } from './test-parameter-content/test-parameter-content.component';
import { ResultDeleteConfirmationComponent } from './result-delete-confirmation/result-delete-confirmation.component';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { Observable, concat, forkJoin, from, of } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
@Component({
  selector: 'app-add-test-results',
  templateUrl: './add-test-results.component.html',
  styleUrls: ['./add-test-results.component.scss']
})
export class AddTestResultsComponent implements OnInit{

  @ViewChildren(TestParameterContentComponent) testParameterComponents: QueryList<TestParameterContentComponent>;
  lengthAfterDelete :number;
  firstTabValues: any = [];
  title: string;
  testId : number ;
  isLoading: boolean =false ;
  inProgress: boolean = false;
  tabStatus: boolean[] = [];
  disableInputTab: boolean = true;
  currentTestResultId: number | null = null;
  inc = 1;
  tabs: { id: number; title: string; content: string; isEditing: boolean; testResultId: number | null; }[] =
      [
          {
              id: 0,
              title: 'Results 1',
              content: 'Tab Content 1',
              isEditing: false,
              testResultId :null
          },
      ];
  activeTab: number = 0;

  constructor(
    public themeService: CustomizerSettingsService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private accountService: AccountService,
   ) {}
  ngOnInit() { 
    this.inProgress = false;
    this.isLoading = false;
    this.testId = +this.route.snapshot.queryParams['id'];
    this.tabs = [];
    this.lengthAfterDelete =0;
    this.activeTab = -1;
    this.addNewTab();

    this.tabStatus[this.testParameterComponents.length]=undefined;
     }


     updateFirstTabValues() {
      if (this.tabs.length > 0) {
        // Assuming you have a way to access the form values of the first tab
      
        this.firstTabValues = this.getFormValuesOfTab(this.tabs[0].id);
      }
    }

    getFormValuesOfTab(tabId: number): any {
      const component = this.testParameterComponents.toArray()[tabId];
      console.log ("component",component);
      if (component) {
        return component.testParametersForm.value; // Assuming testParametersForm is the name of the form in the child component
      }
      return null;
    
      // Logic to retrieve and return form values for the given tab ID
    }

     addNewTab() {
      this.lengthAfterDelete =this.lengthAfterDelete+1;
      const newTabId = this.tabs.length  ; // or another logic to generate unique ID
      let initialValues = [];

    
      // If adding second or later tab, copy values from the first tab
      if (newTabId > 0) {
        this.updateFirstTabValues();
        initialValues = this.firstTabValues;
      
      }
    
      const newTab = {
          id: newTabId,
          title: `Test Result ${this.lengthAfterDelete}`,
          content: `Tab Content ${newTabId}`,
          isEditing: false,
          testResultId: null,
          initialValues: initialValues
      };
      this.tabs.push(newTab);
      this.selectTab(newTabId); // Automatically select the new tab
  }
  // selectTab(index: number) {
  //   this.activeTab = index;
  //  this.currentTestResultId = this.tabs[index].testResultId;
  //                         }

  selectTab(index: number) {

    if (this.tabs && index >= 0 && index < this.tabs.length) {
      this.activeTab = index;
     
      this.currentTestResultId = this.tabs[index].testResultId;
    } else {
      console.error('Invalid tab index or tabs are undefined');
    }
  }


  deleteTestResult(index: number) {
    var id = this.tabs[index]?.id;
    this.activeTab = this.tabs.length - 2;
    console.log ("this.tabs.length",this.tabs.length);
    const modalRef = this.modalService.open(
      ResultDeleteConfirmationComponent,
        {
            size: 'lg',
            backdrop: 'static'
        }
    );
    modalRef.componentInstance.row = this.tabs[index].testResultId;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.deleteChanged.subscribe((data) => {
        if (data) {
          this.tabs.splice(index, 1);
            this.modalService.dismissAll();
           

        }
    })

    console.log ("this.tabs.length",this.tabs.length);
}

// saveAll() {
//   this.tabStatus = [];
//   console.log('Save All clicked');
//   this.inProgress =true;
//   this.isLoading =true;

//   // Iterate over all instances of TestParameterContentComponent and call saveOrUpdateTestResult() on each instance
//   // this.testParameterComponents.forEach((component,index) => {
//   //   component.saveResult.subscribe(success => {
    
//   //     this.tabStatus[index] = success;
//   //     console.log("end of treatement ");
//   //     console.log("this.tabStatus[" + index + "]", this.tabStatus[index]);

//   //   });
//   // });
//   const observables = this.testParameterComponents.toArray().map(component => 
//     component.saveResultSubject.asObservable().pipe(
//       take(1), // Take only the first emission
//       map(success => ({ success: success })),
//       catchError(error => of({ success: false, error: error }))
//     )
//   );

//   forkJoin(observables).subscribe({
//     next: (results) => {
//       // results is an array of objects with `success` (and possibly `error`) properties
//       results.forEach((result, index) => {
//         this.tabStatus[index] = result.success; // Update tabStatus based on each operation's success status
//         if (result.success) {
//           console.log(`Operation for tab ${index + 1} succeeded.`);
//         } else {
//           console.error(`Operation for tab ${index + 1} failed.`, result);
//         }
//       });

//       console.log("Final tab statuses:", this.tabStatus);
//       // At this point, all operations have completed, and tabStatus is fully populated
//       this.inProgress = false;
//       this.isLoading = false;
//     },
//     error: (error) => {
//       console.error("An error occurred during save operations:", error);
//       // Handle any unexpected errors from forkJoin itself
//       this.inProgress = false;
//       this.isLoading = false;
//     }
//   });
// }
saveAll() {
  console.log("Got to save all");
  this.inProgress = true;
  this.isLoading = true;

  // Reset tabStatus for all operations
  this.tabStatus = new Array(this.testParameterComponents.length).fill(undefined);

  const operations = this.testParameterComponents.toArray().map((component, index) =>
  component.saveOrUpdateTestResult().pipe(
    tap(success => console.log(`Operation for component ${index + 1} completed with status: ${success}`)), // Log the success status immediately
    map(success => ({ success, index })) // Then map the success status to include the index for further processing
  )
);

  // Wait for all operations to complete
  forkJoin(operations).subscribe({
    next: (results) => {
      // Process each result with its index
      results.forEach(({ success, index }) => {
        this.tabStatus[index] = success; // Update the specific index with the operation's success status
        console.log(`Operation for tab ${index + 1}: ${success ? 'Succeeded' : 'Failed'}`);
      });
      this.inProgress = false;
      this.isLoading = false;
      console.log("Final tab statuses:", this.tabStatus);
    },
    error: (error) => {
      console.error("An error occurred during the operations.", error);
      this.inProgress = false;
      this.isLoading = false;
    },
    complete: () => console.log("All operations completed.")
  });
}

handleSaveStatus(event: SaveStatusEvent) {
  this.tabStatus[event.index] = event.success; // Update the specific index based on the event
  if (event.success) {
    console.log(`Save operation for tab ${event.index + 1} was successful.`);
  } else {
    console.error(`Save operation for tab ${event.index + 1} failed.`);
  }
}
get canManageTests() {
  return this.accountService.userHasPermission(
      Permission.manageTestsPermission
  );
}

get canManageUsers() {
  return this.accountService.userHasPermission(Permission.manageConfigurationsPermission);
}

}

import { cl } from "@fullcalendar/core/internal-common";

export class UnitModel {
    id: number;
    descriptionValues: DescriptionValueViewModel[];
    descriptionViewModels: DescriptionViewModel[];
    name: string;
    rfId1: string;
    rfId2: string;
    createdBy: string;
    updatedBy: string;
    updatedDate: Date;
    createdDate: Date;
    progress: number;
    mouldId: number;
}
export class DescriptionValueViewModel {
    id: number;
    value: string;
    descriptionId: number;
    unitId: number;
    createdBy: string;
    description: DescriptionsListResponseModel;
}
export class DescriptionsListResponseModel {
    id: number;
    name: string;
    inputType: InputType;
    inputTypeValues: InputTypeValueListResponseModel[];
}
export class InputTypeValueListResponseModel {
    id: number;
    value: string;
}
export class InputType {
    id: number;
    name: string;
    value: string;
}
export class DescriptionValue {
    model: {
        id: number;
        value: string;
        isVisible: string;
        descriptionId: number;
        unitId: number;
        inputType: string;
    }
}
export interface DescriptionViewModel {
    id: number;
    name: string;
    value: string;
    inputTypeId: number;
    categoryId: number;
    inputTypeName: string;
    inputTypeValues: InputTypeValueListResponseModel[];
}

import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DescriptionValueService } from 'src/app/services/description-value.service';
import { UnitService } from 'src/app/services/unit.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { FileService } from 'src/app/services/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { DescriptionValue, DescriptionValueViewModel, DescriptionViewModel, UnitModel } from 'src/app/models/description-value-model';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import { DatePipe } from '@angular/common';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LoadingService } from 'src/app/services/loader/loading.service';
import { MatSelect } from '@angular/material/select';
import { UnitRequest } from 'src/app/models/unit-request.model';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { DescriptionService } from 'src/app/services/description.service';
import { MatDialog } from '@angular/material/dialog';
import { DropdownItemComponent } from '../../unit-description/dropdown-item/dropdown-item.component';
import { FileEndpointService } from 'src/app/services/file-endpoint.service';
import { WebcamImage, WebcamInitError, WebcamComponent as NgxWebcamComponent } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { DeleteUnitComponent } from '../delete-unit/delete-unit.component';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { DeleteDescriptionValueComponent } from '../delete-description-value/delete-description-value.component';
import {
    NgxMatDateFormats,
    NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { MouldService } from '../../../services/mouldServices/mould.service';
import { Mould } from '../../../models/mould.model';
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'DD-MM-YYYY HH:mm'
    },
    display: {
        dateInput: 'DD-MM-YYYY HH:mm',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
@Component({
    selector: 'app-view-unit',
    templateUrl: './view-unit.component.html',
    styleUrls: ['./view-unit.component.scss'],
    providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }]
})
export class ViewUnitComponent implements OnInit {
    descriptionValue: UnitModel = new UnitModel();
    descriptionWithoutValues: DescriptionViewModel[] = [];
    unitForm: FormGroup;
    unitForm1: FormGroup;
    originalUnitName: string;
    isEditing: boolean = false;
    unitRequest: DescriptionValue = new DescriptionValue();
    unitId: number = 0;
    editingIndex: number = -1;
    @ViewChild('inputElement', { static: false }) inputElement!: ElementRef;
    @ViewChild('inputElement1') inputElement1: ElementRef;

    @ViewChild('picker') datePicker: any; // Reference to the MatDatepicker
    @ViewChild('timepicker') timepicker: any; // Reference to the MatDatepicker
    @ViewChild(MatSlideToggle) toggle: MatSlideToggle;
    //@ViewChild('selectElement') selectElement: MatSelect;
    @ViewChild('selectElement2') selectElement: MatSelect;

    public valueToSave: string;
    public colorChange: any[] = [];
    //@ViewChild('picker') picker: any;
    popName = 'Signature';


    public date: moment.Moment;
    public disabled = false;
    public showSpinners = true;
    public showSeconds = false;
    public touchUi = false;
    public enableMeridian = false;
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    imageUrl: string;
    editingIndex1: number;
    inputTypeValue: any[] = [];
    selectedFiles: any;
    public webcamImage: WebcamImage = null;
    isOpenCamera = false;
    controlName: any;
    public allowCameraSwitch = true;
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
    };
    trigger: Subject<void> = new Subject<void>();
    public errors: WebcamInitError[] = [];
    @ViewChild(SignaturePadComponent, { static: true }) signaturePad!: SignaturePadComponent;
    signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
        minWidth: 5,
        canvasWidth: 795,
        canvasHeight: 500,
        backgroundColor: 'white',
    };
    @ViewChild('signaturePad', { static: true }) signaturePadElement!: ElementRef;

    private originalCompositeOperation: any;
    signatureImage: string | ArrayBuffer | null = null;
    signatureSelectedValue: any;
    descriptionId: number;
    indexvalue: number;

    isSelectOpen: boolean;
    isInputElementOpen: boolean;
    isToggleOpen: boolean;
    useDefault: boolean;

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    constructor(
        private descriptionValueService: DescriptionValueService,
        private accountService: AccountService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private modalService: NgbModal,
        private fileUploadService: FileService,
        private renderer: Renderer2,
        private datePipe: DatePipe,
        public loadingService: LoadingService,
        private unitService: UnitService,
        private descriptionService: DescriptionService,
        public dialog: MatDialog,
        private fileEndpoint: FileEndpointService,
        private mouldStatusService: MouldService,
        private cd: ChangeDetectorRef

    ) { }

    ngOnInit(): void {
        this.loadingService.setLoading(true);
        this.route.queryParams.subscribe((params) => {
            const id = params['id'];
            if (id) {
                this.unitId = Number(id);
            }
        });
        this.getAllUnitDetails();


    }
    getAllUnitDetails() {
        this.descriptionValueService.getDescriptionValue(this.unitId).subscribe((data) => {
            if (data) {
                this.loadingService.setLoading(false);
                this.descriptionValue = data;
                this.descriptionWithoutValues = data.descriptionViewModels;
                const promises: Promise<void>[] = [];

                const formControls = {};
                const formControls1 = {};

                this.descriptionValue.descriptionValues.forEach((item, index) => {

                    //Sat Values here
                    switch (item.description.inputType.name) {
                        case 'ImageUpload':
                        case 'Handwriting':
                        case 'Signature':
                            const promise = this.getImageUrlPromise(item.value).then((imageUrl) => {
                                this.loadingService.setLoading(false);
                                item.value = imageUrl;
                            }).catch((error) => {
                                this.loadingService.setLoading(false);
                                console.error('Error fetching image URL:', error);
                            });
                            promises.push(promise);
                            break;

                        default:
                            break;
                    }

                    switch (item.description.inputType.name) {
                        case 'DropDown':
                            ;
                            formControls[`value${index}`] = Number([item.value]); // Set initial value for the dropdown
                            break;
                        case 'Date':
                            //const dateValue = this.parseISOStringToDate(item.value);
                            let date = new Date(item.value);
                            let date1 = moment(date).format("YYYY-MM-DD HH:mm ");
                            let date111 = new Date(date1);

                            let date11 = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
                            formControls[`value${index}`] = [date111];
                            break;
                        case 'ToggleButton':
                        case 'PartialValidation':
                            if (item.value == 'true') {
                                formControls[`value${index}`] = [true];
                            } else {
                                formControls[`value${index}`] = [false];
                            }
                            break;
                        default:
                            formControls[`value${index}`] = [item.value];
                            break;
                    }
                });

                Promise.all(promises).then(() => {
                    this.unitForm = this.formBuilder.group(formControls);
                });

                this.unitForm = this.formBuilder.group(formControls);
                this.descriptionWithoutValues.forEach((item, index) => {

                    //Sat Values here
                    switch (item.inputTypeName) {
                        case 'ImageUpload':
                        case 'Handwriting':
                        case 'Signature':
                            const promise = this.getImageUrlPromise(item.value).then((imageUrl) => {
                                this.loadingService.setLoading(false);
                                item.value = imageUrl;
                            }).catch((error) => {
                                this.loadingService.setLoading(false);
                                console.error('Error fetching image URL:', error);
                            });
                            promises.push(promise);
                            break;

                        default:
                            break;
                    }

                    switch (item.inputTypeName) {
                        case 'DropDown':
                            ;
                            formControls1[`value${index}`] = Number([item.value]); // Set initial value for the dropdown
                            break;
                        default:
                            formControls1[`value${index}`] = [];
                            break;
                    }
                });
                Promise.all(promises).then(() => {
                    this.unitForm1 = this.formBuilder.group(formControls1);
                });

                this.unitForm1 = this.formBuilder.group(formControls1);
            }
        });
    }
    getLiveTime(): void {
        this.unitForm1.get('value' + 0).setValue(new Date());
    }
    parseISOStringToDate(dateString: string): Date {
        return new Date(dateString);
    }
    formatDate(date: Date): string {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    }
    getImageUrlPromise(value: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.fileUploadService.getImageUrl(value).subscribe(
                (imageUrl: string) => {
                    if (imageUrl) {
                        resolve(imageUrl);
                    } else {
                        reject('Image URL not found');
                    }
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    editingField: string | null = null;

    startEditing(field: string) {
        this.isEditing = true;
        this.editingField = field;
    }
    finishUnitEditing(field) {
        if (this.isEditing) {
            this.isEditing = false;
            this.editingField = field;

            // Check if the field being edited is one of the fields that require an API update
            if (this.requiresApiUpdate()) {
                this.updateUnitInfo();
            }
        }
    }
    requiresApiUpdate(): boolean {
        // Add logic to determine if an API update is needed based on the edited field
        return ['name', 'rfId1', 'rfId2'].includes(this.editingField);
    }

    updateUnitInfo() {
        const updatedUnit: UnitRequest = {
            model: {
                id: this.unitId,
                name: this.editingField === 'name' ? this.descriptionValue.name : this.descriptionValue.name,
                rfId1: this.editingField === 'rfId1' ? this.descriptionValue.rfId1 : this.descriptionValue.rfId1,
                rfId2: this.editingField === 'rfId2' ? this.descriptionValue.rfId2 : this.descriptionValue.rfId2,
                progress: this.descriptionValue.progress,
                mouldId: this.descriptionValue.mouldId
            }
        };

        this.unitService.updateUnit(updatedUnit, this.unitId).subscribe(
            (response) => {
                // Handle the response from the API
                this.toastr.success('Unit information updated successfully.', 'Update Unit');
            },
            (error) => {
                // Handle API error
                console.error('Error updating unit information:', error);
                this.toastr.error('Error updating unit information. Please try again.', 'Update Unit');
            }
        );
    }

    updateUnitRfid(rfid1: string, rfid2: string, name: string) {
        const updatedUnit: UnitRequest = {
            model: {
                id: this.unitId,
                name: name != null ? name : this.descriptionValue.name,
                rfId1: rfid1 != null ? rfid1 : this.descriptionValue.rfId1,
                rfId2: rfid2 != null ? rfid2 : this.descriptionValue.rfId2,
                progress: this.descriptionValue.progress,
                mouldId: this.descriptionValue.mouldId
            }
        };

        this.unitService.updateUnit(updatedUnit, this.unitId).subscribe(
            (response) => {
                this.getAllUnitDetails();
            },
            (error) => {
                // Handle API error
                console.error('Error updating unit information:', error);
            }
        );
    }

    getFieldData(field: string): string {
        switch (field) {
            case 'name':
                return this.descriptionValue.name;
            case 'rfId1':
                return this.descriptionValue.rfId1;
            case 'rfId2':
                return this.descriptionValue.rfId2;
            default:
                return '';
        }
    }

    getFieldPlaceholder(field: string): string {
        switch (field) {
            case 'name':
                return 'Name';
            case 'rfId1':
                return 'RFID1';
            case 'rfId2':
                return 'RFID2';
            default:
                return '';
        }
    }

    finishEditing() {
        this.isEditing = false;
        this.editingField = null;
    }

    getDropdownOptions(index: number): string[] {
        const commaSeparatedValues: string = this.unitForm.get('value' + index)?.value || '';
        return commaSeparatedValues.split(',').map(value => value.trim());
    }

    startEditingUnitName(index: number) {
        this.isEditing = true;
        this.editingIndex = index;
    }

    startEditingUnitValue(index: number, inputElement: HTMLInputElement) {
        this.isEditing = true;
        this.editingIndex = index;

        setTimeout(() => {
            if (this.datePicker) {
                this.datePicker.open();
            } else if (this.timepicker) {
                this.timepicker.open();
            } else if (this.inputElement && this.inputElement.nativeElement) {
                this.inputElement.nativeElement.focus();
            } else if (this.toggle) {
                this.isToggleOpen = true;
                this.toggle.focus();
            } else if (this.selectElement) {
                this.openOrCloseMatSelect();
            }
        }, 0);
    }


    openOrCloseMatSelect() {
        if (this.selectElement && !this.selectElement.panelOpen) {
            this.isSelectOpen = true;
            this.selectElement.open();
        }
    }

    closeMatSelect() {
        if (this.selectElement && this.selectElement.panelOpen) {
            this.isSelectOpen = false;
            this.selectElement.close();
        }
    }

    startEditingUnitValue1(index: number, inputElement1: HTMLInputElement) {
        this.isEditing = true;
        this.editingIndex1 = index;

        // Set focus to the input element or toggle based on the condition
        setTimeout(() => {
            if (this.datePicker) {
                this.datePicker.open();
            } else if (this.timepicker) {
                this.timepicker.open();
            } else if (inputElement1) {
                this.renderer.selectRootElement(this.inputElement1).focus();
            } else if (this.toggle) {
                this.toggle.focus();
            }
        }, 0);

    }
    finishEditingUnitValue() {
        //if (!this.isSelectOpen) {
        this.isEditing = false;
        this.editingIndex = -1;
        //}

    }
    private isClickOutside(event: Event): boolean {
        const targetElement = event.target as HTMLElement;

        // Check if the click occurred outside of the select element and input element
        const isOutsideSelect = this.selectElement?._elementRef?.nativeElement?.contains(targetElement);
        const isOutsideInput = this.inputElement?.nativeElement?.contains(targetElement);

        return !(isOutsideSelect || isOutsideInput);
    }


    @HostListener('document:click', ['$event'])
    handleDocumentClick(event: Event) {
        if (this.isEditing && (this.selectElement || this.toggle || this.inputElement)) {
            if (this.isEditing && this.selectElement && this.isClickOutside(event) && this.isSelectOpen) {
                this.closeMatSelect();
                this.isSelectOpen = false;
                this.finishEditingUnitValue();
            }
            if (this.isEditing && this.toggle && this.isToggleOpen) {
                this.finishEditingUnitValue();
                this.isToggleOpen = false;
            }
            setTimeout(() => {
                if (this.datePicker) {
                    this.datePicker.open();
                } else if (this.toggle) {
                    this.isToggleOpen = true;
                    this.toggle.focus();
                } else if (this.selectElement) {
                    this.openOrCloseMatSelect();
                }
            }, 0);

        }
    }

    onDateSelected(event: any, index: number, descriptionValId: number, descriptionId: number) {
        console.log('Date selected:', event);
        if (this.isEditing && (this.datePicker || this.timepicker)) {
            this.unitForm.get(`value${index}`).setValue(event);

            this.updateUnitValue(index, descriptionValId, descriptionId, null);
        }
    }

    onDropdownValueAdded(index: number, descriptionId: number) {
        let existingObjectIndex = -1;
        if (existingObjectIndex === -1) {
            this.inputTypeValue.push({ id: -1, descriptionId: descriptionId, value: "" });
        }
        const arryObject = this.inputTypeValue;
        const dialogRef = this.dialog.open(DropdownItemComponent, {
            width: '550px',
            data: { arryObject }
        });
        dialogRef.componentInstance.deleteItemEvent.subscribe((param: any) => {
            // this.deleteInputTypeValue(param);
        });
        dialogRef.componentInstance.saveItemEvent.subscribe((param: any) => {
            param.forEach(element => {
                // if(element.id == 0){
                const viewModel: any = {
                    id: element.id,
                    descriptionId: descriptionId,
                    value: element.value
                }
                this.createInputTypeValue(index, viewModel);
                //}
            });
        });

    }

    createInputTypeValue(index: number, viewModel: any) {
        this.descriptionService.createInputTypeValue(viewModel).subscribe(
            (result: any) => {
                debugger;
                this.unitForm1.get(`value${index}`).setValue(result.inputTypeId);
                this.addDescriptionValue(index, viewModel.descriptionId);
            },
            (error) => {
                console.error('Error creating inputtype value:', error);
            }
        );
    }

    addOnDateSelected(event: any, index: number, descriptionId: number) {
        debugger
        console.log('Date selected:', event);
        if (this.isEditing && (this.datePicker || this.timepicker)) {
            this.unitForm1.get(`value${index}`).setValue(event);

            this.addDescriptionValue(index, descriptionId);
        }
    }

    getIconClassName(selectedInputType: any): string {
        switch (selectedInputType) {

            case 'Name':
                return 'ri-character-recognition-line';
            case 'UnitReference':
                return 'ri-settings-6-line';
            case 'Text':
                return 'ri-file-text-line';
            case 'Number':
                return 'ri-hashtag';
            case 'DropDown':
                return 'ri-file-list-line';
            case 'ImageUpload':
                return 'ri-chat-upload-line';
            case 'Signature':
                return 'ri-sketching';
            case 'PartialValidation':
                return 'ri-pass-valid-line';
            case 'Handwriting':
                return 'ri-hand-coin-line';
            case 'Hall':
                return 'ri-door-lock-box-line';
            case 'MouldNumber':
                return 'ri-list-ordered-2';
            case 'Date':
                return 'ri-calendar-line';
            case 'ToggleButton':
                return 'ri-toggle-line';
            default:
                return '';
        }
    }

    getProgressBarColor(percentage: number): string {
        if (percentage < 30) {
            return 'warn';
        } else if (percentage < 70) {
            return 'primary';
        } else {
            return 'accent';
        }
    }

    getColor(controllName): string {
        let obj = this.colorChange.find(x => x.control == controllName)
        if (obj != undefined) {
            return obj.color
        } else return 'red'
    }

    updateUnitValue(index: number, descriptionValId: number, descriptionId: number, name: string, inputType?: string, isNew?: boolean) {

        this.isEditing = false;
        this.isSelectOpen = false;

        let updatedDescriptionValueName: string = "";
        this.editingIndex = -1;
        if (this.unitForm.valid) {
            if (isNew) {
                if (inputType == 'DropDown') {
                    updatedDescriptionValueName = this.unitForm1.get(`value${index}`).value;
                    descriptionValId = 0;
                }
            } else {
                updatedDescriptionValueName = this.unitForm.get(`value${index}`).value;
            }

            let updatedValueName = "";
            updatedValueName = updatedDescriptionValueName;
            let selectedValue: string = '';
            if (isNew) {
                if (inputType == 'DropDown') {
                    selectedValue = this.unitForm1.get(`value${index}`).value;
                }
            } else {
                selectedValue = this.unitForm.get(`value${index}`).value;

            }

            this.unitRequest.model = { ...this.unitForm.value };
            this.unitRequest.model.value = selectedValue.toString();
            this.unitRequest.model.id = descriptionValId;
            this.unitRequest.model.unitId = this.unitId;
            this.unitRequest.model.descriptionId = descriptionId;
            this.unitRequest.model.inputType = inputType;
            let currentValue: string = '';

            if (isNew) {
                if (inputType == 'DropDown') {
                    selectedValue = this.unitForm1.get(`value${index}`).value;
                    currentValue = this.unitForm1.get(`value${index}`).value.toString();
                    let dropdownModel1;
                    this.descriptionWithoutValues.map(m => m.inputTypeValues).forEach(itemArray => {
                        itemArray.forEach(item => {
                            if (item.id === Number(this.unitRequest.model.value)) {
                                dropdownModel1 = item;
                                this.unitRequest.model.value = dropdownModel1.id.toString();
                                updatedValueName = dropdownModel1.value;
                            }
                        });
                    });
                }

                this.descriptionValueService.updateDescriptionValue(this.unitRequest, this.descriptionValue?.id).subscribe((response) => {
                    if (response) {
                        this.getAllUnitDetails();
                        this.toastr.success(`Description Value ${updatedValueName} has been updated successfully.`, 'Update Description Value');
                    }
                });
            } else {
                let descriptionValue = this.descriptionValue.descriptionValues.find(m => m.id == descriptionValId);

                if (descriptionValue.description.inputType.name === 'DropDown') {

                    const dropdownModel = descriptionValue.description.inputTypeValues.find(item => item.id === Number(this.unitRequest.model.value));
                    this.unitRequest.model.value = dropdownModel.id.toString();
                    updatedValueName = dropdownModel.value;

                    this.descriptionValue.descriptionValues.find(m => m.id == descriptionValId).value = descriptionValue.description.inputTypeValues.find(item => item.id === Number(this.unitRequest.model.value))?.id.toString();
                }
                if (descriptionValue.description.inputType.name === 'Date') {
                    let formattedDate = this.datePipe.transform(this.unitForm.get(`value${index}`).value, 'yyyy-MM-dd HH:mm');
                    formattedDate = moment(formattedDate).format("YYYY-MM-DD HH:mm a");
                    updatedValueName = formattedDate;
                }
                if (descriptionValue.description.inputType.name === 'Time') {
                    this.unitRequest.model.value = this.unitForm.get(`value${index}`).value;
                }

                // Handle the specific case of mat-slide-toggle
                currentValue = this.unitForm.get(`value${index}`).value.toString();

                if (descriptionValue.description.inputType.name === 'ToggleButton') {
                    debugger

                    this.unitRequest.model.value = selectedValue.toString();
                }
                if (descriptionValue.description.inputType.name === 'Date') {

                    const formattedDate = new Date(this.datePipe.transform(this.unitForm.get(`value${index}`).value, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSZ'));
                    this.unitRequest.model.value = moment(formattedDate).format("YYYY-MM-DD[T]HH:mm:ss.SSS");
                }
                if (descriptionValue.description.inputType.name === 'Time') {

                }

                if (updatedDescriptionValueName !== descriptionValue.value || currentValue !== this.descriptionValue.descriptionValues[index].value) {
                    this.descriptionValueService.updateDescriptionValue(this.unitRequest, this.descriptionValue.id).subscribe((response) => {
                        if (response) {
                            this.getAllUnitDetails();
                            this.toastr.success(`Description Value ${updatedValueName} has been updated successfully.`, 'Update Description Value');
                        }
                    });

                    if (name == 'RFID 1') {
                        // Update unit rfid 1 and rfid2
                        this.updateUnitRfid(updatedValueName, null, null);
                    }
                    else if (name == 'RFID 2') {
                        // Update unit rfid 1 and rfid2
                        this.updateUnitRfid(null, updatedValueName, null);
                    }
                    else if (name == 'Unit Reference') {
                        // Update unit rfid 1 and rfid2
                        this.updateUnitRfid(null, null, updatedValueName);
                    }
                }
            }

            this.isEditing = false;
            this.editingIndex = -1;
        }
    }

    addDescriptionValue(index: number, descriptionId: number) {
        debugger
        this.isEditing = false;
        this.editingIndex = -1;
        if (this.unitForm1.valid) {

            const selectedValue = this.unitForm1.get(`value${index}`).value;

            this.unitRequest.model = { ...this.unitForm1.value };
            this.saveDescriptionValue(selectedValue, descriptionId);
        }
    }

    addSignatureDescriptionValue(index: number, descriptionId: number) {
        this.isEditing = false;
        this.editingIndex = -1;
        if (this.unitForm.valid) {
            const selectedValue = this.unitForm.get(`value${index}`).value;

            this.unitRequest.model = { ...this.unitForm.value };
            this.saveDescriptionValue(selectedValue, descriptionId);
        }
    }

    private saveDescriptionValue(selectedValue: string, descriptionId: number) {
        this.unitRequest.model.value = selectedValue.toString();
        this.unitRequest.model.id = descriptionId;
        this.unitRequest.model.unitId = this.unitId;
        this.unitRequest.model.descriptionId = descriptionId;
        const descriptionValueModel = {
            Value: this.unitRequest.model.value,
            UnitId: this.unitRequest.model.unitId,
            descriptionId: this.unitRequest.model.descriptionId,
        }
        this.descriptionValueService.createDescriptionValue(descriptionValueModel).subscribe((response) => {
            if (response) {
                this.getAllUnitDetails();
                this.toastr.success(`Description Value  has been added successfully.`, 'Added Description Value');
            }
        });
    }

    selectFile(event, descriptionId: number, index: number) {
        debugger
        this.selectedFiles = event.target.files[0] as File;
        this.fileEndpoint.uploadImage(this.selectedFiles)
            .subscribe((response: any) => {
                this.unitForm1.get(`value${index}`).setValue(response.value);
                this.addDescriptionValue(index, descriptionId);
            },
                (error) => {
                    console.error('', error)
                });

    }

    openCamera(descriptionId: number, index: number) {
        this.indexvalue = index;
        this.descriptionId = descriptionId,
            this.controlName = null;
        this.isOpenCamera = true;
        this.webcamImage = null;

        document.getElementById('cameraOpen')?.click();
    }

    handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;

    }

    nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    cameraWasSwitched(deviceId: string): void {
        this.deviceId = deviceId;
    }

    handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    takePicture(): void {
        this.trigger.next();
    }

    closeCamera() {
        this.isOpenCamera = false;
    }

    drawComplete(event: MouseEvent | Touch) {
    }

    drawStart(event: MouseEvent | Touch) {
    }

    saveSignatureAsImage(): void {
        debugger
        if (this.signaturePad.isEmpty()) {
            return;
        }

        const canvas = this.signaturePad.getCanvas();
        const imageData = canvas.toDataURL('image/jpg');
        this.signatureImage = imageData;

        function dataURItoBlob(dataURI: string): Blob {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        }

        const blob = dataURItoBlob(imageData);
        const file = new File([blob], 'signature.jpg', { type: 'image/jpeg' });
        this.fileEndpoint.uploadImage(file)
            .subscribe((response: any) => {
                this.signatureSelectedValue = response.value;
                // this.inputDescriptionArray[0].get(this.UnitRefernceControlName)
                //     .setValue(response.value)
                this.unitForm1.get(`value${this.indexvalue}`).setValue(response.value);
                this.addDescriptionValue(this.indexvalue, this.descriptionId);
            },
                (error) => {

                    console.error('', error)
                });


    }

    savePicture() {
        debugger
        this.isOpenCamera = false;
        function dataURItoBlob(dataURI: string): Blob {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        }

        const blob = dataURItoBlob(this.webcamImage.imageAsDataUrl);
        const file = new File([blob], 'picture.jpg', { type: 'image/jpeg' });

        this.fileEndpoint.uploadImage(file)
            .subscribe((response: any) => {
                this.unitForm1.get(`value${this.indexvalue}`).setValue(response.value);
                this.addDescriptionValue(this.indexvalue, this.descriptionId);
            },
                (error) => {

                    console.error('', error)
                });
    }

    signaturePadPopup(descriptionId: number, index: number, inputTypeName: string) {
        this.signaturePad.clear()
        // this.popName = popName;
        this.indexvalue = index;
        this.descriptionId = descriptionId;
        document.getElementById('menuModal')?.click();

        if (inputTypeName == "Handwriting") {
            this.setBackgroundImage()
        }
    }

    setBackgroundImage() {
        const canvas = this.signaturePad.getCanvas();
        const context = canvas.getContext('2d');

        // Replace 'your-image-url.jpg' with the actual URL or path to your image
        const imageUrl = 'assets\\img\\error.png';

        const img = new Image();
       // img.src = imageUrl;

        this.mouldStatusService.getMould(this.descriptionValue.mouldId).subscribe(
            (res: Mould) => {

                switch (res.mouldTypeName) {
                    case 'CW':
                        img.src = 'assets\\img\\covercheck_cw.jpg';
                        break;
                    case 'AW':
                        img.src = 'assets\\img\\covercheck_aw.jpg';
                        break;
                    case 'AR':
                        img.src = 'assets\\img\\covercheck_ar.jpg';
                        break;
                }
            },
            (err) => {
                console.log(err);
            }
        )



        // Draw the image on the canvas as a background
        img.onload = () => {
            // Set composite operation to "destination-over" to draw new content behind the existing content
            context?.drawImage(img, 0, 0, canvas.width, canvas.height);
            context.globalCompositeOperation = this.originalCompositeOperation;
        };
    }

    openImagePreview(imageUrl: string, index: number, descriptionValId: number, descriptionId: number) {
        const modalRef = this.modalService.open(ImagePreviewComponent, {
            size: 'lg',
            backdrop: 'static'
        });

        this.mouldStatusService.getMould(this.descriptionValue.mouldId).subscribe(
            (res: Mould) => {
                modalRef.componentInstance.mouldName = res.mouldTypeName;
            },
            (err) => {
                console.log(err);
            }
        )

        modalRef.componentInstance.imageUrl = imageUrl;
        modalRef.componentInstance.inputTypeName = this.descriptionValue.descriptionValues[index].description.inputType.name;
        modalRef.componentInstance.imageUrlChange.subscribe((newImageUrl: string) => {
            // Update the imageUrl with the newly uploaded image URL
            this.saveImage(newImageUrl, index, descriptionValId, descriptionId);
        });
        modalRef.componentInstance.signatureChange.subscribe((newImageUrl: string) => {
            this.saveImage(newImageUrl, index, descriptionValId, descriptionId);

        });
        modalRef.result.catch((error) => {
            // Handle errors or user cancellation
            console.error('Image upload error:', error);
        });
    }

    saveImage(newImageUrl: string, index: number, descriptionValId: number, descriptionId: number) {

        this.imageUrl = newImageUrl;
        this.modalService.dismissAll();

        const itemToUpdate = this.descriptionValue.descriptionValues[index];

        switch (itemToUpdate.description.inputType.name) {
            case 'ImageUpload':
            case 'Handwriting':
            case 'Signature':
                this.getImageUrlPromise(this.imageUrl).then((updatedImageUrl) => {
                    this.loadingService.setLoading(false);
                    itemToUpdate.value = updatedImageUrl;

                    this.unitRequest.model = { ...this.unitForm.value };
                    this.unitRequest.model.value = this.imageUrl;
                    this.unitRequest.model.id = descriptionValId;
                    this.unitRequest.model.unitId = this.unitId;
                    this.unitRequest.model.descriptionId = descriptionId;

                    if (this.imageUrl) {
                        this.descriptionValueService.updateDescriptionValue(this.unitRequest, this.descriptionValue.id).subscribe((response) => {
                            if (response) {
                                this.toastr.success(`Description Value image ${this.imageUrl} has been updated successfully.`, 'Update Description Value');
                            }
                        });
                    }
                }).catch((error) => {
                    this.loadingService.setLoading(false);
                    console.error('Error fetching image URL:', error);
                });
                break;

            default:
                break;
        }
    }

    getDisplayValue(descriptionVal: DescriptionValueViewModel, i: number): string {
        if (descriptionVal.description != undefined) {
            if (descriptionVal.description != undefined && !this.isEditing || (this.isEditing && this.editingIndex !== i)) {
                if (
                    descriptionVal.description.inputType.name !== 'ImageUpload' &&
                    descriptionVal.description.inputType.name !== 'Handwriting' &&
                    descriptionVal.description.inputType.name !== 'Signature'
                ) {
                    if (descriptionVal.description.inputType.name === 'DropDown') {
                        return descriptionVal.description.inputTypeValues.find(item => item.id === Number(descriptionVal.value))?.value;
                    } else if (descriptionVal.description.inputType.name === 'ToggleButton' ||
                        descriptionVal.description.inputType.name === 'PartialValidation') {
                        return descriptionVal.value === 'true' ? 'Active' : 'In-Active';
                    }
                    else {
                        return this.unitForm.get('value' + i)?.value || '';
                    }
                }
            }
            return '';
        } else {
            return '';
        }

    }

    isDropdownDisabled(index: number): boolean {
        const shouldDisableDropdown = this.isEditing && this.editingIndex === index;

        if (shouldDisableDropdown && (!this.canManageUnits)) {
            this.disableDropdownControl(index);
        }

        return (!this.canManageUnits) || !shouldDisableDropdown;
    }
    onApplyDate(controlName: string) {
        // Additional logic when the 'Apply' button is clicked
        console.log(`Date applied for ${controlName}`);
        // Set a flag or call a function to hide the input
        this.finishEditingUnitValue();
    }
    disableDropdownControl(index: number): void {
        this.unitForm.get('value' + index)?.disable();
    }

    get canManageUnits() {
        return this.accountService.userHasPermission(
            Permission.manageUnitsPermission
        );
    }
    get canViewUnits() {
        return this.accountService.userHasPermission(
            Permission.viewUnitsPermission
        );
    }

    deleteDescriptionValue(index: number, descriptionTitle: string, descriptionId: number, descriptionValueId: number) {
        const modalRef = this.modalService.open(DeleteDescriptionValueComponent, {
            size: 'lg',
            backdrop: 'static'
        });
        modalRef.componentInstance.descriptionTitle = descriptionTitle;

        modalRef.componentInstance.descriptionId = descriptionId;
        modalRef.componentInstance.descriptionValueId = descriptionValueId;

        modalRef.componentInstance.unitId = this.unitId;
        modalRef.componentInstance.descriptionValueChanged.subscribe((newImageUrl: string) => {
            this.getAllUnitDetails();
        });

    }

    removeUnit() {
        const modalRef = this.modalService.open(DeleteUnitComponent, {
            size: 'lg',
            backdrop: 'static'
        });

        modalRef.componentInstance.unitId = this.unitId;
        modalRef.componentInstance.unitTitle = this.descriptionValue['name'];
    }

}

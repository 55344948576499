import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { AuthService } from '../auth.service';
import { MouldStatus } from 'src/app/models/enums';

@Injectable()
export class MouldEndpointService extends EndpointBase {
    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get mouldsUrl() {
        return this.configurations.baseUrl + '/api/moulds';
    }

    getMouldsEndpoint<T>(workspaceId: number, page?: number, pageSize?: number, searchValue?: string): Observable<T> {
        //const endpointUrl =  this.mouldsUrl;

        //const endpointUrl = page && pageSize ? `${this.mouldsUrl}/${page}/${pageSize}` : this.mouldsUrl;
        const endpointUrl = `${this.mouldsUrl}?id=${workspaceId}&page=${page}&pageSize=${pageSize}&searchValue=${searchValue}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>((error: any) => {
                return this.handleError(error, () => this.getMouldsEndpoint(page, pageSize));
            }));
    }
    getupdateMouldStatus(id: number, status: MouldStatus): Observable<any> {
        const endpointUrl = `${this.mouldsUrl}/UpdateStatus/${id}?status=${status}`;
        const body = { id, status };

        return this.http
            .put(endpointUrl, JSON.stringify(body), this.requestHeaders)
            .pipe(
                catchError((error) =>
                    this.handleError(error, () => this.getupdateMouldStatus(id, status))
                )
            );
    }
    getNewMouldEndpoint<T>(mouldObject: any): Observable<T> {

        return this.http.post<T>(this.mouldsUrl, JSON.stringify(mouldObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getNewMouldEndpoint(mouldObject));
            }));
    }

    getUpdateMouldEndpoint<T>(mouldObject: any, id?: number): Observable<T> {
        const endpointUrl = `${this.mouldsUrl}/${id}`;

        return this.http.put<T>(endpointUrl, JSON.stringify(mouldObject.model), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateMouldEndpoint(mouldObject, id));
            }));
    }

    getMouldEndpoint<T>(mouldId?: number): Observable<T> {

        const endpointUrl = `${this.mouldsUrl}/${mouldId}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getMouldEndpoint(mouldId));
            })
        );

    }

    getDeleteMouldEndpoint<T>(mouldId: number): Observable<T> {
        const endpointUrl = `${this.mouldsUrl}/${mouldId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDeleteMouldEndpoint(mouldId));
            }));
    }

    getUpdateParentEndpoint<T>(viewModel: any): Observable<any> {
        const endpointUrl = `${this.mouldsUrl}/updateparent`;

        return this.http.put<T>(endpointUrl, JSON.stringify(viewModel), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateParentEndpoint(viewModel));
        }));
    }

    getUpdateMouldUnitIdEndpoint<T>(id: number, unitId: number): Observable<any> {
        const endpointUrl = `${this.mouldsUrl}/UpdateUnitId/${id}?unitId=${unitId}`;

        return this.http.put<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateMouldUnitIdEndpoint(id, unitId));
            }));
    }

    getMouldByIdEndpoint<T>(id: number): Observable<any> {

        const endpointUrl = `${this.mouldsUrl}/${id}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getMouldByIdEndpoint(id));
            })
        );

    }

    getUpdateMouldUnitIdNullEndpoint<T>(id: number): Observable<any> {
        const endpointUrl = `${this.mouldsUrl}/UpdateUnitId/${id}`;

        return this.http.put<T>(endpointUrl, null, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateMouldUnitIdNullEndpoint(id));
            }));
    }
}

<mat-dialog-content class="custom-scroll">
  <h3 mat-dialog-title>Interval Values</h3>
  <form [formGroup]="intervalsForm">
  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let intervalGroup of intervals.controls; let i = index" [formGroup]="intervalGroup">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{  intervalGroup.get('variant').value }}

        </mat-panel-title>
      </mat-expansion-panel-header>

        <mat-form-field appearance="fill" class="full-width-input">
          <mat-label>Enter Min value </mat-label>
          <input matInput placeholder="Enter the min value" formControlName="minValue" type="number" (change)="checkIntervals()">
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width-input">
          <mat-label>Enter Max value </mat-label>
          <input matInput placeholder="Enter the max value" formControlName="maxValue" type="number" (change)="checkIntervals()">
        </mat-form-field>

    </mat-expansion-panel>
  </mat-accordion>
  </form>


<mat-dialog-actions style ="float: right;">
  <button mat-stroked-button
   (click)="addIntervalValue()"  
   [disabled]="!checkIntervals()"
   style="width:45%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white">
   Save
  </button>

  <button mat-stroked-button
    (click)="closeIntervalValue()"  
    style="width:50%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white">
    Cancel
 </button>
  
</mat-dialog-actions>
  

</mat-dialog-content>
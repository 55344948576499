import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MouldStatus } from 'src/app/models/enums';
import { MouldService } from 'src/app/services/mouldServices/mould.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
    selector: 'app-validate-content',
    templateUrl: './validate-content.component.html',
    styleUrls: ['./validate-content.component.scss']
})
export class ValidateContentComponent implements OnInit {

    @Input() mouldId: number;
    @Output() deleteChanged: EventEmitter<any> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private mouldService: MouldService,
    ) {
    }

    ngOnInit(): void { }

    confirm() {
        this.validateContentHelper();
    }

    decline() {
        this.activeModal.close();
    }

    validateContentHelper() {
        this.toastr.success('Validating...');
        this.mouldService.updateMouldStatus(this.mouldId, MouldStatus.Empty)
            .subscribe((res) => {

                this.mouldService.updateMouldUnitIdNull(this.mouldId)
                    .subscribe({
                        next: (_) => {
                            this.toastr.success('Validation', `Validated Successfully.`);
                            this.deleteChanged.emit(true);
                        },
                        error: (error) => {

                            this.toastr.error(
                                'Vaildate Error',
                                `An error occurred while validation.\r\nError: "${Utilities.getHttpResponseMessages(
                                    error
                                )}"`
                            );
                        },
                    });
            });
    }
}

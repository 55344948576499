import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityType, MouldStatus } from 'src/app/models/enums';
import { MouldRequest } from 'src/app/models/mould-request.model';
import { MouldTypesList } from 'src/app/models/mould-type-list.model';
import { Mould } from 'src/app/models/mould.model';
import { WorkspacesList } from 'src/app/models/workspaces-list.model';
import { AccountService } from 'src/app/services/account.service';
import { FactoryService } from 'src/app/services/factory.service';
import { MouldTypeService } from 'src/app/services/mould-type.service';
import { MouldService } from 'src/app/services/mouldServices/mould.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { CustomValidators } from 'src/app/shared/custom-validators';

@Component({
    selector: 'app-add-edit-mould',
    templateUrl: './add-edit-mould.component.html',
    styleUrls: ['./add-edit-mould.component.scss']
})
export class AddEditMouldComponent {
    mouldForm: FormGroup;
    isEditMode = false;
    mouldData: Mould;
    mouldRequest: MouldRequest = new MouldRequest();
    mouldId: number = 0;
    workspaceId: number;
    workspacesList: WorkspacesList[] = [];
    inProgress: boolean;
    isLoading: boolean;
    moulTypesList: MouldTypesList[] = [];
    statusOptions: number[] = [];
    keys: string[];
    enum = MouldStatus;


    constructor(
        private fb: FormBuilder,
        private mouldService: MouldService,
        private workspaceService: WorkspaceService,
        private accountService: AccountService,
        private factoryService: FactoryService,
        private route: ActivatedRoute,
        private router: Router,
        private mouldTypeService: MouldTypeService,
        private toasterService: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.statusOptions = Object.keys(MouldStatus)
            .filter(key => typeof MouldStatus[key] === 'number')
            .map(key => MouldStatus[key]);

        this.route.queryParams.subscribe((queryParams) => {

            if (queryParams['id']) {
                this.mouldId = queryParams['id'];
                this.isEditMode = true;


                this.loadMould(this.mouldId);
            }
        });
        this.createForm();
        this.route.params.subscribe((params) => {

            if (params['workspaceId']) {
                this.workspaceId = Number(params['workspaceId']);
                if (this.workspaceId > 0)
                    this.mouldForm.get('workspaceId').setValue(this.workspaceId);
            }
        });
        this.getWorkspaceList();
        this.getMoulTypesList();

    }

    getWorkspaceList() {
        this.workspaceService.getWorkspacesList(this.mouldId).subscribe((data: any) => {
            this.workspacesList = data;
        })
    }

    getMoulTypesList() {
        this.mouldTypeService.getMouldTypesList().subscribe((data: any) => {
            this.moulTypesList = data;
        })
    }

    save(): void {

        this.isLoading = true;

        if (this.mouldForm.valid) {
            if (this.inProgress) {
                return;
            }
            this.isLoading = true;
            this.inProgress = true;
            const formData = this.mouldForm.value as Mould;
            this.mouldRequest.model = { ...this.mouldForm.value };
            this.mouldRequest.model.id = this.mouldId;
            this.mouldRequest.model.UserId = this.accountService.currentUser.id;

            if (this.isEditMode) {
                // Update an existing mould
                this.mouldService.updateMould(this.mouldRequest, this.mouldId).subscribe(() => {
                    // Handle success or navigate to a different page

                    this.isLoading = false;
                    this.inProgress = false;
                    this.toasterService.success(`Mould ${this.mouldRequest.model.name} has been updated.`, 'Mould Updated');
                    this.router.navigate(['/moulds']);
                });
            } else {
                // Create a new mould
                this.mouldService.createMould(this.mouldRequest).subscribe(() => {

                    this.isLoading = false;
                    this.inProgress = false;
                    this.toasterService.success(`Mould ${this.mouldRequest.model.name} has been created.`, 'Mould Created');
                    // Handle success or navigate to a different page
                    this.router.navigate(['/moulds']);
                });
            }
        } else {
            this.isLoading = false;
            this.inProgress = false;
            this.toasterService.error(`Kindly fill the Mould type form correctly.`, 'Mould Failed');
        }

    }

    cancel() {
        this.router.navigate(['/moulds']);
    }

    private createForm(): void {
        this.mouldForm = this.fb.group({
            name: ['', Validators.required, [CustomValidators.validateNameDuplicate(this.factoryService, EntityType.Mould, this.mouldId)]],
            workspaceId: [null, Validators.required],
            mouldTypeId: [null, Validators.required],
            status: [0, Validators.required]

        });
    }

    private loadMould(id: number): void {
        this.mouldService.getMould(id).subscribe((mould) => {

            this.mouldData = mould;
            this.mouldForm.patchValue(mould);
        });
    }
}

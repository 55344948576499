export interface TestResultValueUpdateRequestModel {
  name: string;
  type: string;
  value: string | string[]; 
  testParameterId: number;
  testResultValueId: number; 
}

export class TestResultValueUpdateRequest {
  model: TestResultValueUpdateRequestModel;

  constructor() {
    this.model = {
      name: '',
      type: '',
      value: '',
      testParameterId: 0,
      testResultValueId: 0 
    };
  }
}

export interface TestResultUpdateRequestModel {
  testResultId: number;
  testID : number;
  rfid : string; 
  testResultValues: TestResultValueUpdateRequestModel[];
}

export class TestResultUpdateRequest {
  model: TestResultUpdateRequestModel;

  constructor() {
    this.model = {
      testResultId: 0,
      testID :0,
      rfid:"",
      testResultValues: []
    };
  }
}

import { Injectable } from '@angular/core';
import { FileEndpointService } from './file-endpoint.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private fileService: FileEndpointService) { }
    getImageUrl(imageName: string) {
        return this.fileService.getDownloadImageEndpoint<string>(imageName);
    }
}

export class Mandatory{

    model : {
    testParameterId : number;
    isMandatory : boolean;
}
constructor() {
    this.model = {
        testParameterId: 0,
        isMandatory: false
    }
}

}
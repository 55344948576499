<div class="modal-header">
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close fs-sm" tabindex="-1"
            title="Close" type="button"></button>
</div>
<div class="modal-body text-center">
    <img [src]="imageUrl" alt="Image Preview" width="400">
</div>
<div class="modal-footer">
    <div class="text-end">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 example-buttons" style="text-align: right;">
                    <ng-container *ngIf="inputTypeName == 'ImageUpload'">
                        <button (click)="openImageUploadModal()" color="warn" class="mx-3" mat-flat-button>
                            Upload Image
                        </button>
                    </ng-container>
                    <ng-container *ngIf="inputTypeName == 'Signature' || inputTypeName == 'Handwriting'">
                        <button (click)="openSignatureUploadModal()" color="warn" class="mx-3" mat-flat-button>Update {{inputTypeName}}</button>
                    </ng-container>
                    <button (click)="decline()" class="cancel-btn" mat-flat-button>Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

import { Component, NgZone, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Permission } from 'src/app/models/permission.model';
import { Unit } from 'src/app/models/unit.model';
import { AccountService } from 'src/app/services/account.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { UnitService } from 'src/app/services/unit.service';
import { Utilities } from 'src/app/services/utilities';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatChipEditedEvent } from '@angular/material/chips';
import { FormBuilder, FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DatePipe } from '@angular/common';
import { MouldTypeService } from 'src/app/services/mould-type.service';
import { UnitsFilterComponent } from '../units-filter/units-filter.component';
import { MouldTypeList } from 'src/app/models/mould-type-list';

@Component({
    selector: 'app-unit-list',
    templateUrl: './unit-list.component.html',
    styleUrls: ['./unit-list.component.scss']
})
export class UnitListComponent {
    loadingIndicator: boolean;
    allUnits: Unit[] = [];
    totalRows = 0;
    pageSize = 10;
    currentPage = 0;
    defaultPageSize = 10;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    displayedColumns: string[] = [
        'name',
        'rfId1',
        'rfId2',
        'createdat',
        'progress'
    ];
    dataSource = new MatTableDataSource<Unit>([]);
    editingUnitName: null;
    sourceUser: null;

    @ViewChild(MatPaginator) paginator!: MatPaginator;
    searchValue: string = "";

    // MatChips properties
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    fruits: Fruit[] = [];
    range: FormGroup; // Declare a FormGroup for the date range picker
    addOnBlur = true;
    moulTypesList: MouldTypeList[] = [];
    showFilter: boolean;
    filterCount: number;
    orignalMoulTypesList: MouldTypeList[];

    constructor(
        public dialog: MatDialog,
        private alertService: AlertService,
        private accountService: AccountService,
        private unitService: UnitService,
        private router: Router,
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        public datepipe: DatePipe,
        private mouldTypeService: MouldTypeService
    ) {
        this.range = this.formBuilder.group({
            start: null, // Initialize with null values
            end: null,
        });
    }

    ngOnInit(): void {
        const savedFilterSettings = localStorage.getItem('unitsFiltersSetting');
        if (savedFilterSettings) {
            const filterSettings = JSON.parse(savedFilterSettings);
            this.range.setValue(filterSettings.range);
            this.moulTypesList = filterSettings.moulTypesList;

            // Calculate filterCount based on the saved filter settings
            this.calculateFilterCount(filterSettings);
        }
        this.getMoulTypesList();
    }
    calculateFilterCount(filterSettings: any) {
        this.filterCount = (
            (filterSettings.range && filterSettings.range.start && filterSettings.range.end) ? 1 : 0
        ) + (filterSettings.moulTypesList && filterSettings.moulTypesList.length > 0 ? 1 : 0);
    }
    loadData() {
        const mouldsTypeIds = this.moulTypesList.map(fruit => fruit.name).join(',');
        // Get the start and end dates from the date range picker
        const startDate = this.range.get('start').value;
        const endDate = this.range.get('end').value;
        // Convert the start and end dates to string format
        const start = this.datepipe.transform(startDate, 'YYYY-MM-dd');
        const end = this.datepipe.transform(endDate, 'YYYY-MM-dd');

        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        // Include MatChips filter in the request

        this.unitService.getUnits(this.currentPage + 1, this.pageSize, this.searchValue, 'date', '', start, end, mouldsTypeIds).subscribe({
            next: (results) => this.onDataLoadSuccessful(results),
            error: (error) => this.onDataLoadFailed(error),
        });
    }
    getProgressBarColor(percentage: number): string {
        if (percentage < 30) {
            return 'warn';
        } else if (percentage < 70) {
            return 'primary';
        } else {
            return 'accent';
        }
    }
    getMoulTypesList() {
        this.mouldTypeService.getMouldTypesList().subscribe((data: MouldTypeList[]) => {
            this.moulTypesList = data;
            this.orignalMoulTypesList = data;
            this.loadData();
        })
    }
    pageChanged(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.currentPage = event.pageIndex;
        this.loadData();
    }

    onDataLoadSuccessful(factories: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = factories.items;
        this.totalRows = factories.totalCount;
    }

    onDataLoadFailed(error: any) {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.alertService.showStickyMessage(
            'Load Error',
            `Unable to retrieve users from the server.\r\nErrors: "${error}"`,
            MessageSeverity.error,
            error
        );
    }

    filter() {
        const modalRef = this.modalService.open(UnitsFilterComponent, {
            size: 'lg',
            backdrop: 'static'
        });
        modalRef.componentInstance.moulTypesList = this.moulTypesList;
        // Assuming you have a selectedMoultypesList variable that contains the selected moultypes list.
        modalRef.componentInstance.initialFilterData = {
            range: {
                start: this.range.get('start').value, // selected start date
                end: this.range.get('end').value // selected end date
            },
            moulTypesList: this.moulTypesList // selected moultypes list
        };

        modalRef.componentInstance.applyFilterChanged.subscribe((data) => {
            if (data) {

                this.modalService.dismissAll();
                this.range = data.range;
                this.moulTypesList = data.mouldsTypes;
                this.filterCount = (
                    (data.range && data.range.get('start').value && data.range.get('end').value) ? 1 : 0
                ) + (data.moulTypesList && data.moulTypesList.length > 0 ? 1 : 0);

                this.loadData();
            }
        })
    }

    editUnit(id: number) {
        this.router.navigate(['/add-edit-moulds-type'], { queryParams: { id: id } });
    }

    onSearchChanged(value: string) {
        this.searchValue = value;
        this.currentPage = 0;
        this.loadData();
    }

    add(event: MatChipInputEvent): void {

        const value = (event.value || '').trim();

        if (value) {
            this.moulTypesList.push({ id: 0, name: value });
            this.loadData();
        }

        event.chipInput!.clear();
    }

    remove(fruit: Fruit): void {
        const index = this.moulTypesList.indexOf(fruit);

        if (index >= 0) {
            this.moulTypesList.splice(index, 1);
            this.loadData();
        }
    }

    edit(fruit: Fruit, event: MatChipEditedEvent) {
        const value = event.value.trim();

        if (!value) {
            this.remove(fruit);
        } else {
            const index = this.fruits.indexOf(fruit);
            if (index >= 0) {
                this.fruits[index].name = value;
                this.loadData();
            }
        }
    }

    openFilterPanel(): void {
        this.showFilter = true;
    }

    applyFilter(data: any): void {
        this.range = data.range;
        this.moulTypesList = data.mouldsTypes;
        this.filterCount = (
            (data.range && data.range.get('start').value && data.range.get('end').value) ? 1 : 0
        ) + (data.mouldsTypes && data.mouldsTypes.length > 0 ? 1 : 0);

        this.loadData();

        this.showFilter = false; // Hide the filter component after applying filters
    }

    get canAssignRoles() {
        return this.accountService.userHasPermission(Permission.assignRolesPermission);
    }

    get canViewRoles() {
        return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

    get canManageUsers() {
        return this.accountService.userHasPermission(Permission.manageUsersPermission);
    }
}
export interface Fruit {
    id: number;
    name: string;
}

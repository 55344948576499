import { Component } from '@angular/core';

@Component({
  selector: 'app-add-edit-test-result',
  templateUrl: './add-edit-test-result.component.html',
  styleUrls: ['./add-edit-test-result.component.scss']
})
export class AddEditTestResultComponent {

}

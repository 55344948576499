<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Factories</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Factories</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>

        <mat-card-header class="d-block">
            <div class="row control-box justify-content-between">
                <div class="col-lg-5">
                    <div class="search-box">
                        <app-search-box (searchChange)="onSearchChanged($event)"
                                        placeholder="{{'search for factory' | translate}}"></app-search-box>
                    </div>
                </div>
                <div class="col-lg-7 text-end">
                    <button (click)="newFactory()" *ngIf="canViewHomePage" class="default-btn tagus" mat-flat-button>
                        <i class="ri-add-fill"></i>{{'Add Factory' | translate}}
                    </button>
                </div>
            </div>
        </mat-card-header>

        <div class="myTasksTable mat-elevation-z8 tagus-mat-table">
            <table [dataSource]="dataSource" mat-table>

                <!-- Task Name Column -->
                Assigned Column
                <!-- <ng-container matColumnDef="assigned">
                    <th mat-header-cell *matHeaderCellDef>Assigned</th>
                    <td mat-cell *matCellDef="let element">
                        <img [src]="element.assigned.img" matTooltip="{{element.assigned.name}}" class="rounded-circle"
                            alt="icon">
                    </td>
                </ng-container> -->
                <!-- Deadline Column -->
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Name' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>
                        <a [queryParams]="{ id: element.id }" [routerLink]="['/view-factory']"
                           class="text-primary cursor-pointer">{{ element.name }}</a>
                    </td>
                </ng-container>
                <!-- Deadline Column -->
                <ng-container matColumnDef="email">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Email' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.email}}</td>
                </ng-container>

                <!-- Completion Column -->
                <ng-container matColumnDef="address">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Address' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.address}}</td>
                </ng-container>

                <!-- Priority Column -->
                <ng-container matColumnDef="description">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Description' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>
                        <span class="badge completed fw-medium">{{element.description}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="mobile">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Mobile' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.mobile}}</td>

                </ng-container>
                <ng-container matColumnDef="phone">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Phone' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.phone}}</td>

                </ng-container>
                <ng-container matColumnDef="support">
                    <th *matHeaderCellDef mat-header-cell>{{ 'Support' | translate }}</th>
                    <td *matCellDef="let element" mat-cell>{{element.support}}</td>

                </ng-container>
                <!-- <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Roles</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="fw-medium" *ngFor="let role of element.roles">{{ role }}</span>
                    </td>

                </ng-container> -->
                <!-- Action Column -->
                <ng-container matColumnDef="actions">
                    <th *matHeaderCellDef mat-header-cell>Action</th>
                    <td *matCellDef="let element" mat-cell>
                        <button [matMenuTriggerFor]="actionMenu" class="position-relative dot-btn p-0 bg-transparent border-none"
                                mat-button
                                type="button">
                            <i class="ri-more-fill"></i>
                        </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">

                            <button (click)="editFactory(element.id)" class="bg-transparent border-none position-relative" mat-menu-item
                                    type="button">
                                <i class="ri-edit-line"></i> Edit
                            </button>
                            <button (click)="deleteFactory(element)" class="bg-transparent border-none position-relative" mat-menu-item
                                    type="button">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

            </table>
            <mat-progress-bar *ngIf="loadingIndicator" mode="indeterminate"></mat-progress-bar>

            <mat-paginator #paginator (page)="pageChanged($event)" [length]="totalRows" [pageIndex]="currentPage"
                           [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" aria-label="Select page">
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

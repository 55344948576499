import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TestResultAddRequest } from 'src/app/models/test-result-add-request.model';
import { TestResultUpdateRequest } from 'src/app/models/test-result-update-request.model';
import { TestResultsEndpointService } from './test-results-endpoint.service';
import { TestResult } from 'src/app/models/test-result.model';

@Injectable({
    providedIn: 'root'
})
export class TestResultsService {
    constructor(private testResultsEndpointService: TestResultsEndpointService) { }

    addTestResult(resultsData: TestResultAddRequest): Observable<any> {
        return this.testResultsEndpointService.getAddTestResultsEndpoint<any>(resultsData);
    }

    updateTestResult(resultsData: TestResultUpdateRequest): Observable<any> {
        return this.testResultsEndpointService.getUpdateTestResultsEndpoint<any>(resultsData);
    }

    getTestResults(testId: number, page?: number, pageSize?: number, searchTerm?: string, searchName?: string, sortColumn?: string, sortOrder?: string, startDate?: string, endDate?: string): Observable<any> {
        return this.testResultsEndpointService.getTestResultsEndpoint<any>(testId, page, pageSize, searchTerm, searchName, sortColumn, sortOrder, startDate, endDate);
    }

    getTestResultValues(testResultId: number): Observable<TestResult> {
        return this.testResultsEndpointService.getTestResultEndpoint<TestResult>(testResultId);
    }

    deleteTestResult(testResultId: number): Observable<any> {
        return this.testResultsEndpointService.getDeleteTestResultEndpoint<any>(testResultId);
    }

    getDeleteTestResultValueEndpoint<T>(testResultId: number, parameterId: number): Observable<any> {
        return this.testResultsEndpointService.getDeleteTestResultValueEndpoint<any>(testResultId, parameterId);
    }
}


    
    // Define the structure of the request payload
    export class TestParameterUpdateRequest {
     model : {
        testParameterId :number;
      columnName: string;
      columnType: string;
      columnValues: string;
      intervals?: IntervalModel[];
      min?: number; // The '?' makes it optional
      max?: number; // The '?' makes it optional
      mandatory : boolean,
      selectList: string[];
      header ?:boolean,
      // You can add 'userId' and 'createdBy' if needed, or set them on the backend
       userId?: string;
       createdBy?: string;
  
     }
  
     constructor() {
      this.model = {
        testParameterId:0,
       columnName: '',
       columnType: '',
       columnValues: '',
       min: 0,
       mandatory : false,
       header:null,
       max: 0,
       intervals: [], 
       selectList:  [],
       userId :'',
       createdBy:''
      }
  }
   
  
    }

    export class IntervalModel {
      min: number;
      max: number;
      mouldTypeId?: number; // Include this if you have a mould type identifier
    
      constructor(min: number, max: number, mouldTypeId?: number) {
        this.min = min;
        this.max = max;
        this.mouldTypeId = mouldTypeId;
      }
    }
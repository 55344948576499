<div class="create-dialog-box scrollable-dialog">
    <form #f="ngForm" (ngSubmit)="f.form.valid ? save() :

      (!userName.valid && showErrorAlert('User name is required', 'Please enter a user name (minimum of 2 and maximum of 200 characters)'));

      (userPassword && !userPassword.valid && showErrorAlert('Password is required', 'Please enter the current password'));

      (email?.errors?.required && email?.touched && showErrorAlert('Email is required', 'Please enter an email address (maximum of 200 characters)'));
      (email?.errors?.pattern && email?.touched && showErrorAlert('Invalid Email', 'Please enter a valid email address'));

      (isChangePassword && isEditingSelf && !currentPassword.valid && showErrorAlert('Current password is required', 'Please enter the current password'));

      ((isChangePassword || isNewUser) && !newPassword.valid && showErrorAlert('New password is required', 'Please enter the new password (minimum of 6 characters)'));

      ((isChangePassword || isNewUser) && newPassword.valid && confirmPassword.errors?.required && showErrorAlert('Confirmation password is required', 'Please enter the confirmation password'));
      ((isChangePassword || isNewUser) && newPassword.valid || confirmPassword.errors?.appValidateEqual && showErrorAlert('Passwword mismatch', 'New password and confirmation password do not match'));

      (canAssignRoles && !roles.valid && showErrorAlert('Roles is required', 'Please select a minimum of 1 role'));" *ngIf="formResetToggle" [attr.autocomplete]="isGeneralEditor ? 'NaN' : null"
          name="userInfoForm"
          novalidate>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <!-- <label class="d-block fw-semibold">Task</label>
                    <input type="text" class="input-control" placeholder="Task *"> -->
                    <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly" class="d-block fw-semibold"
                           for="jobTitle-{{uniqueId}}">{{'users.editor.JobTitle' | translate}}</label>
                    <div *ngIf="!isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <p class="form-control-plaintext">{{user.jobTitle}}</p>
                    </div>
                    <div *ngIf="isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <input [(ngModel)]="userEdit.jobTitle" appAutofocus attr.id="jobTitle-{{uniqueId}}" class="input-control"
                               name="jobTitle" placeholder="Enter Job Title" type="text"/>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly" class="d-block fw-semibold"
                           for="email-{{uniqueId}}">{{'users.editor.Email' | translate}}</label>
                    <div *ngIf="!isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <p class="form-control-plaintext">{{user.email}}</p>
                    </div>
                    <div *ngIf="isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <input #email="ngModel" [(ngModel)]="userEdit.email" [ngClass]="{'is-valid': email.valid, 'is-invalid' : !email.valid}" attr.id="email-{{uniqueId}}"
                               class="input-control"
                               maxlength="200"
                               name="email" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" placeholder="Enter email address" required
                               type="text"/>
                        <span *ngIf="showValidationErrors  && email?.touched && email?.errors?.required"
                              class="invalid-feedback">
                      {{'users.editor.EmailRequired' | translate}}
                    </span>
                        <span *ngIf="showValidationErrors && email?.touched && email?.errors?.pattern"
                              class="invalid-feedback">
                      {{'users.editor.InvalidEmail' | translate}}
                    </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <hr [class.edit-separator]="isEditMode" [class.info-separator]="!isEditMode" class="hr-separator"/>
            </div>
            <div class="col-lg-12">
                <div class="form-group">
                    <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly" class="d-block fw-semibold"
                           for="userName-{{uniqueId}}">{{'users.editor.UserName' | translate}}</label>
                    <div *ngIf="!isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <p class="form-control-plaintext">{{user.userName}}</p>
                    </div>
                    <div *ngIf="isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <input #userName="ngModel" [(ngModel)]="userEdit.userName" [attr.autocomplete]="isGeneralEditor ? 'new-password' : null"
                               [ngClass]="{'is-valid': userName.valid, 'is-invalid' : !userName.valid}"
                               attr.id="userName-{{uniqueId}}"
                               class="input-control"
                               maxlength="200"
                               minlength="2" name="userName" placeholder="Enter user name" required
                               type="text"/>

                    </div>
                </div>

            </div>
            <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName"
                 class="row">
                <div class="col-lg-12">
                    <hr class="hr-password-separator"/>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <div *ngIf="isEditMode" class="row">
                        <label class="d-block fw-semibold col-lg-2"
                               for="newPassword-{{uniqueId}}">{{'users.editor.Password' | translate}}</label>
                        <div *ngIf="!isChangePassword && !isNewUser" class="col-lg-12">
                            <button (click)="changePassword()" class="tagus" mat-flat-button
                                    type="button">{{'users.editor.ChangePassword' | translate}}</button>
                        </div>

                        <div *ngIf="isChangePassword || isNewUser" class="col-lg-12">
                            <div class="password-well card card-body bg-light">
                                <div *ngIf="isEditingSelf" class="row">
                                    <label class="col-form-label col-lg-3 d-block fw-semibold"
                                           for="currentPassword-{{uniqueId}}">{{'users.editor.CurrentPassword' | translate}}</label>
                                    <div class="col-lg-12">
                                        <input #currentPassword="ngModel" [(ngModel)]="userEdit.currentPassword"
                                               [attr.autocomplete]="isGeneralEditor ? 'new-password' : null"
                                               [ngClass]="{'is-valid': currentPassword.valid, 'is-invalid' : !currentPassword.valid}"
                                               attr.id="currentPassword-{{uniqueId}}" class="input-control"
                                               name="currentPassword"
                                               placeholder="Enter current password" required
                                               type="password"/>
                                        <span
                                            *ngIf="showValidationErrors && currentPassword?.touched  && !currentPassword?.valid"
                                            class="invalid-feedback">
                              {{'users.editor.CurrentPasswordRequired' | translate}}
                            </span>
                                    </div>
                                </div>

                                <div *ngIf="!isNewUser" class="row">
                                    <div class="col-lg-12">
                                        <hr class="hr-password-separator"/>
                                    </div>
                                </div>

                                <div>
                                    <label class="col-form-label col-lg-3 d-block fw-semibold"
                                           for="newPassword-{{uniqueId}}">{{'users.editor.NewPassword' | translate}}</label>
                                    <div class="col-lg-12">
                                        <input #newPassword="ngModel" [(ngModel)]="userEdit.newPassword" [attr.autocomplete]="isGeneralEditor ? 'new-password' : null"
                                               [ngClass]="{'is-valid': newPassword.valid, 'is-invalid' : !newPassword.valid}"
                                               appValidateEqual="confirmPassword" attr.id="newPassword-{{uniqueId}}"
                                               class="input-control"
                                               minlength="6" name="newPassword"
                                               placeholder="Enter new password" required reverse="true"
                                               type="password"/>
                                        <span
                                            *ngIf="showValidationErrors  && newPassword?.touched && !newPassword?.valid"
                                            class="invalid-feedback">
                              {{'users.editor.NewPasswordRequired' | translate}}
                            </span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <hr class="hr-password-separator"/>
                                    </div>
                                </div>

                                <div>
                                    <label class="col-form-label col-lg-3 d-block fw-semibold"
                                           for="confirmPassword-{{uniqueId}}">{{'users.editor.ConfirmPassword' | translate}}</label>
                                    <div class="col-lg-12">
                                        <input #confirmPassword="ngModel" [(ngModel)]="userEdit.confirmPassword"
                                               [attr.autocomplete]="isGeneralEditor ? 'new-password' : null"
                                               [ngClass]="{'is-valid': confirmPassword.valid, 'is-invalid' : !confirmPassword.valid}"
                                               appValidateEqual="newPassword" attr.id="confirmPassword-{{uniqueId}}"
                                               class="input-control"
                                               name="confirmPassword" placeholder="Confirm new password"
                                               required type="password"/>
                                        <span
                                            *ngIf="showValidationErrors && confirmPassword?.touched  && confirmPassword?.errors?.required"
                                            class="invalid-feedback">
                              {{'users.editor.ConfirmationPasswordRequired' | translate}}
                            </span>
                                        <span
                                            *ngIf="confirmPassword?.touched  && confirmPassword?.errors?.validateEqual && !confirmPassword?.errors?.required"
                                            class="invalid-feedback">
                              {{'users.editor.PasswordMismatch' | translate}}
                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--******************************************************************************************************-->

            <div *ngIf="!isEditMode || canAssignRoles" class="row">
                <div class="col-lg-6 col-md-6">
                    <hr [class.edit-separator]="isEditMode" [class.info-separator]="!isEditMode" class="hr-separator"/>
                </div>
            </div>
            <div *ngIf="!isEditMode || canAssignRoles" class="row">
                <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly" class="d-block fw-semibold"
                       for="roles-user-info">{{'users.editor.Roles' | translate}}</label>
                <div *ngIf="!isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                    <p class="form-control-plaintext" ngPreserveWhitespaces>
            <span *ngFor="let role of user.roles">
              <span class="badge rounded-pill bg-secondary" title='{{getRoleByName(role)?.description}}'>{{role}}</span>
            </span>
                    </p>
                </div>
                <div *ngIf="isEditMode && canAssignRoles"
                     [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly"
                     [ngClass]="{'is-valid': roles.valid, 'is-invalid' : !roles.valid}">
                    <ng-select #roles="ngModel" [(ngModel)]="userEdit.roles" [closeOnSelect]="false" [items]="allRoles" [multiple]="true"
                               bindLabel="name" bindValue="name"
                               class="input-control left-status pill-items" id="roles-user-info" name="roles"
                               placeholder="Select Roles" required>
                        <ng-template let-clear="clear" let-item="item" ng-label-tmp>
                            <span class='badge rounded-pill bg-secondary' title='{{item.description}}'>{{item.name}}
                                <span (click)="clear(item)" aria-hidden="true"
                                      class="ng-value-icon right">×</span></span>
                        </ng-template>

                        <ng-template let-item="item" ng-option-tmp>
                            <span class='badge rounded-pill bg-secondary'
                                  title='{{item.description}}'>{{item.name}}</span>
                            <i class="fa fa-check d-none"></i>
                        </ng-template>
                    </ng-select>
                    <span *ngIf="showValidationErrors && roles?.touched && !roles.valid" class="invalid-feedback">
            {{'users.editor.RoleRequired' | translate}}
          </span>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label class="d-block fw-semibold">Status</label>
                    <input type="text" class="input-control" placeholder="Status *">
                </div>
            </div> -->

            <!--******************************************************************************************************-->

            <div class="row">
                <div class="col-lg-12">
                    <hr [class.edit-separator]="isEditMode" [class.info-separator]="!isEditMode" class="hr-separator"/>
                </div>
            </div>

            <div class="row">
                <div class="form-group">
                    <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly" class="d-block fw-semibold"
                           for="fullName-{{uniqueId}}">{{'users.editor.FullName' | translate}}</label>
                    <div *ngIf="!isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <p class="form-control-plaintext">{{user.fullName}}</p>
                    </div>
                    <div *ngIf="isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <input [(ngModel)]="userEdit.fullName" attr.id="fullName-{{uniqueId}}" class="input-control" name="fullName"
                               placeholder="Enter full name" type="text"/>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12">
                    <hr [class.edit-separator]="isEditMode" [class.info-separator]="!isEditMode" class="hr-separator"/>
                </div>
            </div>

            <div class="row">
                <div class="form-group">
                    <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly" class="d-block fw-semibold"
                           for="phoneNumber-{{uniqueId}}">{{'users.editor.PhoneNumber' | translate}}</label>
                    <div *ngIf="!isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <p class="form-control-plaintext">{{user.phoneNumber}}</p>
                    </div>
                    <div *ngIf="isEditMode" [class.col-lg-12]="isViewOnly" [class.col-lg-12]="!isViewOnly">
                        <input [(ngModel)]="userEdit.phoneNumber" attr.id="phoneNumber-{{uniqueId}}" class="input-control"
                               name="phoneNumber" placeholder="Enter phone number"
                               type="text"/>
                    </div>
                </div>
            </div>

            <div *ngIf="!isViewOnly" class="row">
                <div class="col-lg-12">
                    <hr [class.last-edit-separator]="isEditMode" [class.last-separator]="!isEditMode"
                        class="hr-separator"/>
                </div>
            </div>

            <div *ngIf="!isViewOnly" class="row">
                <div class="col-lg-5">
                    <div class="form-group">
                        <div *ngIf="isGeneralEditor && isEditMode" class="float-start">
                            <div class="form-check user-enabled">
                                <input [(ngModel)]="userEdit.isEnabled" class="form-check-input" id="idEnabled" name="isEnabled"
                                       type="checkbox">
                                <label class="form-check-label"
                                       for="idEnabled">{{'users.editor.Enabled' | translate}}</label>
                            </div>
                            <button (click)="unlockUser()" *ngIf="userEdit.isLockedOut" [disabled]="isSaving"
                                    class="btn btn-warning unblock-user" type="button"><i
                                class='fa fa-unlock-alt'></i> {{'users.editor.Unblock' | translate}}</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-sm-7">
                  <div ngPreserveWhitespaces class="float-end">
                    <button *ngIf="!isEditMode && isGeneralEditor" type="button" (click)="close()" class="btn btn-outline-secondary"><i class='fa fa-close'></i> {{'users.editor.Close' | translate}}</button>
                    <button *ngIf="!isEditMode && !isGeneralEditor" type="button" (click)="edit()" class="btn btn-outline-secondary"><i class='fa fa-edit'></i> {{'users.editor.Edit' | translate}}</button>
                    <button *ngIf="isEditMode" type="button" (click)="cancel()" class="btn btn-danger" [disabled]="isSaving"><i class='fa fa-times'></i> {{'users.editor.Cancel' | translate}}</button>
                    <button *ngIf="isEditMode" type="submit" class="btn btn-primary" [disabled]="isSaving">
                      <i *ngIf="!isSaving" class='fa fa-save'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Save' | translate)}}
                    </button>
                  </div>
                </div> -->
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="text-end">
            <button (click)="close()" *ngIf="!isEditMode && isGeneralEditor" class="gray button-spacing" mat-flat-button
                    type="button"> {{'users.editor.Close' | translate}}</button>
            <button (click)="edit()" *ngIf="!isEditMode && !isGeneralEditor" class="gray button-spacing" mat-flat-button
                    type="button">{{'users.editor.Edit' | translate}}</button>
            <button (click)="cancel()" *ngIf="isEditMode" [disabled]="isSaving" class="btn gray text-primary " mat-flat-button
                    type="button"> {{'users.editor.Cancel' | translate}}</button>
            <button *ngIf="isEditMode" [disabled]="isSaving" class="tagus" mat-flat-button type="submit">
                <i *ngIf="isSaving"
                   class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) : ('users.editor.Save' | translate)}}
            </button>
            <button *ngIf="!isEditMode && !isGeneralEditor" type="submit"><i class="ri-add-fill button-spacing"></i> Add
                New User
            </button>
        </div>
    </form>

</div>

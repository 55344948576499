<!-- factory-details.component.html -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <!-- Breadcrumb and page title -->
    <h5 class="mb-0">Workspace Details</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/workspace">
                <i class="ri-home-8-line"></i>
                Workspaces
            </a>
        </li>
        <li class="breadcrumb-item">
            Workspace Details
        </li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Details"><br>
                <!-- Factory details content -->
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">Name</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-user-3-line"></i>
                                <mat-label>Name</mat-label>
                                <input [value]="workspaceData.name" matInput readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">Workplace Name</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-user-3-line"></i>
                                <mat-label>Workplace Name</mat-label>
                                <input [value]="workspaceData.workplaceName" matInput readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">Description</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-phone-line"></i>
                                <mat-label>Description</mat-label>
                                <input [value]="workspaceData.description" matInput readonly>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="mb-3 example-buttons" style="text-align: right;">
                            <button (click)="goBack()" class="default-btn tagus" mat-flat-button>
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Moulds"><br>
                <app-mould-list [workspaceId]="workspaceId"></app-mould-list>
            </mat-tab>
        </mat-tab-group>

    </mat-card-content>
</mat-card>

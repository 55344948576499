<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Tests</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Tests</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>

        <mat-card-header class="d-block">
            <div class="row control-box justify-content-between">
                <div class="col-lg-5">
                    <div class="search-box">
                        <app-search-box (searchChange)="onSearchChanged($event)"
                            placeholder="{{'search for Test' | translate}}"></app-search-box>
                    </div>
                </div>
                <div class="col-lg-7 text-end">
                    <button *ngIf="canManageUsers" mat-flat-button class="default-btn tagus" (click)="newTest()">
                        <i class="ri-add-fill"></i>{{'Add Test' | translate}}
                    </button>
                </div>
            </div>
        </mat-card-header>

        <div class="myTasksTable mat-elevation-z8 tagus-mat-table">
            <table mat-table [dataSource]="dataSource">

                <!-- Task Name Column -->
                Assigned Column
                <!-- <ng-container matColumnDef="assigned">
                    <th mat-header-cell *matHeaderCellDef>Assigned</th>
                    <td mat-cell *matCellDef="let element">
                        <img [src]="element.assigned.img" matTooltip="{{element.assigned.name}}" class="rounded-circle"
                            alt="icon">
                    </td>
                </ng-container> -->
                <!-- Deadline Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Name' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <a class="text-primary cursor-pointer"
                            [routerLink]="(canManageUsers || canManageTests || canAddTests ) ? ['/add-testresults'] : null"
                            [queryParams]="{ id: element.id }"
                            [disabled]="!(canManageUsers || canManageTests || canAddTests)">
                            {{ element.name }}
                            </a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="testTypeName">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Test Type' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <a class="text-primary cursor-pointer" [routerLink]="['/view-test']" [queryParams]="{ id: element.id }">{{ element.testTypeName }}</a>
                    </td>
                </ng-container>
                <!-- Deadline Column -->
                <!-- <ng-container matColumnDef="testType">
                    <th mat-header-cell *matHeaderCellDef>{{ 'TestType' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{element.TestType}}</td>
                </ng-container> -->

                <!-- Completion Column -->
                <!-- <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Address' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{element.address}}</td>
                </ng-container> -->

                <!-- Priority Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Description' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="badge completed fw-medium">{{element.description}}</span>
                    </td>
                </ng-container>
                <!-- <ng-container matColumnDef="mobile">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Mobile' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{element.mobile}}</td>

                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Phone' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{element.phone}}</td>

                </ng-container>
                <ng-container matColumnDef="support">
                    <th mat-header-cell *matHeaderCellDef>{{ 'Support' | translate }}</th>
                    <td mat-cell *matCellDef="let element">{{element.support}}</td>

                </ng-container> -->
                <!-- <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Roles</th>
                    <td mat-cell *matCellDef="let element">
                        <span class="fw-medium" *ngFor="let role of element.roles">{{ role }}</span>
                    </td>

                </ng-container> -->
                <!-- Action Column -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <button type="button" mat-button
                            class="position-relative dot-btn p-0 bg-transparent border-none"
                            [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                                </button>
                        <mat-menu #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">

                            <button  *ngIf="canManageUsers" mat-menu-item type="button" (click)="editTest(element.id)"
                                class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Edit
                            </button>
                            <button *ngIf="canManageUsers" mat-menu-item type="button" (click)="deleteTest(element)"
                                class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>

                            <button mat-menu-item type="button" (click)="ConfigureTest(element.id)"
                            class="bg-transparent border-none position-relative">
                            <i class="ri-tools-line"></i> Parameters
                            </button>

                            <button *ngIf="canManageUsers && canManageTests" mat-menu-item type="button" (click)="AddTestResult(element.id)"
                            class="bg-transparent border-none position-relative">
                            <i class="ri-database-2-line"></i> Add Results
                            </button>

                            <button mat-menu-item type="button" (click)="ViewTestResult(element.id)"
                            class="bg-transparent border-none position-relative">
                            <i class="ri-eye-line"></i> View Results
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <mat-progress-bar mode="indeterminate" *ngIf="loadingIndicator"></mat-progress-bar>

            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CategoryEndpointService extends EndpointBase {


    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get categoryUrl() {
        return this.configurations.baseUrl + '/api/Category';
    }


    createCategory<T>(name: string, mouldTypeId: number, order: number): Observable<T> {
        const categoryData = { Name: name, MouldTypeId: mouldTypeId, Order: order };
        const endpointUrl = `${this.categoryUrl}`;
        return this.http.post<T>(endpointUrl, JSON.stringify({ Model: categoryData }), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.createCategory(name, mouldTypeId, order));
            }));
    }

    getAllCategories<T>(): Observable<T> {
        const endpointUrl = `${this.categoryUrl}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getAllCategories());
            }));
    }

    updateCategory<T>(categoryId: number, name: string, mouldTypeId: number, order: number): Observable<T> {

        const categoryData = { Id: categoryId, Name: name, MouldTypeId: mouldTypeId, Order: order };
        const endpointUrl = `${this.categoryUrl}/${categoryId}`;
        return this.http.put<T>(endpointUrl, JSON.stringify({ Model: categoryData }), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.updateCategory(categoryId, name, mouldTypeId, order));
            }));
    }

    deleteCategory<T>(categoryId: number): Observable<T> {
        const endpointUrl = `${this.categoryUrl}/${categoryId}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.deleteCategory(categoryId));
            }));
    }

    getCategoriesByMouldTypeId<T>(mouldTypeId: number): Observable<T> {
        const endpointUrl = `${this.categoryUrl}/GetCategoriesByMouldTypeId/${mouldTypeId}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getAllCategories());
            }));
    }

    validateFormByMouldTypeId<T>(mouldTypeId: number): Observable<T> {
        const endpointUrl = `${this.categoryUrl}/ValidateForm/${mouldTypeId}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.validateFormByMouldTypeId(mouldTypeId));
            }));
    }
}

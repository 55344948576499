<div
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.component-dark-theme]="themeService.isDark()"
    class="h-100vh pt-50 pb-50"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="logout-box text-center bg-white border-radius ms-auto me-auto">
                <div class="logo">
                    <img alt="logo" src="assets/img/logo.png">
                </div>
                <div class="logo white-logo">
                    <img alt="logo" src="assets/img/white-logo.png">
                </div>
                <img alt="mail" src="assets/img/icon/mail.png">
                <h5 class="fw-semibold mb-12">Success !</h5>
                <p>A email has been send to hello@tagus.com. Please check for an email from company and click on the
                    included link to reset your password.</p>
                <a class="tagus d-block fw-semibold" mat-flat-button routerLink="/">Back To Home</a>
            </div>
        </div>
    </div>
</div>

import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileEndpointService } from 'src/app/services/file-endpoint.service';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
    @ViewChild('fileInput') fileInput: ElementRef;
    selectedFile: File | null = null;
    previewImageUrl: string | null = null;
    imageSelectedValue: any;
    @Output() imageUrlChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(public activeModal: NgbActiveModal,
        private fileEndpoint: FileEndpointService,) { }

    handleFileChange(event: any): void {
        const file = event.target.files?.[0];

        if (file) {
            this.selectedFile = file;

            // Use FileReader to display a preview image
            const reader = new FileReader();
            reader.onload = (e) => {
                this.previewImageUrl = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        } else {
            this.selectedFile = null;
            this.previewImageUrl = null;
        }
    }

    uploadImage(): void {
        debugger
        // Implement the logic to upload the image and obtain the image URL
        // For simplicity, you can assume that the image is immediately uploaded and the URL is obtained
        this.fileEndpoint.uploadImage(this.selectedFile)
            .subscribe((response: any) => {
                // this.controlNameArray.find(x=>x.name == this.UnitRefernceControlName).loading = true

                this.imageSelectedValue = response.value;
                console.log('img', this.imageSelectedValue);
                this.imageUrlChange.emit(this.imageSelectedValue);
                // this.inputDescriptionArray[0].get(this.UnitRefernceControlName)
                //     .setValue(response.value)
                //this.saveValue(response.value, this.UnitRefernceControlName);
            },
                (error) => {

                    console.error('', error)
                });
        // Close the modal and pass the image URL back to the calling component
        this.activeModal.close();
    }

    closeModal(): void {
        this.activeModal.dismiss();
    }
}

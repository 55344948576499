import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MouldTypeList } from 'src/app/models/mould-type-list';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
    selector: 'app-units-filter',
    templateUrl: './units-filter.component.html',
    styleUrls: ['./units-filter.component.scss']
})
export class UnitsFilterComponent implements OnInit {
    @Input() moulTypesList: MouldTypeList[] = [];
    mouldsTypes: MouldTypeList[] = [];
    @Input() orignalMoulTypesList: MouldTypeList[] = [];
    range: FormGroup = new FormGroup({});
    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    filteredMouldTypes: Observable<MouldTypeList[]>;
    mouldTypeCtrl = new FormControl();

    @Output() applyFilterChanged: EventEmitter<any> = new EventEmitter();
    dateRangeFilterApplied: boolean = false;
    moultypesFilterApplied: boolean = false;
    @Input() initialFilterData: any;
    originalMoulTypesList: MouldTypeList[] = [];

    @ViewChild('mouldTypeInput') mouldTypeInput: ElementRef<HTMLInputElement>;

    constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private announcer: LiveAnnouncer) { }

    ngOnInit(): void {
        this.setupDateRangeForm();
        this.prepopulateFilters();

        this.filteredMouldTypes = this.mouldTypeCtrl.valueChanges.pipe(
            startWith(null),
            map((mouldType: string | null) => (mouldType ? this._filter(mouldType) : this.orignalMoulTypesList.slice())),
        );
    }

    prepopulateFilters() {
        // Load the saved filter settings from local storage
        const savedFilterSettings = localStorage.getItem('unitsFiltersSetting');
        if (savedFilterSettings) {
            const filterSettings = JSON.parse(savedFilterSettings);
            this.range.setValue(filterSettings.range);
            this.mouldsTypes = filterSettings.mouldsTypes;
        }

        if (this.range) {
            // Prepopulate the entire FormGroup using patchValue
            this.range.patchValue(this.range);
        }

        // Reset the mouldsTypes to an empty array
        this.orignalMoulTypesList = this.mouldsTypes.slice(); // Copy the original data
    }

    setupDateRangeForm() {
        this.range = this.fb.group({
            start: null,
            end: null
        });
    }

    applyFilter() {
        this.dateRangeFilterApplied = this.range.get('start').value !== null &&
            this.range.get('end').value !== null;
        this.moultypesFilterApplied = this.mouldsTypes.length > 0;
        const unitsFiltersSetting = {
            range: this.range.value,
            mouldsTypes: this.mouldsTypes
        };

        localStorage.setItem('unitsFiltersSetting', JSON.stringify(unitsFiltersSetting));
        // Emit the event as before
        this.applyFilterChanged.emit({ range: this.range, mouldsTypes: this.mouldsTypes });
    }

    clearFilters() {
        this.range.reset(); // Reset the date range form
        this.mouldsTypes = this.orignalMoulTypesList.slice(); // Reset to the original data
        localStorage.removeItem('unitsFiltersSetting');
        // Optionally, you can also emit an event to inform other components of the filter reset:
        this.applyFilterChanged.emit({ range: this.range, mouldsTypes: this.mouldsTypes });
    }

    decline() {
        this.activeModal.close();
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            // Check if the value is already in the mouldTypesList
            const existingMouldType = this.moulTypesList.find(mouldType => mouldType.name === value);

            if (existingMouldType) {
                // If it exists, add it to mouldsTypes
                this.mouldsTypes.push({ id: existingMouldType.id, name: existingMouldType.name });
            }
        }

        event.chipInput!.clear();
    }


    remove(mouldType: MouldTypeList): void {
        const index = this.mouldsTypes.indexOf(mouldType);

        if (index >= 0) {
            this.mouldsTypes.splice(index, 1);
        }
    }

    edit(mouldType: MouldTypeList, event: MatChipEditedEvent) {
        const value = event.value.trim();

        if (!value) {
            this.remove(mouldType);
        } else {
            const index = this.mouldsTypes.indexOf(mouldType);
            if (index >= 0) {
                this.mouldsTypes[index].name = value;
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        // Check if the selected value is already in the mouldsTypes
        const existingMouldType = this.mouldsTypes.find(mouldType => mouldType.name === event.option.viewValue);

        if (!existingMouldType) {
            // If it doesn't exist, add it to mouldsTypes
            this.mouldsTypes.push({ id: 0, name: event.option.viewValue });
        }

        this.mouldTypeInput.nativeElement.value = '';
        this.mouldTypeCtrl.setValue(null);
    }

    private _filter(value: string): MouldTypeList[] {
        const filterValue = value.toLowerCase();

        return this.orignalMoulTypesList.filter(mouldType => mouldType.name.toLowerCase()
            .includes(filterValue));
    }
}

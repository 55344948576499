import { Injectable } from '@angular/core';
import { UnitEndpointService } from './unit-endpoint.service';
import { UnitList } from '../models/unit-list.model';
import { UnitRequest } from '../models/unit-request.model';
import { Observable } from 'rxjs';
import { Unit } from '../models/unit.model';

@Injectable({
    providedIn: 'root'
})
export class UnitService {

    constructor(private unitEndpoint: UnitEndpointService) {
    }

    getUnits(page?: number, pageSize?: number, searchTerm?: string, sortColumn?: string, sortOrder?: string, startDate?: string, endDate?: string, mouldsTypeIds?: string) {
        return this.unitEndpoint.getUnitsEndpoint<Unit[]>(page, pageSize, searchTerm, sortColumn, sortOrder, startDate, endDate, mouldsTypeIds);
    }

    getUnitList() {
        return this.unitEndpoint.getUnitsListEndpoint<UnitList[]>();
    }

    createUnit(unit: any) {
        return this.unitEndpoint.getNewUnitEndpoint<any>(unit);
    }

    updateUnit(unit: UnitRequest, id: number) {
        return this.unitEndpoint.getUpdateUnitEndpoint<Unit>(unit, id);
    }

    getUnit(workplaceId?: string) {
        return this.unitEndpoint.getUnitEndpoint<Unit>(workplaceId);
    }

    deleteUnit(unitOrUnitId: string | Unit): Observable<Unit> {
        if (typeof unitOrUnitId === 'string' || unitOrUnitId instanceof String) {
            alert(unitOrUnitId);
            return this.unitEndpoint.getDeleteUnitEndpoint<Unit>(unitOrUnitId as string);
        } else {
            if (unitOrUnitId) {
                return this.deleteUnit(unitOrUnitId.id);
            } else {
                throw new Error("Invalid unitOrUnitId"); // Add this line to handle the case where id does not exist
            }
        }
    }

    getUnitDuplicateStatus(unitName?: string) {
        return this.unitEndpoint.getUnitDuplicateStatusEndpoint<boolean>(unitName);
    }

    generateUnitName(name: string) {
        return this.unitEndpoint.generateUnitName<string>(name);
    }

    getUnitByRfid(RFID : string){
        return this.unitEndpoint.getUnitByRfidEndpoint<Unit>(RFID)
    }
}

<header class="pageHeader">
    <h3>
        <i aria-hidden="true"
           class="fa fa-exclamation-circle fa-lg page-caption"></i> {{'pageHeader.NotFound' }}
    </h3>
</header>

<div [@fadeInOut] class="d-flex h-75">
    <div class="m-auto">
        <div class="icon-container"><i class='fa fa-exclamation-circle'></i> {{'notFound.404' }}</div>
        <div class="text-muted error-description">{{'notFound.pageNotFound' }}</div>
        <div>
            <a class="btn btn-primary" routerLink="/">
                <i class='fa fa-home'></i> {{'notFound.backToHome' }}
            </a>
        </div>
    </div>
</div>

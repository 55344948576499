import { Injectable } from '@angular/core';
import { CategoryEndpointService } from './category-endpoint.service';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    constructor(private categoryEndpoint: CategoryEndpointService) { }

    createCategory(name: string, mouldTypeId: number, order: number) {
        return this.categoryEndpoint.createCategory(name, mouldTypeId, order)
    }

    getAllCategories() {
        return this.categoryEndpoint.getAllCategories()
    }

    updateCategory(categoryId: number, name: string, mouldTypeId: number, order: number) {
        return this.categoryEndpoint.updateCategory(categoryId, name, mouldTypeId, order)
    }

    deleteCategory(categoryId: number) {
        return this.categoryEndpoint.deleteCategory(categoryId)
    }

    getCategoriesByMouldTypeId(mouldTypeId: number) {
        return this.categoryEndpoint.getCategoriesByMouldTypeId(mouldTypeId)
    }

    validateFormByMouldTypeId(mouldTypeId: number) {
        return this.categoryEndpoint.validateFormByMouldTypeId(mouldTypeId)
    }
}

<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Enter value</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeDialog()" aria-label="Close"></button>
        </div>
        <div class="modal-body">

            <div class="input-group mt-1" *ngFor="let item of inputTypeValue;index as i">
                <span class="input-group-text">Item {{i+1}}</span>
                <input type="text" aria-label="First name" [(ngModel)]="item.value" placeholder="Enter Item value" class="form-control">
                <button [class.cancleButtonColor]="inputTypeValue[i]?.id > -1"
                        [class.saveButtonColor]="inputTypeValue[i]?.id < 0" class="btn mx-1" [disabled]="!inputTypeValue[i]?.value" (click)="inputTypeValue[i]?.id > -1 ? removeItem(i,item.id):addValue(item)">
                    {{ inputTypeValue[i]?.id > -1 ? '-' : '+' }}
                </button>
            </div>


        </div>
        <div class="modal-footer">
            <button type="button" class="btn cancleButtonColor" data-bs-dismiss="modal" (click)="closeDialog()">Close</button>
            <button type="button" class="btn saveButtonColor" data-bs-dismiss="modal" (click)="saveItems()">Save</button>
        </div>
    </div>
</div>

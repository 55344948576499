import { Injectable } from '@angular/core';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TestResultsEndpointService extends EndpointBase {

    get testResultssUrl() { return this.configurations.baseUrl + '/api/testResults'; }
    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)

    }
    getAddTestResultsEndpoint<T>(testResultsObject?: any): Observable<T> {

        return this.http.post<T>(this.testResultssUrl, JSON.stringify(testResultsObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getAddTestResultsEndpoint(testResultsObject));
            }));
    }

    getUpdateTestResultsEndpoint<T>(testResultsObject?: any): Observable<T> {
        return this.http.put<T>(this.testResultssUrl, JSON.stringify(testResultsObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateTestResultsEndpoint(testResultsObject));
            }));
    }


    getTestResultsEndpoint<T>(testId: number, page?: number, pageSize?: number, searchTerm?: string, searchName?: string, sortColumn?: string, sortOrder?: string, startDate?: string, endDate?: string): Observable<T> {
        const endpointUrl = page && pageSize ? `${this.testResultssUrl}/TestResultsByTest?testId=${testId}&pageNumber=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&searchName=${searchName}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}` : this.testResultssUrl;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getTestResultsEndpoint(testId));
            })
        );
    }

    getTestResultEndpoint<T>(testResultId: number): Observable<T> {
        const endpointUrl = `${this.testResultssUrl}/${testResultId}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getTestResultEndpoint(testResultId));
            })
        );
    }

    getDeleteTestResultEndpoint<T>(testResultId: number): Observable<T> {
        const endpointUrl = `${this.testResultssUrl}/${testResultId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDeleteTestResultEndpoint(testResultId));
            }));
    }

    getDeleteTestResultValueEndpoint<T>(testResultId: number, parameterId: number): Observable<T> {
        const endpointUrl = `${this.testResultssUrl}/DeleteTestResultValue/${testResultId}/${parameterId}`;
        console.log(endpointUrl);
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDeleteTestResultEndpoint(testResultId));
            }));
    }
}

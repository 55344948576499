import { Component, EventEmitter, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Description } from 'src/app/models/description.model';
import { CategoryService } from 'src/app/services/category.service';
import { DescriptionService } from 'src/app/services/description.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-tab-delete-confirmation',
  templateUrl: './tab-delete-confirmation.component.html',
  styleUrls: ['./tab-delete-confirmation.component.scss']
})
export class TabDeleteConfirmationComponent {
    public deleteChanged = new EventEmitter<boolean>();
    @Input() row: number;
    @Input() id: number;

    activeTab: number = 0;
    myFormTab: any;
    tabs: { id: number; title: string; content: string; isEditing: boolean }[] =
    [
        {
            id: 0,
            title: 'Category 0',
            content: 'Tab Content 0',
            isEditing: false,
        },
    ];
    constructor(public activeModal: NgbActiveModal,
        private categoryService: CategoryService,
        private toastr: ToastrService,
      ) { }
    confirm() {
        this.deleteFactoryHelper(this.row);
      }

    get items() {
      return this.myFormTab.get('items') as FormArray;
    }

    deleteFactoryHelper(row: any) {
      this.toastr.success('Deleting...');
      const categoryId = this.id;
      const c = this.row;
      this.categoryService.deleteCategory(categoryId).subscribe({
          next: (_) => {
              this.toastr.success(`Tab has been deleted successfully.`);
              this.tabs = this.tabs.filter((tab) => tab.id !== row);

              this.deleteChanged.emit(true);
          },
          error: (error) => {
              this.toastr.error(
                  'Delete Error',
                  `An error occurred whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(
                      error
                  )}"`
              );
          },
      });
  }

    decline() {
      this.activeModal.close();
    }
}

import {Component} from '@angular/core';

@Component({
    selector: 'app-view-mould-type',
    templateUrl: './view-mould-type.component.html',
    styleUrls: ['./view-mould-type.component.scss']
})
export class ViewMouldTypeComponent {

}

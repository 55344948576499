<!-- <h5 class="mb-15">{{title}}</h5>
<mat-card
    class="mb-25 tagus-card"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <mat-card-content> -->
<!-- another method -->
<!-- <form [formGroup]="FormData" role="form" name="myForm" (ngSubmit)="onSubmit(FormData.value)" class="mb-25">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 posRelative" (mouseover)="dislayDeletBtn(i)"  (focus)="onFocus(i)"  id="id_{{i}}"       *ngFor="let inputsDesc of getInputDescription(); let i=index">
            <div class="tagus-form-group">
                <label class="d-block mb-12 fw-semibold gray-color">Label: <span>*</span></label>
                <mat-form-field appearance="fill" class="input-container">
                  <i class="fa fa-database"></i>
                  <input matInput  class="fullWidth" id="idInput_{{i}}"  type="text"      [disabled]="addDisabledTypeInput" [formControl]="$any(inputsDesc)"  (keydown)="getInputId($event,i)"  placeholder="Add text label of input type"/>
                    <div style="display: inline-block; text-align: right;position: absolute;right: 0%">
                        <mat-select [value]="defaultValue">
                            <mat-option [value]="defaultValue" hidden   [selected]="defaultValue.selected == true" >{{defaultValue}}</mat-option>
                            <mat-option *ngFor="let cntrlType of ControlTypes" [value]="cntrlType.value" >
                                {{cntrlType.viewValue}}
                            </mat-option>
                        </mat-select>
                    </div>

                </mat-form-field>

              </div>
              <button (click)="deleteItem(i)"  id="{{i}}" *ngIf="displayInputDeleteBtn && indexDeleteBtn == i && countInputs!=0"   class="bg-red dltTabPosDescription "> <i class="ri-delete-bin-line clr-white" > </i></button>

          </div>

    </div>
</form> -->
<!--save section -->
<!-- <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
        <div style="text-align: left;" class="mb-3 example-buttons">
            <button mat-stroked-button [disabled]="addDisabledButtonInput"  class="gray text-primary" (click)="addItem()" style="width:50% ; border-color: #c1d3dc; padding-left: 27px;   padding-right: 27px; padding-top: 8px;
            padding-bottom: 5px;  background: white;"><i class="ri-add-line" style="font-size: 12px;"></i> Add </button>
        </div>

    </div>
</div>
</mat-card-content>
</mat-card> -->

<ng-content select="ng-container"></ng-content>

import { NgModule, isDevMode } from '@angular/core';
import { NgbActiveModal, NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatMenuModule } from '@angular/material/menu';
import { FullCalendarModule } from '@fullcalendar/angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NgxEditorModule } from 'ngx-editor';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatCardModule } from '@angular/material/card';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgApexchartsModule } from "ng-apexcharts";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgChartsModule } from 'ng2-charts';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ColorPickerModule } from 'ngx-color-picker';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './components/common/sidebar/sidebar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FileUploaderComponent } from './components/forms/file-uploader/file-uploader.component';
import { TimelineComponent } from './components/pages/timeline/timeline.component';
import { SearchComponent } from './components/pages/search/search.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { InternalErrorComponent } from './components/common/internal-error/internal-error.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { RegisterComponent } from './components/authentication/register/register.component';
import { SigninSignupComponent } from './components/authentication/signin-signup/signin-signup.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { ConfirmMailComponent } from './components/authentication/confirm-mail/confirm-mail.component';
import { LockScreenComponent } from './components/authentication/lock-screen/lock-screen.component';
import { ToastaModule } from 'ngx-toasta';
import { AlertService } from './services/alert.service';
import { ConfigurationService } from './services/configuration.service';
import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { OidcHelperService } from './services/oidc-helper.service';
import { ThemeManager } from './services/theme-manager';
import { OAuthModule } from 'angular-oauth2-oidc';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserInfoComponent } from './components/settings/user/user-info/user-info.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';

import {
    UsersManagementComponent
} from './components/settings/user/user-mangement/app-users-management/users-management.component';
import {
    UserPreferencesComponent
} from './components/settings/user/user-mangement/user-preferences/user-preferences.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BootstrapTabDirective } from './directives/bootstrap-tab.directive';
import {
    RolesManagementComponent
} from './components/settings/roles/roles-mangement/roles-management/roles-management.component';
import { RoleEditorComponent } from './components/settings/roles/role-editor/role-editor/role-editor.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { GroupByPipe } from './pipes/group-by.pipe';
import { SearchBoxComponent } from './components/settings/search-box/search-box/search-box.component';
import { EqualValidator } from './directives/equal-validator.directive';
import { FactoryListComponent } from './components/factories/factory-list/factory-list.component';
import { FactoryService } from './services/factory.service';
import { FactoryEndpointService } from './services/factory-endpoint.service';
import { AddEditFactoryComponent } from './components/factories/add-edit-factory/add-edit-factory.component';
import { WorkplacesListComponent } from './components/workplaces/workplaces-list/workplaces-list.component';
import { AddEditWorkplaceComponent } from './components/workplaces/add-edit-workplace/add-edit-workplace.component';
import { ViewFactoryComponent } from './components/factories/view-factory/view-factory.component';
import { ViewWorkplaceComponent } from './components/workplaces/view-workplace/view-workplace.component';
import { AddEditWorkspaceComponent } from './components/workspaces/add-edit-workspace/add-edit-workspace.component';
import { ViewWorkspaceComponent } from './components/workspaces/view-workspace/view-workspace.component';
import { WorkspacesListComponent } from './components/workspaces/workspaces-list/workspaces-list.component';
import { WorkplaceService } from './services/workplace.service';
import { WorkspaceService } from './services/workspace.service';
import { WorkplaceEndpointService } from './services/workplace-endpoint.service';
import { WorkspaceEndpointService } from './services/workspace-endpoint.service';
import { AddEditMouldComponent } from './components/moulds/add-edit-mould/add-edit-mould.component';
import { MouldListComponent } from './components/moulds/mould-list/mould-list.component';
import { MouldEndpointService } from './services/mouldServices/mould-endpoint.service';
import { MouldService } from './services/mouldServices/mould.service';
import { ViewMouldComponent } from './components/moulds/view-mould/view-mould.component';
import { ToastrModule } from 'ngx-toastr';
import { DeleteFactoryComponent } from './components/factories/delete-factory/delete-factory.component';
import { DeleteWorkplaceComponent } from './components/workplaces/delete-workplace/delete-workplace.component';
import { DeleteWorkspaceComponent } from './components/workspaces/delete-workspace/delete-workspace.component';
import { DeleteUserComponent } from './components/settings/user/delete-user/delete-user.component';
import { DeleteRoleComponent } from './components/settings/roles/delete-role/delete-role.component';
import { DeleteMouldComponent } from './components/moulds/delete-mould/delete-mould.component';
import { HomeComponent } from './components/home/home.component';
import { UnitDescriptionComponent } from './components/unit-description/unit-description.component';
import { UnitVisualisationComponent } from './components/unit-visualisation/unit-visualisation.component';
import {
    UnitTabsContentDescriptionComponent
} from './components/unit-tabs-content-description/unit-tabs-content-description.component';
import { MouldTypeListComponent } from './components/mould-types/mould-type-list/mould-type-list.component';
import {
    AddEditMouldTypeComponent
} from './components/mould-types/add-edit-mould-type/add-edit-mould-type.component';
import { ViewMouldTypeComponent } from './components/mould-types/view-mould-type/view-mould-type.component';
import { DeleteMouldTypeComponent } from './components/mould-types/delete-mould-type/delete-mould-type.component';
import { NavUnitDescriptionComponent } from './components/nav-unit-description/nav-unit-description.component';
import { UnitListComponent } from './components/units/unit-list/unit-list.component';
import { AddEditUnitComponent } from './components/units/add-edit-unit/add-edit-unit.component';
import { ViewUnitComponent } from './components/units/view-unit/view-unit.component';
import { DeleteUnitComponent } from './components/units/delete-unit/delete-unit.component';
import { UnitsFilterComponent } from './components/units/units-filter/units-filter.component';
import { DeleteConfirmationDialogComponent } from './components/unit-description/tabs-content/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { TabDeleteConfirmationComponent } from './components/unit-description/tabs-content/delete-confirmation-dialog/tab-delete-confirmation/tab-delete-confirmation.component';
import { ActiveReportComponent } from './components/active-report/active-report.component';
import { AccountComponent } from './components/pages/account/account.component';
import { TabsContentComponent } from './components/unit-description/tabs-content/tabs-content.component';
import { DateDayPipe, DateFormatPipe, DateTime24HoursPipe, DateTimePipe, TimePipe } from './pipes/date-format.pipe';
import { ContentComponent } from './components/unit-visualisation/content/content.component';

import { ImagePreviewComponent } from './shared/components/image-preview/image-preview.component';
import { DropdownItemComponent } from './components/unit-description/dropdown-item/dropdown-item.component';
import { ValidateContentComponent } from './components/unit-visualisation/validate-content/validate-content.component';
import { DescriptionValueEndpointService } from './services/description-value-endpoint.service';
import { DescriptionValueService } from './services/description-value.service';
import { DeleteTestComponent } from './components/views/delete-test/delete-test.component';
import { AddEditTestComponent } from './components/views/add-edit-test/add-edit-test.component';
import { TestListComponent } from './components/views/test-list/test-list.component';
import { TestService } from './services/testServices/test.service';
import { TestEndpointService } from './services/testServices/test-endpoint.service';
import { ViewTestComponent } from './components/views/view-test/view-test.component';
import { ViewTestTypeComponent } from './components/views/view-testType/view-testType.component';
import { TestParametersComponent } from './components/views/test-parameters/test-parameters.component';

import { DeleteTestTypeComponent } from './components/views/delete-testType/delete-testType.component';
import { AddEditTestTypeComponent } from './components/views/add-edit-testType/add-edit-testType.component';
import { TestTypeListComponent } from './components/views/testType-list/testType-list.component';
import { TestTypeService } from './services/testTypeServices/testType.service';
import { TestTypeEndpointService } from './services/testTypeServices/testType-endpoint.service';
import { TestParametersService } from './services/testParametersServices/test-parameters.service';
import { TestParametersEndpointService } from './services/testParametersServices/test-parameters-endpoint.service';
import { testTypeForTestListComponent } from './components/views/testTypeForTest-list/testTypeForTest-list.component';
import { SelectListDialogComponent } from './components/views/select-list-dialog/select-list-dialog.component';

import { SelectIntervalDialogComponent } from './components/views/select-interval-dialog/select-interval-dialog.component';
import { TestParametersNewComponent } from './components/views/test-parameters-new/test-parameters-new.component';
import { DeleteTestParameterComponent } from './components/views/delete-test-parameter/delete-test-parameter.component';

import { AddEditTestResultComponent } from './components/views/add-edit-test-result/add-edit-test-result.component';
import { AddTestResultsComponent } from './components/add-test-results/add-test-results.component';
import { TestParameterContentComponent } from './components/add-test-results/test-parameter-content/test-parameter-content.component';
import { TestResultsService } from './services/testResultsServices/test-results.service';
import { TestResultsEndpointService } from './services/testResultsServices/test-results-endpoint.service';
import { TestResultsListComponent } from './components/test-results-list/test-results-list.component';
import { DeleteTestResultComponent } from './components/delete-test-result/delete-test-result.component';
import { EditTestResultsValuesComponent } from './components/edit-test-results-values/edit-test-results-values.component';
import { TestResultsFilterComponent } from './components/test-results-filter/test-results-filter.component';

import { LoadingComponent } from './components/shared/loading/loading.component';
import { LoadingService } from './services/loader/loading.service';
import { SchmidtCubeService } from './services/schmidt-cube.service';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

import { WebcamModule } from 'ngx-webcam';
import { ResultDeleteConfirmationComponent } from './components/add-test-results/result-delete-confirmation/result-delete-confirmation.component';

import { ImageUploadComponent } from './components/shared/image-upload/image-upload.component';

import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { ServiceWorkerModule } from '@angular/service-worker';
import { UploadSignatureComponent } from './components/shared/upload-signature/upload-signature.component';
import { DeleteDescriptionValueComponent } from './components/units/delete-description-value/delete-description-value.component';
import { RemoveTestResultsValues } from './components/edit-test-results-values/remove-test-results-values/remove-test-results-values.component';

@NgModule({
    declarations: [
        AppComponent,
        SidebarComponent,
        FooterComponent,
        HeaderComponent,
        HomeComponent,
        FileUploaderComponent,
        TimelineComponent,
        SearchComponent,
        NotFoundComponent,
        InternalErrorComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        LoginComponent,
        RegisterComponent,
        SigninSignupComponent,
        LogoutComponent,
        ConfirmMailComponent,
        LockScreenComponent,
        UserInfoComponent,
        UsersManagementComponent,
        UserPreferencesComponent,
        BootstrapTabDirective,
        RolesManagementComponent,
        RoleEditorComponent,
        GroupByPipe,
        SearchBoxComponent,
        EqualValidator,
        FactoryListComponent,
        AddEditFactoryComponent,
        WorkplacesListComponent,
        AddEditWorkplaceComponent,
        ViewFactoryComponent,
        AddEditMouldComponent,
        MouldListComponent,
        ViewMouldComponent,
        ViewWorkplaceComponent,
        AddEditWorkspaceComponent,
        ViewWorkspaceComponent,
        WorkspacesListComponent,
        DeleteFactoryComponent,
        DeleteWorkplaceComponent,
        DeleteWorkspaceComponent,
        DeleteUserComponent,
        DeleteRoleComponent,
        DeleteMouldComponent,
        UnitDescriptionComponent,
        UnitVisualisationComponent,
        UnitTabsContentDescriptionComponent,
        MouldTypeListComponent,
        AddEditMouldTypeComponent,
        ViewMouldTypeComponent,
        DeleteMouldTypeComponent,
        NavUnitDescriptionComponent,
        UnitListComponent,
        AddEditUnitComponent,
        ViewUnitComponent,
        DeleteUnitComponent,
        DeleteDescriptionValueComponent,
        RemoveTestResultsValues,
        UnitsFilterComponent,
        DeleteConfirmationDialogComponent,
        TabDeleteConfirmationComponent,
        TabsContentComponent,
        ActiveReportComponent,
        AccountComponent,
        DateFormatPipe,
        DateDayPipe,
        DateTimePipe,
        TimePipe,
        DateTime24HoursPipe,
        ContentComponent,
        ImagePreviewComponent,
        ValidateContentComponent,
        TestListComponent,
        AddEditTestComponent,
        DeleteTestComponent,
        ViewTestComponent,
        DeleteTestTypeComponent,
        AddEditTestTypeComponent,
        TestTypeListComponent,
        ViewTestTypeComponent,
        TestParametersComponent,
        testTypeForTestListComponent,
        SelectListDialogComponent,
        SelectIntervalDialogComponent,
        TestParametersNewComponent,
        DeleteTestParameterComponent,
        AddEditTestResultComponent,
        AddTestResultsComponent,
        TestParameterContentComponent,
        TestResultsListComponent,
        DeleteTestResultComponent,
        EditTestResultsValuesComponent,
        TestResultsFilterComponent,
        LoadingComponent, 
        ResultDeleteConfirmationComponent,
        DropdownItemComponent,
        ImageUploadComponent,
        UploadSignatureComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatMenuModule,
        MatCardModule,
        MatTableModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        NgApexchartsModule,
        MatProgressBarModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        NgScrollbarModule,
        FormsModule,
        FullCalendarModule,
        MatNativeDateModule,
        ReactiveFormsModule,
        CarouselModule,
        NgxEditorModule,
        DragDropModule,
        HttpClientModule,
        CdkAccordionModule,
        AngularSignaturePadModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader
            }
        }),
        NgChartsModule,
        NgxMatTimepickerModule,
        NgxDropzoneModule,
        ColorPickerModule,
        ToastaModule.forRoot(),
        NgSelectModule,
        NgxDatatableModule,
        OAuthModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 900,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        ReactiveFormsModule,
        AngularSignaturePadModule,
        MatDatepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        WebcamModule,
        NgxMatMomentModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        NgbActiveModal,
        DatePipe,
        AlertService,
        ThemeManager,
        ConfigurationService,
        AppTitleService,
        AppTranslationService,
        NotificationService,
        NotificationEndpoint,
        AccountService,
        AccountEndpoint,
        LocalStoreManager,
        OidcHelperService,
        FactoryService,
        FactoryEndpointService,
        DescriptionValueEndpointService,
        DescriptionValueService,
        MouldEndpointService,
        MouldService,
        WorkplaceService,
        WorkplaceEndpointService,
        WorkspaceEndpointService,
        WorkspaceService,
        TestService,
        TestEndpointService,
        TestTypeService,
        TestTypeEndpointService,
        TestParametersService,
        TestParametersEndpointService,
        TestResultsService,
        TestResultsEndpointService,
        LoadingService,
        SchmidtCubeService,
        NgbTimepickerModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

import { Component, OnChanges } from '@angular/core';
import { CustomizerSettingsService } from '../customizer-settings/customizer-settings.service';
import { CategoryService } from 'src/app/services/category.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TabDeleteConfirmationComponent } from './tabs-content/delete-confirmation-dialog/tab-delete-confirmation/tab-delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

export class Tab {
    constructor(
        public id: number,
        public label: string,
        public content: string,
        public dialog: MatDialog
    ) { }
}

@Component({
    selector: 'app-unit-description',
    templateUrl: './unit-description.component.html',
    styleUrls: ['./unit-description.component.scss'],
})
export class UnitDescriptionComponent {
    title: string;
    inc = 1;
    tabs: { id: number; title: string; content: string; isEditing: boolean }[] =
        [
            {
                id: 0,
                title: 'Category 0',
                content: 'Tab Content 0',
                isEditing: false,
            },
        ];
    activeTab: number = 0;
    lengthValue: number;
    addDisabledButtonInput: boolean = true;
    addDisabledTypeInput: boolean = false;
    myFormTab: any;
    selectTabDynamic: any;
    TabSelctedval: any;
    TabSelctedvalNow: any;
    editMode: boolean = false;
    editedLabel: string = '';
    Countclick: any = 0;
    disableInputTab: boolean = true;
    mouldTypeId: number;

    constructor(
        public themeService: CustomizerSettingsService,
        private categoryService: CategoryService,
        private route: ActivatedRoute,
        private modalService: NgbModal,
    ) { }
    ngOnInit() {
        // Call the getCategories method on initialization to populate tabs based on existing categories

        this.route.queryParams.subscribe((params) => {
            const mouldTypeId = +params['mouldTypeId'];

            if (mouldTypeId) {
                this.loadCategoriesByMouldType(mouldTypeId);
            }
        });
        this.inc = this.tabs.length;
    }
    loadCategoriesByMouldType(mouldTypeId: number) {
        this.categoryService.getCategoriesByMouldTypeId(mouldTypeId).subscribe(
            (categories: any[]) => {
                this.tabs = categories.map((category) => {
                    return {
                        id: category.id,
                        title: category.name,
                        content: category.content,
                        isEditing: false,
                    };
                });
                // this.activeTab = this.tabs.length > 0 ? 0 : -1;
            },
            (error) => {
                console.error('Error fetching categories by mouldTypeId:', error);
            }
        );
    }

    selectLastTab() {
        this.activeTab = this.tabs.length - 1; // Select the last tab after the data is loaded
    }
    readOnlyUntouched() {
        this.Countclick = 0;
        this.disableInputTab = true;
    }

    addTab(title: string, content: string) {
        // this.inc++;
        this.inc = this.getMaxExistingValueFromDatabase() + 1;
        title = 'Category ' + this.inc;
        const name = title; // Name of the category
        this.route.queryParams.subscribe((params) => {
            this.mouldTypeId = +params['mouldTypeId'];
        });
        const order = this.tabs.length + 1; // Incrementing order for the new category

        this.categoryService.createCategory(name, this.mouldTypeId, order).subscribe(
            (response: any) => {
                // Handle the response if necessary, such as updating the local tabs with the new category
                this.tabs.push({
                    id: response.id,
                    title,
                    content,
                    isEditing: false,
                });



                this.route.queryParams.subscribe((params) => {
                    const mouldTypeId = +params['mouldTypeId'];

                    if (mouldTypeId) {
                        this.loadCategoriesByMouldType(mouldTypeId);
                    }
                });
                this.activeTab = this.tabs.length - 1;
            },

            (error) => {
                console.error('Error creating category:', error);
                // Handle error scenario if needed
            }
        );
    }

    getMaxExistingValueFromDatabase(): number {
        const existingTabNumbers = this.tabs.map(tab => {
            const categoryNumber = tab.title.split(' ')[1];
            const parsedNumber = parseInt(categoryNumber, 10); // Parsing to an integer
            return isNaN(parsedNumber) ? 0 : parsedNumber; // Return 0 for non-numeric cases
        });
        return Math.max(...existingTabNumbers, 0);
    }


    selectTab(index: number) {
        this.activeTab = index;
    }
    renameTab(tab: any) {
        const newLabel = prompt('Enter a new label for the tab:', tab.title);
        if (newLabel !== null) {
            tab.title = newLabel;
        }
    }
    startEditingTabLabel(i: number) {
        this.tabs[i].isEditing = true;
        document.getElementById('enable').focus();
    }

    finishEditingTabLabel(index: number) {
        const name = this.tabs[index].title.trim();
        const categoryId = this.tabs[index].id; // Assuming 'id' holds the category ID
        this.route.queryParams.subscribe((params) => {
            // Access mouldTypeId from the query parameters
            this.mouldTypeId = +params['mouldTypeId'];
        });

        if (name && categoryId) {
            this.categoryService
                .updateCategory(categoryId, name, this.mouldTypeId, 1)
                .subscribe(
                    (updatedCategory) => {
                        this.tabs[index].title = name; // Make sure the response has the property Name
                        this.tabs[index].isEditing = false;
                        this.editMode = false;
                    },
                    (error) => {
                        console.error('Error updating category name:', error);
                        // Handle error scenario, if needed
                    }
                );
            this.tabs[index].isEditing = false;
            this.editMode = false;
        } else {
            console.error('Category name or ID cannot be empty.');
            // Handle scenario where the name or ID is empty, show an error message or handle as needed
        }
    }

    removeTab(index: number) {
        const categoryId = this.tabs[index]?.id;
        if (categoryId) {
            // Make the API call to delete the category by its ID
            this.categoryService.deleteCategory(categoryId).subscribe(
                () => {
                    // Category successfully deleted from the backend, now remove it from the UI
                    this.tabs.splice(index, 1);

                    // Update the activeTab if it was the last tab being removed
                    if (this.activeTab >= this.tabs.length) {
                        this.activeTab = this.tabs.length - 1;
                    }
                },
                (error) => {
                    console.error('Error deleting category:', error);
                }
            );
        }
    }
    deleteDescription(index: number, id: number) {
        id = this.tabs[index]?.id;
        this.activeTab = this.tabs.length - 2;
        const modalRef = this.modalService.open(
            TabDeleteConfirmationComponent,
            {
                size: 'lg',
                backdrop: 'static'
            }
        );
        modalRef.componentInstance.row = index;
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.deleteChanged.subscribe((data) => {
            if (data) {

                this.modalService.dismissAll();
                this.route.queryParams.subscribe((params) => {
                    const mouldTypeId = +params['mouldTypeId'];

                    if (mouldTypeId) {
                        this.loadCategoriesByMouldType(mouldTypeId);
                    }
                });

            }
        })
    }
}

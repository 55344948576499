export class SelectListItem {
  model :{
    
    value? :string;
  }
  constructor() {
    this.model = {
      
    value: ''
    }
  }
}
  
  // Define the structure of the request payload
  export class TestParameterRequest {
   model : {
    columnName: string;
    columnType: string;
    columnValues: string;
    testId? :number;
    intervals?: Array<{ min: number, max: number, mouldTypeId: number }>;
    selectList: SelectListItem[];
    mandatory : boolean;
    testParameterRepeatValue : boolean ;
    // You can add 'userId' and 'createdBy' if needed, or set them on the backend
     userId?: string;
     createdBy?: string;

   }

   constructor() {
    this.model = {
     columnName: '',
     columnType: '',
     columnValues: '',
     testId :0,
     intervals: [],
     mandatory :  false,
     testParameterRepeatValue: false,
     selectList:  [],
     userId :'',
     createdBy:''
    }
}
 

  }
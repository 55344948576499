<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Description</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Description</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div>
        <div class="row">
            <ul class="nav nav-tabs ps-0 mt-0 tabContainerUnitDescription" id="nav-tab" role="tablist" #myTab>
                <li class="posRelative HeaTabs tabMargin" *ngFor="let tab of tabs; let i = index">
                    <input [readonly]="disableInputTab" [ngClass]="i === activeTab ? 'inputTabStyle activeTabStyle' : 'NotActiveinputTabStyle'" class="nav-link" (dblclick)="startEditingTabLabel(i)" (click)="selectTab(i)" [(ngModel)]="tab.title" *ngIf="!tab.isEditing; else editModeTemplate" style="cursor: pointer;">
                    <button (click)="deleteDescription(i)" *ngIf="i === activeTab" class="deleteTab bg-red dltTabPos"><i class="ri-delete-bin-line clr-white"></i></button>
                    <ng-template #editModeTemplate>
                        <input id="enable"
                               class="nav-link"
                               [ngClass]="i === activeTab ? 'inputTabStyle activeTabStyle' : 'NotActiveinputTabStyle'"
                               [(ngModel)]="tabs[i].title"
                               (blur)="finishEditingTabLabel(i)"
                               (keyup.enter)="finishEditingTabLabel(i)">
                    </ng-template>
                </li>
                <li>
                    <button class="btn btn-light" style="border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px" (click)="addTab('Category '+inc, '')">+</button>
                </li>
            </ul>
        </div>
        <div class="tab-content">
            <div class="tab-pane" [class.show]="i === activeTab" [class.active]="i === activeTab" *ngFor="let tab of tabs; let i = index">
                <app-tabs-content [catId]="tab.id"></app-tabs-content>
            </div>
        </div>
    </div>
</mat-card>

<form class="search-box position-relative">
    <input
        #searchInput
        (input)="onValueChange($event.target.value)"
        [placeholder]="placeholder"
        class="input-search d-block"
        type="text"
    >

    <button (click)="clear()" type="button">
        <i *ngIf="searchInput.value === ''" class="ri-search-line"></i>
        <i *ngIf="searchInput.value !== ''" class="fa fa-times-circle"></i>
    </button>
</form>


<div class="modal-header">
    <h5 class="modal-title" id="staticBackdropLabel">{{inputTypeName}}</h5>

    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close fs-sm" tabindex="-1"
            title="Close" type="button">
    </button>
</div>
<div class="modal-body text-center">
    <ng-container>
        <signature-pad #signaturePad [options]="signaturePadOptions" (drawStart)="drawStart($event)"
                       (drawEnd)="drawComplete($event)"></signature-pad>
    </ng-container>
</div>
<div class="modal-footer">
    <div class="text-end">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 example-buttons" style="text-align: right;">
                    <button (click)="saveSignatureAsImage()" color="warn" class="mx-3" mat-flat-button>Update</button>
                    <button (click)="closeModal()" class="cancel-btn" mat-flat-button>Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

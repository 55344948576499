import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { catchError, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DescriptionValueEndpointService extends EndpointBase {

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get unitsUrl() {
        return this.configurations.baseUrl + '/api/descriptionValue';
    }

    getUpdateDescriptionValueEndpoint<T>(unitObject: any, id?: number): Observable<T> {
        const endpointUrl = `${this.unitsUrl}/${id}`;

        return this.http.put<T>(endpointUrl, JSON.stringify(unitObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateDescriptionValueEndpoint(unitObject, id));
            }));
    }

    getDescriptionValueEndpoint<T>(unitId?: number): Observable<T> {
        const endpointUrl = `${this.unitsUrl}/GetDescriptionValuesTimeLine/${unitId}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDescriptionValueEndpoint(unitId));
            })
        );

    }

    createDescriptionValue<T>(descriptionValueModel?: any): Observable<T> {
        const endpointUrl = `${this.unitsUrl}`;
        const command = { Model: descriptionValueModel };
        return this.http.post<T>(endpointUrl, JSON.stringify(command), this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDescriptionValueEndpoint(descriptionValueModel));
            })
        );

    }

    getDescriptionValues(categoryId: number, unitId: number): Observable<any> {
        const endpointUrl = `${this.unitsUrl}/GetDescriptionValues/${categoryId}?unitId=${unitId}`;

        return this.http.get<any>(endpointUrl, this.requestHeaders).pipe(
            catchError<any, Observable<any>>(error => {
                return this.handleError(error, () => this.getDescriptionValues(categoryId, unitId));
            })
        );

    }

    getDuplicateDescriptionValue(value: string, control: string, unitId: number): Observable<any> {
        const endpointUrl = `${this.unitsUrl}/validateDuplicateName?value=${value}&control=${control}&unitId=${unitId}`;

        return this.http.get<any>(endpointUrl, this.requestHeaders).pipe(
            catchError<any, Observable<any>>(error => {
                return this.handleError(error, () => this.getDuplicateDescriptionValue(value, control, unitId));
            })
        );

    }

    deleteDescriptionValueEndpoint<T>(descriptionId?: number): Observable<T> {
        debugger
        const endpointUrl = `${this.unitsUrl}/${descriptionId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.deleteDescriptionValueEndpoint(descriptionId));
            })
        );

    }

    deleteDescriptionValueByUnitEndpoint<T>(descriptionValId: number, unitId: number, descriptionId?: number): Observable<T> {
        debugger
        const endpointUrl = `${this.unitsUrl}/DeleteDescriptionValue?id=${descriptionId}&descriptionValId=${descriptionValId}&unitId=${unitId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.deleteDescriptionValueByUnitEndpoint(descriptionValId, unitId, descriptionId));
            })
        );

    }
}

export type PermissionNames =
    'View Users' | 'Manage Users' |
    'View Roles' | 'Manage Roles' | 'Assign Roles' |
    'View Homepage' | 'Manage Homepage' |'View Units'| 'Manage Units' |
    'View Configuration' | 'Manage Configuration' | 'View Active Report' | 'Manage Active Report' |
    'View Tests' |'Manage Tests' |'Add Tests'|'Edit Tests'
    ;

export type PermissionValues =
    'users.view' | 'users.manage' |
    'roles.view' | 'roles.manage' | 'roles.assign'
    | 'homepage.view' | 'homepage.manage' | 'configuration.view' | 'configuration.manage' |
    'units.view' | 'units.manage' | 'active-report.view' | 'active-report.manage' |
    'tests.view' | 'tests.manage'|'tests.add'| 'tests.edit';

export class Permission {

    public static readonly viewUsersPermission: PermissionValues = 'users.view';
    public static readonly manageUsersPermission: PermissionValues = 'users.manage';

    public static readonly viewRolesPermission: PermissionValues = 'roles.view';
    public static readonly manageRolesPermission: PermissionValues = 'roles.manage';
    public static readonly assignRolesPermission: PermissionValues = 'roles.assign';
    public static readonly viewHomepagePermission: PermissionValues = 'homepage.view';
    public static readonly manageHomepagePermission: PermissionValues = 'homepage.manage';

    public static readonly viewUnitsPermission: PermissionValues = 'units.view';
    public static readonly manageUnitsPermission: PermissionValues = 'units.manage';

    public static readonly viewConfigurationsPermission: PermissionValues = 'configuration.view';
    public static readonly manageConfigurationsPermission: PermissionValues = 'configuration.manage';

    public static readonly viewActiveReportPermission: PermissionValues = 'active-report.view';
    public static readonly manageActiveReportPermission: PermissionValues = 'active-report.manage';

    public static readonly viewTestsPermission: PermissionValues = 'tests.view';
    public static readonly addTestsPermission: PermissionValues = 'tests.add';
    public static readonly editTestsPermission: PermissionValues = 'tests.edit';
    public static readonly manageTestsPermission: PermissionValues = 'tests.manage';

    public name: PermissionNames;
    public value: PermissionValues;
    public groupName: string;
    public description: string;

    constructor(name?: PermissionNames | any, value?: PermissionValues | any, groupName?: string | any, description?: string | any) {
        this.name = name;
        this.value = value;
        this.groupName = groupName;
        this.description = description;
    }
}

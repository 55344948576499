<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Visualisation</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Visualisation</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()" [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div>
        <div class="row">
            <ul class="nav nav-tabs ps-0 mt-0 tabContainerUnitDescription" id="nav-tab" role="tablist" #myTab>
                <li class="posRelative HeaTabs tabMargin" *ngFor="let tab of tabs; let i = index">
                    <input [readonly]="disableInputTab" [ngClass]="i === activeTab ? 'inputTabStyle activeTabStyle' : 'NotActiveinputTabStyle'" class="nav-link" (dblclick)="startEditingTabLabel(i)" (click)="selectTab(i)" [(ngModel)]="tab.title" *ngIf="!tab.isEditing; else editModeTemplate" style="cursor: pointer;">
                    <ng-template #editModeTemplate>
                        <input id="enable"
                               class="nav-link"
                               [ngClass]="i === activeTab ? 'inputTabStyle activeTabStyle' : 'NotActiveinputTabStyle'"
                               [(ngModel)]="tabs[i].title"
                               (blur)="finishEditingTabLabel(i)"
                               (keyup.enter)="finishEditingTabLabel(i)">
                    </ng-template>
                </li>
            </ul>
        </div>
        <div class="tab-content" *ngIf="mould != null && tabs.length > 0">
            <div class="tab-pane" [class.show]="i === activeTab" [class.active]="i === activeTab" *ngFor="let tab of tabs; let i = index">
                <app-content *ngIf="i === activeTab" (dataEvent)="handleDataFromChild($event)" [createdUnitId]="uniId" [mould]="mould" [catId]="tab.id" [index]="i"></app-content>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div style="text-align: left;" class="mb-3 example-buttons">
                    <button *ngIf="activeTab > 0" mat-stroked-button class="gray text-primary" (click)="previous()"
                            style="width:49.5%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white;">
                        <i class="ri-arrow-left-s-line" style="font-size: 16px;"></i> Previous
                    </button>
                </div>
            </div>
            <div class="col-lg-6">
                <div style="text-align: right;" class="mb-3 example-buttons">
                    <button mat-stroked-button class="gray text-primary"
                            (click)="activeTab === tabs.length - 1 ? validate() : next()"
                            style="width:49.5%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white;">
                        {{ activeTab === tabs.length - 1 ? 'Validate' : 'Next' }}
                        <i *ngIf="activeTab !== lastIndex" class="ri-arrow-right-s-line" style="font-size: 16px;"></i>
                    </button>
                </div>
            </div>
        </div>

    </div>
</mat-card>

// loading.component.ts
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loader/loading.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    animations: [
        trigger('fadeInOut', [
            state('void', style({ opacity: 0 })),
            transition(':enter, :leave', [animate(1000)])
        ])
    ]
})
export class LoadingComponent implements OnInit {
    loading: boolean = false;

    constructor(public loadingService: LoadingService) { }

    ngOnInit() {
        this.loadingService.isLoading$.subscribe((loading) => {
            this.loading = loading;
        });
    }
}

<div class="filter-container">
    <mat-card class="mb-25 tagus-card" (click)="$event.stopPropagation();">
        <mat-card-content>

            <mat-card-header class="d-block">
                <div class="row control-box justify-content-between">

                    <div class="col-12 text-start full-width-container">
                        <mat-form-field appearance="fill" class="example-full-width full-width-form-field">
                            <mat-label>Enter a date range</mat-label>
                            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                                <input matStartDate formControlName="start" placeholder="Start date" (click)="$event.stopPropagation();">
                                <input matEndDate formControlName="end" placeholder="End date" (click)="$event.stopPropagation();">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                            <mat-error *ngIf="range.controls['start'].hasError('matStartDateInvalid')">Invalid start date</mat-error>
                            <mat-error *ngIf="range.controls['end'].hasError('matEndDateInvalid')">Invalid end date</mat-error>
                        </mat-form-field>
                        <!-- <span class="d-block gray-color mt-15">Selected range: {{ range.value | json }}</span> -->
                    </div>
                </div>
            </mat-card-header>
        </mat-card-content>
    </mat-card>
    <div class="modal-footer">
        <button mat-flat-button class="gray icon-button-small" title="Clear" (click)="clearFilters()">
            <mat-icon>clear</mat-icon>
        </button>
        <div class="button-space"></div> <!-- Add space between the buttons -->
        <button mat-flat-button color="warn" title="Filter" class="icon-button-small"
         (click)="applyFilter()" mat-small>
            <mat-icon class="ri-filter-fill"></mat-icon>
        </button>
    </div>
</div>
<mat-card class="mb-25 tagus-card p-0">
    <mat-card-content>
        <div class="create-dialog-box scrollable-dialog">
            <form #f="ngForm" (ngSubmit)="f.form.valid ? save() :
              (!roleName.valid && showErrorAlert('Role name is required', 'Please enter a role name (minimum of 2 and maximum of 200 characters)'));" *ngIf="formResetToggle" name="roleEditorForm"
                  novalidate>
                <div class="row mb-5">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                                   class="d-block fw-semibold"
                                   for="roleName">{{'roles.editor.Name' | translate}}</label>
                            <input #roleName="ngModel" [(ngModel)]="roleEdit.name" [disabled]="!canManageRoles" [ngClass]="{'is-valid':  roleName.valid, 'is-invalid' :  !roleName.valid}"
                                   class="input-control" id="roleName"
                                   maxlength="200"
                                   minlength="2"
                                   name="roleName" placeholder="Enter role name" required type="text" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                                   class="d-block fw-semibold"
                                   for="roleDescription">
                                {{
'roles.editor.Description' |
                                translate
                                }}
                            </label>
                            <input [(ngModel)]="roleEdit.description" [disabled]="!canManageRoles" [ngClass]="{'is-valid':  roleName.valid, 'is-invalid' :  !roleName.valid}" class="input-control"
                                   id="roleDescription" name="roleDescription"
                                   placeholder="Enter role description"
                                   type="text" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-5">
                            <span *ngIf="showValidationErrors && roleName?.touched && !roleName.valid" class="invalid-feedback">
                                {{'roles.editor.RoleNameRequired' | translate}}
                            </span>
                        </div>
                    </div>
                    <!--******************************************************************************************************-->
                    <div class="row">
                        <div class="col-sm-12 mat-card card-body bg-light well-sm permissionsRow mx-3 px-0 mb-5">
                            <div class="row">
                                <mat-accordion>
                                    <mat-expansion-panel *ngFor="let permissionGroup of allPermissions | groupBy: 'groupName'; let i = index"
                                                         [expanded]="i === openPanelIndex"
                                                         (opened)="openPanel(i)"
                                                         (closed)="closePanel(i)">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="col-md-5 col-form-label group-name">
                                                    {{ permissionGroup.key }}
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <div class="col-md-7">
                                            <div *ngFor="let permission of permissionGroup.value" class="form-check">
                                                <input [(ngModel)]="selectedValues[permission.value]"
                                                       [disabled]="!canManageRoles"
                                                       class="form-check-input" id="checkboxes-{{permission.value}}"
                                                       name="checkboxes-{{permission.value}}"
                                                       type="checkbox">
                                                <label class="d-block fw-semibold" for="checkboxes-{{permission.value}}"
                                                       ngbTooltip="permission.description">{{permission.name}}</label>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>

                            </div>
                        </div>

                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div *ngIf="canManageRoles" class="float-start">
                            <a (click)="selectAll()" class="btn gray btn-link text-link btn-select"
                               href="javascript:" mat-flat-button mat-small>
                                <mat-icon>check_box</mat-icon>{{
'roles.editor.SelectAll' |
                                translate
                                }}
                            </a>|<a (click)="selectNone()" class="btn gray btn-link text-link btn-select"
                                    href="javascript:" mat-flat-button mat-small><mat-icon>check_box_outline_blank</mat-icon>{{'roles.editor.SelectNone' | translate}}</a>
                        </div>
                    </div>

                    <div class="col-7">
                        <div class="text-end">
                            <button (click)="cancel()" *ngIf="!canManageRoles" class="btn gray text-primary"
                                    type="button" mat-flat-button>
                                {{'roles.editor.Close' | translate}}
                            </button>
                            <button (click)="cancel()" *ngIf="canManageRoles" [disabled]="isSaving"
                                    class="btn gray text-primary button-spacing" mat-flat-button
                                    type="button">
                                {{'roles.editor.Cancel' | translate}}
                            </button>
                            <!-- <button *ngIf="canManageRoles" type="submit" class="btn btn-primary" [disabled]="isSaving">
                                <i *ngIf="!isSaving" class='ri-edit-fill'></i><i *ngIf="isSaving" class='fa fa-circle-o-notch fa-spin button-spacing'></i>  {{isSaving ? ('roles.editor.Saving' | translate) : ('roles.editor.Save' | translate)}}
                              </button> -->
                            <button (click)="save()" *ngIf="canManageRoles" [disabled]="isSaving" class="tagus"
                                    mat-flat-button>
                                <span *ngIf="isSaving" aria-hidden="true" class="gray"
                                      role="status"></span>
                                <span *ngIf="isSaving; else saveText">{{('roles.editor.Saving' | translate)}}</span>
                                <ng-template #saveText>{{('roles.editor.Save' | translate)}}</ng-template>
                            </button>
                        </div>
                    </div>
                </div>

            </form>

        </div>
    </mat-card-content>
</mat-card>

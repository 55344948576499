import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UnitEndpointService } from 'src/app/services/unit-endpoint.service';

@Component({
    selector: 'app-delete-unit',
    templateUrl: './delete-unit.component.html',
    styleUrls: ['./delete-unit.component.scss']
})
export class DeleteUnitComponent {

    unitId: number;
    unitTitle: string;

    constructor(private unitService: UnitEndpointService, public activeModal: NgbActiveModal,
        private toastr: ToastrService, private router: Router) { }

    closeModal(): void {
        this.activeModal.dismiss();
    }

    confirm() {
        this.unitService
            .getDeleteUnitEndpoint(this.unitId.toString())
            .subscribe((res) => {
                this.toastr.success(`Unit ${this.unitTitle} has been deleted successfully.`, 'Remove Unit');
                this.router.navigateByUrl('/units');
                this.activeModal.close();
            });
    }

    decline() {
        this.activeModal.close();
    }
}

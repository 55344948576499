<ng-container>
    <form [formGroup]="testParametersForm" (ngSubmit)="onSubmit()" class="mb-25">
        <div class="row" formArrayName="parameters">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngFor="let paramGroup of parameters.controls; let i = index"
                [formGroupName]="i">
                <div class="tagus-form-group">
                    <div class="tagus-element-group">
                    <label class="d-block mb-12 fw-semibold gray-color">
                        <input *ngIf="rowId === 0" type="checkbox"  formControlName="myCheckbox" class="form-check-input bg-secondary checkBoxPosDescription" title="Repeat">
                   
                        {{ formData[i].columnName }}
                        <span *ngIf="formData[i].mandatory">*</span>
                       
                    </label>
                </div>
                    <!-- Text Input -->
                    
                        
                    <mat-form-field *ngIf="formData[i].columnType === 'Text'" appearance="fill" class="input-container">
                        <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescriptionForSLListText"  formControlName="myCheckbox" title="Repeat"> -->
                    
                        <i [style.color]=" getColor(i,'textValue')" [class]="getIconClass('textValue')+ ' icon-spacing'"></i>
                        <input matInput placeholder="Enter {{ formData[i].columnName }}" formControlName="textValue">

                          
                    </mat-form-field>
                    
              
                    <!-- SelectList Input -->
                    
                    
                    <mat-form-field *ngIf="formData[i].columnType === 'SelectList'" appearance="fill"
                        class="input-container">
                        <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescriptionForSLListText"  formControlName="myCheckbox" title="Repeat"> -->
                        <i [style.color]=" getColor(i,'selectValue')" [class]="getIconClass('selectValue')+ ' icon-spacing'"></i>
                        <mat-select placeholder="Select {{ formData[i].columnName }}" formControlName="selectValue">
                            <mat-option *ngFor="let option of formData[i].selectList" [value]="option.value">
                                {{ option.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                  
                    <!-- Checkbox Inputs -->
                    
                        
                       
                    <mat-accordion *ngIf="formData[i].columnType === 'Checkbox'" class="checkbox-accordion">
                        <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescription"  formControlName="myCheckbox" title="Repeat"> -->
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <i [style.color]="getColor(i,formData[i].columnType)"
                                    [class]="getIconClass(formData[i].columnType)+ ' icon-spacing'"></i>
                                <mat-panel-title>
                                    Select Options
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <div formArrayName="checkboxValues">
                                <div *ngFor="let checkbox of formData[i].selectList; let j = index">

                                    <mat-checkbox [formControlName]="j">{{ checkbox?.value }}</mat-checkbox>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
              
                    
                
                    <div *ngIf="formData[i].columnType === 'Interval'" class="interval-group">
                         <mat-form-field appearance="fill" class="interval-field">
                            
                                <div class="input-spinner-container">
                                <input matInput placeholder="Min Value" formControlName="minValue" [readonly]="true">
                                <mat-spinner *ngIf="currentLoadingIndexForInterval === i" diameter="20" class="input-spinner"></mat-spinner>
                            </div>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="interval-field user-value-field">
                            <!-- <input  *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescriptionForInterval"  formControlName="myCheckbox" title="Repeat">                       -->
                            <i [style.color]=" getColor(i,'userValue')" [class]="getIconClass('userValue') + ' icon-spacing'"></i>
                            <input matInput type="number" placeholder="Enter value"  formControlName="userValue" [class.in-range]="isUserValueInRange(i)"
                            [class.out-of-range]="!isUserValueInRange(i)">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="interval-field">
                            <div class="input-spinner-container">
                            <input matInput placeholder="Max Value" formControlName="maxValue" [readonly]="true">
                            <mat-spinner *ngIf="currentLoadingIndexForInterval === i" diameter="20" class="input-spinner"></mat-spinner>
                                 </div>
                        </mat-form-field>
                    </div>
                    

                   
                        
                    
                    <div *ngIf="formData[i].columnType === 'SearchUnitId'" class="interval-group">
                        <mat-form-field appearance="fill" class="interval-field">
                            <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescriptionForRFID"  formControlName="myCheckbox" title="Repeat"> -->
                       
                            <i [style.color]=" getColor(i,'rfidValue')" [class]="getIconClass('rfidValue')+ ' icon-spacing'"></i>
                            <div class="input-spinner-container">
                                <input matInput type="text" formControlName="rfidValue"
                                autocomplete="off"
                                placeholder="Enter Rfid 1 Value"
                                maxLength="6"
                                pattern="[0-9]*"
                                (input)="rfidSearchSubject.next({rfid: $event.target.value, index: i})">
                                <mat-spinner *ngIf="isSearchingForUnit[i]" diameter="20"></mat-spinner>
                                </div>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="interval-field user-value-field">
                            <i [style.color]=" getColor(i,'unitValue')" [class]="getIconClass('unitValue')"></i>
                            <input matInput type="text" formControlName="unitValue" [readonly]="true">
                        </mat-form-field>
                    </div>
                    <mat-form-field *ngIf="formData[i].columnType === 'Time'" appearance="fill" class="input-container">
                        <!-- <input *ngIf="rowId === 0"  type="checkbox" class="form-check-input bg-secondary checkBoxPosDescription"  formControlName="myCheckbox" title="Repeat"> -->
                       
                        <i [style.color]="getColor(i, 'timeValue')" [class]="getIconClass('timeValue')+ ' icon-spacing'"></i>
                        <input matInput [ngxMatTimepicker]="timepicker" format="24" placeholder="Choose a time"
                                           formControlName="timeValue"
                                           [disabled]="disabled">
                                    <ngx-mat-timepicker-toggle matSuffix [for]="timepicker"></ngx-mat-timepicker-toggle>
                                    <ngx-mat-timepicker #timepicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                                        [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                                                        [enableMeridian]="enableMeridian" [hideTime]="hideTime" >
                                    </ngx-mat-timepicker>
                        <!-- <input matInput type="time" placeholder="Choose a time" formControlName="timeValue"> -->
                      </mat-form-field>
                     
                        
                    
                    <!-- Numeric Input -->
                    <mat-form-field *ngIf="formData[i].columnType === 'Numeric'" appearance="fill"
                        class="input-container">
                        <!-- <input  *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescription"  formControlName="myCheckbox" title="Repeat"> -->
                       
                        <i [style.color]=" getColor(i,'numericValue')" [class]="getIconClass('numericValue')+ ' icon-spacing'"></i>
                        <input matInput type="number" placeholder="Enter {{ formData[i].columnName }}"
                            formControlName="numericValue" (change)="onReadingValueChange($event, i)">
                    </mat-form-field>
                 
                    <!-- Image Input -->
                  
                        
                    
                    <!-- <div class="file-input-container"> -->
                    <mat-form-field appearance="fill" *ngIf="formData[i].columnType === 'Image'">
                        <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescription"  formControlName="myCheckbox" title="Repeat"> -->
                       
                        <i [style.color]=" getColor(i,'ImageValue')" [class]="getIconClass('ImageValue')+ ' icon-spacing'"></i>
                        <ng-template #newFile>
                            <mat-label>Image Name</mat-label>
                        </ng-template>
                        <input matInput readonly placeholder="Select Image" [value]="formData[i].imageName"
                            formControlName="ImageValue" readonly>

                        <button mat-icon-button matSuffix (click)="preventDefault($event); imageInput.click() ">
                            <mat-icon matSuffix class="ri-image-add-line"></mat-icon>
                        </button>
                        <input type="file" #imageInput (change)="onImageSelected($event, i)" hidden>
                    </mat-form-field>
                    <!-- </div> -->
                   


                    <!-- Signature Input -->
                   
                         
                    <div *ngIf="formData[i].columnType == 'Signature' ">
                    <mat-form-field appearance="fill" class="input-container" (click)="signaturePadPopup(i,'Signature')"
                        >
                        
                        <i [style.color]=" getColor(i,'signatureValue')" [class]="getIconClass('signatureValue')+ ' icon-spacing'"></i>
                        <mat-icon matSuffix class="ri-chat-upload-fill"></mat-icon>
                        <input matInput type="text" [placeholder]="paramGroup.get('signaturePlaceHolder').value"
                            formControlName="signatureValue">
                            <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescription"  formControlName="myCheckbox" title="Repeat"> -->

                    </mat-form-field>
                </div>
                        
                    
                    <mat-form-field appearance="fill" (click)="signaturePadPopup(i,'Handwriting')"
                        *ngIf="formData[i].columnType == 'HandWriting' ">
                        <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescription"  formControlName="myCheckbox" title="Repeat"> -->
                       
                        <i [style.color]=" getColor(i,'handWriteValue')" [class]="getIconClass('handWriteValue')+ ' icon-spacing'"></i>
                        <mat-icon matSuffix class="ri-chat-upload-fill"></mat-icon>
                        <input matInput type="text" [placeholder]="paramGroup.get('handWritePlaceHolder').value"
                            formControlName="handWriteValue">
                    </mat-form-field>
                  
                    <!-- Date Input -->
                
                    <!-- <ng-container *ngFor="let param of testParametersForm.get('parameters').controls; let i = index">
                        <mat-form-field *ngIf="formData[i].columnType === 'Date'" appearance="fill" class="input-container">
                          
                    
                          <input *ngIf="!formattedDates[i]" matInput [matDatepicker]="picker" placeholder="Choose a date"
                                 formControlName="dateValue" (dateChange)="formatDateValue(i)" type="date">
                          <mat-datepicker-toggle *ngIf="!formattedDates[i]" matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                          
                       
                          <span *ngIf="formattedDates[i]">{{ formattedDates[i] }}</span>
                        </mat-form-field>
                      </ng-container> -->
                      
                   
                    <!-- <mat-form-field *ngIf="formData[i].columnType === 'Date'" appearance="fill" class="input-container">
                       
                        <i [style.color]=" getColor(i,'dateValue')" [class]="getIconClass('dateValue')+ ' icon-spacing'"></i>
                        <input *ngIf="!formattedDates[i]" matInput [matDatepicker]="picker" 
                        [placeholder]="param?.get('dateValue')?.value ? 'Choose a date' : ''"
                        formControlName="dateValue" (dateChange)="formatDateValue(i)" type="date" readonly>
                 <mat-datepicker-toggle *ngIf="!formattedDates[i]" matSuffix [for]="picker"></mat-datepicker-toggle>
                 <mat-datepicker #picker></mat-datepicker>
                 
                 <span *ngIf="formattedDates[i]">{{ formattedDates[i] }}</span>
             
               </mat-form-field> -->
                    
               <mat-form-field *ngIf="formData[i].columnType === 'Date'" appearance="fill" class="input-container">
                       
                <i [style.color]=" getColor(i,'dateValue')" [class]="getIconClass('dateValue')+ ' icon-spacing'"></i>
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date"
                    formControlName="dateValue" [disabled]="disabled">


                    <!-- <input *ngIf="rowId === 0" type="checkbox"  formControlName="myCheckbox" title="Repeat"> -->

                    <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                                                             [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="true">
                                        <ngx-mat-datepicker-actions>
                                            <button mat-button ngxMatDatepickerCancel>Cancel</button>
                                            <button mat-raised-button color="primary" ngxMatDatepickerApply>Apply</button>
                                        </ngx-mat-datepicker-actions>
                                    </ngx-mat-datetime-picker>
               
            </mat-form-field>

               
                    <!-- DateDifference Input -->
              
                        
                    
                    <div *ngIf="formData[i].columnType === 'DateDifference'" class="date-diff-group"
                        [formGroupName]="i">
                        <mat-form-field [formGroup]="paramGroup.get('dateRange')"  appearance="fill" class="full-width-form-field">
                           
                            <i [style.color]=" getColor(i,'dateRange')" [class]="getIconClass('dateRange')+ ' icon-spacing'"></i>
                            <mat-label>Enter a date range</mat-label>
                            <mat-date-range-input  [rangePicker]="picker">
                                <input matStartDate formControlName="startDate" placeholder="Start date">
                                <input matEndDate formControlName="endDate" placeholder="End date">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                        <!-- <input *ngIf="rowId === 0" type="checkbox" class="form-check-input bg-secondary checkBoxPosDescription"  formControlName="myCheckbox" title="Repeat"> -->
                       
                    </div>
                

               
                    <!-- <input type ="checkbox"  [checked]="checkboxStates[i]" (change)="checkboxStates[i] = $event.target.checked" class="form-check-input bg-secondary checkBoxPosDescription"> -->
                
                    <!-- Math Input -->
                    <mat-form-field *ngIf="formData[i].columnType === 'Math'" appearance="fill" class="input-container">
                        <i [style.color]=" getColor(i,'mathValue')" [class]="getIconClass('mathValue')+ ' icon-spacing'"></i>
                        <input matInput placeholder="Value will be generated" formControlName="mathValue" readonly>
                    </mat-form-field>
                  

                    <!-- File Input -->
                
                        
                    
                    <div *ngIf="formData[i].columnType === 'File'" class="file-input-container">
                        <mat-form-field class="example-full-width">
                            <mat-label>Upload File</mat-label>
                            <input matInput placeholder="Choose file" [value]="formData[i].fileName" readonly>
                            <button mat-icon-button matSuffix (click)="fileInput.click()">
                                <mat-icon>folder_open</mat-icon>
                            </button>
                            <input type="file" #fileInput accept="*" (change)="onFileSelected($event, i)" hidden>
                        </mat-form-field>
                    
                    </div>  


                </div>
            </div>
        </div>

        <!-- Save Button -->
        <div class="form-footer">
            <div class="divider"></div>

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-3 example-buttons" style="text-align: right;">
                        <button type="submit" [disabled]="inProgress || isLoading || testParametersForm.invalid" 
                        class="tagus" mat-flat-button>
                            <span *ngIf="inProgress" class="spinner-border spinner-border-sm me-1" role="status"
                            aria-hidden="true"></span>
                             <span *ngIf="inProgress; else saveText">Saving...</span>
                        <ng-template #saveText>Save</ng-template>
                        </button>
                        <span class="mx-2"></span>
                        <button (click)="cancel()" class="gray text-primary" mat-flat-button>Cancel</button>
                    </div>
                </div>
            </div>

            <!-- <div class="save-button-container">
                    <button type="submit" mat-stroked-button class="save-button">Save</button>
                </div> -->



        </div>
    </form>


</ng-container>


<button type="button" class="d-none" id="menuModal" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
</button>

<div  class="modal fade" id="staticBackdrop" data-bs-backdrop="static" [attr.data-modal-id]="modalId" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true" #modalElement>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Signature </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <ng-container>
                <signature-pad #signaturePad [options]="signaturePadOptions" (drawStart)="drawStart($event)"
                    (drawEnd)="drawComplete($event)"></signature-pad>
            </ng-container>

            <div class="modal-footer">
                <button type="button" class="btn cancleButtonColor" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
                <button type="button" class="btn saveButtonColor" data-bs-dismiss="modal"
                    (click)="saveSignatureAsImage()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>
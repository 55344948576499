export interface TestResultValueRequestModel  {
    name: string ;
    type:string;
    value: string | string[];
    testParamterId: number;
   
  }
  export class TestResultValueRequest {
    model: TestResultValueRequestModel;
  
    constructor() {
      this.model = {
        name: '',
        type: '',
        value: '',
        testParamterId: 0
      
      };
    }
  }
  export class TestResultAddRequestModel  {
    testID: number;
    rfid :string;
    testResultValues: TestResultValueRequestModel[];
  }
  export class TestResultAddRequest {
    model: TestResultAddRequestModel;
  
    constructor() {
      this.model = {
        testID: 0,
        rfid :"",
        testResultValues: []
      };
    }
  }
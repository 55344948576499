import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityType } from 'src/app/models/enums';
import { MouldType } from 'src/app/models/mould-type';
import { MouldTypeRequest } from 'src/app/models/mould-type-request.model';
import { FactoryService } from 'src/app/services/factory.service';
import { MouldTypeService } from 'src/app/services/mould-type.service';
import { CustomValidators } from 'src/app/shared/custom-validators';

@Component({
    selector: 'app-add-edit-mould-type',
    templateUrl: './add-edit-mould-type.component.html',
    styleUrls: ['./add-edit-mould-type.component.scss']
})
export class AddEditMouldTypeComponent {
    mouldTypeForm: FormGroup;
    isEditMode = false;
    factoryData: MouldType;
    mouldTypeRequest: MouldTypeRequest = new MouldTypeRequest();
    mouldTypeId: number = 0;
    isLoading: boolean;
    inProgress: boolean;
    mouldTypeEnum = [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '1/2' }
    ]

    constructor(
        private fb: FormBuilder,
        private mouldTypeService: MouldTypeService,
        private factoryService: FactoryService,
        private route: ActivatedRoute,
        private router: Router,
        private toasterService: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((queryParams) => {
            if (queryParams['id']) {
                this.mouldTypeId = queryParams['id'];
                this.isEditMode = true;
                this.loadMouldtype(this.mouldTypeId.toString());
            }
        });
        this.createForm();
    }

    save(): void {
        this.isLoading = true;

        if (this.mouldTypeForm.valid) {
            if (this.inProgress) {
                return;
            }
            this.inProgress = true;
            const formData = this.mouldTypeForm.value as MouldType;
            this.mouldTypeRequest.model = { ...this.mouldTypeForm.value };
            this.mouldTypeRequest.model.id = this.mouldTypeId;
            if (this.isEditMode) {
                // Update an existing factory
                this.mouldTypeService.updateMouldType(this.mouldTypeRequest, this.mouldTypeId).subscribe(() => {
                    this.isLoading = false;
                    this.inProgress = false;
                    // Handle success or navigate to a different page
                    this.toasterService.success(`Mould type ${this.mouldTypeRequest.model.name} has been updated.`, 'Factroy Updated',);
                    this.router.navigate(['/moulds-type']);
                });
            } else {
                // Create a new factory
                this.mouldTypeService.createMouldType(this.mouldTypeRequest).subscribe(() => {
                    this.isLoading = false;
                    this.inProgress = false;
                    this.toasterService.success(`Mould type ${this.mouldTypeRequest.model.name} has been created.`, 'Factroy Created');
                    // Handle success or navigate to a different page
                    this.router.navigate(['/moulds-type']);
                });
            }
        } else {
            this.isLoading = false;
            this.inProgress = false;
            this.toasterService.error(`Kindly fill the Mould type form correctly.`, 'Mould type Failed');
        }

    }

    cancel() {
        this.router.navigate(['/moulds-type']);
    }

    private createForm(): void {
        this.mouldTypeForm = this.fb.group({
            name: ['', [Validators.required], [CustomValidators.validateNameDuplicate(this.factoryService, EntityType.MouldType, this.mouldTypeId)]],
            type: ['', [Validators.required]],
        });
    }

    private loadMouldtype(id: string): void {
        this.mouldTypeService.getMouldType(id).subscribe((factory) => {
            this.factoryData = factory;
            this.mouldTypeForm.patchValue(factory);
        });
    }
}

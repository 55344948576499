import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {MouldType} from 'src/app/models/mould-type';
import {MouldTypeService} from 'src/app/services/mould-type.service';
import {Utilities} from 'src/app/services/utilities';

@Component({
    selector: 'app-delete-mould-type',
    templateUrl: './delete-mould-type.component.html',
    styleUrls: ['./delete-mould-type.component.scss']
})
export class DeleteMouldTypeComponent {
    @Input() row: MouldType;
    @Output() deleteChanged: EventEmitter<any> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal,
                private toastr: ToastrService,
                private mouldTypeService: MouldTypeService,
    ) {
    }

    ngOnInit(): void {
        // this.afterOnInit.emit(this);

    }

    confirm() {
        this.deleteFactoryHelper(this.row);
    }

    decline() {
        this.activeModal.close();
    }

    deleteFactoryHelper(row: MouldType) {
        this.toastr.success('Deleting...');
        // this.loadingIndicator = true;

        this.mouldTypeService.deleteMouldType(row).subscribe({
            next: (_) => {
                //  this.alertService.stopLoadingMessage();
                //this.loadingIndicator = false;
                this.toastr.success('Delete Mould Type', `Mould Type ${row.name} has been deleted successfully.`);
                this.deleteChanged.emit(true);
            },
            error: (error) => {
                // this.alertService.stopLoadingMessage();
                // this.loadingIndicator = false;

                this.toastr.error(
                    'Delete Error',
                    `An error occurred whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(
                        error
                    )}"`
                );
            },
        });
    }
}

import { Injectable } from '@angular/core';
import { Unit } from '../models/unit.model';
import { DescriptionValueEndpointService } from './description-value-endpoint.service';
import { DescriptionValue, UnitModel } from '../models/description-value-model';

@Injectable()
export class DescriptionValueService {

    constructor(private descriptionValueEndpoint: DescriptionValueEndpointService) {
    }

    getDescriptionValue(unitId?: number) {
        return this.descriptionValueEndpoint.getDescriptionValueEndpoint<UnitModel>(unitId);
    }

    updateDescriptionValue(unit: DescriptionValue, id: number) {
        return this.descriptionValueEndpoint.getUpdateDescriptionValueEndpoint<Unit>(unit, id);
    }

    createDescriptionValue(descriptionValueModel: any) {
        return this.descriptionValueEndpoint.createDescriptionValue<any>(descriptionValueModel);
    }

    getDescriptionValues(categoryId: number, unitId: number) {
        return this.descriptionValueEndpoint.getDescriptionValues(categoryId, unitId);
    }

    getDuplicateDescriptionValue(value: string, control: string, unitId: number) {
        return this.descriptionValueEndpoint.getDuplicateDescriptionValue(value, control, unitId);
    }

    deleteDescriptionValue(descriptionId?: number) {
        return this.descriptionValueEndpoint.deleteDescriptionValueEndpoint<any>(descriptionId);
    }

    deleteDescriptionValueByUnit(descriptionValId: number, unitId: number, descriptionId?: number) {
        return this.descriptionValueEndpoint.deleteDescriptionValueByUnitEndpoint<any>(descriptionValId, unitId, descriptionId);
    }
}

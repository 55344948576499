<div [class.dark-sidebar]="themeService.isSidebarDark()" [class.hide-sidebar]="themeService.isHideSidebar()"
     [class.right-sidebar]="themeService.isRightSidebar()" [ngClass]="{'active': isToggled}"
     class="sidebar-area bg-white box-shadow">
    <div class="logo bg-white">
        <a class="d-flex align-items-center" routerLink="/">
            <!--            <img src="assets/img/logo-icon.png" alt="logo">-->
            <span>Minerva</span>
        </a>
    </div>
    <div (click)="toggle()" [ngClass]="{'active': isToggled}" class="burger-menu">
        <span class="top-bar"></span>
        <span class="middle-bar"></span>
        <span class="bottom-bar"></span>
    </div>
    <ng-scrollbar style="height: 100vh;" visibility="hover">
        <div class="sidebar-inner">
            <div class="sidebar-menu">
                <mat-accordion>
                    <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-menu-link" routerLink="/"
                       routerLinkActive="active">
                        <i class="flaticon-home"></i><span class="title">Home</span>
                    </a>
                    <ng-container *ngIf="canViewUnits">
                        <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-menu-link" routerLink="/units"
                           routerLinkActive="active">
                            <i class="flaticon-menu-2"></i><span class="title">Units</span>
                        </a>
                    </ng-container>

                    <ng-container *ngIf="canViewActiveReport">
                        <a [routerLinkActiveOptions]="{exact: true}" class="sidebar-menu-link"
                           routerLink="/active-report" routerLinkActive="active">
                            <i class="ri-file-chart-line"></i><span class="title">Active Report</span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="canViewConfigurations">
                        <mat-expansion-panel (closed)="panelOpenState = true" (opened)="panelOpenState = true">

                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="ri-tools-line"></i>
                                    <span class="title">Configuration</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link"
                                       routerLink="/factories" routerLinkActive="active">
                                        Factory
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link"
                                       routerLink="/workplaces" routerLinkActive="active">
                                        Workplace
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link"
                                       routerLink="/workspaces" routerLinkActive="active">
                                        Workspace
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link"
                                       routerLink="/moulds" routerLinkActive="active">
                                        Moulds
                                    </a>
                                </li>
                                <li class="sidemenu-item">
                                    <a [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link"
                                       routerLink="/moulds-type" routerLinkActive="active">
                                        Moulds Type
                                    </a>
                                </li>

                            </ul>
                        </mat-expansion-panel>
                    </ng-container>

                    <ng-container  *ngIf="canViewTests">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="ri-test-tube-line"></i>
                                    <span class="title">Quality Tests</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <li class="sidemenu-item">
                                    <a routerLink="/tests" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Tests
                                    </a>
                                </li>

                                <li class="sidemenu-item">
                                    <a routerLink="/testtypes" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link">
                                        Test Types
                                    </a>
                                </li>

                            </ul>
                        </mat-expansion-panel>

                    </ng-container>

                    <ng-container *ngIf="canManageUsers">
                        <mat-expansion-panel (closed)="panelOpenState = true" (opened)="panelOpenState = true">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <i class="flaticon-user-1"></i>
                                    <span class="title">User Management</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ul class="sidebar-sub-menu">
                                <ng-container *ngIf="canViewUsers">
                                    <li class="sidemenu-item">
                                        <a [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link"
                                           routerLink="/user-mangement" routerLinkActive="active">
                                            Users
                                        </a>
                                    </li>
                                </ng-container>

                                <ng-container *ngIf="canViewRoles">
                                    <li class="sidemenu-item">
                                        <a [routerLinkActiveOptions]="{exact: true}" class="sidemenu-link"
                                           routerLink="/role-mangement" routerLinkActive="active">
                                            Roles
                                        </a>
                                    </li>
                                </ng-container>
                            </ul>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </div>
        </div>
    </ng-scrollbar>
</div>

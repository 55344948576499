<ng-container>
    <form [formGroup]="FormData" role="form" name="myForm" class="mb-25">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 posRelative" *ngFor="let inputDesc of objDetails; let i = index" (mouseenter)="showIcons(i)" (mouseleave)="hideIcons(i)">
                <div class="tagus-form-group">
                    <label class="d-block mb-12 fw-semibold gray-color">
                        Label:
                        <span *ngIf="objDetails[i]?.isRequired">*</span>
                    </label>

                    <mat-form-field appearance="fill" class="input-container">
                        <i [class]="(selectedControlTypeEdit[i] ? getIconClassName(selectedControlTypeEdit[i]) : getIconClassName(objDetails[i].inputTypeName))"></i>
                        <input matInput name="input" class="fullWidth" id="idInput_{{i}}" type="text" [formControl]="FormData.get('inputsDescription.' + i)" (keyup.enter)="updateDescription(FormData.get('inputsDescription.' + i).value, i,type.value)" placeholder="Enter Name" />
                        <div style="display: inline-block; text-align: right;position: absolute;right: 0%">
                            <mat-select [formControlName]="'inputType' + i" (selectionChange)="selectedControlType = $event.value;onSelectChangeEdit($event, i)" placeholder="Select Type" #type>
                                <mat-option (click)="pickDropDown(cntrlType,i,objDetails[i]?.id)" *ngFor="let cntrlType of ControlTypes" [value]="cntrlType.id">
                                    {{ cntrlType.name }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-form-field>

                </div>
                <button *ngIf="iconHoverStatus[i]" id="{{i}}" (click)="enableInputForEdit(i)" class="bg-primary editTabPosDescription"> <i class="ri-pencil-line clr-white "></i></button>
                <input *ngIf="iconHoverStatus[i]" class="form-check-input bg-secondary checkBoxPosDescription" (click)="checkboxClicked(i)" [checked]="objDetails[i]?.isRequired" type="checkbox" value="" id="checkbox1">
                <button *ngIf="iconHoverStatus[i]" (click)="deleteTabContent(inputDesc)" id="{{i}}" class="bg-red dltTabPosDescription "> <i class="ri-delete-bin-line clr-white"> </i></button>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 posRelative">
                <div class="tagus-form-group">
                    <label class="d-block mb-12 fw-semibold gray-color">
                        Label:
                        <span *ngIf="additionalDataCheckboxStatus">*</span>
                    </label>

                    <mat-form-field appearance="fill" class="input-container">
                        <i [class]="
                  'fas ' + getIconClassName(selectedControlType1) ||
                  'fa-database'
                 "></i>
                        <input matInput [formControl]="additionalDataForm.get('additionalInput')" placeholder="Enter Name" />
                        <div style="display: inline-block; text-align: right;position: absolute;right: 0%">
                            <mat-select placeholder="Select Type" (selectionChange)="selectedControlType = $event.value" #selectType>
                                <mat-option (click)="onSelectChange(cntrlType)" *ngFor="let cntrlType of ControlTypes" [value]="cntrlType.id">
                                    {{ cntrlType.name }}
                                </mat-option>
                            </mat-select>
                        </div>
                    </mat-form-field>

                </div>
                <input class="form-check-input bg-secondary checkBoxPosDescription" (click)="additionalDataCheckboxClicked()" [checked]="additionalDataCheckboxStatus" type="checkbox" value="" id="checkbox1">
            </div>
        </div>
    </form>
    <!-- Save section -->
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div style="text-align: left;" class="mb-3 example-buttons">
                <button mat-stroked-button class="gray text-primary" (click)="addAndSave(selectType.value)" style="width:49.5%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white;">
                    <i class="ri-add-line" style="font-size: 16px;"></i> Add
                </button>
            </div>
        </div>
    </div>
</ng-container>

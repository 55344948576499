<header
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.component-dark-theme]="themeService.isDark()"
    [class.dark-header]="themeService.isHeaderDark()"
    [class.hide-sidebar]="themeService.isHideSidebar()"
    [class.right-sidebar]="themeService.isRightSidebar()"
    [class.rtl-enabled]="themeService.isRTLEnabled()"
    [ngClass]="{'active': isToggled, 'sticky': isSticky}"
    class="header-area bg-white border-bottom-radius box-shadow mb-25"
>
    <div class="d-md-flex align-items-center justify-content-space-between">
        <div class="header-left-side d-flex align-items-center">
            <div (click)="toggle()" [ngClass]="{'active': isToggled}" class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </div>
            <div class="position-relative" *ngIf="!production">
                <div style="color: red; font-weight: bold; background-color: yellow; font-size: 18px;">This is a test environment, please do not use it for production.</div>
            </div>
        </div>
        <ul class="header-right-side d-flex align-items-center mt-0 mb-0">
            <li>
                <button [matMenuTriggerFor]="languageMenu" class="btn p-0 border-none rounded-circle position-relative"
                        mat-icon-button>
                    <img alt="usa" src="assets/img/flag/uk.png">
                </button>
                <mat-menu #languageMenu="matMenu" class="language-menu-dropdown" xPosition="after">
                    <ul class="m-0">
                        <li>
                            <button class="d-flex align-items-center border-none" type="button">
                                <img alt="usa" src="assets/img/flag/uk.png">
                                English
                            </button>
                        </li>
                    </ul>
                </mat-menu>
            </li>
            <li>
                <button [matMenuTriggerFor]="profileMenu" class="profile-btn main-color border-none bg-transparent position-relative"
                        mat-icon-button>
                    <img alt="{{userName}}" class="rounded-circle" src="assets/img/admin.jpg">
                    {{'app.Welcome' | translate}}
                    <span class="fw-semibold">{{userName}}</span>
                </button>
                <mat-menu #profileMenu="matMenu" class="profile-menu-dropdown" xPosition="before">
                    <ul class="m-0">
                        <li>
                            <a routerLink="/account">
                                <i class="ri-user-3-line"></i> Profile
                            </a>
                        </li>
                        <li>
                            <div class="row" style="margin-left: 1px;">
                                <a class="col-md-8"><i class="ri-moon-line"></i> Dark Mode</a>
                                <button (click)="toggleTheme()" [class.active]="isDark()"
                                        class="switch-btn col-md-3"></button>
                            </div>
                        </li>
                        <li *ngIf="isUserLoggedIn">
                            <a (click)="logout()">
                                <i class="ri-shut-down-line"></i> {{'mainMenu.Logout' | translate}}
                            </a>
                        </li>

                        <li *ngIf="!isUserLoggedIn">
                            <a routerLink="/authentication/login">
                                <i class="ri-shut-down-line"></i> Login
                            </a>
                        </li>
                    </ul>
                </mat-menu>
            </li>
        </ul>
    </div>
</header>

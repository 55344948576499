<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Add Test</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/tests">
                <i class="ri-home-8-line"></i>
                Tests
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Add Test</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>
        <form [formGroup]="testForm" class="mb-25">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="tagus-form-group without-icon">
                    <label class="d-block mb-12 fw-semibold gray-color">Test</label>
                    <mat-form-field appearance="fill">
                        <mat-label>Select Test Type</mat-label>
                        <mat-select formControlName="testTypeId" placeholder="Test Type">
                            <mat-option *ngFor="let item of testTypesList" [value]="item.id">
                                {{ item.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>


            
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Name <span>*</span></label>
                        <mat-form-field appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <mat-label>Enter name</mat-label>
                            <input matInput formControlName="name"  id="nameInput">
                            <mat-icon class="valid-icon" *ngIf="testForm.get('name').valid && !testForm.get('name').hasError('nameDuplicate')">check_circle</mat-icon>
                            <mat-icon class="invalid-icon" *ngIf="testForm.get('name').hasError('nameDuplicate')">cancel</mat-icon>
                            <div class="mat-spinner" *ngIf="testForm.get('name').pending && testForm.get('name').touched">
                              <mat-progress-spinner [diameter]="30" mode="indeterminate"></mat-progress-spinner>
                            </div>

                        </mat-form-field>
                        <mat-error *ngIf="testForm.get('name').hasError('nameDuplicate')">Test name already exists.</mat-error>
                        <mat-error *ngIf="testForm.get('name').hasError('required') && testForm.get('name').touched">Test name require.</mat-error>




                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">Description</label>
                        <mat-form-field appearance="fill">
                            <i class="ri-phone-line"></i>
                            <mat-label>Enter description</mat-label>
                            <input matInput formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
               
            </div> 
        </form>
        
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div style="text-align: right;" class="mb-3 example-buttons">
                    <button mat-flat-button class="tagus" (click)="save()" [disabled]="inProgress || isLoading || testForm.invalid">
                        <span *ngIf="inProgress" class="spinner-border spinner-border-sm me-1" role="status"
                            aria-hidden="true"></span>
                        <span *ngIf="inProgress; else saveText">Saving...</span>
                        <ng-template #saveText>Save</ng-template>
                    </button>
                    <span class="mx-2"></span>
                    <button mat-flat-button class="gray text-primary" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>

    </mat-card-content>
</mat-card>
import { Component, Input, Output, EventEmitter, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-test-results-filter',
  templateUrl: './test-results-filter.component.html',
  styleUrls: ['./test-results-filter.component.scss']
})
export class TestResultsFilterComponent  implements OnInit {

  range: FormGroup = new FormGroup({});
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;


  @Output() applyFilterChanged: EventEmitter<any> = new EventEmitter();
  dateRangeFilterApplied: boolean = false;

  @Input() initialFilterData: any;
 

  //@ViewChild('mouldTypeInput') mouldTypeInput: ElementRef<HTMLInputElement>;

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private announcer: LiveAnnouncer) { }

  ngOnInit(): void {
    this.setupDateRangeForm();
   // this.prepopulateFilters();

    
  }

  prepopulateFilters() {
    // Load the saved filter settings from local storage
    const savedFilterSettings = localStorage.getItem('unitsFiltersSetting');
    if (savedFilterSettings) {
      const filterSettings = JSON.parse(savedFilterSettings);
      this.range.setValue(filterSettings.range);
     
    }

    if (this.range) {
      // Prepopulate the entire FormGroup using patchValue
      this.range.patchValue(this.range);
    }


  }

  setupDateRangeForm() {
    this.range = this.fb.group({
      start: null,
      end: null
    });
  }

  applyFilter() {
    this.dateRangeFilterApplied = this.range.get('start').value !== null &&
      this.range.get('end').value !== null;
    
    const unitsFiltersSetting = {
      range: this.range.value,

    };

    localStorage.setItem('unitsFiltersSetting', JSON.stringify(unitsFiltersSetting));
    // Emit the event as before
    this.applyFilterChanged.emit({ range: this.range});
  }

  clearFilters() {
    this.range.reset(); // Reset the date range form
 
    localStorage.removeItem('unitsFiltersSetting');
    // Optionally, you can also emit an event to inform other components of the filter reset:
    this.applyFilterChanged.emit({ range: this.range});
  }

  decline() {
    this.activeModal.close();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
debugger
    if (value) {
      // Check if the value is already in the mouldTypesList
     // const existingMouldType = this.moulTypesList.find(mouldType => mouldType.name === value);

      // if (existingMouldType) {
      //   // If it exists, add it to mouldsTypes
      //   this.mouldsTypes.push({ id: existingMouldType.id, name: existingMouldType.name });
      // }
    }

    event.chipInput!.clear();
  }


  // remove(mouldType: MouldTypeList): void {
  //  // const index = this.mouldsTypes.indexOf(mouldType);

  //   // if (index >= 0) {
  //   //   this.mouldsTypes.splice(index, 1);
  //   // }
  // }

  edit( event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
     // this.remove(mouldType);
    } else {
     // const index = this.mouldsTypes.indexOf(mouldType);
      // if (index >= 0) {
      //   this.mouldsTypes[index].name = value;
      // }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // Check if the selected value is already in the mouldsTypes
    // const existingMouldType = this.mouldsTypes.find(mouldType => mouldType.name === event.option.viewValue);

    // if (!existingMouldType) {
    //   // If it doesn't exist, add it to mouldsTypes
    //   this.mouldsTypes.push({ id: 0, name: event.option.viewValue });
    // }

    // this.mouldTypeInput.nativeElement.value = '';
    // this.mouldTypeCtrl.setValue(null);
  }

  // private _filter(value: string): MouldTypeList[] {
  //   const filterValue = value.toLowerCase();

  //   // return this.orignalMoulTypesList.filter(mouldType => mouldType.name.toLowerCase()
  //   //   .includes(filterValue));
  // }
}

import { Component, Input, NgZone, ViewChild } from '@angular/core';
import { FormGroup, FormArray, AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { DescriptionService } from 'src/app/services/description.service';
import { DeleteConfirmationDialogComponent } from './delete-confirmation-dialog/delete-confirmation-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Description } from 'src/app/models/description.model';
import { MatDialog } from '@angular/material/dialog';
import { DropdownItemComponent } from '../dropdown-item/dropdown-item.component';

@Component({
    selector: 'app-tabs-content',
    templateUrl: './tabs-content.component.html',
    styleUrls: ['./tabs-content.component.scss']
})
export class TabsContentComponent {
    @Input() catId: number
    defaultTtabCategorie: any = "categorie";
    selectedControlType: any;
    descriptions: any[] = [];
    additionalDataForm: FormGroup;
    ControlTypes: any[] = [];
    checkboxStatus: boolean[] = [];
    additionalDataCheckboxStatus: boolean = false;
    isInputDisabled: boolean = true;
    inputTypeRequiredError: string = 'Please select an Input Type.';
    iconHoverStatus: boolean[] = [];
    objDetails: any[] = [];
    inputTypeValue: any[] = [];
    inputTypeValues: any[] = [];
    descriptionId = 0;
    showIcons(index: number) {
        this.initializeIcons();
        this.iconHoverStatus[index] = true;
    }

    hideIcons(index: number) {
        this.initializeIcons();
        // Only set to false if the mouse leaves the area.
        // Adjust this logic if needed to handle other scenarios.
        this.iconHoverStatus[index] = false;
    }

    initializeIcons() {
        this.iconHoverStatus = new Array(this.descriptions.length).fill(false);
    }

    selectedControlType1: string; // Declare a variable to hold the selected control type
    selectedControlTypeEdit: any[] = [];


    onSelectChange(model: any): void {
        this.selectedControlType1 = model?.name;
    }

    onSelectChangeEdit(event: any, index: number): void {
        this.selectedControlTypeEdit[index] = event.value?.name;
    }

    getIconClassName(selectedInputType: any): string {
        switch (selectedInputType) {

            case 'Name':
                return 'ri-character-recognition-line';
            case 'UnitReference':
                return 'ri-settings-6-line';
            case 'Text':
                return 'ri-file-text-line';
            case 'Number':
                return 'ri-hashtag';
            case 'DropDown':
                return 'ri-file-list-line';
            case 'ImageUpload':
                return 'ri-chat-upload-line';
            case 'Signature':
                return 'ri-sketching';
            case 'PartialValidation':
                return 'ri-pass-valid-line';
            case 'Handwriting':
                return 'ri-hand-coin-line';
            case 'Hall':
                return 'ri-door-lock-box-line';
            case 'MouldNumber':
                return 'ri-list-ordered-2';
            case 'Date':
                return 'ri-calendar-line';
            case 'ToggleButton':
                return 'ri-toggle-line';
            case 'Time':
                return 'ri-time-line';
            default:
                return '';
        }
    }
    constructor(private descriptionService: DescriptionService,
        public themeService: CustomizerSettingsService,
        private builder: FormBuilder,
        private modalService: NgbModal,
        private ngZone: NgZone,
        public dialog: MatDialog
    ) {
        this.additionalDataForm = this.builder.group({
            additionalInput: [''],
            additionalCategory: ['']
        });
        this.FormData = this.builder.group({
            inputsDescription: this.builder.array([]),
        });
        this.descriptions.forEach((description, index) => {
            (this.FormData.get('inputsDescription') as FormArray).push(this.builder.control(description));
            const inputTypeControl = new FormControl(3);
            this.FormData.addControl('inputType' + index, inputTypeControl);
        });

    }
    FormData: FormGroup;
    public menuList: any = [
        { inputValue: '' }
    ]

    ngOnInit() {
        if (this.catId !== undefined) {
            this.loadDescriptions(this.catId);
            this.fetchControlTypes();
        } else {
            console.error("`this.catId` is undefined");
        }
        this.FormData.setControl('inputsDescription', this.builder.array([]));
    }

    createInputTypeValue(viewModel: any) {
        this.descriptionService.createInputTypeValue(viewModel).subscribe(
            (result: any) => {
                this.loadDescriptions(this.catId);
            },
            (error) => {
                console.error('Error creating inputtype value:', error);
            }
        );
    }

    deleteInputTypeValue(inputTypeValueId: number) {
        this.descriptionService.deleteInputTypeValue(inputTypeValueId).subscribe(
            (result: any) => {
                this.loadDescriptions(this.catId);
            },
            (error) => {
                console.error('Error delete inputtype value:', error);
            }
        );
    }

    pickDropDown(dropdown: any, i: number, descriptionId: number) {
        this.descriptionId = descriptionId;
        this.inputTypeValue = this.inputTypeValues[i];

        const existingObjectIndex = this.inputTypeValue.findIndex(
            item => item.id === -1 && item.descriptionId === this.descriptionId
        );

        if (existingObjectIndex === -1) {
            this.inputTypeValue.push({ id: -1, descriptionId: this.descriptionId, value: "" });
        }

        this.selectedControlTypeEdit[i] = dropdown?.name;

        if (dropdown.name == 'DropDown') {
            const arryObject = this.inputTypeValue;

            const dialogRef = this.dialog.open(DropdownItemComponent, {
                width: '550px',
                data: { arryObject }
            });

            dialogRef.componentInstance.deleteItemEvent.subscribe((param: any) => {
                this.deleteInputTypeValue(param);
            });

            dialogRef.componentInstance.saveItemEvent.subscribe((param: any) => {
                param.forEach(element => {
                    const viewModel: any = {
                        id: element.id,
                        descriptionId: this.descriptionId,
                        value: element.value
                    }
                    this.createInputTypeValue(viewModel);
                });
            });
        }
    }

    checkboxClicked(index: number) {
        this.objDetails[index].isRequired = !this.checkboxStatus[index];
        this.checkboxStatus[index] = !this.checkboxStatus[index]; // Toggle the checkbox status

    }

    fetchControlTypes() {
        this.descriptionService.getAllInputTypes().subscribe(
            (types: any) => {
                this.ControlTypes = types;
                this.FormData.get('inputType')?.setValue(this.selectedControlType);
            },
            (error) => {
                console.error('Error fetching input types:', error);
            }
        );
    }

    additionalDataCheckboxClicked() {
        this.additionalDataCheckboxStatus = !this.additionalDataCheckboxStatus; // Toggle the checkbox status
    }

    addAndSave(selectedValue: number) {
        let additionalInputControl = this.additionalDataForm.get('additionalInput');
        let categoryValue = this.catId;

        if (additionalInputControl && additionalInputControl.value) {
            const inputValue = additionalInputControl.value;

            const descriptionData = {
                Name: inputValue,
                Order: 0,
                CategoryId: categoryValue,
                InputTypeId: selectedValue,
                isRequired: this.additionalDataCheckboxStatus
            };

            this.descriptionService.createDescription(descriptionData).subscribe(
                (response) => {
                    this.loadDescriptions(descriptionData.CategoryId);
                    this.additionalDataForm.reset();

                },
                (error) => {
                    console.error('Error adding description:', error);
                }
            );
        }
    }

    get itemsControls() {
        return (this.FormData.get('items') as FormArray).controls;
    }
    get items() {
        return this.FormData.get('items') as FormArray;
    }

    name: FormControl;

    loadDescriptions(categoryId: number) {
        this.descriptionService.getDescriptionsByCategoryId(categoryId).subscribe(
            (descriptions: any[]) => {
                if (descriptions && descriptions.length > 0) {
                    this.objDetails = descriptions;
                    this.inputTypeValues = this.objDetails
                        .map(item => item.inputTypeValues);

                    this.descriptions = descriptions.map(d => {
                        return {
                            name: d.name,
                            id: d.id,
                            categoryId: categoryId,
                            required: d.isRequired,
                            inputTypeId: d.inputTypeId
                        };
                    });

                    const formControls = this.descriptions.map((description, index) => {
                        const control = this.builder.control(description.name);
                        this.FormData.addControl('inputType' + index, new FormControl(description.inputTypeId));
                        control.disable(); // Disabling the form control
                        return control;
                    });
                    const controlArray = this.FormData.get('inputsDescription') as FormArray;
                    controlArray.clear();
                    formControls.forEach(control => controlArray.push(control));
                } else {
                    this.descriptions = [];
                    const controlArray = this.FormData.get('inputsDescription') as FormArray;
                    controlArray.clear();
                }
            },
        );
    }



    disableAllInputs(): void {
        const inputsDescriptionArray = this.FormData.get('inputsDescription') as FormArray;

        if (inputsDescriptionArray) {
            inputsDescriptionArray.controls.forEach((control) => {
                control.disable();
            });
        }
    }
    enableInputByIndex(index: number): void {
        const arrayItems = this.FormData.get('inputsDescription') as FormArray;
        const inputElement = arrayItems.at(index).get("myinput")
        if (inputElement) {

            if (inputElement.disabled) {
                inputElement.enable()
            }
            else {
                inputElement.disable()
            }
        }
    }

    enableAllInputs(): void {
        const inputsDescriptionArray = this.FormData.get('inputsDescription') as FormArray;
        if (inputsDescriptionArray) {
            inputsDescriptionArray.controls.forEach((control, index) => {
                const inputElement = document.getElementById(`idInput_${index}`) as HTMLInputElement;
                if (inputElement) {
                    inputElement.disabled = false;
                }
            });
        }
    }



    enableInputForEdit(index: number) {
        const controlArray = this.FormData.get('inputsDescription') as FormArray;

        if (controlArray && controlArray.controls[index]) {
            const specificControl = controlArray.controls[index];
            specificControl.enable(); // Enables the specific form control at the provided index
        }
    }

    get inputsDescriptionControls() {
        return this.FormData.get('inputsDescription') as FormArray;
    }
    // Update an input description
    updateDescription(name: string, index: number, selectedType: number) {

        let descriptionIdToUpdate = this.descriptions[index]; // Use the appropriate ID property
        descriptionIdToUpdate.name = name;
        descriptionIdToUpdate.InputTypeId = selectedType;
        descriptionIdToUpdate.isRequired = this.checkboxStatus[index]

        this.descriptionService.updateDescription(descriptionIdToUpdate.id, descriptionIdToUpdate).subscribe(
            (updatedDescription) => {
                console.log('Updated description:', updatedDescription);

                // Disable the control in the form after successful update
                const inputsDescriptionArray = this.FormData.get('inputsDescription') as FormArray;
                const controlToDisable = inputsDescriptionArray.at(index);

                if (controlToDisable) {
                    controlToDisable.disable();
                }
            },
            (error) => {
                console.error('Error updating description:', error);
            }
        );
    }

    deleteItem(index: number) {
        const descriptionIdToDelete = this.descriptions[index].id; // Use the appropriate ID property
        this.descriptionService.deleteDescription(descriptionIdToDelete).subscribe(
            (response) => {
                console.log('Description deleted:', response);
                this.items.removeAt(index); // Remove the deleted item from the form
            },
            (error) => {
                console.error('Error deleting description:', error);
            }
        );
    }

    deleteTabContent(row: Description) {
        const modalRef = this.modalService.open(
            DeleteConfirmationDialogComponent,
            {
                size: 'lg',
                backdrop: 'static'
            }
        );
        modalRef.componentInstance.row = row;
        modalRef.componentInstance.deleteChanged.subscribe((data) => {
            if (data) {
                this.loadDescriptions(this.catId);
                this.modalService.dismissAll();
            }
        })
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

}

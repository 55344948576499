<div class="modal-header">
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close fs-sm" tabindex="-1" title="Close"
            type="button"></button>
</div>
<div class="modal-body text-center">
    <img alt="Image" class="mx-auto d-block my-5" src="assets/img/validate_image.jpg" width="150px" />

    <h1 class="delete-title">Validate</h1>
    <p>Are you sure you want to validate?</p>
    <div class="text-end">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 example-buttons" style="text-align: right;">
                    <button (click)="decline()" class="btn cancleButtonColor">No</button>
                    <span class="mx-2"></span>
                    <button (click)="confirm()" class="btn saveButtonColor">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    class="h-100vh pt-50 pb-50"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="position-relative" *ngIf="!production">
                <div style="color: red; font-weight: bold; background-color: yellow; font-size: 18px; text-align: center;">This is a test environment, please do not use it for production.</div>
            </div>
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">Minerva</h2>
                    </div>
                </div>

                <form #f="ngForm" (ngSubmit)="f.form.valid ? login() :
                          (!username.valid && showErrorAlert('Username is required', 'Please enter a valid username'));
                          (!password.valid && showErrorAlert('Password is required', 'Please enter a valid password'))" *ngIf="formResetToggle" class="login" name="loginForm"
                      novalidate>
                    <div class="login-with-socials d-md-flex align-items-center justify-content-space-between">
                        <button class="gray fw-semibold d-block me-5" mat-flat-button>
                            <img alt="Microsot" src="assets/img/icon/microsoft.png"> Login with Microsoft
                        </button>
                    </div>
                    <div class="or text-center position-relative">
                        <span class="fw-semibold fs-16">or</span>
                    </div>
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input #username="ngModel" [(ngModel)]="userLogin.userName" autocomplete="username" class="form-control"
                                       id="login-username" matInput
                                       name="username" placeholder="Enter username" required type="text">
                            </mat-form-field>
                        </div>
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input #password="ngModel" [(ngModel)]="userLogin.password" [type]="hide ? 'password' : 'text'" autocomplete="current-password"
                                       class="form-control" id="login-password"
                                       matInput name="password" placeholder="Enter password" required
                                       type="password">
                                <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                        mat-icon-button matSuffix>
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex info align-items-center justify-content-space-between">
                        <mat-checkbox [(ngModel)]="userLogin.rememberMe" class="gray-color" id="login-rememberme"
                                      name="rememberMe">
                            Remember me
                        </mat-checkbox>
                        <a class="d-inline-block main-color fw-medium" routerLink="/authentication/forgot-password">
                            Forgot
                            your password?
                        </a>
                    </div>
                    <button [disabled]="inProgress || isLoading || !f.form.valid" class="tagus d-block fw-semibold"
                            mat-flat-button>
                        <span *ngIf="inProgress" aria-hidden="true" class="spinner-border spinner-border-sm me-1"
                              role="status"></span>
                        <span *ngIf="inProgress; else saveText">Log In...</span>
                        <ng-template #saveText>Log In</ng-template>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>

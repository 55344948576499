import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SchmidtCubeService {
  private schmidtToCubeMappings =[
    {"Schmidt": 5, "Cube": -0.58},
    {"Schmidt": 6, "Cube": -0.345},
    {"Schmidt": 7, "Cube": 0},
    {"Schmidt": 8, "Cube": 0.452},
    {"Schmidt": 9, "Cube": 1.008},
    {"Schmidt": 10, "Cube": 1.665},
    {"Schmidt": 11, "Cube": 2.42},
    {"Schmidt": 12, "Cube": 3.27},
    {"Schmidt": 13, "Cube": 4.212},
    {"Schmidt": 14, "Cube": 5.243},
    {"Schmidt": 15, "Cube": 6.36},
    {"Schmidt": 16, "Cube": 7.56},
    {"Schmidt": 17, "Cube": 8.84},
    {"Schmidt": 18, "Cube": 10.197},
    {"Schmidt": 19, "Cube": 11.628},
    {"Schmidt": 20, "Cube": 13.13},
    {"Schmidt": 21, "Cube": 14.7},
    {"Schmidt": 22, "Cube": 16.335},
    {"Schmidt": 23, "Cube": 18.032},
    {"Schmidt": 24, "Cube": 19.788},
    {"Schmidt": 25, "Cube": 21.6},
    {"Schmidt": 26, "Cube": 23.465},
    {"Schmidt": 27, "Cube": 25.38},
    {"Schmidt": 28, "Cube": 27.342},
    {"Schmidt": 29, "Cube": 29.348},
    {"Schmidt": 30, "Cube": 31.395},
    {"Schmidt": 31, "Cube": 33.48},
    {"Schmidt": 32, "Cube": 35.6},
    {"Schmidt": 33, "Cube": 37.752},
    {"Schmidt": 34, "Cube": 39.933},
    {"Schmidt": 35, "Cube": 42.14},
    {"Schmidt": 36, "Cube": 44.37},
    {"Schmidt": 37, "Cube": 46.62},
    {"Schmidt": 38, "Cube": 48.887},
    {"Schmidt": 39, "Cube": 51.168},
    {"Schmidt": 40, "Cube": 53.46}
  ]

    // -0,0005*N3*N3*N3+0,064*N3*N3-0,4235*N3

  constructor() { }

  getMappings() {
    return this.schmidtToCubeMappings;
  }

  getClosestCubeValue(schmidtValue: number): number {
    const closest = this.schmidtToCubeMappings.reduce((prev, curr) => {
      return (Math.abs(curr.Schmidt - schmidtValue) < Math.abs(prev.Schmidt - schmidtValue) ? curr : prev);
    });
    return closest.Cube;
  }
}

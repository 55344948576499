import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const date = moment(value);
            return date.format('h:mm A dddd');
        }
        return '';
    }
}
@Pipe({
    name: 'dateDayFormat'
})
export class DateDayPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const date = moment(value);
            return date.format('ddd');
        }
        return '';
    }
}

@Pipe({
    name: 'timeFormat'
})
export class TimePipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const date = moment(value);
            return date.format('h:mm A');
        }
        return '';
    }
}

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimePipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const date = moment(value);
            return date.format('MM/DD/YYYY, hh:mm:ss');
        }
        return '';
    }
}

@Pipe({
    name: 'dateTime24HoursFormat'
})
export class DateTime24HoursPipe implements PipeTransform {
    transform(value: string): string {
        if (value) {
            const dateTime = moment(value);
            return dateTime.format('YYYY-MM-DD HH:mm A');
            // Adjust the format string according to your specific date-time format requirements
        }
        return '';
    }
}

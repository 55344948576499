import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { EndpointBase } from './endpoint-base.service';
import { AuthService } from './auth.service';
import { EntityType } from '../models/enums';

@Injectable()
export class FactoryEndpointService extends EndpointBase {
    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get factoriesUrl() {
        return this.configurations.baseUrl + '/api/factory';
    }

    get workspacesUrl() {
        return this.configurations.baseUrl + '/api/workspace';
    }

    get workplacesUrl() {
        return this.configurations.baseUrl + '/api/workplace';
    }

    get mouldsUrl() {
        return this.configurations.baseUrl + '/api/moulds';
    }

    get mouldsTypesUrl() {
        return this.configurations.baseUrl + '/api/mouldType';
    }

    get descriptionvalueUrl() {
        return this.configurations.baseUrl + '/api/DescriptionValue';
    }

    get testTypesUrl() {
        return this.configurations.baseUrl + '/api/testType';
    }

    get testsUrl() {
        return this.configurations.baseUrl + '/api/test';
    }

    getFactoriesEndpoint<T>(page?: number, pageSize?: number, searchTerm?: string, sortColumn?: string, sortOrder?: string): Observable<T> {
        const endpointUrl = page && pageSize ? `${this.factoriesUrl}/Allfactories?pageNumber=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&sortColumn=${sortColumn}/sortOrder?=${sortOrder}` : this.factoriesUrl;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>((error: any) => {
                return this.handleError(error, () => this.getFactoriesEndpoint(page, pageSize));
            }));
    }

    getFactoriesListEndpoint<T>(): Observable<T> {
        const endpointUrl = `${this.factoriesUrl}/factories`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>((error: any) => {
                return this.handleError(error, () => this.getFactoriesEndpoint());
            }));
    }

    getNewFactoryEndpoint<T>(factoriyObject: any): Observable<T> {

        return this.http.post<T>(this.factoriesUrl, JSON.stringify(factoriyObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getNewFactoryEndpoint(factoriyObject));
            }));
    }

    getUpdateFactoryEndpoint<T>(factoriyObject: any, id?: number): Observable<T> {
        const endpointUrl = `${this.factoriesUrl}/${id}`;

        return this.http.put<T>(endpointUrl, JSON.stringify(factoriyObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateFactoryEndpoint(factoriyObject, id));
            }));
    }

    getFactoryEndpoint<T>(factoryId?: string): Observable<T> {
        const endpointUrl = `${this.factoriesUrl}/${factoryId}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getFactoryEndpoint(factoryId));
            })
        );

    }

    getDeleteFactoryEndpoint<T>(factoryId: string): Observable<T> {
        const endpointUrl = `${this.factoriesUrl}/${factoryId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDeleteFactoryEndpoint(factoryId));
            }));
    }

    getFactoryDuplicateStatusEndpoint<T>(factoryId: number, entityType: EntityType, name?: string, dependentId?: number): Observable<T> {
        let endpointUrl: string;
        let queryString = '';

        switch (entityType) {
            case EntityType.Factory:
                queryString = `name=${name}&factoryId=${factoryId}`;
                endpointUrl = `${this.factoriesUrl}/validateDuplicateName?${queryString}`;
                break;
            case EntityType.Workplace:
                queryString = `name=${name}&workplaceId=${factoryId}`;
                endpointUrl = `${this.workplacesUrl}/validateDuplicateName?${queryString}`;
                break;
            case EntityType.Workspace:
                queryString = `name=${name}&workspaceId=${factoryId}&dependentId=${dependentId}`;
                endpointUrl = `${this.workspacesUrl}/validateDuplicateName?${queryString}`;
                break;
            case EntityType.Mould:
                queryString = `name=${name}&mouldId=${factoryId}`;
                endpointUrl = `${this.mouldsUrl}/validateDuplicateName?${queryString}`;
                break;
            case EntityType.MouldType:
                queryString = `name=${name}&mouldTypeId=${factoryId}`;
                endpointUrl = `${this.mouldsTypesUrl}/validateDuplicateName?${queryString}`;
                break;
             case EntityType.Test:                                         
                   queryString = `name=${name}&testId=${factoryId}`;         
                    endpointUrl = `${this.testsUrl}/validateDuplicateName?${queryString}`;  
                break;
            case EntityType.TestType:           
                queryString = `name=${name}&testTypeId=${factoryId}`;  
                endpointUrl = `${this.testTypesUrl}/validateDuplicateName?${queryString}`;
                break;
            default:
                // Handle a default case if needed
                break;
        }

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getFactoryDuplicateStatusEndpoint(factoryId, entityType, name, dependentId));
            })
        );
    }
}

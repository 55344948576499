<!-- mould-details.component.html -->
<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <!-- Breadcrumb and page title -->
    <h5 class="mb-0">Mould Details</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/moulds">
                <i class="ri-home-8-line"></i>
                Moulds
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">
            Mould Details
        </li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
    <mat-card-content>
        <mat-tab-group>
            <mat-tab label="Details"><br>
                <!-- Mould details content -->
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">Name</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-user-3-line"></i>
                                <mat-label>Name</mat-label>
                                <input [value]="mouldData.name" matInput readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">Workspace</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-mail-line"></i>
                                <mat-label>Workspace</mat-label>
                                <input [value]="mouldData.workspaceName" matInput readonly>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tagus-form-group">
                            <label class="d-block mb-12 fw-semibold gray-color">MouldType</label>
                            <mat-form-field appearance="fill">
                                <i class="ri-mail-line"></i>
                                <mat-label>MouldType</mat-label>
                                <input [value]="mouldData.mouldTypeName" matInput readonly>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <!-- Remove the button, as this is a read-only details page -->
                    </div>
                </div>
                <div class="text-end">
                    <button (click)="goBack()" class="default-btn tagus" mat-flat-button>
                        Back
                    </button>
                </div>
            </mat-tab>
            <!-- <mat-tab label="description<br>
            <app-descriptions-list [mouldId]="mouldId"></app-descriptions-list>
            </mat-tab> -->
        </mat-tab-group>

    </mat-card-content>
</mat-card>

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Edit Test Results</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
        <li class="breadcrumb-item">
            <a [routerLink]="['/view-testresults']" [queryParams]="{id : this.testId}">
                <i class="ri-home-8-line"></i>
                Test Results
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Edit Test Results</li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()"
          [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div>
        <div class="row">
            <ng-container>
                <form *ngIf="testParametersForm" [formGroup]="testParametersForm" (ngSubmit)="onSubmit()" class="mb-25">
                    <div class="row" formArrayName="parameters">
                        <div class="col-lg-6 col-md-6 col-sm-6"
                             *ngFor="let paramGroup of parameters.controls; let i = index" [formGroupName]="i">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">
                                    {{ formData[i].columnName }}
                                    <span *ngIf="formData[i].mandatory">*</span>
                                </label>

                                <!-- Dynamically create form fields based on the columnType -->
                                <ng-container [ngSwitch]="formData[i].columnType">
                                    <!-- Text Input -->
                                    <mat-form-field *ngSwitchCase="'Text'" appearance="fill" class="input-container">
                                        <i [style.color]=" getColor(i,'textValue')"
                                           [class]="getIconClass('textValue')"></i>
                                        <input matInput placeholder="Enter {{ formData[i].columnName }}"
                                               formControlName="textValue">
                                        <button mat-icon-button *ngIf="isVisible(i);" matSuffix (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                            <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <!-- SelectList Input -->
                                    <mat-form-field *ngSwitchCase="'SelectList'" appearance="fill"
                                                    class="input-container">
                                        <i [style.color]=" getColor(i,'selectValue')"
                                           [class]="getIconClass('selectValue')"></i>
                                        <mat-select placeholder="Select {{ formData[i].columnName }}"
                                                    formControlName="selectValue">
                                            <mat-option *ngFor="let option of formData[i].selectList"
                                                        [value]="option.value">
                                                {{ option.value }}
                                            </mat-option>
                                        </mat-select>
                                        <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                            <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <!-- Checkbox Inputs -->
                                    <mat-accordion *ngIf="formData[i].columnType === 'Checkbox'"
                                                   class="checkbox-accordion">
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Select Options
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>

                                            <div formArrayName="checkboxValues">
                                                <div *ngFor="let checkbox of formData[i].selectList; let j = index">
                                                    <!-- {{ checkbox | json }} -->
                                                    <mat-checkbox [formControlName]="j">
                                                        {{
 checkbox?.value
                                                        }}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>

                                    <div *ngIf="formData[i].columnType === 'Interval'" class="interval-group">
                                        <mat-form-field appearance="fill" class="interval-field">
                                            <div class="input-spinner-container">
                                                <input matInput placeholder="Min Value" formControlName="minValue" [readonly]="true">
                                                <mat-spinner *ngIf="currentLoadingIndexForInterval === i" diameter="20" class="input-spinner"></mat-spinner>
                                            </div>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="interval-field user-value-field">
                                            <!-- [ngClass]="getBorderColor(i)" >  -->
                                            <i [style.color]=" getColor(i,'userValue')"
                                               [class]="getIconClass('userValue')"></i>
                                            <input matInput type="number" placeholder="Enter value" formControlName="userValue" [class.in-range]="isUserValueInRange(i)"
                                                   [class.out-of-range]="!isUserValueInRange(i)">

                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="interval-field">
                                            <div class="input-spinner-container">
                                                <input matInput placeholder="Max Value" formControlName="maxValue" [readonly]="true">
                                                <mat-spinner *ngIf="currentLoadingIndexForInterval === i" diameter="20" class="input-spinner"></mat-spinner>
                                            </div>
                                        </mat-form-field>
                                    </div>


                                    <div *ngIf="formData[i].columnType === 'SearchUnitId'" class="interval-group">
                                        <mat-form-field appearance="fill" class="interval-field">
                                            <i [style.color]=" getColor(i,'rfidValue')"
                                               [class]="getIconClass('rfidValue')"></i>
                                            <div class="input-spinner-container">
                                                <input matInput type="text" formControlName="rfidValue" title="Rfid update is available in unit details only!"
                                                       placeholder="Enter Rfid 1 Value" pattern="[0-9]*" maxLength="6"
                                                       (input)="rfidSearchSubject.next({rfid: $event.target.value, index: i})" readonly>
                                                <mat-spinner *ngIf="isSearchingForUnit[i]" diameter="20"></mat-spinner>
                                            </div>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill" class="interval-field user-value-field">
                                            <i [style.color]=" getColor(i,'unitValue')"
                                               [class]="getIconClass('unitValue')"></i>
                                            <input matInput type="text" formControlName="unitValue" [readonly]="true">
                                        </mat-form-field>
                                    </div>

                                    <!-- Numeric Input -->
                                    <mat-form-field *ngSwitchCase="'Numeric'" appearance="fill" class="input-container">
                                        <i [style.color]=" getColor(i,'numericValue')"
                                           [class]="getIconClass('numericValue')"></i>
                                        <input matInput type="number" placeholder="Enter {{ formData[i].columnName }}"
                                               formControlName="numericValue" (change)="onReadingValueChange($event, i)">
                                        <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                            <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                        </button>

                                    </mat-form-field>


                                    <div *ngIf="formData[i].columnType === 'Image'">
                                        <!-- <div class="image-input-container"> -->
                                        <mat-form-field appearance="fill">
                                            <i [style.color]=" getColor(i,'ImageValue')"
                                               [class]="getIconClass('ImageValue')"></i>
                                            <mat-label>Image Name</mat-label>
                                            <input matInput placeholder="Select Image" [value]="formData[i].imageName"
                                                   formControlName="ImageValue" readonly>
                                            <button mat-icon-button matSuffix
                                                    *ngIf="isImageUrl(paramGroup.get('CurrentImage').value)"
                                                    (click)="openImagePreview(paramGroup.get('CurrentImage').value) ; preventDefault($event)">
                                                <mat-icon matSuffix class="ri-eye-line"></mat-icon>
                                            </button>
                                            <button mat-icon-button matSuffix
                                                    (click)="preventDefault($event); imageInput.click() ">
                                                <mat-icon matSuffix class="ri-image-add-line"></mat-icon>
                                            </button>
                                            <input type="file" #imageInput (change)="onImageSelected($event, i)" hidden>
                                            <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                                <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                            </button>
                                        </mat-form-field>

                                    </div>


                                    <mat-form-field appearance="fill" *ngIf="formData[i].columnType == 'Signature' ">
                                        <i [style.color]=" getColor(i,'signatureValue')"
                                           [class]="getIconClass('signatureValue')"></i>

                                        <input matInput type="text" (change)="onImageChange($event, i)"
                                               [placeholder]="paramGroup.get('signaturePlaceholder').value"
                                               formControlName="signatureValue" readonly placeholder="Select Image">
                                        <button mat-icon-button matSuffix
                                                *ngIf="isImageUrl(paramGroup.get('CurrentImage').value)"
                                                (click)="openImagePreview(paramGroup.get('CurrentImage').value) ; preventDefault($event)  ">
                                            <mat-icon matSuffix class="ri-eye-line"></mat-icon>
                                        </button>
                                        <button mat-icon-button matSuffix
                                                (click)="signaturePadPopup(i,'Signature') ; preventDefault($event)">
                                            <mat-icon matSuffix class="ri-chat-upload-fill"></mat-icon>
                                        </button>
                                        <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                            <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill" *ngIf="formData[i].columnType == 'HandWriting' ">
                                        <i [style.color]=" getColor(i,'handWriteValue')"
                                           [class]="getIconClass('handWriteValue')"></i>

                                        <input matInput type="text" (change)="onImageChange($event, i)"
                                               [placeholder]="paramGroup.get('handWritePlaceHolder').value"
                                               formControlName="handWriteValue" readonly placeholder="Select Image">
                                        <button mat-icon-button matSuffix
                                                *ngIf="isImageUrl(paramGroup.get('CurrentImage').value)"
                                                (click)="openImagePreview(paramGroup.get('CurrentImage').value) ; preventDefault($event)  ">
                                            <mat-icon matSuffix class="ri-eye-line"></mat-icon>
                                        </button>
                                        <button mat-icon-button matSuffix
                                                (click)="signaturePadPopup(i,'Handwriting') ; preventDefault($event)">
                                            <mat-icon matSuffix class="ri-chat-upload-fill"></mat-icon>
                                        </button>
                                        <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                            <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <!-- Date Input -->
                                    <mat-form-field *ngIf="formData[i].columnType === 'Date'" appearance="fill"
                                                    class="input-container">
                                        <i [style.color]=" getColor(i,'dateValue')"
                                           [class]="getIconClass('dateValue')"></i>
                                        <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date"
                                               formControlName="dateValue" [disabled]="disabled">


                                        <!-- <input *ngIf="rowId === 0" type="checkbox"  formControlName="myCheckbox" title="Repeat"> -->

                                        <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
                                        <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                                                 [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                                                                 [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="true">
                                            <ngx-mat-datepicker-actions>
                                                <button mat-button ngxMatDatepickerCancel>Cancel</button>
                                                <button mat-raised-button color="primary" ngxMatDatepickerApply>Apply</button>
                                            </ngx-mat-datepicker-actions>
                                        </ngx-mat-datetime-picker>
                                        <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                            <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <mat-form-field *ngIf="formData[i].columnType === 'Time'" appearance="fill" class="input-container">
                                        <i [style.color]="getColor(i, 'timeValue')" [class]="getIconClass('timeValue')"></i>
                                        <input matInput [ngxMatTimepicker]="timepicker" format="24" placeholder="Choose a time"
                                               formControlName="timeValue"
                                               [disabled]="disabled">
                                        <ngx-mat-timepicker-toggle matSuffix [for]="timepicker"></ngx-mat-timepicker-toggle>
                                        <ngx-mat-timepicker #timepicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                                            [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                                                            [enableMeridian]="enableMeridian" [hideTime]="hideTime">
                                        </ngx-mat-timepicker>
                                        <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                            <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                        </button>
                                    </mat-form-field>

                                    <!-- DateDifference Input -->
                                    <div *ngIf="formData[i].columnType === 'DateDifference'" class="date-diff-group"
                                         [formGroupName]="i">
                                        <mat-form-field appearance="fill" class="full-width-form-field">
                                            <i [style.color]=" getColor(i,'dateRange')"
                                               [class]="getIconClass('dateRange')"></i>
                                            <mat-label>Enter a date range</mat-label>
                                            <mat-date-range-input [formGroup]="paramGroup.get('dateRange')"
                                                                  [rangePicker]="picker">
                                                <input matStartDate formControlName="startDate"
                                                       placeholder="Start date">
                                                <!-- (dateChange)="onDateChange($event, 'startDate', i)"> -->
                                                <input matEndDate formControlName="endDate" placeholder="End date">
                                                <!-- (dateChange)="onDateChange($event, 'endDate', i)"> -->
                                            </mat-date-range-input>
                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-date-range-picker #picker></mat-date-range-picker>
                                            <button mat-icon-button matSuffix *ngIf="isVisible(i);" (click)="deleteTestValue(formData[i], i);preventDefault($event);">
                                                <mat-icon matSuffix class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>



                                    <!-- Math Input -->

                                    <mat-form-field *ngSwitchCase="'Math'" appearance="fill" class="input-container">
                                        <i [style.color]=" getColor(i,'mathValue')"
                                           [class]="getIconClass('mathValue')"></i>
                                        <input matInput type="number" placeholder="Value Will be generated"
                                               formControlName="mathValue">
                                    </mat-form-field>
                                    <!-- File Input -->
                                    <!-- Your existing File implementation -->
                                    <!-- <div *ngIf="formData[i].columnType === 'File'" class="file-input-container">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Upload File</mat-label>
                                            <input matInput placeholder="Choose file" [value]="formData[i].fileName" readonly>
                                            <button mat-icon-button matSuffix (click)="fileInput.click()">
                                                <mat-icon>folder_open</mat-icon>
                                            </button>
                                            <input type="file" #fileInput accept="*" (change)="onFileSelected($event, i)" hidden>
                                        </mat-form-field>
                                    </div> -->
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <!-- Save Button -->
                    <div class="form-footer">
                        <div class="divider"></div>

                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="mb-3 example-buttons" style="text-align: right;">
                                    <button type="submit" [disabled]="inProgress || isLoading || testParametersForm.invalid" class="tagus"
                                            mat-flat-button>
                                        <span *ngIf="inProgress" aria-hidden="true" class="spinner-border spinner-border-sm me-1"
                                              role="status"></span>
                                        <span *ngIf="inProgress; else saveText">Saving...</span>
                                        <ng-template #saveText>Save</ng-template>
                                    </button>
                                    <span class="mx-2"></span>
                                    <button (click)="cancel()" class="gray text-primary" mat-flat-button>Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>


            <button type="button" class="d-none" id="menuModal" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            </button>

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                 aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">{{popName}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <ng-container>
                            <signature-pad #signaturePad [options]="signaturePadOptions" (drawStart)="drawStart($event)"
                                           (drawEnd)="drawComplete($event)"></signature-pad>
                        </ng-container>

                        <div class="modal-footer">
                            <button type="button" class="btn cancleButtonColor" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn saveButtonColor" data-bs-dismiss="modal"
                                    (click)="saveSignatureAsImage()">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RolesChangedEventArg, RolesChangedOperation } from '../account.service';
import { MouldEndpointService } from './mould-endpoint.service';
import { Mould } from 'src/app/models/mould.model';
import { MouldRequest } from 'src/app/models/mould-request.model';
import { MouldStatus } from 'src/app/models/enums';

@Injectable()
export class MouldService {
    public static readonly roleAddedOperation: RolesChangedOperation = 'add';
    public static readonly roleDeletedOperation: RolesChangedOperation = 'delete';
    public static readonly roleModifiedOperation: RolesChangedOperation = 'modify';

    private rolesChanged = new Subject<RolesChangedEventArg>();

    constructor(private mouldEndpointService: MouldEndpointService) {
    }

    getMoulds(workspaceId: number, page?: number, pageSize?: number, searchValue?: string) {
        return this.mouldEndpointService.getMouldsEndpoint<Mould[]>(workspaceId, page, pageSize, searchValue);
    }

    createMould(mould: MouldRequest) {
        return this.mouldEndpointService.getNewMouldEndpoint<Mould>(mould);
    }

    updateMould(mould: MouldRequest, id: number) {
        return this.mouldEndpointService.getUpdateMouldEndpoint<Mould>(mould, id);
    }

    updateMouldStatus(id: number, status: MouldStatus): Observable<any> {
        return this.mouldEndpointService.getupdateMouldStatus(id, status);
    }

    getMould(mouldId?: number) {
        return this.mouldEndpointService.getMouldEndpoint<Mould>(mouldId);
    }

    deleteMould(mouldOrMouldId: number | Mould): Observable<Mould> {
        if (typeof mouldOrMouldId === 'number') {
            return this.mouldEndpointService.getDeleteMouldEndpoint<Mould>(mouldOrMouldId);
        } else {
            if (mouldOrMouldId.id) {
                return this.deleteMould(mouldOrMouldId.id);
            } else {
                throw new Error("Invalid mouldOrMouldId"); // Add this line to handle the case where id does not exist
            }
        }
    }

    updateParent(viewModel: any): Observable<any> {
        return this.mouldEndpointService.getUpdateParentEndpoint(viewModel);
    }

    updateMouldUnitId(id: number, unitId: number): Observable<any> {
        return this.mouldEndpointService.getUpdateMouldUnitIdEndpoint(id, unitId);
    }

    getMouldById(id: number): Observable<any> {
        return this.mouldEndpointService.getMouldByIdEndpoint(id);
    }

    updateMouldUnitIdNull(id: number): Observable<any> {
        return this.mouldEndpointService.getUpdateMouldUnitIdNullEndpoint(id);
    }
}

import { Component,Inject,inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TestParametersService } from 'src/app/services/testParametersServices/test-parameters.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-list-dialog',
  templateUrl: './select-list-dialog.component.html',
  styleUrls: ['./select-list-dialog.component.scss']
})
export class SelectListDialogComponent {
  listItems: { value: string }[] = [];
  testParameterId:  number;

  constructor(
    public dialogRef: MatDialogRef<SelectListDialogComponent>,
    private testParametersService: TestParametersService,
    private toasterService:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: { items: { value: string }[] ,testParameterId:  number}
  ) {
    if (data.items) {
      this.listItems = [...data.items];
    }

    if (data.testParameterId){
      this.testParameterId=data.testParameterId;
    }
  }

  addItem() {
    this.listItems.push({ value: '' });
  }

  removeItem(index: number) {
    if (index > -1 && this.listItems[index] && this.data.items.some(item => item.value === ( this.listItems[index].value))) {
      const itemValue = this.listItems[index].value; // Get the value of the item
      this.testParametersService.removeTestParameterItem(this.testParameterId, itemValue).subscribe({
        next: () => {
          this.listItems.splice(index, 1); // Remove the item from the list on successful deletion
          this.toasterService.success(`Test parameter Item ${itemValue}  has been deleted.`,'test parameter deleted');
         
        },
        error: (error) => {
          // Handle any errors here
        }
      });
    } else {
      this.listItems.splice(index, 1);

    }
  }
  

  closeSelectListValue() {
    this.dialogRef.close();
  }

  addSelectListValue() {
   // console.log(this.listItems); 
    this.dialogRef.close(this.listItems.map(item => item.value));
  }

  get hasErrors(): boolean {
    const values = this.listItems.map(item => item.value);
    const uniqueValues = new Set(values);
    const hasEmpty = values.some(value => value.trim() === ''); // Check for empty values
    const hasDuplicates = uniqueValues.size !== values.length; // Check for duplicates
    return hasDuplicates || hasEmpty;
  }

  isInvalid(value: string): boolean {
    return value.trim() === '' || this.listItems.filter(item => item.value === value).length > 1;
  }

}

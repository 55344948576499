import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { EndpointBase } from './endpoint-base.service';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class DescriptionEndpointService extends EndpointBase {


    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get descriptionUrl() {
        return this.configurations.baseUrl + '/api/Description';
    }
    get inputTypeUrl() {
        return this.configurations.baseUrl + '/api/InputType';
    }
    get inputTypeValueUrl() {
        return this.configurations.baseUrl + '/api/InputTypeValue';
    }
    createDescription<T>(descriptionData: any): Observable<T> {
        const endpointUrl = `${this.descriptionUrl}`;
        return this.http.post<T>(endpointUrl, JSON.stringify({ Model: descriptionData }), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.createDescription(descriptionData));
            }));
    }

    updateDescription<T>(descriptionId: number, updatedData: any): Observable<T> {
        const endpointUrl = `${this.descriptionUrl}/${descriptionId}`;
        return this.http.put<T>(endpointUrl, JSON.stringify({ Model: updatedData }), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.updateDescription(descriptionId, updatedData));
            }));
    }

    deleteDescription<T>(descriptionId: any): Observable<T> {
        const endpointUrl = `${this.descriptionUrl}/${descriptionId}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.deleteDescription(descriptionId));
            }));
    }

    getAllDescriptions<T>(): Observable<T> {
        const endpointUrl = `${this.descriptionUrl}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getAllDescriptions());
            }));
    }

    getDescriptionsByCategoryId<T>(categoryId: number): Observable<T> {
        const endpointUrl = `${this.descriptionUrl}/GetDescriptionsByCategoryId/${categoryId}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDescriptionsByCategoryId(categoryId));
            }));
    }

    getAllInputTypes<T>(): Observable<T> {
        const endpointUrl = `${this.inputTypeUrl}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getAllInputTypes());
            }));
    }

    createInputTypeValue<T>(viewModel: any): Observable<T> {
        const endpointUrl = `${this.inputTypeValueUrl}`;
        return this.http.post<T>(endpointUrl, JSON.stringify({ Model: viewModel }), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.createInputTypeValue(viewModel));
            }));
    }

    deleteInputTypeValue<T>(id: any): Observable<T> {
        const endpointUrl = `${this.inputTypeValueUrl}/${id}`;
        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.deleteInputTypeValue(id));
            }));
    }
}


import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageUploadComponent } from 'src/app/components/shared/image-upload/image-upload.component';
import { UploadSignatureComponent } from 'src/app/components/shared/upload-signature/upload-signature.component';

@Component({
    selector: 'app-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent {
    @Input() imageUrl: string;
    @Output() imageUrlChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() inputTypeName: string;
    @Output() signatureChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() mouldName: string;

    constructor(public activeModal: NgbActiveModal,
        private modalService: NgbModal) {
    }

    decline() {
        this.activeModal.close();
    }

    openImageUploadModal(): void {
        const modalRef = this.modalService.open(ImageUploadComponent, {
            size: 'lg',
            backdrop: 'static'
        });
        modalRef.componentInstance.imageUrlChange.subscribe((newImageUrl: string) => {
            // Update the imageUrl with the newly uploaded image URL
            this.imageUrl = newImageUrl;
            this.imageUrlChange.emit(this.imageUrl);
        });
        modalRef.result.catch((error) => {
            // Handle errors or user cancellation
            console.error('Image upload error:', error);
        });
    }

    openSignatureUploadModal(): void {
        const modalRef = this.modalService.open(UploadSignatureComponent, {
            size: 'lg',
            backdrop: 'static'
        });
        modalRef.componentInstance.inputTypeName = this.inputTypeName;
        modalRef.componentInstance.mouldName = this.mouldName;
        modalRef.componentInstance.signatureChange.subscribe((newImageUrl: string) => {
            this.imageUrl = newImageUrl;
            this.signatureChange.emit(this.imageUrl);
        });
        modalRef.result.catch((error) => {
            console.error('Signature upload error:', error);
        });
    }
}

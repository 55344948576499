export enum Gender {
    None,
    Female,
    Male
}

export enum EntityType {
    Factory,
    Workplace,
    Workspace,
    Mould,
    MouldType,
    DescriptionValue,
    Test,
    TestType,
    TestParameter
}

export enum MouldStatus {
    Empty = 1,
    Initial = 2,
    Open = 3,
    Closed = 4,
    Curing = 5
}

export enum MouldTypeEnum {
    One = 1,
    Two
}

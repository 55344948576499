import { Component, NgZone } from '@angular/core';
import { CustomizerSettingsService } from '../customizer-settings/customizer-settings.service';
import { WorkplaceService } from 'src/app/services/workplace.service';
import { WorkplacesList } from 'src/app/models/workplaces-list.model';
import { FactoryList } from 'src/app/models/factories-list.model';
import { FactoryService } from 'src/app/services/factory.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { MouldService } from 'src/app/services/mouldServices/mould.service';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    factoryId: number = -1;
    factoriesList: FactoryList[] = [];
    currentPage: number = 0;
    allWorkplaces: WorkplacesList[] = [];
    isToggled = false;
    get canViewHomePage() {
        return this.accountService.userHasPermission(Permission.viewHomepagePermission);
    }
    public backgroundColor = '#F7F8F7 !important'
    constructor(
        public themeService: CustomizerSettingsService,
        private workplaceService: WorkplaceService,
        private factoryService: FactoryService,
        private workspaceService: WorkspaceService,
        private mouldService: MouldService,
        private router: Router,
        private route: ActivatedRoute,
        private ngZone: NgZone,
        private accountService: AccountService,


    ) {
        this.getWorkplaces();
        this.getFactoriesList();

    }



    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    getWorkplaces() {
        this.workplaceService.getWorkplacesListByFactoryId(this.factoryId).subscribe({
            next: (results) => this.getWorkSpaces(results),
            error: (error) => this.allWorkplaces = null,
        });
    }

    getFactoriesList() {
        this.factoryService.getFactoriesList().subscribe((data: any) => {
            this.factoriesList = data;
            if (this.factoriesList.length > 0) {
                this.changeFactory(this.factoriesList[0].id);
            }
        });
    }

    isLinkDisabled(workspace: any): boolean {
        if (workspace.mouldName == null) {
            return true;
        }
        else {
            return false;
        }

    }

    // Define a function to handle the navigation with query parameters
    navigateToUnitVisualisation(mouldId: string) {
        this.router.navigate(['/unit-visualisation'], {
            relativeTo: this.route,
            queryParams: { id: mouldId },
            queryParamsHandling: 'merge' // or 'preserve'
        });
    }

    getWorkSpaces(result: any) {
        this.allWorkplaces = result;

        for (let element = 0; element < this.allWorkplaces.length; element++) {
            this.workspaceService.getWorkspacesListbyWPId(result[element].id).subscribe({
                next: (results) => this.allWorkplaces[element].Workspaces = results,
            });
        }
    }

    getStatusTextColor(status: string): string {
        switch (status) {
            case 'Empty':
                return 'Green';
            case 'Open':
                return 'Orange';
            case 'Closed':
                return 'Grey';
            case 'Curing':
                return 'Red';

            default:
                return 'Blue';
        }
    }

    getBackgroundColor(status: string): string {
        switch (status) {
            case 'Empty':
                return '#C8E6C9'; // Light Green
            case 'Open':
                return '#FFE0B2'; // Light Orange
            case 'Closed':
                return '#F5F5F5'; // Light Grey
            case 'Curing':
                return '#FFCDD2'; // Light Red

            default:
                return '#BBDEFB'; // Light Blue
        }
    }


    changeFactory(id: number) {
        this.factoryId = id;
        this.getWorkplaces();
    }

    dropWorkplaces(event: CdkDragDrop<any>) {

        let previousItem = this.allWorkplaces[event.previousContainer.data.index];

        this.allWorkplaces[event.previousContainer.data.index] = this.allWorkplaces[event.container.data.index];
        this.allWorkplaces[event.container.data.index] = previousItem;
        let ids = [];
        event.container.data.item.forEach(element => {
            ids.push(element.id)
        });
        this.updateOrderWorkPlace(ids);
    }

    updateOrderWorkPlace(ids: any) {
        const viewModel: any = {
            Ids: ids
        }
        this.workplaceService.updateOrderWorkPlace(viewModel).subscribe(() => {

        });
    }

    drop(event: CdkDragDrop<any>, workspaces: any[]) {
        if (!this.canManageHome) {
            return;
        }
        let previousItem = workspaces[event.previousContainer.data.index];
        let previousId = previousItem.id
        workspaces[event.previousContainer.data.index] = workspaces[event.container.data.index];

        let nextMould = workspaces[event.previousContainer.data.index];
        let nextId = nextMould.id;
        let mouldId = nextMould.mouldId

        workspaces[event.container.data.index] = previousItem;
        const viewModel: any = {
            PreviousId: previousId,
            NextId: nextId
        }
        this.updateParent(viewModel, mouldId);
    }

    updateParent(viewModel: any, mouldId: Number) {
        this.mouldService.updateParent(viewModel).subscribe(() => {
            if (mouldId == 0) {
                this.getWorkplaces();
            }
        });
    }
    get canManageHome() {
        return this.accountService.userHasPermission(
            Permission.manageHomepagePermission
        );
    }
}

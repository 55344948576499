<div
    [class.card-border-radius]="themeService.isCardBorderRadius()"
    [class.card-borderd-theme]="themeService.isCardBorder()"
    [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()"
    class="h-100vh pt-50 pb-50"
>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto border-top-radius">
                <div class="top border-top-radius position-relative">
                    <img alt="welcome" src="assets/img/welcome.png">
                    <h4 class="main-color">Welcome to Gree Tunnel !</h4>
                    <p class="main-color">Hello {{ User }}, enter your password to unlock the screen!</p>
                </div>
                <div class="user bg-white text-center pt-30 ps-25 pe-25">
                    <img alt="user" class="rounded-circle" src="assets/img/user/user15.jpg" width="60">
                    <h5 class="mb-0 mt-8">{{ User }}</h5>
                </div>
                <form>
                    <div class="bg-white border-bottom-radius pt-30 pb-30 ps-25 pe-25">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input [type]="hide ? 'password' : 'text'" matInput>
                                <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                        mat-icon-button matSuffix>
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <button class="tagus d-block fw-semibold" mat-flat-button>Unlock</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import { ToggleService } from '../header/toggle.service';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

    panelOpenState = false;

    isToggled = false;

    constructor(
        private toggleService: ToggleService,
        public themeService: CustomizerSettingsService,
        private accountService: AccountService,
    ) {
        this.toggleService.isToggled$.subscribe(isToggled => {
            this.isToggled = isToggled;
        });
    }
    get canViewConfigurations() {
        return this.accountService.userHasPermission(Permission.viewConfigurationsPermission);
    }
    get canManageUnits() {
        return this.accountService.userHasPermission(
            Permission.manageUnitsPermission
        );
    }
    get canViewUnits() {
      return this.accountService.userHasPermission(
          Permission.viewUnitsPermission
      );
    }
    get canViewActiveReport() {
        return this.accountService.userHasPermission(Permission.viewActiveReportPermission);
    }
    get canViewRoles() {
        return this.accountService.userHasPermission(Permission.viewRolesPermission);
    }

    get canManageUsers() {
        return this.accountService.userHasPermission(
            Permission.manageUsersPermission
        );
    }
    get canViewUsers() {
        return this.accountService.userHasPermission(
            Permission.viewUsersPermission
        );
    }
    get canViewTests() {
        return this.accountService.userHasPermission(
            Permission.viewTestsPermission
        );
    }

     toggle() {
        this.toggleService.toggle();
    }

    toggleSidebarTheme() {
        this.themeService.toggleSidebarTheme();
    }

    toggleHideSidebarTheme() {
        this.themeService.toggleHideSidebarTheme();
    }

}

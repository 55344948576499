import { formatDate, DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { ToastrService } from 'ngx-toastr';
import { TestResultAddRequest } from 'src/app/models/test-result-add-request.model';
import { TestResultUpdateRequest } from 'src/app/models/test-result-update-request.model';
import { TestParametersService } from 'src/app/services/testParametersServices/test-parameters.service';
import { TestResultsService } from 'src/app/services/testResultsServices/test-results.service';
import { Observable, Subject, catchError, debounceTime, distinctUntilChanged, map, of } from 'rxjs';
import { UnitService } from 'src/app/services/unit.service';
import { Router } from '@angular/router';
import { SchmidtCubeService } from 'src/app/services/schmidt-cube.service';
import { AccountService } from 'src/app/services/account.service';
import { Permission } from 'src/app/models/permission.model';
import {
    NgxMatDateFormats,
    NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'DD-MM-YYYY'
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
export interface SaveStatusEvent {
    success: boolean;
    index: number; // Adjust based on how you identify tabs, could also be an ID or other identifier
}
@Component({
    selector: 'app-test-parameter-content',
    templateUrl: './test-parameter-content.component.html',
    styleUrls: ['./test-parameter-content.component.scss'],

    providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],

})
export class TestParameterContentComponent implements OnInit {
    // @Output() saveResult = new EventEmitter<boolean>();
    public saveResultSubject = new Subject<boolean>();
    saveResult$ = this.saveResultSubject.asObservable();
    @Output() saveStatus = new EventEmitter<SaveStatusEvent>();
    showModal: boolean = false;
    @Input() rowId: number;
    @Input() testId: number;
    @Input() tabs: any[];
    @Input() initialValues: any;
    popName = 'Signature';
    @ViewChild('modalElement') modalElement: ElementRef;
    formattedDates: { [key: number]: string } = {};
    @Input() testResultId: number;
    @Output() dataEvent = new EventEmitter<number>();
    //@ViewChildren(SignaturePadComponent) signaturePads: QueryList<SignaturePadComponent>;
    @ViewChild('picker') picker: any;
    @ViewChild(SignaturePadComponent, { static: false }) signaturePad!: SignaturePadComponent;
    signaturePadOptions: NgSignaturePadOptions = {
        minWidth: 5,
        canvasWidth: 795,
        canvasHeight: 500,
        backgroundColor: 'white'
    };
    rfidSearchSubject = new Subject<{ rfid: string, index: number }>();
    public testParametersForm: FormGroup;
    testParameters: any[] = []; // Replace with your actual type
    route: any;
    formData: any[];
    valuestoprint: string;
    imageInputValue: string;
    fileName: string;
    checkValuesOfForm: string;
    signaturePlaceholder: string[] = [];
    handWritePlaceHolder: string[] = [];
    isLoading: boolean;
    inProgress: boolean = false;
    success: boolean = false;
    isSearchingForUnit: boolean[] = [];
    modalId: string;
    private originalCompositeOperation: any
    currentLoadingIndexForInterval: number | null = null;
    constructor(
        private fb: FormBuilder,
        private testParameterService: TestParametersService,// Assuming you have a service
        private testResultsServices: TestResultsService,
        private toasterService: ToastrService,
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef,
        private unitService: UnitService,
        private router: Router,
        private schmidtCubeService: SchmidtCubeService,
        private accountService: AccountService,


    ) { }
    ngOnInit(): void {

        this.testParametersForm = this.fb.group({
            parameters: this.fb.array([])
        });
        this.loadExistingParameters();
        // if (this.rowId !== 0) {
        //     this.applyInitialValues();
        // } 



        this.signaturePlaceholder = this.testParametersForm.get('parameters').value.map(param => {
            return param.columnType === 'Signature' ? 'No signature yet' : '';
        });
        this.handWritePlaceHolder = this.testParametersForm.get('parameters').value.map(param => {
            return param.columnType === 'Handwriting' ? 'Image not filled yet' : '';
        });

        this.rfidSearchSubject.pipe(
            debounceTime(50)
        ).subscribe(data => {
            this.currentLoadingIndexForInterval = data.index;
            this.searchUnit(data.rfid, data.index);
        });

        this.isSearchingForUnit = new Array(this.formData.length).fill(false);

    }


    searchUnit(rfid: string, index: number) {
        this.isSearchingForUnit[index] = true; // Start loading
        if (!rfid) {
            return; // or handle empty input case
        }
        this.isSearchingForUnit[index] = true; // Start loading

        this.unitService.getUnitByRfid(rfid).subscribe({
            next: (UnitNumber) => {

                if (UnitNumber) {

                    const parametersArray = this.testParametersForm.get('parameters') as FormArray;
                    const imageGroup = parametersArray.at(index) as FormGroup;

                    imageGroup.get('unitValue').setValue(UnitNumber.name);
                    this.isSearchingForUnit[index] = false; // Start loading
                    this.fetchIntervalValuesForUnit(UnitNumber.name, this.testId);

                } else {

                }
            },
            error: (error) => {
                console.error('Error fetching parameters:', error);
                // Handle error
                this.isSearchingForUnit[index] = false;
            }
        });

    }
    fetchIntervalValuesForUnit(unitName: string, testId: number) {
        this.testParameterService.fetchIntervalDataForUnit(unitName, testId).subscribe({
            next: (response) => {

                const parametersArray = this.testParametersForm.get('parameters') as FormArray;
                response.forEach(intervalData => {
                    const intervalIndex = parametersArray.controls.findIndex(control => control.value.testParameterId === intervalData.testParameterId);
                    if (intervalIndex !== -1) {
                        parametersArray.at(intervalIndex).patchValue({
                            minValue: intervalData.min,
                            maxValue: intervalData.max
                        });
                    }
                });
                this.currentLoadingIndexForInterval = null;
            },
            error: (error) => {
                console.error('Error fetching interval values:', error);

            }
        });
    }

    isUserValueInRange(index: number): boolean {
        const minControl = this.parameters.at(index).get('minValue');
        const maxControl = this.parameters.at(index).get('maxValue');
        const userValueControl = this.parameters.at(index).get('userValue');

        const min = minControl.value;
        const max = maxControl.value;
        const userValue = userValueControl.value;
        const inRange = userValue >= min && userValue <= max;

        return inRange;
    }


    isObjectNotEmpty(obj) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;

    }

    formatDateValue(index: number) {
        const formGroup = (this.testParametersForm.get('parameters') as FormArray).at(index) as FormGroup;
        const dateControl = formGroup.get('dateValue');

        if (dateControl.value) {
            const formattedDate = this.datePipe.transform(dateControl.value, 'yyyy-MM-dd');
            this.formattedDates[index] = formattedDate; // Store the formatted date
        }
    }



    onDateChange(event: MatDatepickerInputEvent<Date>, controlName: string, groupIndex: number) {
        const rawDate = event.value;
        const formattedDate = this.datePipe.transform(rawDate, 'dd/MM/yyyy');

        if (formattedDate) {
            const parametersArray = this.testParametersForm.get('parameters') as FormArray;
            const formGroup = parametersArray.at(groupIndex) as FormGroup;
            formGroup.get(controlName).setValue(formattedDate, { emitEvent: false });
        }
    }

    getIntervalRespected(intervalUserValue: number) {



    }



    shouldApplyValue(group: FormGroup, initialValue: any): boolean {
        // Implement your logic here
        // For example, if you want to apply only if a certain checkbox is checked:
        return group.get('myCheckbox')?.value;
    }

    loadExistingParameters() {


        this.testParameterService.getExistingTestParameters(this.testId).subscribe({
            next: (parameters) => {

                if (parameters.length) {

                    this.formData = parameters;
                    parameters.forEach((param, index) => {
                        const paramGroup = this.createTestParametersGroup(param, this.initialValues);
                        (this.testParametersForm.get('parameters') as FormArray).push(paramGroup);
                    });
                } else {

                }
            },
            error: (error) => {
                console.error('Error fetching parameters:', error);
                // Handle error
            }
        });



    }

    get parameters(): FormArray {
        return this.testParametersForm.get('parameters') as FormArray;
    }
    createTestParametersGroup(param, initialValue = null): FormGroup {

        const parametersArray = initialValue && initialValue.parameters ? initialValue.parameters : null;

        const initialData = parametersArray ? parametersArray.find(init => init.testParameterId === param.id && init.myCheckbox == true) : null;

        const isMandatory = param.mandatory;
        let group = {};
        switch (param.columnType) {
            case 'Interval':

                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    minValue: new FormControl(initialData?.minValue ?? ''),
                    maxValue: new FormControl(initialData?.maxValue ?? ''),
                    userValue: new FormControl(initialData?.userValue ?? '', param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };

                break;

            case 'SearchUnitId':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    rfidValue: new FormControl(initialData?.rfidValue ?? null, [param.mandatory ? Validators.required : null].filter(v => v != null)),
                    unitValue: new FormControl(initialData?.unitValue ?? '', param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                //  (group as any).unitValue.disable();
                break;

            case 'SelectList':

                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    selectValue: new FormControl(initialData?.selectValue ?? '', param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;

            case 'Checkbox':

                const checkboxes = param.selectList.map(() => new FormControl(false)); // Create a control for each checkbox
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    checkboxValues: new FormArray(checkboxes, this.atLeastOneCheckboxCheckedValidator(param.mandatory)),
                    selectList: param.selectList || [],
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };


                break;

            case 'Text':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    textValue: new FormControl(initialData?.textValue ?? '', param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;
            case 'Time':
                const currentTime = new Date();
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    timeValue: new FormControl(initialData?.timeValue ?? this.datePipe.transform(currentTime, 'HH:mm'), param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;
            case 'Numeric':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    numericValue: new FormControl(initialData?.numericValue ?? '', param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;
            case 'Math':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    mathValue: new FormControl(initialData?.mathValue ?? '', param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;
            case 'Signature':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    signatureValue: new FormControl(initialData?.signatureValue ?? '', param.mandatory ? Validators.required : null),
                    signaturePlaceHolder: new FormControl('no Signature yet', param.mandatory ? Validators.required : null),
                    signatureBase64: new FormControl(initialData?.signatureBase64 ?? ''),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;
            case 'HandWriting':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    handWriteValue: new FormControl(initialData?.handWriteValue ?? '', param.mandatory ? Validators.required : null),
                    handWritePlaceHolder: new FormControl('Image not filled yet', param.mandatory ? Validators.required : null),
                    handWriteBase64: new FormControl(initialData?.handWriteBase64 ?? ''),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;

            case 'Image':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    ImageValue: new FormControl(initialData?.ImageValue ?? '', param.mandatory ? Validators.required : null),
                    imageBase64: new FormControl(initialData?.imageBase64 ?? ''),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;

            case 'Date':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    dateValue: new FormControl(initialData?.dateValue ?? new Date(), param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;

            case 'DateDifference':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    dateRange: new FormGroup({
                        startDate: new FormControl(initialData?.startDate ?? '', param.mandatory ? Validators.required : null),
                        endDate: new FormControl(initialData?.endDate ?? '', param.mandatory ? Validators.required : null)
                    }),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;
            case 'File':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    fileValue: new FormControl(initialData?.fileValue ?? '', param.mandatory ? Validators.required : null),
                    testParameterId: param.id,
                    testResultValueId: 0,
                    myCheckbox: new FormControl(param.testParameterRepeatValue)
                };
                break;
            default:
                group = {
                    columnName: new FormControl(param.columnName),
                    myCheckbox: new FormControl(false)
                }; // A generic control for any unhandled types
        }

        return this.fb.group(group);
    }

    // getSelectedFieldsForNewTab(): any {
    //   const selectedValues = {};
    //   this.parameters.controls.forEach((control, index) => {
    //     if (this.checkboxStates[index]) {
    //       selectedValues[index] = control.value;
    //     }
    //   });
    //   return selectedValues;
    // }

    onSubmit() {


        if (this.testParametersForm.valid) {
            this.saveOrUpdateTestResult();
        } else {

            this.logInvalidControls(this.testParametersForm);
        }
    }


    logInvalidControls(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
            const control = formGroup.controls[key];
            if (control instanceof FormGroup) {
                this.logInvalidControls(control);
            } else if (control.invalid) {
                this.toasterService.error('Form is not valid. Please check your inputs.');
                console.error('Invalid Control:', key, control.errors);
            }
        });
    }

    saveOrUpdateTestResult(): Observable<boolean> {
        console.log('Save result emitted');
        const formData = this.testParametersForm.value.parameters;

        const testResultId = this.tabs.find(tab => tab.id === this.rowId).testResultId;
        console.log("testresultid id is ");
        console.log(testResultId);
        if (testResultId) {
            // Update existing test result
            return this.updateTestResult(testResultId, formData);
        } else {
            // Add new test result
            return this.addNewTestResult(formData);
        }


    }

    atLeastOneCheckboxCheckedValidator(mandatory: boolean): ValidatorFn {
        return (formArray: FormArray) => {
            if (!mandatory) {
                return null;
            }
            const isAtLeastOneChecked = formArray.controls.some(control => control.value);
            return isAtLeastOneChecked ? null : { 'atLeastOneRequired': true };
        };
    }

    addNewTestResult(formData: any): Observable<boolean> {
        if (!this.testParametersForm.valid) {
            console.error('Form is not valid.');
            this.toasterService.error(`Test Result has not been created please insert mandatory data.`, 'test result not create');
            this.saveStatus.emit({ success: false, index: this.rowId });
            return of(false); // Observable emitting false indicating failure due to form validation
        }


        var rfid = "";
        const testResultValues = formData.map((data: any) => {
            let value;
            switch (data.columnType) {
                case 'Checkbox':
                    if (data.checkboxValues) {
                        // Join the boolean values (checked states) into a single string, separated by commas
                        value = data.checkboxValues.map(checked => checked.toString()).join(',');
                    } else {
                        value = ''; // Default to an empty string if checkboxValues is not aligned or missing
                    }
                    break;
                case 'DateDifference':
                    // Format the date range as a string
                    value = [data.dateRange.startDate, data.dateRange.endDate];
                    break;
                case 'SearchUnitId':
                    value = data.unitValue;
                    rfid = data.rfidValue.toString().padStart(6, '0');
                    break;
                case 'File':
                    break;
                case 'Interval':
                    value = [data.minValue, data.userValue, data.maxValue]
                    break
                case 'Numeric':

                    value = data.numericValue !== null && data.numericValue !== undefined ? parseFloat(data.numericValue) : null;
                    break;
                default:
                    // Handle other types like Text, SelectList, Numeric, Date, etc.
                    value = data.selectValue || data.textValue ||
                        data.dateValue || data.signatureBase64 || data.imageBase64 || data.handWriteBase64 || data.mathValue || data.timeValue;

            }

            return {
                value: value, // This could be a string or an array depending on the input type
                testParameterId: data.testParameterId,
                name: data.columnName,
                type: data.columnType
            };
        });

        const testResultRequest = new TestResultAddRequest();
        testResultRequest.model.testID = this.testId;
        testResultRequest.model.rfid = rfid;
        testResultValues.forEach(trv => {
            if (trv.type !== 'File') {
                testResultRequest.model.testResultValues.push(trv);
            }
        });
        this.isLoading = true;
        this.inProgress = true;

        //   if (this.inProgress) {
        //     return;
        //   }
        this.testResultsServices.addTestResult(testResultRequest).subscribe(response => {
            console.log("got response from backend");
            const currentTab = this.tabs.find(tab => tab.id === this.rowId);
            this.isLoading = false;
            this.inProgress = false;
            currentTab.testResultId = response.testResultId;
            const formArray = this.testParametersForm.get('parameters') as FormArray;

            formArray.controls.forEach((group: FormGroup, index) => {

                const testParameterId = group.get('testParameterId').value;


                const matchingTestResultValue = response.testResultValues.find(trv => trv.testParameterId === testParameterId);

                if (matchingTestResultValue) {

                    const testResultValueId = matchingTestResultValue.testResultValueId;
                    group.get('testResultValueId').setValue(testResultValueId);


                }
            });
            this.success = true;
            this.saveResultSubject.next(this.success);
            console.log("this.success", this.success);
            this.toasterService.success(`Test Result ${currentTab.title}  has been created.`, 'test result created');
            this.saveStatus.emit({ success: true, index: this.rowId });
            return true;

        }),
            catchError(error => {
                // Handle the error case by logging and returning an observable of false
                this.success = false;
                console.error('An error occurred:', error);
                this.saveResultSubject.next(this.success);
                this.saveStatus.emit({ success: false, index: this.rowId });
                return of(false); // Observable emitting false indicating failure
            })


            ;





        return of(true);
    }

    updateTestResult(testResultId: number, formData: any): Observable<boolean> {

        if (!this.testParametersForm.valid) {
            console.error('Form is not valid.');
            this.toasterService.error(`Test Result has not been updated please insert mandatory data.`, 'test result not updated');
            this.saveStatus.emit({ success: false, index: this.rowId });
            return of(false); // Observable emitting false indicating failure due to form validation
        }

        var rfid = "";
        const testResultValues = formData.map((data: any) => {
            let value;
            switch (data.columnType) {
                case 'Checkbox':
                    value = data.checkboxValues.map(checked => checked.toString()).join(',');
                    break;
                case 'DateDifference':
                    // Format the date range as a string
                    value = [data.dateRange.startDate, data.dateRange.endDate];

                    break;
                case 'SearchUnitId':
                    value = data.unitValue;
                    rfid = data.rfidValue.toString().padStart(6, '0');
                    break;
                case 'Math':
                    value = data.MathValue
                    break;
                case 'File':
                    break;
                case 'Interval':
                    value = [data.minValue, data.userValue, data.maxValue]
                    break;
                default:
                    // Handle other types like Text, SelectList, Numeric, Date, etc.
                    value = data.selectValue || data.textValue ||
                        data.numericValue || data.dateValue || data.signatureBase64 || data.imageBase64 || data.handWriteBase64 || data.timeValue;

            }

            return {
                value: value, // This could be a string or an array depending on the input type
                testParameterId: data.testParameterId,
                name: data.columnName,
                type: data.columnType
            };
        });

        const testResultUpdateRequest = new TestResultUpdateRequest();
        testResultUpdateRequest.model.testResultId = testResultId;
        testResultUpdateRequest.model.testID = this.testId;
        testResultUpdateRequest.model.rfid = rfid;
        testResultValues.forEach(trv => {
            if (trv.type !== 'File') {
                testResultUpdateRequest.model.testResultValues.push(trv);
            }
        });
        this.isLoading = true;
        this.inProgress = true;

        // if (this.inProgress) {
        //   return;
        // }



        this.testResultsServices.updateTestResult(testResultUpdateRequest).subscribe(response => {
            const currentTab = this.tabs.find(tab => tab.id === this.rowId);
            this.isLoading = false;
            this.inProgress = false;
            this.toasterService.success(`Test Result ${currentTab.title}  has been updated.`, 'test result updated');
            this.success = true;
            this.saveStatus.emit({ success: true, index: this.rowId });
            return true;

        }),
            catchError(error => {
                // Handle the error case by logging and returning an observable of false
                console.error('An error occurred:', error);
                this.saveStatus.emit({ success: false, index: this.rowId });
                return of(false); // Observable emitting false indicating failure
            });
        return of(true);
    }
    onReadingValueChange(event: any, index: number) {

        const numericValue = parseFloat(event.target.value); // Ensure it's a number
        this.formData[index].numericValue = numericValue;
        const formData = this.testParametersForm.value.parameters;

        let readings = [];
        let SchmitdtDemouldingReadings = this.testId === 23 ?
            [381, 380, 379, 378, 377, 376, 375, 374, 384, 383, 382, 373] :
            this.testId === 24 ?
                [410, 409, 408, 407, 406, 405, 404, 403, 413, 412, 411, 402] :
                [];
        // Loop to collect readings
        formData.forEach(element => {
            if (SchmitdtDemouldingReadings.includes(element.testParameterId)) {
                if (element.numericValue != null && element.numericValue !== '') {
                    let numericValue = parseFloat(element.numericValue);
                    if (!isNaN(numericValue)) {
                        readings.push(numericValue);
                    }
                }
            }
        });
        // Ensure all values are inserted
        let schmidtresult = this.testId === 23 ? formData.find(p => p.testParameterId === 628) :
            this.testId === 24 ? formData.find(p => p.testParameterId === 630) :
                '';
        let testResultInMPA = this.testId === 23 ? formData.find(p => p.testParameterId === 629) :
            this.testId === 24 ? formData.find(p => p.testParameterId === 631) :
                '';

        if (readings.length === 12) {
            // Find highest and lowest values
            let maxReading = Math.max(...readings);
            let minReading = Math.min(...readings);

            // Remove highest and lowest readings
            let sum = readings.reduce((accumulator, currentValue) => accumulator + currentValue, 0) - minReading - maxReading;
            let average = sum / (readings.length - 2);
            average = parseFloat(average.toFixed(2));

            // Update the form control for testParameterId 628 with the calculated average
            if (schmidtresult) {
                schmidtresult.mathValue = average;
                if (testResultInMPA) {
                    testResultInMPA.mathValue = this.schmidtCubeService.getClosestCubeValue(average);
                }
            }

            // Update the form group (if necessary)
        } else {

            if (schmidtresult) {
                schmidtresult.mathValue = null;
            }
            if (testResultInMPA) {
                testResultInMPA.mathValue = null;

            }
        }

        this.testParametersForm.patchValue({ parameters: formData });
    }

    getIconClass(inputTypeName: string): string {

        return this.inputTypeNameMapping[inputTypeName] || 'ri-keyboard-line';
    }


    inputTypeNameMapping: { [key: string]: string } = {
        'textValue': 'ri-quill-pen-line',
        'ImageValue': 'ri-chat-upload-line',
        'userValue': 'ri-expand-left-right-line',
        'rfidValue': 'ri-hashtag',
        'signatureValue': 'ri-sketching',
        'numericValue': 'ri-hashtag',
        'selectValue': 'ri-list-ordered-2',
        'dateRange': 'ri-calendar-line',
        'dateValue': 'ri-calendar-line',
        'unitValue': 'ri-settings-6-line',
        'handWriteValue': 'ri-hand-coin-line',
        'mathValue': 'ri-calculator-line',
        'timeValue': 'ri-time-line'
    };

    public colorChange: any[] = []

    getColor(index: number, controlName: string): string {
        // Access the form group at the specified index within the FormArray
        const formGroup = (this.testParametersForm.get('parameters') as FormArray).at(index) as FormGroup;

        // Access the specific control within the form group
        const control = formGroup.get(controlName);

        // Check the validation status of the control
        if (control && control.invalid && control.errors?.['required'] && control.value === null || control.value === undefined || control.value === '') {
            return 'red';
        } else if (control && control.valid) {
            return 'green';
        }

        return 'grey'; // Default color
    }

    private findControlByName(controlName: string): FormControl | null {
        let foundControl: FormControl | null = null;

        // Assuming 'parameters' is the name of your FormArray
        const parametersArray = this.testParametersForm.get('parameters') as FormArray;
        parametersArray.controls.forEach((group: FormGroup) => {
            if (group.contains(controlName)) {
                foundControl = group.get(controlName) as FormControl;
            }
        });

        return foundControl;
    }
    setColor(controlName, controlValue): void {

        let color
        if (controlValue == '') {
            color = 'red'
        }
        else {
            color = 'green'
        }
        this.colorChange.push({
            color: color,
            value: controlValue,
            control: controlName
        })
    }
    currentSignatureIndex: number;
    signatureSaved: boolean[] = [];
    signatureDisplayValue: string[] = [];

    signaturePadPopup(index: number, parameterType: string): void {
        console.log("this.rowId", this.rowId);

        this.currentSignatureIndex = index;
        this.modalId = 'signatureModal' + this.rowId;
        this.modalElement.nativeElement.setAttribute('data-modal-id', this.modalId);
        const modalIdSelector = '[data-modal-id="' + this.modalId + '"]';
        const modalElement = document.querySelector(modalIdSelector);

        setTimeout(() => {
            //  this.signaturePad.clear();
            // this.signaturePad.getCanvas();

            if (parameterType === "Handwriting") {
                this.setBackgroundImage();
            }
            // const modalId = 'signatureModal' + this.rowId;
            //   const modalElement = this.modalElement.nativeElement;

            //   ($(modalElement) as any).on('shown.bs.modal', function () {
            //     $('body').addClass('modal-open');
            //     $('<div class="modal-backdrop fade show"></div>').appendTo(document.body);
            // });
            //  document.getElementById('staticBackdrop').style.visibility = 'true';

            ($(modalElement) as any).modal('show');
            this.signaturePadOptions = {
                minWidth: 5,
                canvasWidth: 795,
                canvasHeight: 500,
                backgroundColor: 'white'
            };


        });
    }
    openModal(): void {
        this.showModal = true;
    }

    // Function to close modal
    closeModal(): void {
        this.showModal = false;
        ($(this.modalElement.nativeElement) as any).modal('hide');
        this.signaturePad.clear();
    }
    setBackgroundImage() {

        const canvas = this.signaturePad.getCanvas();
        const context = canvas.getContext('2d');
        // Replace 'your-image-url.jpg' with the actual URL or path to your image
        const imageUrl = 'assets\\img\\covercheck.jpg';

        const img = new Image();
        img.src = imageUrl;

        // Draw the image on the canvas as a background
        img.onload = () => {
            // Set composite operation to "destination-over" to draw new content behind the existing content
            context?.drawImage(img, 0, 0, canvas.width, canvas.height);
            context.globalCompositeOperation = this.originalCompositeOperation;
        };
    }


    drawComplete(event: MouseEvent | Touch) { }
    drawStart(event: MouseEvent | Touch) { }

    saveSignatureAsImage(): void {

        const signatureData = this.captureSignatureData();

        if (signatureData) {

            const parametersArray = this.testParametersForm.get('parameters') as FormArray;
            const signatureControl = parametersArray.at(this.currentSignatureIndex).get('signatureBase64');
            const hanWriteControl = parametersArray.at(this.currentSignatureIndex).get('handWriteBase64');

            if (signatureControl === null) {
                const hanWriteHolderControl = parametersArray.at(this.currentSignatureIndex).get('handWriteValue');
                hanWriteControl.setValue(signatureData);
                hanWriteHolderControl.setValue("Hand write value captured");
                this.toasterService.success(`Test Result Hand Write value Added.`, 'Hand write value Captured Succesfully');

            }
            else {
                const signatureHolderControl = parametersArray.at(this.currentSignatureIndex).get('signatureValue');
                signatureControl.setValue(signatureData);
                signatureHolderControl.setValue("Signature captured");
                this.toasterService.success(`Test Result Signature Value Added.`, 'Signature Captured Succesfully');
            }


        }
        this.cdr.detectChanges();
        const modalIdSelector = '[data-modal-id="' + this.modalId + '"]';
        const modalElement = document.querySelector(modalIdSelector);

        this.showModal = false;
        ($(modalElement) as any).on('hidden.bs.modal', function () {
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
        });

        this.signaturePad.clear();
    }


    captureSignatureData(): string {

        if (this.signaturePad.isEmpty()) {

            return '';
        }

        return this.signaturePad.toDataURL('image/png'); // or 'image/jpeg'
    }


    onImageSelected(event: any, index: number) {
        const file = event.target.files[0] as File;

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Image = reader.result as string;
                this.setImageValue(base64Image, index, file.name);

            };
            reader.readAsDataURL(file);
        }
    }


    setImageValue(base64Image: string, index: number, fileName: string): void {
        const parametersArray = this.testParametersForm.get('parameters') as FormArray;
        const imageGroup = parametersArray.at(index) as FormGroup;
        // No need to set ImageValue to an empty string anymore
        imageGroup.get('imageBase64').setValue(base64Image);
        imageGroup.get('ImageValue').setValue(fileName);
        this.toasterService.success(`Image uploaded successfully.`, 'Image Captured Successfully');
    }



    preventDefault(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }
    cancel() {
        this.router.navigate(['/tests']);
    }

    get canManageTests() {
        return this.accountService.userHasPermission(
            Permission.manageTestsPermission
        );
    }

}

<div [class.card-border-radius]="themeService.isCardBorderRadius()" [class.card-borderd-theme]="themeService.isCardBorder()"
     [class.component-dark-theme]="themeService.isDark()"
     [class.component-rtl-enabled]="themeService.isRTLEnabled()"
     class="h-100vh pt-50 pb-50">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">Forgot Password?</h2>
                    </div>
                    <p>Enter your email and we′ll send you instructions to reset your password</p>
                    <div *ngIf="showError" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                        {{successMessage}}
                    </div>
                </div>
                <form (ngSubmit)="forgotPassword(forgotPasswordForm.value)" [formGroup]="forgotPasswordForm" autocomplete="off"
                      novalidate>
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Email Address <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter email address</mat-label>
                                <input formControlName="email" id="email" matInput type="email">
                            </mat-form-field>
                            <mat-error *ngIf="validateControl('email') && hasError('email', 'required')">Enter your
                                email.
                            </mat-error>
                        </div>
                    </div>
                    <button [disabled]="isLoading" class="tagus d-block fw-semibold" mat-flat-button
                            type="submit">Send Reset Link
                    </button>
                    <div class="text-center back-to-login">
                        <a class="d-inline-block main-color fw-medium position-relative"
                           routerLink="/authentication/login"><i
                            class="flaticon-chevron-1"></i> Back to Login</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div mat-dialog-content class="select-list-dialog">

  <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="tagus-form-group">

              <label class="d-block mb-12 fw-semibold gray-color"> Items</label>

              <div class="relative-container" *ngFor="let item of listItems; let i = index"
              (mouseenter)="hoveredItemIndex = i"
              (mouseleave)="hoveredItemIndex = null">

                  <mat-form-field appearance="fill" class="input-container">
                      <i class="fa fa-list"></i>
                      <input matInput class="fullWidth" [(ngModel)]="listItems[i].value" placeholder="Add list item">
                      <mat-icon *ngIf="!isInvalid(item.value)" class="valid-icon">check_circle</mat-icon>
                      <mat-icon *ngIf="isInvalid(item.value)" class="invalid-icon">cancel</mat-icon>
                  </mat-form-field>
                  <button *ngIf="hoveredItemIndex === i"  (click)="removeItem(i)" class="bg-red dltTabPosDescription">
                      <i class="ri-delete-bin-line clr-white AdjustPosDelete"></i>
                  </button>
              </div>

              <div class="mt-2">
                  <button mat-stroked-button (click)="addItem()" 
                  class="gray text-primary"
                   style="width: 100%; border-color: #c1d3dc; background: white;"
                   [disabled]="listItems.length === null || hasErrors"
                   >
                      Add  Item
                  </button>
              </div>
          </div>
      </div>
  </div>

</div>



<mat-dialog-actions style ="float: right;">
    <button
    mat-stroked-button
     style="width:45%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white"
     [disabled]="listItems.length === 0 || hasErrors"
     (click)="addSelectListValue()">Save</button>
    <button mat-button 
    mat-stroked-button
    style="width:50%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white"
    (click)="closeSelectListValue()">Close</button>
</mat-dialog-actions>














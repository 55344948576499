<div class="modal-header">
    <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="btn-close fs-sm" tabindex="-1"
            title="Close" type="button"></button>
</div>

<div class="modal-body text-center">
    <!-- Image preview column -->
    <div class="mb-4">
        <label for="fileInput" class="file-input-label">
            <mat-card-content>
                <div class="d-flex align-items-center justify-content-center">
                    <img *ngIf="selectedFile" [src]="previewImageUrl" width="400" alt="Image Preview">
                </div>
            </mat-card-content>
            <div *ngIf="!selectedFile" class="upload-icon mx-3">
                <div class="glyph">
                    <i class="flaticon flaticon-gallery fa-3x"></i>
                </div>
            </div>
        </label>
    </div>

    <!-- File input -->
    <div class="mx-auto" style="max-width: 200px;">
        <input id="fileInput" type="file" #fileInput accept="image/*" (change)="handleFileChange($event)">
    </div>
</div>

<div class="modal-footer">
    <div class="text-end">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="mb-3 example-buttons" style="text-align: right;">
                    <button [disabled]="!selectedFile" (click)="uploadImage()" color="warn" class="mx-3"
                            mat-flat-button>
                        Upload
                    </button>
                    <button (click)="closeModal()" class="cancel-btn" mat-flat-button>Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { catchError, Observable } from 'rxjs';
import { EntityType } from '../models/enums';

@Injectable({
    providedIn: 'root'
})
export class MouldTypeEndpointService extends EndpointBase {

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get mouldTypesUrl() {
        return this.configurations.baseUrl + '/api/mouldType';
    }

    get workspacesUrl() {
        return this.configurations.baseUrl + '/api/workspace';
    }

    get workplacesUrl() {
        return this.configurations.baseUrl + '/api/workplace';
    }

    get factoriesUrl() {
        return this.configurations.baseUrl + '/api/factory';
    }

    getMouldTypesEndpoint<T>(page?: number, pageSize?: number, searchTerm?: string, sortColumn?: string, sortOrder?: string): Observable<T> {
        const endpointUrl = page && pageSize ? `${this.mouldTypesUrl}/AllmouldTypes?pageNumber=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&sortColumn=${sortColumn}/sortOrder?=${sortOrder}` : this.mouldTypesUrl;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>((error: any) => {
                return this.handleError(error, () => this.getMouldTypesEndpoint(page, pageSize));
            }));
    }

    getMouldTypesListEndpoint<T>(): Observable<T> {
        const endpointUrl = `${this.mouldTypesUrl}/mouldTypes`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>((error: any) => {
                return this.handleError(error, () => this.getMouldTypesListEndpoint());
            }));
    }

    getNewMouldTypeEndpoint<T>(factoriyObject: any): Observable<T> {

        return this.http.post<T>(this.mouldTypesUrl, JSON.stringify(factoriyObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getNewMouldTypeEndpoint(factoriyObject));
            }));
    }

    getUpdateMouldTypeEndpoint<T>(factoriyObject: any, id?: number): Observable<T> {
        const endpointUrl = `${this.mouldTypesUrl}/${id}`;

        return this.http.put<T>(endpointUrl, JSON.stringify(factoriyObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateMouldTypeEndpoint(factoriyObject, id));
            }));
    }

    getMouldTypeEndpoint<T>(mouldTypeId?: string): Observable<T> {
        const endpointUrl = `${this.mouldTypesUrl}/${mouldTypeId}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getMouldTypeEndpoint(mouldTypeId));
            })
        );

    }

    getDeleteMouldTypeEndpoint<T>(mouldTypeId: string): Observable<T> {

        const endpointUrl = `${this.mouldTypesUrl}/${mouldTypeId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDeleteMouldTypeEndpoint(mouldTypeId));
            }));
    }

    getMouldTypeDuplicateStatusEndpoint<T>(mouldTypeId: number, entityType: EntityType, factoryName?: string): Observable<T> {
        let endpointUrl: string;

        switch (entityType) {
            case EntityType.Factory:
                endpointUrl = `${this.factoriesUrl}/validateDuplicateName/${factoryName}/mouldTypeId/${mouldTypeId}`;
                break;
            case EntityType.Workplace:
                endpointUrl = `${this.workplacesUrl}/validateDuplicateName/${factoryName}/workplaceId/${mouldTypeId}`;
                break;
            case EntityType.Workspace:
                endpointUrl = `${this.workspacesUrl}/validateDuplicateName/${factoryName}/workspaceId/${mouldTypeId}`;
                break;
            case EntityType.MouldType:
                endpointUrl = `${this.mouldTypesUrl}/validateDuplicateName/${factoryName}/mouldTypeId/${mouldTypeId}`;
                break;
            default:
                // Handle a default case if needed
                break;
        }

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getMouldTypeDuplicateStatusEndpoint(mouldTypeId, entityType, factoryName));
            })
        );

    }
}

import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileEndpointService } from 'src/app/services/file-endpoint.service';

@Component({
    selector: 'app-upload-signature',
    templateUrl: './upload-signature.component.html',
    styleUrls: ['./upload-signature.component.scss']
})
export class UploadSignatureComponent implements OnInit {
    @ViewChild(SignaturePadComponent, { static: true }) signaturePad!: SignaturePadComponent;
    signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
        minWidth: 5,
        canvasWidth: 795,
        canvasHeight: 500,
        backgroundColor: 'white',
    };
    @ViewChild('signaturePad', { static: true }) signaturePadElement!: ElementRef;
    signatureImage: string | ArrayBuffer | null = null;
    signatureSelectedValue: any;
    descriptionId: number;
    indexvalue: number;
    mouldName: string;
    private originalCompositeOperation: any;
    @Output() signatureChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() inputTypeName: string = '';
    constructor(public activeModal: NgbActiveModal,
        private fileEndpoint: FileEndpointService,) { }
    ngOnInit(): void {
        if (this.inputTypeName == "Handwriting") {
            this.setBackgroundImage()
        }
    }
    signaturePadPopup(descriptionId: number, index: number, inputTypeName: string, mouldName: string) {
        this.signaturePad.clear()
        this.indexvalue = index;
        this.descriptionId = descriptionId;
        this.mouldName = mouldName;
    }
    setBackgroundImage() {
        const canvas = this.signaturePad.getCanvas();
        const context = canvas.getContext('2d');

        // Replace 'your-image-url.jpg' with the actual URL or path to your image
        const img = new Image();
        switch (this.mouldName) {
            case 'CW':
                img.src = 'assets\\img\\covercheck_cw.jpg';
                break;
            case 'AW':
                img.src = 'assets\\img\\covercheck_aw.jpg';
                break;
            case 'AR':
                img.src = 'assets\\img\\covercheck_ar.jpg';
                break;
        }

        // Draw the image on the canvas as a background
        img.onload = () => {
            // Set composite operation to "destination-over" to draw new content behind the existing content
            context?.drawImage(img, 0, 0, canvas.width, canvas.height);
            context.globalCompositeOperation = this.originalCompositeOperation;
        };
    }
    drawComplete(event: MouseEvent | Touch) {
    }

    drawStart(event: MouseEvent | Touch) {
    }
    saveSignatureAsImage(): void {
        if (this.signaturePad.isEmpty()) {
            return;
        }

        const canvas = this.signaturePad.getCanvas();
        const imageData = canvas.toDataURL('image/jpg');
        this.signatureImage = imageData;

        function dataURItoBlob(dataURI: string): Blob {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        }

        const blob = dataURItoBlob(imageData);
        const file = new File([blob], 'signature.jpg', { type: 'image/jpeg' });
        this.fileEndpoint.uploadImage(file)
            .subscribe((response: any) => {
                this.signatureSelectedValue = response.value;
                this.signatureChange.emit(response.value);
            },
                (error) => {

                    console.error('', error)
                });

        this.activeModal.close();
    }
    closeModal(): void {
        this.activeModal.dismiss();
    }
}

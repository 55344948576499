export class UnitRequest {
    model: {
        id: number;
        name: string;
        rfId1: string;
        rfId2: string;
        progress: number;
        mouldId: number;
    }

    constructor() {
        this.model = {
            id: 0,
            name: "",
            rfId1: "",
            rfId2: "",
            progress: 0,
            mouldId: 0,
        }
    }
}

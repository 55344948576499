<ng-container>
    <form [formGroup]="FormData" class="mb-25">
        <div formArrayName="inputsDescription">


            <div *ngFor="let group of FormData.get('inputsDescription')['controls']; let i = index">
                <div [formGroupName]="i">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 posRelative" *ngFor="let controlName of controlNameArray">
                            <div class="tagus-form-group">
                                <label class="d-block mb-12 fw-semibold gray-color">
                                    {{ controlName.name.split('_').slice(0, -1).join('_') }}
                                    <span *ngIf="controlName.isRequired">*</span>
                                </label>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'UnitReference'"
                                                (click)="unitReferencePadPopup(i,controlName.name)" (keydown.enter)="$event.preventDefault()">
                                    <i [style.color]=" getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <input matInput [formControlName]="controlName.name"
                                           [placeholder]="'Enter ' + controlName.name.split('_').slice(0, -1).join('_')">
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'Hall'"
                                                (keydown.enter)="$event.preventDefault()">
                                    <i [style.color]=" getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <input matInput readonly [formControlName]="controlName.name"
                                           [placeholder]="'Enter ' + controlName.name.split('_').slice(0, -1).join('_')">
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'MouldNumber'"
                                                (keydown.enter)="$event.preventDefault()">
                                    <i [style.color]=" getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <input matInput readonly [formControlName]="controlName.name"
                                           [placeholder]="'Enter ' + controlName.name.split('_').slice(0, -1).join('_')">
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType !== 'Number' &&
                controlName.inputType !== 'UnitReference' &&
                controlName.inputType !== 'DropDown' &&
                controlName.inputType !== 'Signature' &&
                controlName.inputType !== 'Handwriting' &&
                controlName.inputType !== 'ToggleButton' &&
                controlName.inputType !== 'PartialValidation' &&
                controlName.inputType !== 'ImageUpload' &&
                controlName.inputType !== 'Hall' &&
                controlName.inputType !== 'Time' &&
                controlName.inputType !== 'MouldNumber' &&
                controlName.inputType !== 'Date' " (keydown.enter)="onEnterKeyPressed($event,previousValues[controlName.name], controlName.name)">
                                    <i [style.color]="getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <input matInput [formControlName]="controlName.name"
                                           (blur)="saveValue($event.target.value,previousValues[controlName.name],controlName.name)"
                                           (focus)="capturePreviousValue($event.target.value, controlName.name)"
                                           [placeholder]="'Enter ' + controlName.name.split('_').slice(0, -1).join('_')">
                                    <mat-icon matSuffix class="loader" *ngIf="controlName.loading"></mat-icon>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName)" class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'Number'" (keydown.enter)="onEnterKeyPressed($event,previousValues[controlName.name], controlName.name)">
                                    <i [style.color]="getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <input matInput [formControlName]="controlName.name" type="number"
                                           (blur)="saveValue($event.target.value,previousValues[controlName.name],controlName.name)"
                                           (focus)="capturePreviousValue($event.target.value, controlName.name)"
                                           [placeholder]="'Enter ' + controlName.name.split('_').slice(0, -1).join('_')">
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName)" class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                    <mat-icon matSuffix class="loader" *ngIf="controlName.loading"></mat-icon>

                                </mat-form-field>
                                <mat-error *ngIf="inputDescriptionArray[0]?.get(controlName.name)?.hasError('nameDuplicate')">
                                    {{ controlName.name.split('_').slice(0, -1).join('_') }} already exists.
                                </mat-error>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'Date'">
                                    <i [style.color]=" getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date"
                                           [formControlName]="controlName.name" [value]="getLiveTime(controlName.name)" (dateChange)="saveDateValue($event.target.value,controlName.name)" [min]="minDate" [max]="maxDate" [disabled]="disabled">
                                    <ngx-mat-datepicker-toggle matSuffix [for]="picker"></ngx-mat-datepicker-toggle>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName)" class="ri-delete-bin-line marginRemove removeIconCursor"></mat-icon>
                                    <ngx-mat-datetime-picker #picker [picker]="defaultDateTime" [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                                             [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi"
                                                             [color]="color" [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
                                        <ngx-mat-datepicker-actions>
                                            <button mat-button ngxMatDatepickerCancel>Cancel</button>
                                            <button mat-raised-button color="primary" ngxMatDatepickerApply>Apply</button>
                                        </ngx-mat-datepicker-actions>
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'Time'">
                                    <i [style.color]="getColor(controlName.name)" [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <input matInput [ngxMatTimepicker]="timepicker" format="24" placeholder="Choose a time"
                                           [formControlName]="controlName.name"
                                           [disabled]="disabled">
                                    <ngx-mat-timepicker-toggle matSuffix [for]="timepicker"></ngx-mat-timepicker-toggle>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName)" class="ri-delete-bin-line marginRemove removeIconCursor"></mat-icon>
                                    <ngx-mat-timepicker #timepicker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                                                        [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                                                        [enableMeridian]="enableMeridian" [hideTime]="hideTime" (timeSet)="saveTimeValue($event, controlName.name)">
                                    </ngx-mat-timepicker>
                                </mat-form-field>

                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'DropDown'">
                                    <mat-select [disabled]="isDropdownDisabled" name="input" [formControlName]="controlName.name" placeholder="Select an option">
                                        <mat-option *ngFor="let option of controlName.inputTypeValues"
                                                    (click)="saveValue(FormData.get('inputsDescription')['controls'][0].get(controlName.name).value,previousValues[controlName.name],controlName.name)"
                                                    [value]="option.id.toString()">
                                            {{ option.value }}
                                        </mat-option>
                                    </mat-select>

                                    <i [style.color]="getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <mat-icon matSuffix class="loader" *ngIf="controlName.loading"></mat-icon>
                                    <br>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName); isDropdownDisabled = true" class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                </mat-form-field>


                                <mat-form-field appearance="fill" *ngIf="controlName.inputType == 'ImageUpload'" (keydown.enter)="$event.preventDefault()">
                                    <i [style.color]=" getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <ng-template #newFile>
                                        <mat-label>Choose file</mat-label>
                                    </ng-template>
                                    <input matInput readonly [formControlName]="controlName.name"
                                           [placeholder]="'Select ' + controlName.name.split('_').slice(0, -1).join('_')">

                                    <!-- <button mat-icon-button matSuffix (click)="fileInput.click()"> -->
                                    <mat-icon matSuffix (click)="fileInput.click()" class="ri-image-add-line"></mat-icon>
                                    <mat-icon matSuffix (click)="openCamera(controlName.name)" class="ri-camera-3-line margin-bottom"></mat-icon>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="showPreview(controlName)" class="ri-gallery-fill margin-bottom"></mat-icon>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName)" class="ri-delete-bin-line removeIconCursor margin-bottom"></mat-icon>
                                    <mat-icon matSuffix class="img-loader" *ngIf="controlName.loading"></mat-icon>
                                    <!-- </button> -->
                                    <input hidden (change)="selectFile($event,controlName.name)" #fileInput type="file" id="file">

                                </mat-form-field>

                                <mat-form-field appearance="fill" (click)="signaturePadPopup(controlName.name)"
                                                *ngIf="controlName.inputType == 'Signature' || controlName.inputType == 'Handwriting' ">
                                    <i [style.color]=" getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <mat-icon matSuffix class="ri-chat-upload-fill" *ngIf="!controlName.loading"></mat-icon>
                                    <input matInput readonly [formControlName]="controlName.name" type="text"
                                           [placeholder]="'Enter ' + controlName.name.split('_').slice(0, -1).join('_')">
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="showPreview(controlName)" class="ri-gallery-fill"></mat-icon>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName)" class="ri-delete-bin-line removeIconCursor"></mat-icon>
                                    <mat-icon matSuffix class="loader" *ngIf="controlName.loading"></mat-icon>

                                </mat-form-field>

                                <mat-form-field appearance="fill"
                                                *ngIf="controlName.inputType == 'PartialValidation' || controlName.inputType == 'ToggleButton' ">
                                    <i [style.color]="getColor(controlName.name)"
                                       [class]="getIconClass(controlName.inputTypeId)"></i>
                                    <span *ngIf="getColor(controlName.name)=='red'">Check {{controlName.name.split('_').slice(0, -1).join('_')}}</span>
                                    <div style="float: right;">

                                        <mat-slide-toggle [color]="FormData.get('inputsDescription')['controls'][0].get(controlName.name).value == '' ?  'red' : 'green'"
                                                          (change)="toggleSaveValue($event,controlName.name)"
                                                          [checked]="FormData.get('inputsDescription')['controls'][0].get(controlName.name).value"
                                                          [formControlName]="controlName.name">
                                        </mat-slide-toggle>
                                    </div>
                                    <textarea matInput hidden></textarea>
                                    <mat-icon matSuffix *ngIf="getColor(controlName.name) == 'green'" (click)="deleteDescriptionValue(controlName)" class="ri-delete-bin-line marginRemoveToggle removeIconCursor"></mat-icon>
                                    <mat-icon matSuffix class="loader" *ngIf="controlName.loading"></mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-container>


<button type="button" class="d-none" id="menuModal" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
</button>

<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">{{popName}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <ng-container>
                <signature-pad #signaturePad [options]="signaturePadOptions" (drawStart)="drawStart($event)"
                               (drawEnd)="drawComplete($event)"></signature-pad>
            </ng-container>

            <div class="modal-footer">
                <button type="button" class="btn cancleButtonColor" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn saveButtonColor" data-bs-dismiss="modal"
                        (click)="saveSignatureAsImage()">
                    Save
                </button>
            </div>
        </div>
    </div>
</div>

<button type="button" class="d-none" id="unitModal" data-bs-toggle="modal" data-bs-target="#staticBackdropUnit">
</button>


<!-- Popup -->
<div class="modal fade" id="staticBackdropUnit"
     data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropUnitLabel" aria-hidden="true">
    <div class="modal-dialog" style="width: 800px;">
        <div class="modal-content" style="width: 800px;">
            <div class="modal-header" style="width: 800px;">
                <h5 class="modal-title" id="staticBackdropUnitLabel">{{unitpopName}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <ng-container>

                <!-- app.component.html -->
                <div class="form-container">
                    <form [formGroup]="unitForm" style="width: 780px;">

                        <mat-card class="mb-25 tagus-card">
                            <mat-card-content>
                                <label><h5 class="mb-0">Type Element</h5></label>
                                <mat-radio-group>
                                    <mat-radio-button class="example-radio-button" *ngFor="let element of elements"
                                                      [value]="element" (change)="handleRadio($event)" [disabled]="element.enabled">
                                        {{element.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="mb-25 tagus-card">
                            <mat-card-content>
                                <label><h5 class="mb-0">Tunnel Name</h5></label>
                                <mat-radio-group>
                                    <mat-radio-button class="example-radio-button" *ngFor="let tunnel of tunnels"
                                                      [value]="tunnel" (change)="handleRadio($event)" [disabled]="tunnel.enabled">
                                        {{tunnel.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="mb-25 tagus-card">
                            <mat-card-content>
                                <label><h5 class="mb-0">Piece</h5></label>
                                <mat-radio-group>
                                    <mat-radio-button class="example-radio-button" *ngFor="let piece of pieces"
                                                      [value]="piece" (change)="handleRadio($event)" [disabled]="piece.enabled">
                                        {{piece.name}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-card-content>
                        </mat-card>

                        <mat-card class="mb-25 tagus-card">
                            <mat-card-content>
                                <label><h5 class="mb-0">Type</h5></label>
                                <mat-radio-group>
                                    <mat-radio-button class="example-radio-button" *ngFor="let type of types"
                                                      [value]="type" (change)="handleRadio($event)">
                                        {{type}}
                                    </mat-radio-button>
                                </mat-radio-group>
                            </mat-card-content>
                        </mat-card>

                        <mat-form-field appearance="fill">
                            <mat-label>Specificity</mat-label>
                            <mat-select placeholder="Select specifity" (selectionChange)="handleRadio($event)">
                                <mat-option *ngFor="let value of specificityValues" [value]="value">
                                    {{ value }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </form>
                </div>

                <div class="fw-semibold mt-10">Unit name: {{unitName}}</div>

            </ng-container>

            <div class="modal-footer">
                <button type="button" class="btn saveButtonColor" data-bs-dismiss="modal" [disabled]="unitName == null"
                        (click)="saveUnitReference()">
                    Save
                </button>
                <button type="button" class="btn cancleButtonColor" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<button type="button" class="d-none" id="cameraOpen" data-bs-toggle="modal" data-bs-target="#staticCamera">
</button>

<div class="modal fade" id="staticCamera" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Image Capture</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeCamera()" aria-label="Close"></button>
            </div>
            <ng-container>
                <div style="text-align:center">
                    <div *ngIf="isOpenCamera && !webcamImage">
                        <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
                                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
                                (initError)="handleInitError($event)"></webcam>
                    </div>
                    <div class="snapshot" *ngIf="webcamImage">
                        <img [src]="webcamImage.imageAsDataUrl" />
                    </div>
                </div>
            </ng-container>

            <div class="modal-footer">
                <button type="button" class="btn saveButtonColor" data-bs-dismiss="modal"
                        (click)="savePicture()">
                    Save
                </button>
                <button type="button" class="btn saveButtonColor" (click)="takePicture()">Take Picture</button>
                <button type="button" class="btn cancleButtonColor" (click)="closeCamera()" data-bs-dismiss="modal">Close</button>

            </div>
        </div>
    </div>
</div>

<button type="button" class="d-none" id="preViewOpen" data-bs-toggle="modal" data-bs-target="#staticPreview">
</button>

<div class="modal fade" id="staticPreview" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Preview</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeCamera()" aria-label="Close"></button>
            </div>
            <ng-container>
                <div style="text-align:center">

                    <div class="snapshot">
                        <img [src]="previewImage" width="798"
                             height="600" />
                    </div>
                </div>
            </ng-container>

            <div class="modal-footer">
                <button type="button" class="btn cancleButtonColor" (click)="closeCamera()" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<button type="button" class="d-none" id="confirmOpen" data-bs-toggle="modal" data-bs-target="#staticConfirm">
</button>

<div class="modal fade" id="staticConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
     aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <ng-container>
                <div style="text-align:center">
                    <div class="modal-header">
                        <button type="button" class="btn-close fs-sm" title="Close" aria-label="Close" tabindex="-1" data-bs-dismiss="modal"
                                (click)="activeModal.dismiss('Cross click'); isDropdownDisabled = false"></button>
                    </div>
                    <div class="modal-body text-center">
                        <img src="assets/img/remove_icon.png" width="150px" alt="Image" class="mx-auto d-block my-5" />

                        <h1 class="delete-title">Delete</h1>
                        <p>Are you sure you want to delete?</p>
                        <div class="text-end">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div style="text-align: right;" class="mb-3 example-buttons">
                                        <button mat-flat-button data-bs-dismiss="modal" class="cancel-btn" (click)="decline()">No</button>
                                        <span class="mx-2"></span>
                                        <button mat-flat-button color="warn" data-bs-dismiss="modal" (click)="confirm()">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>


        </div>
    </div>
</div>

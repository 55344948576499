import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TestTypeRequest } from 'src/app/models/testType-request.model';
import { TestType } from 'src/app/models/testType.model';
import { TestTypeService } from 'src/app/services/testTypeServices/testType.service';
import { FactoryService } from 'src/app/services/factory.service';
import { CustomValidators } from 'src/app/shared/custom-validators';
import { EntityType } from 'src/app/models/enums';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-add-edit-testType',
  templateUrl: './add-edit-testType.component.html',
  styleUrls: ['./add-edit-testType.component.scss']
})
export class AddEditTestTypeComponent implements OnInit {
  testTypeForm: FormGroup;
  isEditMode = false;
  testTypeData: TestType;
  testTypeRequest: TestTypeRequest = new TestTypeRequest();
  testTypeId: number = 0;
  isLoading: boolean;
  inProgress: boolean;
  constructor(
    private fb: FormBuilder,
    private testTypeService: TestTypeService,
    private factoryService : FactoryService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService:ToastrService
  ) { }

  ngOnInit(): void {
    if (+this.route.snapshot.queryParams['id'])
    {
        this.createForm( +this.route.snapshot.queryParams['id']);
    }else{
      this.createForm( 0);
    }

    this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['id']) {
        this.testTypeId= +this.route.snapshot.queryParams['id'];
    //    this.testTypeId = queryParams['id'];
        this.isEditMode = true;
        this.loadTestType(this.testTypeId.toString());
      }
    });
  }

  private createForm(testTypeId : number): void {
    this.testTypeForm = this.fb.group({
       name: ['', [Validators.required],[CustomValidators.validateNameDuplicate(this.factoryService,EntityType.TestType,testTypeId)]],
      //  email: ['', [Validators.required, Validators.email]],
      //  address: [''],
       description: [''],
      //  mobile: [''],
      //  phone: [''],
      //  support: ['']
    });
  }

  private loadTestType(id: string): void {
    debugger
    this.testTypeService.getTestType(id).subscribe((testType) => {
      debugger
      this.testTypeData = testType;
      this.testTypeForm.patchValue(testType);
    });
  }

  save(): void {
    this.isLoading = true;

    if (this.testTypeForm.valid) {
      if (this.inProgress) {
       return;
      }
      this.inProgress = true;
      const formData = this.testTypeForm.value as TestType;
      this.testTypeRequest.model = { ...this.testTypeForm.value };
      this.testTypeRequest.model.id = this.testTypeId;
      if (this.isEditMode) {
        // Update an existing testType
        this.testTypeService.updateTestType(this.testTypeRequest).subscribe(() => {
          this.isLoading = false;
          this.inProgress = false;
          // Handle success or navigate to a different page
          this.toasterService.success(`Test Type ${this.testTypeRequest.model.name} has been updated.`,'Test Type Updated',);
                  
          this.router.navigate(['/testtypes']);
        });
      } else {
        // Create a new testType
        this.testTypeService.createTestType(this.testTypeRequest).subscribe(() => {
          this.isLoading = false;
          this.inProgress = false;
          this.toasterService.success(`Test Type ${this.testTypeRequest.model.name} has been created.`,'Test Type Created');
                    
          // Handle success or navigate to a different page
          this.router.navigate(['/testtypes']);
        });
      }
    } else {
      this.isLoading = false;
      this.inProgress = false;

      this.toasterService.error(`Kindly fill the Test Type form correctly.`,'Test Type Failed');
    }

  }
  cancel() {
    this.router.navigate(['/testtypes']);
  }
}

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Active Report</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Active Report</li>
    </ol>
</div>

<div id="ar-web-designer" class="ar-web-designer">
    <span class="ar-web-designer__loader"><b>AR Web Designer</b></span>
</div>

import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { catchError, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UnitEndpointService extends EndpointBase {

    constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
        super(http, authService)
    }

    get unitsUrl() {
        return this.configurations.baseUrl + '/api/unit';
    }

    getUnitsEndpoint<T>(page?: number, pageSize?: number, searchTerm?: string, sortColumn?: string, sortOrder?: string, startDate?: string, endDate?: string, mouldsTypeIds?: string): Observable<T> {
        const endpointUrl = page && pageSize ? `${this.unitsUrl}/AllUnits?pageNumber=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&startDate=${startDate}&endDate=${endDate}&mouldsTypeIds=${mouldsTypeIds}` : this.unitsUrl;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>((error: any) => {
                return this.handleError(error, () => this.getUnitsEndpoint(page, pageSize));
            }));
    }

    getUnitsListEndpoint<T>(): Observable<T> {
        const endpointUrl = `${this.unitsUrl}/units`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>((error: any) => {
                return this.handleError(error, () => this.getUnitsEndpoint());
            }));
    }

    getNewUnitEndpoint<T>(unitObject: any): Observable<T> {

        return this.http.post<T>(this.unitsUrl, JSON.stringify({ Model: unitObject }), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getNewUnitEndpoint(unitObject));
            }));
    }

    getUpdateUnitEndpoint<T>(unitObject: any, id?: number): Observable<T> {
        const endpointUrl = `${this.unitsUrl}/${id}`;

        return this.http.put<T>(endpointUrl, JSON.stringify(unitObject), this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUpdateUnitEndpoint(unitObject, id));
            }));
    }

    getUnitEndpoint<T>(unitId?: string): Observable<T> {
        const endpointUrl = `${this.unitsUrl}/${unitId}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUnitEndpoint(unitId));
            })
        );

    }

    getDeleteUnitEndpoint<T>(unitId: string): Observable<T> {
        const endpointUrl = `${this.unitsUrl}/${unitId}`;

        return this.http.delete<T>(endpointUrl, this.requestHeaders).pipe<T>(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getDeleteUnitEndpoint(unitId));
            }));
    }
    getUnitByRfidEndpoint <T> (RFID : string) : Observable<T> {
        const endpointUrl = `${this.unitsUrl}/GetByRfid?rfid=${RFID}`;
        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUnitByRfidEndpoint(RFID));
            })
        );
    }
    getUnitDuplicateStatusEndpoint<T>(unitName?: string): Observable<T> {
        const endpointUrl = `${this.unitsUrl}/validateDuplicateName/${unitName}`;

        return this.http.get<T>(endpointUrl, this.requestHeaders).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.getUnitDuplicateStatusEndpoint(unitName));
            })
        );
    }

    generateUnitName<T>(name: string): Observable<any> {
        const endpointUrl = `${this.unitsUrl}/generateName/${name}`;

        let header = this.requestHeaders;
        header['responseType'] = 'text';

        return this.http.get<T>(endpointUrl, header).pipe(
            catchError<T, Observable<T>>(error => {
                return this.handleError(error, () => this.generateUnitName(name));
            })
        );
    }
}

<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Profile</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">

    <mat-card-content>

        <form #f="ngForm" (ngSubmit)="f.form.valid ? save() :

          (!userName.valid && showErrorAlert('User name is required', 'Please enter a user name (minimum of 2 and maximum of 200 characters)'));

          (userPassword && !userPassword.valid && showErrorAlert('Password is required', 'Please enter the current password'));

          (email.errors?.required && email?.touched && showErrorAlert('Email is required', 'Please enter an email address (maximum of 200 characters)'));
          (email.errors?.pattern && email?.touched && showErrorAlert('Invalid Email', 'Please enter a valid email address'));

          (isChangePassword  && isEditingSelf && isChangePassword?.touched && !currentPassword.valid && showErrorAlert('Current password is required', 'Please enter the current password'));

          ((isChangePassword || isNewUser) && newPassword?.touched  &&  !newPassword.valid && showErrorAlert('New password is required', 'Please enter the new password (minimum of 6 characters)'));

          ((isChangePassword || isNewUser) && newPassword?.touched && newPassword.valid && confirmPassword.errors?.required && showErrorAlert('Confirmation password is required', 'Please enter the confirmation password'));
          ((isChangePassword || isNewUser) && newPassword?.touched && newPassword.valid && confirmPassword.errors?.appValidateEqual && showErrorAlert('Passwword mismatch', 'New password and confirmation password do not match'));

          (canAssignRoles && !roles.valid && showErrorAlert('Roles is required', 'Please select a minimum of 1 role'));" *ngIf="formResetToggle"
              [attr.autocomplete]="isGeneralEditor ? 'NaN' : null" name="userInfoForm"
              novalidate>
            <div class="row mt-20">
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                               class="d-block mb-12 fw-semibold gray-color"
                               for="jobTitle-{{uniqueId}}">{{'users.editor.JobTitle' | translate}}</label>
                        <div *ngIf="!isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly">
                            <p class="form-control-plaintext">{{user.jobTitle}}</p>
                        </div>
                        <mat-form-field *ngIf="isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly"
                                        appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <input [(ngModel)]="userEdit.jobTitle" attr.id="jobTitle-{{uniqueId}}" matInput name="jobTitle"
                                   placeholder="Enter Job Title" type="text" value="Andrew">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                               class="d-block mb-12 fw-semibold gray-color"
                               for="email-{{uniqueId}}">{{'users.editor.Email'
                            | translate}}</label>
                        <div *ngIf="!isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly">
                            <p class="form-control-plaintext">{{user.email}}</p>
                        </div>
                        <mat-form-field *ngIf="isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly"
                                        appearance="fill">
                            <i class="ri-mail-line"></i>
                            <input #email="ngModel" [(ngModel)]="userEdit.email" attr.id="email-{{uniqueId}}" matInput
                                   maxlength="200"

                                   name="email" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" placeholder="Enter email address" required
                                   type="text">

                        </mat-form-field>
                        <span *ngIf="showValidationErrors  && email?.errors?.required" class="invalid-feedback d-block">
                            {{'users.editor.EmailRequired' | translate}}
                        </span>
                        <span *ngIf="showValidationErrors  && email?.errors?.pattern" class="invalid-feedback d-block">
                            {{'users.editor.InvalidEmail' | translate}}
                        </span>
                    </div>

                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                               class="d-block mb-12 fw-semibold gray-color"
                               for="userName-{{uniqueId}}">{{'users.editor.UserName' | translate}}</label>
                        <div *ngIf="!isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly">
                            <p class="form-control-plaintext">{{user.userName}}</p>
                        </div>
                        <mat-form-field *ngIf="isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly"
                                        appearance="fill">
                            <i class="ri-user-3-line"></i>
                            <input #userName="ngModel" [(ngModel)]="userEdit.userName" [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" attr.id="userName-{{uniqueId}}"
                                   matInput
                                   maxlength="200"
                                   minlength="2" name="userName" placeholder="Enter user name" required
                                   type="text">
                        </mat-form-field>
                        <span *ngIf="showValidationErrors  && !userName?.valid" class="invalid-feedback d-block">
                            {{'users.editor.UserNameRequired' | translate}}
                        </span>
                    </div>
                </div>
                <div *ngIf="isEditMode && isEditingSelf && !isChangePassword && user.userName != userEdit.userName"
                     class="row">
                    <div class="col-lg-12">
                        <hr class="hr-password-separator"/>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <div *ngIf="isEditMode" class="row">
                            <label class="d-block mb-12 fw-semibold gray-color"
                                   for="newPassword-{{uniqueId}}">{{'users.editor.Password' |
                                translate}}</label>
                            <div *ngIf="!isChangePassword && !isNewUser" class="col-lg-10">
                                <button (click)="changePassword()" class="btn btn-link text-link"
                                        type="button">{{'users.editor.ChangePassword' |
                                    translate}}</button>
                            </div>

                            <div *ngIf="isChangePassword || isNewUser" class="col-lg-10">
                                <div class="password-well card card-body px-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="tagus-form-group password-form-height">
                                                <label class="d-block mb-12 fw-semibold gray-color"
                                                       for="currentPassword-{{uniqueId}}">
                                                    {{
'users.editor.CurrentPassword'
                                                    |
                                                    translate
                                                    }}
                                                </label>
                                                <mat-form-field appearance="fill">
                                                    <i class="ri-lock-line"></i>
                                                    <mat-label>Type password</mat-label>
                                                    <input #currentPassword="ngModel" [(ngModel)]="userEdit.currentPassword"
                                                           [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" [type]="hide ? 'password' : 'text'"
                                                           attr.id="currentPassword-{{uniqueId}}"
                                                           matInput
                                                           name="currentPassword"
                                                           placeholder="Enter current password" required>
                                                    <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                                            mat-icon-button
                                                            matSuffix>
                                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>

                                                <span *ngIf="showValidationErrors  && !currentPassword?.valid  && currentPassword?.touched"
                                                      class="invalid-feedback d-block">
                                                    {{'users.editor.CurrentPasswordRequired' | translate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="tagus-form-group">
                                                <label class="d-block mb-12 fw-semibold gray-color"
                                                       for="newPassword-{{uniqueId}}">
                                                    {{
'users.editor.NewPassword' |
                                                    translate
                                                    }}
                                                </label>
                                                <mat-form-field appearance="fill">
                                                    <i class="ri-lock-line"></i>
                                                    <mat-label>Type password</mat-label>
                                                    <input #newPassword="ngModel" [(ngModel)]="userEdit.newPassword"
                                                           [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" [type]="hidePass ? 'password' : 'text'"
                                                           appValidateEqual="confirmPassword"
                                                           attr.id="newPassword-{{uniqueId}}"
                                                           matInput minlength="6"
                                                           name="newPassword" placeholder="Enter new password"
                                                           required
                                                           reverse="true">
                                                    <button (click)="hidePass = !hidePass" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePass"
                                                            mat-icon-button
                                                            matSuffix>
                                                        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <span *ngIf="showValidationErrors && confirmPassword?.errors?.validateEqual && !confirmPassword?.errors?.required"
                                                      class="invalid-feedback d-block">
                                                    {{'register.PasswordMismatch' | translate}}
                                                </span>
                                                <span *ngIf="showValidationErrors && !confirmPassword?.valid  && confirmPassword?.touched  && confirmPassword?.errors?.required"
                                                      class="invalid-feedback d-block">
                                                    {{'register.ConfirmPasswordRequired' | translate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="tagus-form-group">
                                                <label class="d-block mb-12 fw-semibold gray-color"
                                                       for="confirmPassword-{{uniqueId}}">{{'users.editor.ConfirmPassword'
                                                    |
                                                    translate}}</label>
                                                <mat-form-field appearance="fill">
                                                    <i class="ri-lock-line"></i>
                                                    <mat-label>Type password</mat-label>
                                                    <input #confirmPassword="ngModel" [(ngModel)]="userEdit.confirmPassword"
                                                           [attr.autocomplete]="isGeneralEditor ? 'new-password' : null" [type]="hideConfirmPass ? 'password' : 'text'"
                                                           appValidateEqual="newPassword"
                                                           attr.id="confirmPassword-{{uniqueId}}" class="form-control"
                                                           matInput
                                                           name="confirmPassword" placeholder="Confirm new password"
                                                           required>
                                                    <button (click)="hideConfirmPass = !hideConfirmPass" [attr.aria-label]="'Hide password'"
                                                            [attr.aria-pressed]="hideConfirmPass"
                                                            mat-icon-button
                                                            matSuffix>
                                                        <mat-icon>{{hideConfirmPass ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <span
                                                    *ngIf="showValidationErrors && confirmPassword?.errors?.validateEqual && !confirmPassword?.errors?.required"
                                                    class="invalid-feedback d-block">
                                                    {{'users.editor.PasswordMismatch' | translate}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!isEditMode || canAssignRoles" class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                               class="d-block mb-12 fw-semibold gray-color"
                               for="roles-user-info">{{'users.editor.Roles' |
                            translate}}</label>
                        <div *ngIf="!isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly">
                            <p class="form-control-plaintext" ngPreserveWhitespaces>
                                <span *ngFor="let role of user.roles">
                                    <span class="badge rounded-pill bg-secondary"
                                          title='{{getRoleByName(role)?.description}}'>{{role}}</span>
                                </span>
                            </p>
                        </div>

                        <!-- <i class="ri-phone-line"></i>
                            <input matInput value="+(555) 555-1234"> -->
                        <div *ngIf="isEditMode && canAssignRoles"

                             [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly">


                            <ng-select #roles="ngModel" [(ngModel)]="userEdit.roles" [closeOnSelect]="false" [items]="allRoles"
                                       [multiple]="true" bindLabel="name" bindValue="name"
                                       class="tagus-form-group"
                                       id="roles-user-info" name="roles"
                                       placeholder="Select Roles" required>
                                <ng-template let-clear="clear" let-item="item" ng-label-tmp>
                                    <span class='badge gray rounded-pill'
                                          style="background: rgb(236,236,236);color: black;"
                                          title='{{item.description}}'>{{item.name}}<span
                                        (click)="clear(item)" aria-hidden="true"
                                        class="ng-value-icon right">×</span></span>
                                </ng-template>

                                <ng-template let-item="item" ng-option-tmp>
                                    <span class='badge rounded-pill'
                                          style="background: rgb(236,236,236);color: black;"
                                          title='{{item.description}}'>{{item.name}}</span>
                                    <i class="fa fa-check d-none"></i>
                                </ng-template>
                            </ng-select>

                            <span *ngIf="showValidationErrors  && !roles?.valid" class="invalid-feedback d-block">
                                {{'users.editor.RoleRequired' | translate}}
                            </span>
                        </div>

                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                               class="d-block mb-12 fw-semibold gray-color"
                               for="fullName-{{uniqueId}}">{{'users.editor.FullName' | translate}}</label>
                        <div *ngIf="!isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly">
                            <p class="form-control-plaintext">{{user.fullName}}</p>
                        </div>
                        <mat-form-field *ngIf="isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly"
                                        appearance="fill">
                            <i class="ri-home-7-line"></i>
                            <input [(ngModel)]="userEdit.fullName" attr.id="fullName-{{uniqueId}}" matInput name="fullName"
                                   placeholder="Enter full name" type="text">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="tagus-form-group">
                        <label [class.col-lg-2]="!isViewOnly" [class.col-lg-3]="isViewOnly"
                               class="d-block mb-12 fw-semibold gray-color"
                               for="phoneNumber-{{uniqueId}}">{{'users.editor.PhoneNumber' | translate}}</label>
                        <div *ngIf="!isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly">
                            <p class="form-control-plaintext">{{user.phoneNumber}}</p>
                        </div>
                        <mat-form-field *ngIf="isEditMode" [class.col-lg-10]="!isViewOnly" [class.col-lg-9]="isViewOnly"
                                        appearance="fill">
                            <i class="ri-home-7-line"></i>
                            <input [(ngModel)]="userEdit.phoneNumber" attr.id="phoneNumber-{{uniqueId}}" matInput name="phoneNumber"
                                   placeholder="Enter phone number" type="text">
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="!isViewOnly">
                    <div class="tagus-form-group">
                        <label class="d-block mb-12 fw-semibold gray-color">{{'users.editor.Enabled' |
                            translate}}</label>
                        <div *ngIf="isGeneralEditor && isEditMode">
                            <div class="form-check user-enabled">
                            </div>
                            <input matInput type="checkbox" id="idEnabled" name="isEnabled"
                                [(ngModel)]="userEdit.isEnabled">
                            <button *ngIf="userEdit.isLockedOut" type="button" (click)="unlockUser()"
                                class="btn btn-warning unblock-user" [disabled]="isSaving"><i
                                    class='fa fa-unlock-alt'></i> {{'users.editor.Unblock' | translate}}</button>
                        </div>
                    </div>
                </div> -->
                <div *ngIf="!isViewOnly" class="row">
                    <div class="col-sm-5">
                        <div class="tagus-form-group">
                            <div *ngIf="isGeneralEditor && isEditMode" class="float-start">
                                <div class="form-check user-enabled">
                                    <input [(ngModel)]="userEdit.isEnabled" class="form-check-input" id="idEnabled" name="isEnabled"
                                           type="checkbox">
                                    <label class="form-check-label" for="idEnabled">{{'users.editor.Enabled' |
                                        translate}}</label>
                                </div>
                                <button (click)="unlockUser()" *ngIf="userEdit.isLockedOut" [disabled]="isSaving"
                                        class="btn btn-warning unblock-user" mat-raised-button
                                        type="button"><i
                                    class='fa fa-unlock-alt'></i> {{'users.editor.Unblock' | translate}}</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <!-- <h5 class="fw-semibold mb-8 mt-5">Your Photo</h5>
            <p>This will be displayed on your profile.</p>
            <div class="d-flex align-items-center">
                <img src="assets/img/user/user15.jpg" class="rounded-circle" width="110" alt="user">
                <div class="tagus-form-group ms-20">
                    <label class="d-block mb-12 fw-semibold gray-color">Upload Image</label>
                    <input type="file">
                </div>
            </div> -->

            <button (click)="close()" *ngIf="!isEditMode && isGeneralEditor" class="tagus fw-semibold button-spacing"
                    mat-raised-button type="button"><i class="ri-close-fill"></i>
                {{'users.editor.Close' | translate}}</button>
            <!-- <button mat-raised-button *ngIf="!isEditMode && !isGeneralEditor" type="button"
                class="tagus fw-semibold button-spacing" (click)="edit()"><i class="ri-edit-fill"></i>
                {{'users.editor.Edit' | translate}}</button> -->
            <!-- <button mat-raised-button *ngIf="isEditMode" type="button" (click)="cancel()"
                class="tagus fw-semibold button-spacing" [disabled]="isSaving"><i class='fa fa-times'></i>
                {{'users.editor.Cancel' | translate}}</button> -->
            <!-- <button mat-raised-button *ngIf="isEditMode" type="submit" class="tagus fw-semibold button-spacing"
                [disabled]="isSaving">
                <i *ngIf="!isSaving" class='ri-edit-fill'></i><i *ngIf="isSaving"
                    class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) :
                ('users.editor.Save' | translate)}}
            </button> -->
            <!-- <button mat-raised-button *ngIf="!isEditMode && !isGeneralEditor" type="submit"
                class="tagus fw-semibold button-spacing"><i class="ri-add-fill"></i> Add New User</button>
        </form> -->
            <div>
                <br/>
                <h5 class="mb-0">More Settings</h5>
                <br/>
                <div class="row">
                    <div class="p-20 col-md-5">
                        <div class="row">
                            <div class="col-md-9">
                                <span class="sub-title d-block mb-12 fw-semibold ">Dark Mode</span>
                            </div>
                            <div class="col-md-3">
                                <button (click)="toggleTheme()" [class.active]="isDark()" class="switch-btn "></button>
                            </div>
                        </div>
                    </div>
                    <div class="p-20 col-md-5">
                        <div class="row">
                            <div class="col-md-9">
                                <span class="sub-title d-block mb-12 fw-semibold">Only Sidebar Dark</span>
                            </div>
                            <div class="col-md-3">
                                <button (click)="toggleSidebarTheme()" [class.active]="isSidebarDark()"
                                        class="switch-btn"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="p-20 col-md-5">
                        <div class="row">
                            <div class="col-md-9">
                                <span class="sub-title d-block mb-12 fw-semibold">Only Header Dark</span>
                            </div>
                            <div class="col-md-3">
                                <button (click)="toggleHeaderTheme()" [class.active]="isHeaderDark()"
                                        class="switch-btn"></button>
                            </div>
                        </div>
                    </div>
                    <div class="p-20 col-md-5">
                        <div class="row">
                            <div class="col-md-9">
                                <span class="sub-title d-block mb-12 fw-semibold">Borderd Card</span>
                            </div>
                            <div class="col-md-3">
                                <button (click)="toggleCardBorderTheme()" [class.active]="isCardBorder()"
                                        class="switch-btn"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <button mat-raised-button *ngIf="!isEditMode && !isGeneralEditor" type="submit"
                    class="tagus fw-semibold button-spacing"> Update</button> -->

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="mb-3 example-buttons" style="text-align: right;">
                        <button *ngIf="isEditMode" [disabled]="isSaving" class="tagus fw-semibold button-spacing"
                                mat-raised-button
                                type="submit">
                            <i *ngIf="isSaving"
                               class='fa fa-circle-o-notch fa-spin'></i> {{isSaving ? ('users.editor.Saving' | translate) :
                            ('Update' | translate)}}
                        </button>

                    </div>
                </div>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<ng-container *ngIf="canViewHomePage">
    <div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
        <h5 class="mb-0">Production Hall</h5>
        <mat-chip-listbox aria-label="Fish selection" class="justify-content-left">
            <mat-chip-option *ngFor="let item of factoriesList; let i = index" [selected]="i===0"
                             (click)="changeFactory(item.id)">{{item.name}}</mat-chip-option>
        </mat-chip-listbox>
        <ol class="breadcrumb mb-0 ps-0">
            <li class="breadcrumb-item">
                <a routerLink="/">
                    <i class="ri-home-8-line"></i>
                    Home
                </a>
            </li>
        </ol>
    </div>
    <div class="bg-white" style="padding: 18px;border-radius: 12px;">
        <div [class.component-dark-theme]="themeService.isDark()"
             [class.component-rtl-enabled]="themeService.isRTLEnabled()"
             [ngClass]="{'active': isToggled}"
             class="row" cdkDropListGroup>
            <div *ngFor="let item of allWorkplaces; let j = index"
                 class="col-lg-4 col-md-6 col-xl-4 col-xxl-4"
                 cdkDropList
                 cdkDropListOrientation="horizontal"
                 [cdkDropListData]="{item: allWorkplaces, index:j}"
                 (cdkDropListDropped)="dropWorkplaces($event)">
                <mat-card class="mb-25 position-relative taguus-card" [cdkDrag]="canManageHome" [cdkDragDisabled]="!canManageHome">
                    <mat-card-header>
                        <h5 class="m-2">{{item.name}}</h5>
                        <img src="assets/img/move-x.svg" style="margin-right: 5px;" alt="move">
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row" cdkDropListGroup>
                            <div *ngFor="let workspace of item.Workspaces; let i = index" class="col-md-6" style="border-radius: 12px;"
                                 cdkDropList
                                 cdkDropListOrientation="horizontal"
                                 [cdkDropListData]="{item:item.Workspaces, index:i}"
                                 (cdkDropListDropped)="drop($event, item.Workspaces)"
                                 (click)="!isLinkDisabled(workspace) && navigateToUnitVisualisation(workspace.mouldId)"
                                 style="text-align: center; margin-bottom: 20px;" [ngClass]="{'pointer': workspace.mouldName}">
                                <div *ngIf="workspace.mouldName" class="kanban-item border-radius p-20 bg-white" [ngClass]="{'dashed': workspace.mouldName == null}" [cdkDrag]="canManageHome" [cdkDragDisabled]="!canManageHome">
                                    <p class=" fw-medium mb-2" [style.backgroundColor]="getBackgroundColor(workspace.status)"
                                       [style.color]="getStatusTextColor(workspace.status)" style="border-radius: 12px;">{{ workspace.name }} - {{ workspace.status }}</p>
                                    <span class="muted-color mt-20 fw-medium">{{ workspace.mouldName }}</span>
                                </div>
                                <div class="kanban-item border-radius p-20 dashed" *ngIf="workspace.mouldName == null" [cdkDragHandleDisabled]="workspace.mouldName == null">
                                    <br><span class="muted-color mt-20 fw-medium">{{ workspace.name }}</span><br>
                                </div>
                            </div>
                        </div>
                        <br />
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</ng-container>


export class TestTypeRequest {
    model: {
        id: number;
        name: string;
        descrition: string;     
    };
  
    constructor() {
      this.model = {
        id:0,
        name: '', 
        descrition: ''
       
      };
    }
  }
  
import { Component , OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SelectIntervalDialogComponent } from '../select-interval-dialog/select-interval-dialog.component';
import { SelectListDialogComponent } from '../select-list-dialog/select-list-dialog.component';
import { TestParametersService } from 'src/app/services/testParametersServices/test-parameters.service';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
import { Permission } from 'src/app/models/permission.model';

import { SelectListItem, TestParameterRequest } from 'src/app/models/TestParameterRequest.model';
import { number } from 'echarts';
import { ToastrService } from 'ngx-toastr';
import { TestParameterUpdateRequest } from 'src/app/models/testParameterUpdateRequest.model';
import { Mandatory } from 'src/app/models/mandatory.model';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteTestParameterComponent } from '../delete-test-parameter/delete-test-parameter.component';
import { AccountService } from 'src/app/services/account.service';
@Component({
selector: 'app-test-parameters-new',
templateUrl: './test-parameters-new.component.html',
styleUrls: ['./test-parameters-new.component.scss']
})
export class TestParametersNewComponent implements OnInit {
parameterForm: FormGroup;
lastActiveParameterIndex: number = 0;
testId: number;
TestParameterRequest: TestParameterRequest = new TestParameterRequest();
isFirstAdd: boolean = true; 
hoveredParameterIndex: number | null = null;
editedParameterIndex: number | null = null;
checkboxStatus: boolean[] = [];
  modalService: any;
  isLoad : boolean = true;
//testParametersService : TestParametersService;
constructor(
private fb: FormBuilder, 
 public dialog: MatDialog,
private toasterService:ToastrService,
private route: ActivatedRoute,
private accountService: AccountService,
private testParametersService: TestParametersService,
private modalServices: NgbModal) { }

ngOnInit(): void {
  this.parameterForm = this.fb.group({
    parameters: this.fb.array([])
  });

  this.testId = +this.route.snapshot.queryParams['id'];
  this.loadExistingParameters();
}
//  testId(): number {

//    let id :  number = 0;
//  this.route.queryParams.subscribe(params => {
//      id = +params['id']; // The '+' operator converts the string to a number
//     // Now 'id' is a number
//     console.log(id);
   
//   });
//  return id ;
// } 
get parameters(): FormArray {
  return this.parameterForm.get('parameters') as FormArray;
}

createParameter(): FormGroup {
  return this.fb.group({
    id: [null], 
    columnName: ['',[Validators.required, this.uniqueColumnNameValidator.bind(this)]],
    columnType: [''],
    columnValues: [''],
    intervals: this.fb.array([]),
    selectList: this.fb.array([]),
    isEditMode: [false],
    isButtonsVisible : [false],
    mandatory: [false],
  });
}
uniqueColumnNameValidator = (control: FormControl): { [key: string]: any } | null => {
  if (this.parameters && this.parameters.length > 1) {
    const formatColumnName = (name: string) => name.replace(/[^a-zA-Z0-9]/g, '').trim().toLowerCase();
    const columnName = formatColumnName(control.value);
    
    // Identify the original value if the parameter is being edited
    const originalValue = control.pristine ? formatColumnName(control.value) : null;

    // Check for duplicates in other controls
    const isDuplicate = this.parameters.controls.some((paramControl, index) => {
      // Skip the comparison for the current control or if the value is the original
      if (control === paramControl || originalValue === columnName) {
        return false;
      }
      const otherColumnName = formatColumnName(paramControl.value.columnName);
      return otherColumnName === columnName;
    });

    return isDuplicate ? { 'uniqueColumnName': true } : null;
  }
  return null;
};

loadExistingParameters() {
  const testId = this.testId;
 // console.log("test ID is :", testId);
 this.parameters.clear(); 

  this.testParametersService.getExistingTestParameters(testId).subscribe({
    next: (parameters) => {
     
      if (parameters.length) {
          parameters.forEach((param,index) => {

           
            const fg = this.createTestParametersGroup(param);
        
            fg.disable(); // Disable the FormGroup
            this.parameters.push(fg);
            this.checkboxStatus[index] = param.mandatory;
        //  this.parameters.push(this.createTestParametersGroup(param));
        });
      } else {
        this.addParameter(); // Add an empty form if no parameters exist
      }

     
    },
    error: (error) => {
      console.error('Error fetching parameters:', error);
      // Handle error
    }
  });

  
}


createTestParametersGroup(param): FormGroup {
  //param.reset();
  let columnValues;
  let minControl = null;
  let maxControl = null;
  let selectListControl = this.fb.array([]);
  let intervalsControl = this.fb.array([]);
  const defaultInterval: Interval = { min: 0, max: 0, mouldTypeId: null,type :null };
  const intervalsFormArray = this.fb.array([]);
//  const defaultInterval = { min: 0, max: 0 };
const defaultSelectListValues = [];
  switch (param.columnType) {
    case 'Interval':
      (param.intervals || [defaultInterval]).forEach((interval: Interval) => {
        return intervalsFormArray.push(
          this.fb.group({
            min: [interval.min, Validators.required],
            max: [interval.max, Validators.required],
            mouldTypeId: [interval.mouldTypeId],
            type :[interval.type]
          }) as unknown as FormControl<unknown>
        );
      });
      
      // Now, assign the intervalsFormArray to intervalsControl
      intervalsControl = intervalsFormArray;
    

      // // Check if interval data is present or use default
      // const interval = param.interval || defaultInterval;
      // columnValues = interval.min && interval.max ? `From ${interval.min} to ${interval.max}` : '';
      // minControl = interval.min;
      // maxControl = interval.max;
      break;
    case 'SelectList' || 'Checkbox':
      const selectListValues = param.selectList || defaultSelectListValues;
      columnValues = selectListValues.length > 0 ? selectListValues.join(', ') : '';
      
      selectListValues.forEach(item => {
        // Add a new FormControl for each item into the 'selectList' FormArray
        selectListControl.push(this.fb.control(item.value))
      });
      break;
    case 'Numeric':
      // Use value if present, or default to empty string
      columnValues = param.value ? param.value.toString() : '';
      break;
    case 'Text' :
      // Use value if present, or default to empty string
      columnValues = param.value || '';
      break;
      case 'SearchUnitId' :
        columnValues = param.value || '';
      break;
    default:
      columnValues = '';
  }
  return this.fb.group({
    id: [param.id], // If you have an ID for the parameters
    columnName: [param.columnName, [Validators.required, this.uniqueColumnNameValidator.bind(this)]],
    columnType: [param.columnType],
    columnValues: [columnValues],
    intervals: intervalsControl,
    selectList: [param.columnType === 'SelectList' || param.columnType ==='Checkbox' ? param.selectList : []],
    isEditMode: [false],
    isButtonsVisible : [true],
    mandatory: [param.mandatory],
  });
}


addParameter(): void {
  const newFormGroup = this.createParameter();

  if (this.parameters.length > 0) {
   // console.log(this.parameters.at(this.parameters.length -1).value);
    // const lastParameter =this.parameters.at(this.parameters.length - 1);
    // lastParameter.disable();  
    this.parameters.controls.forEach((parameter, index) => {
      
        parameter.disable();
    
    });
  }
  if (this.editedParameterIndex !== null) {
    this.parameters.at(this.editedParameterIndex).disable();
    this.editedParameterIndex = null;
  }

  if ( this.isLoad ) {
     
    this.isFirstAdd = false; // Set the flag to false after the first 'Add' action
    this.parameters.push(newFormGroup);
    this.lastActiveParameterIndex = this.parameters.length - 1;
    this.isLoad =false;
   
  } 
  else{
    if (this.isFirstAdd ) {
     
      this.isFirstAdd = false; // Set the flag to false after the first 'Add' action
      this.parameters.push(newFormGroup);
      this.lastActiveParameterIndex = this.parameters.length - 1;

     
    } 
    
    else {
  
    // It's not the first click, proceed with saving the last parameter
    // before adding a new one to the form array
    if (this.parameters.length >= 1) {
 
      const lastParameterNAvigation = this.parameters.at(this.parameters.length - 1);
   
      const lastSavedParameter = this.parameters.at(this.parameters.length - 2);
      const selectListFormArray = lastParameterNAvigation.get('selectList') as FormArray;
      const intervalsFormArray = lastParameterNAvigation.get('intervals') as FormArray;
      const intervalsData = intervalsFormArray.controls.map(intervalControl => {
        const intervalGroup = intervalControl as FormGroup;
        return {
          min: intervalGroup.get('min').value,
          max: intervalGroup.get('max').value,
          mouldTypeId: intervalGroup.get('mouldTypeId').value ,
          type :intervalGroup.get('type').value 
        };
      });

      // Extract the data from the last saved parameter to be sent to the backend
      this.TestParameterRequest.model.columnName = lastParameterNAvigation.get('columnName').value;
         this.TestParameterRequest.model.columnType = lastParameterNAvigation.get('columnType').value;
         this.TestParameterRequest.model.columnValues = lastParameterNAvigation.get('columnValues').value;
         this.TestParameterRequest.model.intervals =intervalsData;
         this.TestParameterRequest.model.selectList = selectListFormArray.controls
  .map(control => control.value);

  
  this.TestParameterRequest.model.mandatory =lastParameterNAvigation.get('mandatory').value;
         this.TestParameterRequest.model.testId =this.testId;
 
      // Make the backend call to save the parameter
      this.testParametersService.addParameter(this.TestParameterRequest).subscribe({
        next: (response) => {
         
          this.toasterService.success(`Test parameter ${this.TestParameterRequest.model.columnName}  has been created.`,'test parameter created');
          // Update the ID of the saved parameter with the response from the server
          lastParameterNAvigation.patchValue({ id: response.testParameterId });
      
       
        },
        error: (error) => {
          // Handle the error, possibly by removing the unsaved parameter
          console.error('Error creating parameter:', error);
          //this.parameters.removeAt(this.parameters.length - 2);
        },
      });
      this.isFirstAdd =false;
    }

    // Finally, add the new parameter form group to the array
    this.parameters.push(newFormGroup);
    this.lastActiveParameterIndex = this.parameters.length - 1;
   
  }
}
 // }


}

removeParameter(index: number): void {

  if (this.editedParameterIndex !== null) {
    this.parameters.at(this.editedParameterIndex).disable();
    this.editedParameterIndex = null;
  }
  const testId: number = this.testId;
  const testParameterId: number = this.parameters.at(index).get('id').value;
  const testParameterName: string =this.parameters.at(index).get('columnName').value;
      if (testParameterId) {
        this.deleteFactory(testParameterId,testParameterName );
      // this.testParametersService.removeTestParameter(testParameterId).subscribe({
      //   next: () => {
      //      this.parameters.removeAt(index);
      //      this.toasterService.success(`Test parameter ${testParameterName} has been deleted.`,'test parameter deleted');
      //   },
      //   error : (error)  => {
      //     console.error('Error occurred while deleting parameter:', error);
      //   }  
      // });
                            }
    else {      
      this.parameters.removeAt(index);
    }
    this.isFirstAdd =true;
}


deleteFactory(testParameterId: number,testParameterName : string) {
  const modalRef = this.modalServices.open(
      DeleteTestParameterComponent,
      {
          size: 'lg',
          backdrop: 'static'
      }
  );
  modalRef.componentInstance.testParameterId = testParameterId;
  modalRef.componentInstance.testParameterName = testParameterName;
  modalRef.componentInstance.deleteChanged.subscribe((data) => {
      if(data){
          this.modalServices.dismissAll();
          this.loadExistingParameters();
         }
  })

}
openPopup(index: number): void {
  const testParameterId = this.parameters.at(index).get('id').value;
const selectedColumnType = this.parameters.at(index).get('columnType').value;

      if (selectedColumnType === 'Interval') {
        const intervalsFormArray = this.parameters.at(index).get('intervals') as FormArray;

        const existingIntervals = intervalsFormArray.controls.map(intervalControl => {
          const intervalGroup = intervalControl as FormGroup;
          return {
            minValue: intervalGroup.get('min').value,
            maxValue: intervalGroup.get('max').value,
            mouldTypeId: intervalGroup.get('mouldTypeId').value ,
            type:intervalGroup.get('type').value
          };
        });
 
        const dialogRef = this.dialog.open(SelectIntervalDialogComponent, {          
          width: '90vw', 
          maxWidth: '500px', 
          data: {
            testParameterId: testParameterId,
            intervals: existingIntervals 
          }
        });
        dialogRef.componentInstance.intervalSelected.subscribe(data => {

          if (data.testParameterId) {
               
          this.parameters.at(index).get('columnValues').setValue(`From ${data.start} to ${data.end}`);
          this.parameters.at(index).get('min').setValue(data.start);
          this.parameters.at(index).get('max').setValue(data.end);
          }
        //  console.log("that is to check if values are inserted ",this.parameters.at(index).value);
      });

        dialogRef.afterClosed().subscribe(result => {
          console.log("result ",result);
       //  if (result ==="Move_Next_Iteration") {
          if (testParameterId !== null) {
            

            this.updateIntervalValues(result.testParameterId, result.intervals, index);
        } else {
          const intervalsArray = this.parameters.at(index).get('intervals') as FormArray;
          result.intervals.forEach(interval => {
            intervalsArray.push(this.fb.group({
              min: [interval.min, Validators.required],
              max: [interval.max, Validators.required],
              mouldTypeId: [interval.mouldTypeId] ,
              type :[interval.type]
            }));
          });


          console.log("result ",result);
          // this.parameters.at(index).get('min').setValue(result.start);
          // this.parameters.at(index).get('max').setValue(result.end);
          this.addParameter();
         // this.parameters.at(index).get('columnValues').setValue(result);
        }//} 
      });

        
      
    } 
    
    else if (selectedColumnType === 'SelectList' || selectedColumnType === 'Checkbox') {
      // Logic to open the SelectList dialog
      const currentItems = this.parameters.at(index).get('selectList').value;
     
      const dialogRef = this.dialog.open(SelectListDialogComponent, {
        width: '90vw', // Use a percentage of the viewport width
        maxWidth: '500px', // Maximum width of the dialog
        data: { 
          items: currentItems,
          testParameterId: testParameterId 
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (testParameterId) {
            const selectListItems: SelectListItem[] = result.map(str => {
              const item = new SelectListItem();
              item.model.value = str;
              return item;
            });
            this.updateSelectListValues(testParameterId, result, index);
          } else {
            // Update the local form array
            const selectListArray = this.parameters.at(index).get('selectList') as FormArray;
            selectListArray.clear(); // Clear the existing array
            result.forEach(selectListItem => {
           
              // Push a new FormGroup for each item into the 'selectList' FormArray
              selectListArray.push(this.fb.group({
                value: selectListItem,
                text:''
              }));
            });
            this.parameters.at(index).get('columnValues').setValue(result.join(', '));
           this.addParameter();
          }
        }
      });
    }

  }

  editParameter(index: number): void {
    
    if (this.editedParameterIndex !== null) {
      // If another parameter is being edited, disable it
      this.parameters.at(this.editedParameterIndex).disable();
    }
  
    if (this.editedParameterIndex === index) {
      // If the same parameter is clicked again, toggle the edit state off
      this.editedParameterIndex = null;
    } else {
      // Enter edit mode for the clicked parameter
      this.editedParameterIndex = index;
      this.parameters.at(index).enable();
    }

  //   if (this.editedParameterIndex !== null && this.editedParameterIndex !== index) {
  //     this.parameters.at(this.editedParameterIndex).disable();
  //   }

  //   this.editedParameterIndex = index;
  // // Enable the form control for editing
  // this.parameters.at(index).enable();
}
updateIntervalValues (testParameterId : number, intervals: any[],index :number)
{ 

  
  let parameterToUpdate: TestParameterUpdateRequest = new TestParameterUpdateRequest();
  
  parameterToUpdate.model = {   
    columnName : this.parameters.at(index).get('columnName').value,
    columnType: this.parameters.at(index).get('columnType').value,
    mandatory : this.parameters.at(index).get('mandatory').value,
    columnValues: `From ${0} to ${0}`, 
    testParameterId: testParameterId,
    min: 0,
    max: 0,
    header: false,
    selectList: [], 
    intervals: intervals,
    userId: "",
    createdBy: ""
    }

    console.log("model to update is",parameterToUpdate);
    this.updateParameter(parameterToUpdate,index);
}
updateSelectListValues (testParameterId : number, items: string[],index :number)
{ 


  console.log ('items are : ',items);
 // const a = items as  FormArray();
  let parameterToUpdate: TestParameterUpdateRequest = new TestParameterUpdateRequest();
 

  parameterToUpdate.model = {   
    columnName : this.parameters.at(index).get('columnName').value,
    columnType: this.parameters.at(index).get('columnType').value,
    mandatory : this.parameters.at(index).get('mandatory').value,
    columnValues: ``, 
    testParameterId: testParameterId,
    min: 0,
    max:0,
    header: false,
    selectList: items,
    userId: "",
    createdBy: ""
    }
    this.updateParameter(parameterToUpdate,index);
}

  updateParameter(testParameter: TestParameterUpdateRequest,index : number): void {

    if (this.isEditing(index)) {
            this.testParametersService.updateParameter(testParameter).subscribe({
              next: (response) => {
                this.toasterService.success(`Test parameter ${ this.TestParameterRequest.model.columnName}  has been udpated.`,'test parameter updated');
                //this.parameters.removeAt(index);
                const lastParameter =this.parameters.at(index);
                lastParameter.disable();  
                this.lastActiveParameterIndex = this.parameters.length - 1;
                this.loadExistingParameters();
                this.isFirstAdd= true; 
              },
              error: (error) => {
      
                console.error('Error creating parameter:', error.innerException);
     
              },
            });




      this.editedParameterIndex = null;
    }

    this.isFirstAdd =true;
  }
  updateParameterOnKeyDown(index : number)
  {

    const columnType = this.parameters.at(index).get('columnType').value;

    const selectListItems: SelectListItem[] = this.parameters.at(index).get('selectList').value.map(str => {
      const item = new SelectListItem();
      item.model.value = str;
      return item;
    });
    let intervals: IntervalModel[] = [];
  if (columnType === 'Interval') {
    const intervalsFormArray = this.parameters.at(index).get('intervals') as FormArray;
    intervals = intervalsFormArray.controls.map(intervalControl => {
      return new IntervalModel(
        intervalControl.get('min').value,
        intervalControl.get('max').value,
        intervalControl.get('mouldTypeId').value,
        intervalControl.get('type').value
      );
    });
  }
  
    let parameterToUpdate: TestParameterUpdateRequest = new TestParameterUpdateRequest();
  parameterToUpdate.model = {   
    columnName : this.parameters.at(index).get('columnName').value,
    columnType: this.parameters.at(index).get('columnType').value,
    mandatory : this.parameters.at(index).get('mandatory').value,
    columnValues: ``, 
    testParameterId: this.parameters.at(index).get('id').value,
    min: 0,
    max:0,
    selectList: [],
    intervals: intervals,
    userId: "",
    header: true,
    createdBy: ""
    }
    console.log ("model to send for update is : ", parameterToUpdate.model);
    this.updateParameter(parameterToUpdate,index);

  }
  canAddParameter(): boolean {
    // If there are no parameters, you can add a new one.
    if (this.parameters.length === 0) {
  
      return true;
    }
  
    // If the last parameter is saved (has an ID), you can add a new one.
    const lastParameter = this.parameters.at(this.parameters.length - 1);
    return !!lastParameter && !!lastParameter.get('testParameterId')?.value;
  }


  showButtons(index: number): void {

    this.hoveredParameterIndex = index;
  
  }
  hideButtons(index: number): void {
    if (this.hoveredParameterIndex === index) {
      this.hoveredParameterIndex = null;
    }
  }
  
  canEdit(index: number): boolean {
    return !this.parameters.at(index).get('isEditMode').value;
  }

  isEditing(index: number): boolean {
    return this.editedParameterIndex === index;
  }
  
  // Function to cancel editing for a parameter
  cancelEdit(index: number): void {
    this.parameters.at(index).get('isEditMode').setValue(false);

  }
  cancelEditOnEditClick(index: number): void {
    if (this.isEditing(index)) {
      this.cancelEdit(index);
    }
  }

  isEditMode(index: number): boolean {
    return this.parameters.at(index).get('isEditMode').value;
}

isButtonsVisible(index: number): boolean {
  return this.parameters.at(index).get('isButtonsVisible').value;
}

shouldUpdateDatabase(index: number): boolean {
  const parameterId = this.parameters.at(index).get('id').value;
  return parameterId != null; 
}

toggleMandatory(index: number): void {
  let currentMandatoryValue = this.parameters.at(index).get('mandatory').value;

  this.parameters.at(index).get('mandatory').setValue(!currentMandatoryValue);

  if (this.shouldUpdateDatabase(index)) {
    this.mandatoryUpdate(index); // Only update the database for existing parameters
  }
  else {
    this.checkboxStatus[index] =!currentMandatoryValue;
  }
}
mandatoryUpdate(index: number): void {

  const parameter = this.parameters.at(index);
  const testParameterId = parameter.get('id').value;
  let isMandatory = !this.checkboxStatus[index];

  this.checkboxStatus[index] = isMandatory;

  this.parameters.at(index).get('mandatory').setValue(this.checkboxStatus[index]);
  let toggleMandatory: Mandatory = new Mandatory()
  toggleMandatory.model.isMandatory=this.checkboxStatus[index];
  toggleMandatory.model.testParameterId =testParameterId;

  this.testParametersService.getupdateParameterMandatoryStatus(toggleMandatory)
    .subscribe({
      next: (response) => {
        this.toasterService.success(`Test parameter ${this.parameters.at(index).get('columnName').value} mandatory configuration has been changed.`,'test parameter mandatory configuration changed');
       
        // Handle successful response
      
      },
      error: (error) => {
        // Handle error
        console.error('Error updating mandatory status:', error);
        // Optionally, revert the checkbox status in case of an error
        this.checkboxStatus[index] = !isMandatory;
        parameter.get('mandatory').setValue(!isMandatory);
      }
    });


  
  // Update the form control value here if necessary
}



isNewParameter(index: number): boolean {
  // Logic to determine if the parameter is new


  return !this.parameters.at(index).get('id').value;
}

get canViewTests() {
  return this.accountService.userHasPermission(
      Permission.viewTestsPermission
  );
}

get canManageTests() {
  return this.accountService.userHasPermission(
      Permission.manageTestsPermission
  );
}
get canManageUsers() {
  return this.accountService.userHasPermission(Permission.manageConfigurationsPermission);
}
getIconClass(inputTypeName: string): string {

  return this.inputTypeNameMapping[inputTypeName];// || 'ri-keyboard-line'; 
}

inputTypeNameMapping: { [key: string]: string } = {
  'Text': 'ri-quill-pen-line',
  'Image': 'ri-chat-upload-line',
  'Interval': "ri-expand-left-right-line",
  'Signature': 'ri-sketching',
  'Numeric': 'ri-hashtag',
  'SelectList': 'ri-list-ordered-2',
  'DateDifference' : 'ri-calendar-line',
  'Date' : 'ri-calendar-line',
  'SearchUnitId': 'ri-settings-6-line',
  'Checkbox': 'ri-checkbox-line',
  'HandWriting' :'ri-hand-coin-line',
  'Math' : 'ri-calculator-line',
  'Time' :'ri-time-line'
};
}

export interface Interval {
  min: number;
  max: number;
  mouldTypeId: number | null;
  type: number | null;
}

export class IntervalModel {
  min: number;
  max: number;
  mouldTypeId?: number;
  type?: number;
  constructor(min: number, max: number, mouldTypeId?: number,type?: number) {
    this.min = min;
    this.max = max;
    this.mouldTypeId = mouldTypeId;
    this.type = type;
  }
}
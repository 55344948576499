<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Add Test Results</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/tests">
                <i class="ri-home-8-line"></i>
                Tests
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Add Test Results</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card" [class.component-dark-theme]="themeService.isDark()"
    [class.component-rtl-enabled]="themeService.isRTLEnabled()">
    <div>
        <div class="row">
            <!-- Tab Headers -->
            <ul class="nav nav-tabs ps-0 mt-0 tabContainerUnitDescription" id="nav-tab" role="tablist">
                <li class="posRelative HeaTabs tabMargin" *ngFor="let tab of tabs; let i = index">

                    <div class="input-with-status" *ngIf="!tab.isEditing; else editModeTemplate">
                        <button (click)="deleteTestResult(i)"
                            *ngIf="i === activeTab && canManageTests && canManageUsers"
                            class="deleteTab bg-red dltTabPos"><i class="ri-delete-bin-line clr-white"></i></button>

                        <input [readonly]="disableInputTab"
                            [ngClass]="i === activeTab ? 'inputTabStyle activeTabStyle' : 'NotActiveinputTabStyle'"
                            class="nav-link" (click)="selectTab(i)" [(ngModel)]="tab.title" style="cursor: pointer;">

                        <div class="status-icon" >
                            <i *ngIf="tabStatus[i] === undefined" class="ri-radio-button-line"></i>
                            <i *ngIf="tabStatus[i]" class="ri-checkbox-circle-line"></i> 
                            <i *ngIf="tabStatus[i] === false" class="ri-close-circle-line"></i> 
                        </div>
                    </div>
                    <!-- Edit Mode Template -->
                    <ng-template #editModeTemplate>
                        <input id="enable" class="nav-link"
                            [ngClass]="i === activeTab ? 'inputTabStyle activeTabStyle' : 'NotActiveinputTabStyle'"
                            [(ngModel)]="tabs[i].title" (blur)="finishEditingTabLabel(i)"
                            (keyup.enter)="finishEditingTabLabel(i)">
                    </ng-template>

                </li>
                <li>

                    <button class="btn btn-light addTabStyle"
                        style="border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 10px;padding-bottom: 10px;"
                        (click)="addNewTab()"> + </button>

                </li>
                <li>
                    <button class="btn btn-light addTabStyle" 
                        style="border-color: #c1d3dc;  padding-left: 15px; padding-right: 15px; padding-top: 7 px;padding-bottom: 5 px; margin-left: 13px;"
                        (click)="saveAll()" >
                        <!-- <span *ngIf="inProgress" class="spinner-border spinner-border-sm me-1" role="status"
                            aria-hidden="true"></span> -->
                         <!-- <span *ngIf="inProgress; else saveText">...</span>  -->
                       <mat-icon #saveText>save</mat-icon>

                        <!-- <ng-template #saveText> Save All</ng-template> -->
                    </button>

                </li>
            </ul>

        </div>

        <!-- Tab Content -->
        <div class="tab-content">
            <div class="tab-pane" [class.show]="i === activeTab" [class.active]="i === activeTab"
                *ngFor="let tab of tabs; let i = index">
                <app-test-parameter-content (saveStatus)="handleSaveStatus($event)" [rowId]="tab.id" [testId]="testId" [testResultId]="currentTestResultId"
                    [tabs]="tabs" [initialValues]="firstTabValues"></app-test-parameter-content>
            </div>
        </div>
    </div>
    <!-- Button to add new tab -->

</mat-card>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TestResultsService } from 'src/app/services/testResultsServices/test-results.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-result-delete-confirmation',
  templateUrl: './result-delete-confirmation.component.html',
  styleUrls: ['./result-delete-confirmation.component.scss']
})
export class ResultDeleteConfirmationComponent implements OnInit {
  @Output() deleteChanged: EventEmitter<any> = new EventEmitter();
    @Input() row: number;
    @Input() id: number;
    
  constructor(public activeModal: NgbActiveModal,
    private toastr: ToastrService,
   
  private testResultService : TestResultsService,
  ) { }

  ngOnInit(): void {
    // this.afterOnInit.emit(this);

  }
  confirm() {
    this.deleteTestHelper(this.row);
  }
  decline() {
    this.activeModal.close();
  }
  deleteTestHelper(row: number) {
    this.toastr.success('Deleting...');
    if (this.row === null) 
    {
   
      this.toastr.success('Delete Test Result', `TestResult  ${row} has been deleted successfully.`);

      this.deleteChanged.emit(true);
    }else 
    {
      this.testResultService.deleteTestResult(row).subscribe({
            next: (_) => {
              //  this.alertService.stopLoadingMessage();
              //this.loadingIndicator = false;
              this.toastr.success('Delete Test Result', `TestResult  ${row} has been deleted successfully.`);
              this.deleteChanged.emit(true);
            },
            error: (error) => {
              // this.alertService.stopLoadingMessage();
              // this.loadingIndicator = false;

              this.toastr.error(
                'Delete Error',
                `An error occurred whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(
                  error
                )}"`
              );
            },
          });
    }
    
    // this.loadingIndicator = true;

   
  }
}


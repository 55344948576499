import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TestParametersService } from 'src/app/services/testParametersServices/test-parameters.service';
import { SelectListDialogComponent } from '../select-list-dialog/select-list-dialog.component';
import { SelectIntervalDialogComponent } from '../select-interval-dialog/select-interval-dialog.component';

@Component({
  selector: 'app-test-parameters',
  templateUrl: './test-parameters.component.html',
  styleUrls: ['./test-parameters.component.scss'],
})
export class TestParametersComponent implements OnInit {
  testParametersForm: FormGroup;
  parameters: FormArray;
  testId: string;
  isInputDisabled : boolean = false;
  selectListValues: FormArray;
  showSelectListValues: boolean = false;
  showIntervalValues: boolean = false;
  

  constructor(
    private fb: FormBuilder,
    private testParametersService: TestParametersService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.initializeForm();
    this.loadTestData();
  }
  initializeForm() {
    this.testParametersForm = this.fb.group({
      parameters: this.fb.array([]),
      columnName: ['',[Validators.required]], // Initialize columnName control
    columnType: [''],  // Initialize columnType control
    selectListValue: ['']
    });
    
    this.parameters = this.testParametersForm.get('parameters') as FormArray;
    this.selectListValues = this.fb.array([]);
  }

  loadTestData() {
    // this.testParametersService.getTestData(this.testId).subscribe((data) => {
    //   if (data && data.parameters) {
    //     data.parameters.forEach((param) => {
    //       this.addParameter(param.columnName, param.columnType);
    //     });
    //   }
    // });
  }

  addParameter(columnName: string , columnType: string ) {
    
       // console.log('array lenghth',arraylengthr);
    this.isInputDisabled = true;
    this.parameters.push(
      this.fb.group({
        columnName: [columnName ,[Validators.required]],
        columnType: [columnType],
      })
    );
    this.addSelectListValue();
    this.disableInputField();

  }

  isSelectListType(): boolean {
    //console.log("column type is select list",this.testParametersForm.get('columnType').value);
    return this.testParametersForm.get('columnType').value === 'selectList';
  }
  isIntervalType(): boolean {
    //console.log("column type is select list",this.testParametersForm.get('columnType').value);
    return this.testParametersForm.get('columnType').value === 'Interval';
  }

  addSelectListValue() {
    if (this.isSelectListType()) {
      this.selectListValues.push(this.fb.control(this.testParametersForm.get('selectListValue').value));
      this.testParametersForm.get('selectListValue').reset(); 
    }
  }

    columnNameInserted () : boolean
    {
      const control = this.testParametersForm.get('columnName');
      if (control)
      {
        const value = control.value;
        console.log (value);
        if (value !== null && value !== '') return true;
        else return false;
      } 
      else return false;
   
    }

  
  onSelectionChange() {   
    this.showSelectListValues = this.isSelectListType() && this.columnNameInserted ();
    this.showIntervalValues = this.isIntervalType() && this.columnNameInserted () ;
    if (this.showSelectListValues) this.showIntervalValues =false;
    if (this.showIntervalValues) this.showSelectListValues =false;
  }
  removeParameter(index: number) {
    this.parameters.removeAt(index);
  }

  saveParameters() {
    const formData = this.testParametersForm.value;

  }


   disableInputField() {

   
    //console.log("isInputDisabled: ", this.isInputDisabled);
    
   const lastIndex = this.parameters.length - 1;
//console.log(this.parameters.at(lastIndex).get('columnName') as FormArray).controls[lastIndex]);
   if (lastIndex >= 0) {    
    
       this.parameters.at(lastIndex).get("columnName").disable();
      // get('columnName').disable();
    //console.log(this.parameters.at(lastIndex).get('columnName').disable());
   }
 }


 openSelectListDialog() {
          const dialogRef = this.dialog.open(SelectListDialogComponent, {
            panelClass: 'myClass',

            // width: '100%',
            // minHeight: 'calc(100vh - 90px)',
            // height : 'auto'
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              //console.log(result);
              // Handle the result (e.g., add to your main form or array)
              this.selectListValues.push(result);
            }
          });
}

openIntervalDialog() {
  const dialogRef = this.dialog.open(SelectIntervalDialogComponent, {
    panelClass: 'myClass',

    // width: '100%',
    // minHeight: 'calc(100vh - 90px)',
    // height : 'auto'
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      // Handle the result (e.g., add to your main form or array)
     // this.selectListValues.push(result);
    }
  });
}
  
}
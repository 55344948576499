import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate, DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { TestResultValue } from 'src/app/models/test-result-value.model';
import { TestResult } from 'src/app/models/test-result.model';
import { TestParameter } from 'src/app/models/testParameter.model';
import { TestParametersService } from 'src/app/services/testParametersServices/test-parameters.service';
import { TestResultsService } from 'src/app/services/testResultsServices/test-results.service';
import { CustomizerSettingsService } from '../customizer-settings/customizer-settings.service';
import { TestResultUpdateRequest } from 'src/app/models/test-result-update-request.model';
import { ToastrService } from 'ngx-toastr';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { UnitService } from 'src/app/services/unit.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagePreviewComponent } from 'src/app/shared/components/image-preview/image-preview.component';
import { SchmidtCubeService } from 'src/app/services/schmidt-cube.service';
import { Subject, debounceTime } from 'rxjs';
import {
    NgxMatDateFormats,
    NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
import { RemoveTestResultsValues } from './remove-test-results-values/remove-test-results-values.component';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'DD-MM-YYYY'
    },
    display: {
        dateInput: 'DD-MM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
@Component({
    selector: 'app-edit-test-results-values',
    templateUrl: './edit-test-results-values.component.html',
    styleUrls: ['./edit-test-results-values.component.scss'],
    providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],
})
export class EditTestResultsValuesComponent implements OnInit {
    editForm: FormGroup;
    testResultId: number;
    testId: number;
    rfid: string;
    formData: TestParameter[] = [];
    testParametersForm: FormGroup;
    popName = 'Signature';
    azureBlobImageAdress = "";
    formattedDates: { [key: number]: string } = {};
    isLoading: boolean;
    UnitId: string;
    rfidSearchSubject = new Subject<{ rfid: string, index: number }>();
    isSearchingForUnit: boolean[] = [];
    currentLoadingIndexForInterval: number | null = null;
    inProgress: boolean = false;
    private originalCompositeOperation: any
    @Output() dataEvent = new EventEmitter<number>();
    @ViewChild(SignaturePadComponent, { static: true }) signaturePad!: SignaturePadComponent;
    signaturePadOptions: NgSignaturePadOptions = { // passed through to szimek/signature_pad constructor
        minWidth: 5,
        canvasWidth: 795,
        canvasHeight: 500,
        backgroundColor: 'white'
    };

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private testResultsService: TestResultsService, // Your service to handle test results
        private testParameterService: TestParametersService,
        private toasterService: ToastrService,
        public themeService: CustomizerSettingsService,
        private unitService: UnitService,
        private router: Router,
        private datePipe: DatePipe,
        private modalService: NgbModal,
        private cdr: ChangeDetectorRef,
        private schmidtCubeService: SchmidtCubeService,
    ) { }

    ngOnInit(): void {
        this.azureBlobImageAdress = "https://satebagreentunnelstorqa.blob.core.windows.net/images/";
        this.testParametersForm = this.fb.group({
            parameters: this.fb.array([])
        });
        this.testResultId = +this.route.snapshot.queryParams['id'];
        this.testId = +this.route.snapshot.queryParams['testId'];
        this.loadTestResultData(this.testResultId, this.testId);

        this.rfidSearchSubject.pipe(
            debounceTime(100) // Adjust the time (in ms) according to your needs

        ).subscribe(data => {
            this.currentLoadingIndexForInterval = data.index;
            this.searchUnit(data.rfid, data.index);
        });
        this.isSearchingForUnit = new Array(this.formData.length).fill(false);

    }
    searchUnit(rfid: string, index: number) {
        this.isSearchingForUnit[index] = true; // Start loading
        if (!rfid) {
            return; // or handle empty input case
        }

        this.unitService.getUnitByRfid(rfid).subscribe({
            next: (UnitNumber) => {

                if (UnitNumber) {
                    const parametersArray = this.testParametersForm.get('parameters') as FormArray;
                    const imageGroup = parametersArray.at(index) as FormGroup;
                    // No need to set ImageValue to an empty string anymore
                    imageGroup.get('unitValue').setValue(UnitNumber.name);

                    this.rfid = rfid.toString();
                    this.isSearchingForUnit[index] = false;
                    this.fetchIntervalValuesForUnit(UnitNumber.name, this.testId);

                } else {

                }
            },
            error: (error) => {
                console.error('Error fetching parameters:', error);
                this.isSearchingForUnit[index] = false;
                // Handle error
            }
        });
    }

    isUserValueInRange(index: number): boolean {
        const minControl = this.parameters.at(index).get('minValue');
        const maxControl = this.parameters.at(index).get('maxValue');
        const userValueControl = this.parameters.at(index).get('userValue');

        const min = minControl.value;
        const max = maxControl.value;
        const userValue = userValueControl.value;
        const inRange = userValue >= min && userValue <= max;


        return inRange;
    }

    formatDateValue(index: number) {
        const formGroup = (this.testParametersForm.get('parameters') as FormArray).at(index) as FormGroup;
        const dateControl = formGroup.get('dateValue');

        if (dateControl.value) {
            const formattedDate = this.datePipe.transform(dateControl.value, 'yyyy-MM-dd');
            this.formattedDates[index] = formattedDate; // Store the formatted date
        }
    }

    fetchIntervalValuesForUnit(unitName: string, testId: number) {
        this.testParameterService.fetchIntervalDataForUnit(unitName, testId).subscribe({
            next: (response) => {

                const parametersArray = this.testParametersForm.get('parameters') as FormArray;
                response.forEach(intervalData => {
                    const intervalIndex = parametersArray.controls.findIndex(control => control.value.testParameterId === intervalData.testParameterId);
                    if (intervalIndex !== -1) {
                        parametersArray.at(intervalIndex).patchValue({
                            minValue: intervalData.min,
                            maxValue: intervalData.max
                        });
                    }
                });
                this.currentLoadingIndexForInterval = null;
            },
            error: (error) => {
                console.error('Error fetching interval values:', error);
                this.currentLoadingIndexForInterval = null;
            }
        });
    }


    loadTestResultData(testResultId: number, testId: number): void {
        forkJoin({
            parameters: this.testParameterService.getExistingTestParameters(testId),
            resultValues: this.testResultsService.getTestResultValues(testResultId)
        }).subscribe({
            next: ({ parameters, resultValues }) => {

                this.buildForm(parameters, resultValues);
            },
            error: (error) => {
                console.error('Error fetching data:', error);
                // Handle error
            }
        });
    }

    buildForm(parameters: TestParameter[], resultValues: TestResult) {
        this.formData = parameters;

        const parametersFormArray = this.testParametersForm.get('parameters') as FormArray;
        parametersFormArray.clear();

        parameters.forEach(param => {
            const resultValueEntries = resultValues.testParameterValues.filter(trv => trv.testParameterId === param.id);

            // Check if the parameter is a date difference type
            if (param.columnType === 'DateDifference' && resultValueEntries.length === 2) {
                // Assuming that the first entry is the start date and the second is the end date
                const startDate = resultValueEntries[0].value;
                const endDate = resultValueEntries[1].value;
                const datePairGroup = this.createDatePairGroup(param, startDate, endDate);
                parametersFormArray.push(datePairGroup);
            } else if (param.columnType === 'Checkbox') {
                // Handling for Checkbox type
                const checkboxFormArray = this.createCheckboxFormArray(param, resultValueEntries);
                const checkboxGroup = this.fb.group({
                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    checkboxValues: checkboxFormArray,
                    testParameterId: param.id,
                });
                parametersFormArray.push(checkboxGroup);
            }
            else if (param.columnType === 'Interval') {
                var intervalGroup;
                if (resultValueEntries[0] === undefined) {
                    intervalGroup = this.fb.group({
                        columnName: new FormControl(param.columnName),
                        columnType: new FormControl(param.columnType),
                        testParameterId: param.id,
                        // ...other controls
                        minValue: new FormControl(''),
                        maxValue: new FormControl(''),
                        userValue: new FormControl('', param.mandatory ? Validators.required : null),
                        // ...other controls
                    });
                }
                else {
                    const minValue = resultValueEntries[0].min !== null ? resultValueEntries[0].min : '';
                    const maxValue = resultValueEntries[0].max !== null ? resultValueEntries[0].max : '';
                    const userValue = resultValueEntries[0].value !== null ? resultValueEntries[0].value : '';

                    intervalGroup = this.fb.group({
                        columnName: new FormControl(param.columnName),
                        columnType: new FormControl(param.columnType),
                        testParameterId: param.id,
                        // ...other controls
                        minValue: new FormControl(minValue),
                        maxValue: new FormControl(maxValue),
                        userValue: new FormControl(userValue, param.mandatory ? Validators.required : null),
                        // ...other controls
                    });
                }

                parametersFormArray.push(intervalGroup);
            }
            else {
                // For all other types, just use the first value or a default if there are no entries
                const value = resultValueEntries.length > 0 ? resultValueEntries[0].value : '';
                const paramGroup = this.createTestParametersGroup(param, value, resultValues.rfid);
                parametersFormArray.push(paramGroup);
            }
        });

        this.fetchIntervalValuesForUnit(this.UnitId, this.testId);
    }

    createTestParametersGroup(param: TestParameter, testResultValue: string, rfid: string): FormGroup {
        let group = {};
        this.rfid = rfid;
        // If testResultValue is not found, default to an empty string or a default value
        const defaultValue = testResultValue ? testResultValue : '';

        switch (param.columnType) {
            case 'SearchUnitId':
                group = {
                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    rfidValue: new FormControl(rfid, [param.mandatory ? Validators.required : null, Validators.pattern(/^[0-9]*$/)].filter(v => v != null)),
                    unitValue: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                    testParameterId: param.id,

                };
                this.UnitId = defaultValue;
                break;
            case 'SelectList':
                group = {
                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    testParameterId: param.id,
                    selectValue: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                };
                break
            case 'Numeric':
                group = {

                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    testParameterId: param.id,

                    numericValue: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                };
                break;
            case 'Math':
                group = {

                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    testParameterId: param.id,
                    mathValue: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                };
                break;
            case 'Date':
                group = {

                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    testParameterId: param.id,
                    dateValue: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                };
                break;
            case 'Image':
                group = {
                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    ImageValue: new FormControl(this.extractFilenameFromUrl(defaultValue), param.mandatory ? Validators.required : null),
                    imageBase64: new FormControl(''),
                    CurrentImage: new FormControl(defaultValue),
                    testParameterId: param.id
                };

                break;
            case 'Text':
                group = {
                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    testParameterId: param.id,
                    textValue: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                };
                break;
            case 'Time':
                group = {
                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    testParameterId: param.id,
                    timeValue: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                };
                break;
            case 'Signature':
                group = {
                    columnName: new FormControl(param.columnName),
                    columnType: new FormControl(param.columnType),
                    testParameterId: param.id,
                    CurrentImage: new FormControl(defaultValue),
                    signatureBase64: new FormControl(''),
                    signatureValue: new FormControl(this.extractFilenameFromUrl(defaultValue) ? this.extractFilenameFromUrl(defaultValue) : '', param.mandatory ? Validators.required : null),
                    signaturePlaceholder: new FormControl(this.extractFilenameFromUrl(defaultValue) ? this.extractFilenameFromUrl(defaultValue) : 'No signature yet')
                };
                break;
            case 'HandWriting':
                group = {
                    columnName: new FormControl(param.columnName, Validators.required),
                    columnType: new FormControl(param.columnType, Validators.required),
                    CurrentImage: new FormControl(defaultValue),
                    handWriteValue: new FormControl(this.extractFilenameFromUrl(defaultValue) ? this.extractFilenameFromUrl(defaultValue) : '', param.mandatory ? Validators.required : null),
                    handWritePlaceHolder: new FormControl(this.extractFilenameFromUrl(defaultValue) ? this.extractFilenameFromUrl(defaultValue) : 'Image not filled yet'),
                    handWriteBase64: new FormControl(''),
                    testParameterId: param.id,

                };
                break;

            default:
                group = {
                    value: new FormControl(defaultValue, param.mandatory ? Validators.required : null),
                };
        }
        return this.fb.group(group);
    }
    extractFilenameFromUrl(url: string | null): string | null {
        if (!url) {
            // If the URL is null, undefined, or an empty string, return null
            return null;
        }

        const parts = url.split('/');
        return parts[parts.length - 1];
    }
    createCheckboxFormArray(param: TestParameter, resultValueEntries: TestResultValue[]): FormArray {
        // Map each select list item to a form control
        const checkboxes = param.selectList.map(selectListItem => {
            // Check if this select list item's value is included in the result value entries
            const isChecked = resultValueEntries.some(resultValue =>
                resultValue.testParameterId === param.id && resultValue.value === selectListItem.value
            );

            return new FormControl(isChecked);
        });

        return this.fb.array(checkboxes);
    }

    createDatePairGroup(param: TestParameter, startDate: string, endDate: string): FormGroup {
        let group = {
            columnName: new FormControl(param.columnName),
            columnType: new FormControl(param.columnType),
            testParameterId: param.id,
            dateRange: new FormGroup({
                startDate: new FormControl(startDate, param.mandatory ? Validators.required : null),
                endDate: new FormControl(endDate, param.mandatory ? Validators.required : null)
            }),
            // Include other controls based on the TestParameter structure
        };
        return this.fb.group(group);
    }

    onSubmit() {
        this.isLoading = true;
        this.inProgress = true;

        if (this.testParametersForm.valid) {
            const formDataArray = this.testParametersForm.get('parameters').value;

            const testResultValues = formDataArray.map((data: any) => {
                let value;
                switch (data.columnType) {
                    case 'Checkbox':
                        value = data.checkboxValues.map(checked => checked.toString()).join(',');
                        break;
                    case 'DateDifference':
                        value = [data.dateRange.startDate, data.dateRange.endDate]; // Joining start and end dates
                        break;

                    case 'File':
                        // Skip these types if you're not sending them to the server
                        break;
                    case 'Interval':
                        value = [data.minValue, data.userValue, data.maxValue]
                        break;
                    case 'Numeric':

                        value = data.numericValue !== null && data.numericValue !== undefined ? parseFloat(data.numericValue) : null;
                        break;
                    // case 'Numeric'
                    // break;
                    default:
                        // Handling other types like Text, SelectList, Numeric, Date
                        value = data.textValue || data.selectValue || data.dateValue || data.signatureBase64 || data.imageBase64 || data.unitValue || data.handWriteBase64 || data.mathValue || data.timeValue;
                }

                return {
                    value: value,
                    testParameterId: data.testParameterId,
                    type: data.columnType
                };
            });

            const updateRequest = new TestResultUpdateRequest();
            updateRequest.model.testResultId = this.testResultId;
            updateRequest.model.testID = this.testId;

            updateRequest.model.rfid = this.rfid.toString().padStart(6, '0');
            // Construct your update request payload
            testResultValues.forEach(trv => {
                if (trv.type !== 'File') {
                    updateRequest.model.testResultValues.push(trv);
                }
            });

            this.testResultsService.updateTestResult(updateRequest).subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this.inProgress = false;
                    this.toasterService.success('Test Result has been updated.', 'Update Successful');
                    // this.loadTestResultData(this.testResultId,this.testId);
                    window.close();

                },
                error: (error) => {
                    this.isLoading = false;
                    this.inProgress = false;
                    console.error('Error updating test result:', error);
                    this.toasterService.error('Error updating test result.');
                }
            });

        } else {
            this.isLoading = false;
            this.inProgress = false;
            // Handle invalid form
            this.toasterService.error('Form is not valid. Please check your inputs.');
        }
    }


    get parameters(): FormArray {
        return this.testParametersForm.get('parameters') as FormArray;
    }


    getIconClass(inputTypeName: string): string {

        return this.inputTypeNameMapping[inputTypeName] || 'ri-keyboard-line';
    }

    inputTypeNameMapping: { [key: string]: string } = {
        'textValue': 'ri-quill-pen-line',
        'ImageValue': 'ri-chat-upload-line',
        'userValue': 'ri-expand-left-right-line',
        'rfidValue': 'ri-hashtag',
        'signatureValue': 'ri-sketching',
        'numericValue': 'ri-hashtag',
        'selectValue': 'ri-list-ordered-2',
        'dateRange': 'ri-calendar-line',
        'dateValue': 'ri-calendar-line',
        'unitValue': 'ri-settings-6-line',
        'handWriteValue': 'ri-hand-coin-line',
        'mathValue': 'ri-calculator-line',
        'timeValue': 'ri-time-line'
    };
    public colorChange: any[] = []

    getColor(index: number, controlName: string): string {
        // Access the form group at the specified index within the FormArray
        const formGroup = (this.testParametersForm.get('parameters') as FormArray).at(index) as FormGroup;

        // Access the specific control within the form group
        const control = formGroup.get(controlName);

        // Check the validation status of the control
        if (control && control.invalid && control.errors?.['required'] && control.value === null || control.value === undefined || control.value === '') {
            return 'red';
        } else if (control && control.valid) {
            return 'green';
        }

        return 'grey'; // Default color
    }

    setColor(controlName, controlValue): void {

        let color
        if (controlValue === '' || controlValue === null || controlValue === undefined) {
            color = 'red'
        }
        else {
            color = 'green'
        }
        this.colorChange.push({
            color: color,
            value: controlValue,
            control: controlName
        })
    }
    currentSignatureIndex: number;
    signatureSaved: boolean[] = [];
    signatureDisplayValue: string[] = [];

    signaturePadPopup(index: number, parameterType: string): void {
        this.currentSignatureIndex = index;
        this.signaturePad.clear()
        document.getElementById('menuModal')?.click();

        if (parameterType == "Handwriting") {
            this.setBackgroundImage()
        }
    }
    setBackgroundImage() {

        const canvas = this.signaturePad.getCanvas();
        const context = canvas.getContext('2d');
        // Replace 'your-image-url.jpg' with the actual URL or path to your image
        const imageUrl = 'assets\\img\\covercheck.jpg';

        const img = new Image();
        img.src = imageUrl;

        // Draw the image on the canvas as a background
        img.onload = () => {
            // Set composite operation to "destination-over" to draw new content behind the existing content
            context?.drawImage(img, 0, 0, canvas.width, canvas.height);
            context.globalCompositeOperation = this.originalCompositeOperation;
        };
    }

    drawComplete(event: MouseEvent | Touch) {
    }

    drawStart(event: MouseEvent | Touch) {
    }

    saveSignatureAsImage(): void {
        const signatureData = this.captureSignatureData();
        if (signatureData) {

            const parametersArray = this.testParametersForm.get('parameters') as FormArray;
            const signatureControl = parametersArray.at(this.currentSignatureIndex).get('signatureBase64');
            const hanWriteControl = parametersArray.at(this.currentSignatureIndex).get('handWriteBase64');

            if (signatureControl === null) {
                const hanWriteHolderControl = parametersArray.at(this.currentSignatureIndex).get('handWriteValue');
                hanWriteControl.setValue(signatureData);
                hanWriteHolderControl.setValue("Hand write value captured");
                this.toasterService.success(`Test Result Hand Write value Added.`, 'Hand write value Captured Succesfully');

            }
            else {
                const signatureHolderControl = parametersArray.at(this.currentSignatureIndex).get('signatureValue');
                signatureControl.setValue(signatureData);
                signatureHolderControl.setValue("Signature captured");
                this.toasterService.success(`Test Result Signature Value Added.`, 'Signature Captured Succesfully');
            }

        }
    }


    captureSignatureData(): string {
        // Assuming `this.signaturePad` is your signature pad component
        // and it has a method `toDataURL()` that returns a Base64 encoded string
        if (this.signaturePad.isEmpty()) {

            return '';
        }

        return this.signaturePad.toDataURL('image/png'); // or 'image/jpeg'
    }


    onImageSelected(event: any, index: number) {
        const file = event.target.files[0] as File;

        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Image = reader.result as string;
                this.setImageValue(base64Image, index, file.name);
                // Store filename in the ImageValue control
                // this.formData[index]. = file.name;
            };
            reader.readAsDataURL(file);
        }
    }


    setImageValue(base64Image: string, index: number, fileName: string): void {
        const parametersArray = this.testParametersForm.get('parameters') as FormArray;
        const imageGroup = parametersArray.at(index) as FormGroup;
        // No need to set ImageValue to an empty string anymore
        imageGroup.get('imageBase64').setValue(base64Image);
        imageGroup.get('ImageValue').setValue(fileName);
        this.toasterService.success(`Image uploaded successfully.`, 'Image Captured Successfully');
    }

    preventDefault(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    cancel() {
        this.router.navigate(['/tests']);
    }

    openImagePreview(imageUrl: string) {
        const modalRef = this.modalService.open(ImagePreviewComponent,
            {
                size: 'lg',
                backdrop: 'static'
            }
        );
        modalRef.componentInstance.imageUrl = this.azureBlobImageAdress + imageUrl;
    }

    isImageUrl(value: string): boolean {

        return /\.(jpg|jpeg|png|gif)$/i.test(value);
    }

    getBorderColor(index: number): string {
        // Access the form group at the specified index within the FormArray
        const formGroup = (this.testParametersForm.get('parameters') as FormArray).at(index) as FormGroup;

        // Extract userValue, minValue, and maxValue from the formGroup
        const userValue = formGroup.get('userValue').value;
        const minValue = formGroup.get('minValue').value;
        const maxValue = formGroup.get('maxValue').value;

        if (userValue === null || userValue === '') {
            return 'no-border'; // No border if empty
        } else if (userValue < minValue || userValue > maxValue) {
            return 'red-border'; // Red border if out of range
        } else {
            return 'green-border'; // Green border if within range
        }
    }

    onReadingValueChange(event: any, index: number) {
        const formData = this.testParametersForm.value.parameters;

        let readings = [];
        let SchmitdtDemouldingReadings = this.testId === 23 ?
            [381, 380, 379, 378, 377, 376, 375, 374, 384, 383, 382, 373] :
            this.testId === 24 ?
                [410, 409, 408, 407, 406, 405, 404, 403, 413, 412, 411, 402] :
                [];
        // Loop to collect readings
        formData.forEach(element => {
            if (SchmitdtDemouldingReadings.includes(element.testParameterId)) {
                if (element.numericValue != null && element.numericValue !== '') {
                    let numericValue = parseFloat(element.numericValue);
                    if (!isNaN(numericValue)) {
                        readings.push(numericValue);
                    }
                }
            }
        });

        // Ensure all values are inserted
        let schmidtresult = this.testId === 23 ? formData.find(p => p.testParameterId === 628) :
            this.testId === 24 ? formData.find(p => p.testParameterId === 630) :
                '';
        let testResultInMPA = this.testId === 23 ? formData.find(p => p.testParameterId === 629) :
            this.testId === 24 ? formData.find(p => p.testParameterId === 631) :
                '';

        if (readings.length === 12) {
            // Find highest and lowest values
            let maxReading = Math.max(...readings);
            let minReading = Math.min(...readings);

            // Remove highest and lowest readings
            let sum = readings.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            let average = sum / readings.length;
            average = parseFloat(average.toFixed(2));

            // Update the form control for testParameterId 628 with the calculated average
            if (schmidtresult) {

                schmidtresult.mathValue = average;
                if (testResultInMPA) {
                    testResultInMPA.mathValue = this.schmidtCubeService.getClosestCubeValue(average);
                }
            }
            // Update the form group (if necessary)

        } else {

            if (schmidtresult) {
                schmidtresult.mathValue = null;
            }
            if (testResultInMPA) {
                testResultInMPA.mathValue = null;
            }
        }

        this.testParametersForm.patchValue({ parameters: formData });
    }

    deleteTestValue(formGroup: any, index: number) {
        const modalRef = this.modalService.open(RemoveTestResultsValues,
            {
                size: 'lg',
                backdrop: 'static'
            }
        );

        modalRef.componentInstance.parameterId = formGroup.id;
        modalRef.componentInstance.testResultId = this.testResultId;
        modalRef.componentInstance.parameterName = formGroup.columnName;

        modalRef.result.then((data) => {
            if (data) {
                this.loadTestResultData(this.testResultId, this.testId);
            }
        }, (reason) => {
            console.log(reason);
        });
    }

    isVisible(index: number) : boolean {
        const parametersArray = this.testParametersForm.get('parameters') as FormArray;
        const value = parametersArray.at(index) as FormGroup;

        if (value.value.columnType == 'Text') {
            return value.value.textValue != '';
        }

        if (value.value.columnType == 'SelectList') {
            return value.value.selectValue != '';
        }

        if (value.value.columnType == 'Date') {
            return value.value.dateValue != '';
        }

        if (value.value.columnType == 'Numeric') {
            return value.value.numericValue != '';
        }

        return value.value != null;
    }
}

interface DatePair {
    startDate: string; // or Date, if you're converting to Date objects
    endDate: string;   // or Date
    testParameterId: number;
}

<div [class.card-border-radius]="themeService.isCardBorderRadius()" [class.card-borderd-theme]="themeService.isCardBorder()"
     [class.component-dark-theme]="themeService.isDark()"
     [class.component-rtl-enabled]="themeService.isRTLEnabled()"
     class="h-100vh pt-50 pb-50">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="tagus-form ms-auto me-auto">
                <div class="title">
                    <div class="d-flex mb-10 align-items-center">
                        <h2 class="mb-0 me-30">Reset Password</h2>
                    </div>
                    <p>Your new password must be different from previously used passwords</p>
                    <div *ngIf="showError" class="alert alert-danger" role="alert">
                        <span [innerHTML]="errorMessage"></span>
                    </div>
                    <div *ngIf="showSuccess" class="alert alert-success" role="alert">
                        Your password has been reset. Please <a [routerLink]="['/authentication/login']"> click here to
                        log in. </a>
                    </div>
                </div>
                <form (ngSubmit)="resetPassword(resetPasswordForm.value)" [formGroup]="resetPasswordForm" autocomplete="off"
                      novalidate>
                    <div class="bg-white border-radius pt-20 ps-20 pe-20">
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">New Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input [type]="hide ? 'password' : 'text'" formControlName="password" id="password" matInput
                                       type="password">
                                <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                        mat-icon-button matSuffix>
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-error *ngIf="validateControl('password') && hasError('password', 'required')">Password
                                is
                                required.
                            </mat-error>
                        </div>
                        <div class="tagus-form-group without-icon">
                            <label class="d-block mb-12 fw-semibold gray-color">Confirm Password <span>*</span></label>
                            <mat-form-field appearance="fill">
                                <mat-label>Enter your password</mat-label>
                                <input [type]="hide ? 'password' : 'text'" formControlName="confirm" id="confirm" matInput
                                       type="password">
                                <button (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                        mat-icon-button matSuffix>
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-error *ngIf="validateControl('confirm') && hasError('confirm', 'required')">Confirm
                                password is
                                required.
                            </mat-error>
                            <mat-error *ngIf="hasError('confirm', 'mustMatch')">Password and confirm password must
                                match.
                            </mat-error>
                        </div>
                    </div>
                    <button [disabled]="!resetPasswordForm.valid" class="tagus d-block fw-semibold" mat-flat-button
                            type="submit">Set New Password
                    </button>
                    <div class="text-center back-to-login">
                        <a class="d-inline-block main-color fw-medium position-relative"
                           routerLink="/authentication/login"><i
                            class="flaticon-chevron-1"></i> Back to Login</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

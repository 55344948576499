import { Injectable } from '@angular/core';
import { DescriptionEndpointService } from './description-endpoint.service';

@Injectable({
    providedIn: 'root',
})
export class DescriptionService {
    constructor(private descriptionEndpoint: DescriptionEndpointService) {
    }

    createDescription(descriptionData: any) {
        return this.descriptionEndpoint.createDescription(descriptionData)
    }

    updateDescription(descriptionId: number, updatedData: any) {
        return this.descriptionEndpoint.updateDescription(descriptionId, updatedData)
    }

    deleteDescription(descriptionId: any) {
        return this.descriptionEndpoint.deleteDescription(descriptionId)
    }

    getAllDescriptions() {
        return this.descriptionEndpoint.getAllDescriptions()
    }

    getDescriptionsByCategoryId(categoryId: number) {
        return this.descriptionEndpoint.getDescriptionsByCategoryId(categoryId)
    }

    getAllInputTypes() {
        return this.descriptionEndpoint.getAllInputTypes<any>()
    }

    createInputTypeValue(viewModel: any) {
        return this.descriptionEndpoint.createInputTypeValue(viewModel)
    }

    deleteInputTypeValue(id: any) {
        return this.descriptionEndpoint.deleteInputTypeValue(id)
    }
}

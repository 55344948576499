<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Test Results</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Home
            </a>
        </li>
        <li class="breadcrumb-item">
            <a routerLink="/tests">
                <i class="ri-home-8-line"></i>
                Tests
            </a>
        </li>
        <li class="breadcrumb-item fw-semibold">Test Results </li>
    </ol>
</div>
<mat-card class="mb-25 tagus-card">
    <mat-card-content>

        <mat-card-header class="d-block">
            <div class="row control-box justify-content-between">
                <div class="col-lg-3">
                    <div class="search-box position-relative">
                        <input #searchInputRfid placeholder="{{'search by rfid' | translate}}"
                               class="input-search d-block"
                               [(ngModel)]="searchValue"
                               (keyup.enter)="onSearchChanged($event)"
                               type="text">
                        <button type="button" (click)="onSearchChanged($event)">
                            <i class="ri-search-fill" *ngIf="!isSearchValueLoading"></i>
                            <mat-spinner *ngIf="isSearchValueLoading" matSuffix diameter="16" style="display: inline-flex"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="search-box position-relative">
                        <input #searchInput placeholder="{{'search by name' | translate}}"
                               class="input-search d-block"
                               [(ngModel)]="searchName"
                               (keyup.enter)="onSearchNameChanged($event)"
                               type="text">
                        <button type="button" (click)="onSearchNameChanged($event)">
                            <i class="ri-search-fill" *ngIf="!isSearchLoading"></i>
                            <mat-spinner *ngIf="isSearchLoading" matSuffix diameter="16" style="display: inline-flex"></mat-spinner>
                        </button>
                    </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-end">
                    <button *ngIf="canManageUsers || canAddTests || canManageTests" mat-flat-button class="default-btn tagus" (click)="newTestResult()">
                        <i class="ri-add-fill"></i>{{'Add Test Result' | translate}}
                    </button>
                    <div class="mx-2">
                        <button mat-flat-button matBadge="{{filterCount}}" matBadgePosition="before"
                                matBadgeColor="accent" class="default-btn tagus" [matMenuTriggerFor]="filterMenu">
                            <i class="ri-filter-fill"></i> {{ 'Filter' | translate }}
                        </button>
                    </div>
                    <mat-menu #filterMenu="matMenu">
                        <div style="overflow-x: hidden;">
                            <app-test-results-filter (applyFilterChanged)="applyFilter($event)">
                            </app-test-results-filter>
                        </div>
                    </mat-menu>
                </div>

            </div>
        </mat-card-header>
        

        <div class="myTasksTable mat-elevation-z8 tagus-mat-table">
            <div class="table-container preserve-line-breaks">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef> 
                        
                        {{ column === 'creationDate' ? 'Creation Date' : (column | titlecase) }}
                      </th>

                 <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="column === 'actions'; else other_columns">                
                  
                        <button type="button" mat-button
                            class="position-relative dot-btn p-0 bg-transparent border-none"
                            [matMenuTriggerFor]="actionMenu">
                            <i class="ri-more-fill"></i>
                                </button>
                        <mat-menu  #actionMenu="matMenu" class="dotMenu two monthMenu" xPosition="before">

                            <button  *ngIf="canManageUsers||canManageTests || canEditTests" mat-menu-item type="button" (click)="editTestResult(element.testResultId) "
                                class="bg-transparent border-none position-relative">
                                <i class="ri-edit-line"></i> Edit
                            </button>
                            
                            <button  *ngIf="canManageUsers ||canManageTests" mat-menu-item type="button" (click)="deleteTestResult(element.testResultId)"
                                class="bg-transparent border-none position-relative">
                                <i class="ri-delete-bin-line"></i> Remove
                            </button>


                        </mat-menu>
                    </ng-container>
                    <ng-template #other_columns>
                        <ng-container *ngIf="isImageUrl(element[column]); else normalData">
                          <img [src]="this.azureBlobImageAdress +element[column]" (click)="openImagePreview(this.azureBlobImageAdress+ element[column])" class="border-radius me-10" width="50"
                          alt="Description Image" /> 
                        </ng-container>
                        <ng-template #normalData>
                          {{ element[column] }}
                        </ng-template>
                      </ng-template>
                    </td>
                </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
           </div>

            <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
            </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>

import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DescriptionValueService } from 'src/app/services/description-value.service';
import { DescriptionService } from 'src/app/services/description.service';

@Component({
    selector: 'app-delete-unit',
    templateUrl: './delete-description-value.component.html',
    styleUrls: ['./delete-description-value.component.scss']
})
export class DeleteDescriptionValueComponent {
    descriptionTitle: string;
    descriptionId: number;
    descriptionValueId: number;
    unitId: number;
    @Output() descriptionValueChanged = new EventEmitter<boolean>();

    constructor(public activeModal: NgbActiveModal, private descriptionvalueService: DescriptionValueService,
        private toastr: ToastrService,) {

    }
    closeModal(): void {
        this.activeModal.dismiss();
    }
    confirm() {
        this.descriptionvalueService
            .deleteDescriptionValueByUnit(this.descriptionValueId, this.unitId, this.descriptionId)
            .subscribe((res) => {
                if (res.statusCode == 200 && res.errorMessage == null) {
                    debugger;
                    this.toastr.success(`Description Value ${this.descriptionTitle} has been deleted successfully.`, 'Remove Description Value');
                    this.emitEvent(true);
                    this.activeModal.close();
                } else {
                    this.toastr.error(res.errorMessage);
                }
            });
    }

    emitEvent(value: boolean) {
        this.descriptionValueChanged.emit(value);
    }

    decline() {
        this.activeModal.close();
    }
}

import { Injectable, NgModule } from '@angular/core';
import { DefaultUrlSerializer, RouterModule, Routes, UrlSerializer, UrlTree } from '@angular/router';
import { InternalErrorComponent } from './components/common/internal-error/internal-error.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { FileUploaderComponent } from './components/forms/file-uploader/file-uploader.component';
import { SearchComponent } from './components/pages/search/search.component';
import { TimelineComponent } from './components/pages/timeline/timeline.component';
import { HomeComponent } from './components/home/home.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { Utilities } from './services/utilities';
import { AccountComponent } from './components/pages/account/account.component';
import { UserInfoComponent } from './components/settings/user/user-info/user-info.component';
import {
    RolesManagementComponent
} from './components/settings/roles/roles-mangement/roles-management/roles-management.component';
import {
    UsersManagementComponent
} from './components/settings/user/user-mangement/app-users-management/users-management.component';
import { FactoryListComponent } from './components/factories/factory-list/factory-list.component';
import { AddEditFactoryComponent } from './components/factories/add-edit-factory/add-edit-factory.component';
import { ViewFactoryComponent } from './components/factories/view-factory/view-factory.component';
import { AddEditWorkplaceComponent } from './components/workplaces/add-edit-workplace/add-edit-workplace.component';
import { ViewWorkplaceComponent } from './components/workplaces/view-workplace/view-workplace.component';
import { AddEditWorkspaceComponent } from './components/workspaces/add-edit-workspace/add-edit-workspace.component';
import { ViewWorkspaceComponent } from './components/workspaces/view-workspace/view-workspace.component';
import { WorkplacesListComponent } from './components/workplaces/workplaces-list/workplaces-list.component';
import { WorkspacesListComponent } from './components/workspaces/workspaces-list/workspaces-list.component';
import { MouldListComponent } from './components/moulds/mould-list/mould-list.component';
import { AddEditMouldComponent } from './components/moulds/add-edit-mould/add-edit-mould.component';
import { ViewMouldComponent } from './components/moulds/view-mould/view-mould.component';
import { LockScreenComponent } from './components/authentication/lock-screen/lock-screen.component';
import { ConfirmMailComponent } from './components/authentication/confirm-mail/confirm-mail.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { SigninSignupComponent } from './components/authentication/signin-signup/signin-signup.component';
import { RegisterComponent } from './components/authentication/register/register.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { UnitDescriptionComponent } from './components/unit-description/unit-description.component';
import { UnitVisualisationComponent } from './components/unit-visualisation/unit-visualisation.component';
import {
    UnitTabsContentDescriptionComponent
} from './components/unit-tabs-content-description/unit-tabs-content-description.component';
import { MouldTypeListComponent } from './components/mould-types/mould-type-list/mould-type-list.component';
import {
    AddEditMouldTypeComponent
} from './components/mould-types/add-edit-mould-type/add-edit-mould-type.component';
import { ViewMouldTypeComponent } from './components/mould-types/view-mould-type/view-mould-type.component';
import { UnitListComponent } from './components/units/unit-list/unit-list.component';
import { AddEditUnitComponent } from './components/units/add-edit-unit/add-edit-unit.component';
import { ActiveReportComponent } from "./components/active-report/active-report.component";
import { ViewUnitComponent } from './components/units/view-unit/view-unit.component';

import { ViewTestComponent } from './components/views/view-test/view-test.component';
import { TestListComponent } from './components/views/test-list/test-list.component';
import { AddEditTestComponent } from './components/views/add-edit-test/add-edit-test.component';
import { TestTypeListComponent } from './components/views/testType-list/testType-list.component';
import { AddEditTestTypeComponent } from './components/views/add-edit-testType/add-edit-testType.component';
import { ViewTestTypeComponent } from './components/views/view-testType/view-testType.component';
import { TestParametersComponent } from './components/views/test-parameters/test-parameters.component';
import { testTypeForTestListComponent } from './components/views/testTypeForTest-list/testTypeForTest-list.component';
import { TestParametersNewComponent } from './components/views/test-parameters-new/test-parameters-new.component';
import { AddTestResultsComponent } from './components/add-test-results/add-test-results.component';
import { TestResultsListComponent } from './components/test-results-list/test-results-list.component';
import { EditTestResultsValuesComponent } from './components/edit-test-results-values/edit-test-results-values.component';
import { environment } from '../environments/environment';

@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
    override parse(url: string): UrlTree {
        const possibleSeparators = /[?;#]/;
        const indexOfSeparator = url.search(possibleSeparators);
        let processedUrl: string;

        if (indexOfSeparator > -1) {
            const separator = url.charAt(indexOfSeparator);
            const urlParts = Utilities.splitInTwo(url, separator);
            urlParts.firstPart = urlParts.firstPart.toLowerCase();

            processedUrl = urlParts.firstPart + separator + urlParts.secondPart;
        } else {
            processedUrl = url.toLowerCase();
        }

        return super.parse(processedUrl);
    }
}

const routes: Routes = [
    { path: '', component: HomeComponent, data: { title: 'Minerva - ' + environment.env_name } },
    { path: 'account', component: AccountComponent, canActivate: [AuthGuard], data: { title: 'Account - ' + environment.env_name } },
    { path: 'home', component: HomeComponent, data: { title: 'Minerva - ' + environment.env_name } },
    { path: 'forms/file-uploader', component: FileUploaderComponent },
    { path: 'profile', component: UserInfoComponent, canActivate: [AuthGuard], data: { title: 'Profile - ' + environment.env_name } },
    { path: 'user-mangement', component: UsersManagementComponent, canActivate: [AuthGuard], data: { title: 'Users - ' + environment.env_name } },
    { path: 'role-mangement', component: RolesManagementComponent, canActivate: [AuthGuard], data: { title: 'Roles - ' + environment.env_name } },
    {
        path: 'unit-description',
        component: UnitDescriptionComponent,
        canActivate: [AuthGuard],
        data: { title: 'Description - ' + environment.env_name }
    },
    {
        path: 'unit-visualisation',
        component: UnitVisualisationComponent,
        canActivate: [AuthGuard],
        data: { title: 'Visualisation - ' + environment.env_name }
    },
    {
        path: 'unit-tabs-content-description',
        component: UnitTabsContentDescriptionComponent,
        canActivate: [AuthGuard],
        data: { title: 'Description - ' + environment.env_name }
    },


    { path: 'timeline', component: TimelineComponent },
    { path: 'search', component: SearchComponent },
    { path: 'error-500', component: InternalErrorComponent },
    { path: 'authentication/forgot-password', component: ForgotPasswordComponent, data: { title: 'Forget password - ' + environment.env_name } },
    { path: 'authentication/reset-password', component: ResetPasswordComponent, data: { title: 'Reset password - ' + environment.env_name } },
    { path: 'authentication/login', component: LoginComponent, data: { title: 'Login - ' + environment.env_name } },
    { path: 'authentication/register', component: RegisterComponent },
    { path: 'authentication/signin-signup', component: SigninSignupComponent },
    { path: 'authentication/logout', component: LogoutComponent },
    { path: 'authentication/confirm-mail', component: ConfirmMailComponent },
    { path: 'authentication/lock-screen', component: LockScreenComponent },
    { path: 'factories', component: FactoryListComponent, canActivate: [AuthGuard], data: { title: 'Factories - ' + environment.env_name } },
    {
        path: 'add-edit-factory',
        component: AddEditFactoryComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Factory - ' + environment.env_name }
    },
    { path: 'view-factory', component: ViewFactoryComponent, canActivate: [AuthGuard], data: { title: 'View Factory - ' + environment.env_name } },
    { path: 'workplaces', component: WorkplacesListComponent, canActivate: [AuthGuard], data: { title: 'Workplaces - ' + environment.env_name } },
    {
        path: 'add-edit-workplace/:factoryId',
        component: AddEditWorkplaceComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Workplace - ' + environment.env_name }
    },
    {
        path: 'add-edit-workplace',
        component: AddEditWorkplaceComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Workplace - ' + environment.env_name }
    },
    {
        path: 'view-workplace',
        component: ViewWorkplaceComponent,
        canActivate: [AuthGuard],
        data: { title: 'View Workplace' + environment.env_name }
    },
    { path: 'workspaces', component: WorkspacesListComponent, canActivate: [AuthGuard], data: { title: 'Workspaces - ' + environment.env_name } },
    {
        path: 'add-edit-workspace/:workplaceId',
        component: AddEditWorkspaceComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Workspace - ' + environment.env_name }
    },
    {
        path: 'add-edit-workspace',
        component: AddEditWorkspaceComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Workspace - ' + environment.env_name }
    },
    {
        path: 'view-factory',
        component: ViewWorkspaceComponent,
        canActivate: [AuthGuard],
        data: { title: 'View Workspace - ' + environment.env_name }
    },
    { path: 'moulds', component: MouldListComponent, canActivate: [AuthGuard], data: { title: 'Moulds' + environment.env_name } },
    {
        path: 'add-edit-mould/:workspaceId',
        component: AddEditMouldComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Mould' + environment.env_name }
    },
    {
        path: 'add-edit-mould',
        component: AddEditMouldComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Mould - ' + environment.env_name }
    },
    { path: 'mould-detail', component: ViewMouldComponent, canActivate: [AuthGuard], data: { title: 'View Mould - ' + environment.env_name } },
    {
        path: 'view-workspace',
        component: ViewWorkspaceComponent,
        canActivate: [AuthGuard],
        data: { title: 'View Workspace - ' + environment.env_name }
    },
    { path: 'moulds-type', component: MouldTypeListComponent, canActivate: [AuthGuard], data: { title: 'Moulds Type - ' + environment.env_name } },
    {
        path: 'add-edit-moulds-type/:mouldTypeId',
        component: AddEditMouldTypeComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Moulds Type - ' + environment.env_name }
    },
    {
        path: 'add-edit-moulds-type',
        component: AddEditMouldTypeComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Moulds Type - ' + environment.env_name }
    },
    {
        path: 'view-moulds-type',
        component: ViewMouldTypeComponent,
        canActivate: [AuthGuard],
        data: { title: 'View Moulds Type - ' + environment.env_name }
    },
    { path: 'units', component: UnitListComponent, canActivate: [AuthGuard], data: { title: 'Units - ' + environment.env_name } },
    {
        path: 'add-unit/:unitId',
        component: AddEditUnitComponent,
        canActivate: [AuthGuard],
        data: { title: 'Add Edit Unit - ' + environment.env_name }
    },
    { path: 'add-unit', component: AddEditUnitComponent, canActivate: [AuthGuard], data: { title: 'Add Edit Unit - ' + environment.env_name } },
    { path: 'view-unit', component: ViewUnitComponent, canActivate: [AuthGuard], data: { title: 'View Unit - ' + environment.env_name } },
    { path: 'active-report', component: ActiveReportComponent, canActivate: [AuthGuard], data: { title: 'Active Report - ' + environment.env_name } },

    { path: 'view-test', component: ViewTestComponent, canActivate: [AuthGuard], data: { title: 'View Test - ' + environment.env_name } },
    { path: 'tests', component: TestListComponent, canActivate: [AuthGuard], data: { title: 'View Test - ' + environment.env_name } },
    { path: 'add-edit-test', component: AddEditTestComponent, canActivate: [AuthGuard], data: { title: 'Add Edit Test - ' + environment.env_name } },
    { path: 'testtypes', component: TestTypeListComponent, canActivate: [AuthGuard], data: { title: 'View Test Types - ' + environment.env_name } },
    { path: 'add-edit-testtype', component: AddEditTestTypeComponent, canActivate: [AuthGuard], data: { title: 'Add Edit Test - ' + environment.env_name } },
    { path: 'view-testtypes', component: ViewTestTypeComponent, canActivate: [AuthGuard], data: { title: 'View Test Types - ' + environment.env_name } },
    { path: 'test-parameters', component: TestParametersComponent, canActivate: [AuthGuard], data: { title: 'Test Parameters - ' } },
    { path: 'test-parameters-new', component: TestParametersNewComponent, canActivate: [AuthGuard], data: { title: 'Test Parameters - ' + environment.env_name } },
    { path: 'add-testresults', component: AddTestResultsComponent, canActivate: [AuthGuard], data: { title: 'Add Test Results - ' + environment.env_name } },
    { path: 'view-testresults', component: TestResultsListComponent, canActivate: [AuthGuard], data: { title: 'View Test Results - ' + environment.env_name } },
    { path: 'testtypesForTest', component: testTypeForTestListComponent, canActivate: [AuthGuard], data: { title: 'View Test Types - ' + environment.env_name } },
    { path: 'edit-test-result-values', component: EditTestResultsValuesComponent, canActivate: [AuthGuard], data: { title: 'Edit Test Result Values - ' + environment.env_name } },

    // Here add new pages component

    { path: 'common/not-found', component: NotFoundComponent }, // This line will remain down from the whole pages component list
    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],
    exports: [RouterModule],
    providers: [
        AuthService,
        AuthGuard,
        { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }
    ]
})
export class AppRoutingModule {
}

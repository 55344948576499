<div class="breadcrumb-card mb-25 d-md-flex align-items-center justify-content-space-between">
    <h5 class="mb-0">Test Parameters</h5>
    <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
            <a routerLink="/">
                <i class="ri-home-8-line"></i>
                Dashboard
            </a>
        </li>

        <li class="breadcrumb-item fw-semibold">Parameters</li>
    </ol>
</div>

<mat-card class="mb-25 tagus-card">
  <mat-card-content>
    <form [formGroup]="testParametersForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 posRelative" *ngFor="let parameter of parameters.controls; let i = index">
          <div class="tagus-form-group">
            <label class="d-block mb-12 fw-semibold gray-color">Parameter<span>*</span></label>
            <mat-form-field appearance="fill" class="input-container">
              <i class="fa fa-database"></i>
              <input
                matInput          
                class="fullWidth"              
                [formControlName]="'columnName'"
                placeholder="Add text label of input type"
                [disabled]="isInputDisabled"               
              />
              <div style="display: inline-block; text-align: right; position: absolute; right: 0%">
                <mat-select [formControlName]="'columnType'" (selectionChange)="onSelectionChange()" >
                  <mat-option value="text">Text</mat-option>
                  <mat-option value="signature">Signature</mat-option>
                  <mat-option value="Image">Image</mat-option>
                  <mat-option value="Interval">Interval</mat-option>
                  <mat-option value="selectList"> Select List </mat-option>
                  <mat-option value="Numeric">  Numeric </mat-option>
                  <mat-option value="Search Unit Id"> Search Unit Id </mat-option>

                </mat-select>
              </div>
              <div *ngIf="showSelectListValues">
                <button
                mat-stroked-button
                (click)="openSelectListDialog()"
                class="gray text-primary"
                style="width:50%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white;float: right;  margin-top:15px;"
              >Add List Values
              </button>


                <!-- <button mat-stroked-button (click)="openSelectListDialog()" class="gray text-primary">
                  <i class="ri-add-line" style="font-size: 12px;"></i> Add Select List Value
                </button> -->
              <!-- <div *ngIf="showSelectListValues">
                <mat-form-field>
                  <input
                    matInput
                    formControlName="selectListValue"
                    placeholder="Enter a text value"
                  />
                </mat-form-field>
            
                <button mat-button (click)="addSelectListValue()">Add Select List Value</button>
            
                <div *ngFor="let selectListValue of selectListValues.controls; let i = index">
                  <div class="tagus-form-group">
                    <label class="d-block mb-12 fw-semibold gray-color">Select List Value</label>
                    <mat-form-field appearance="fill" class="input-container">
                      <input
                        matInput
                        formControlName="{{ i }}"
                      />
                    </mat-form-field>
                    <button mat-button (click)="removeSelectListValue(i)">Remove</button>
                  </div>
                </div> -->
              </div>
              <div *ngIf="showIntervalValues">
                <button
                mat-stroked-button
                (click)="openIntervalDialog()"
                class="gray text-primary"
                style="width:50%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white;float: right;  margin-top:15px;"
              >Add Interval
              </button>
              </div>



            </mat-form-field>
            <button (click)="removeParameter(i)" *ngIf="parameters.length > 1" class="bg-red dltTabPosDescription"> <i class="ri-delete-bin-line clr-white"></i>
               
            </button>
          </div>
        </div>
      </div>


      
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div style="text-align: left;" class="mb-3 example-buttons">
            <button
              mat-stroked-button
              (click)="addParameter(this.testParametersForm.controls['columnName'].value,this.testParametersForm.controls['columnType'].value)"
              class="gray text-primary"
              style="width:50%; border-color: #c1d3dc; padding-left: 27px; padding-right: 27px; padding-top: 8px; padding-bottom: 5px; background: white;"
            >
              <i class="ri-add-line" style="font-size: 12px;"></i> Add
            </button>
          </div>
        </div>
      </div>
     
    </form>
  </mat-card-content>
</mat-card>
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Output, EventEmitter } from '@angular/core';
import { start } from '@popperjs/core';
import { MouldTypeService } from 'src/app/services/mould-type.service';
import { MouldTypeList } from 'src/app/models/mould-type-list';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-select-interval-dialog',
  templateUrl: './select-interval-dialog.component.html',
  styleUrls: ['./select-interval-dialog.component.scss']
})
export class SelectIntervalDialogComponent {
  @Output() intervalSelected = new EventEmitter<any>();
  minValue: number;
  maxValue: number;
  intervalsForm: FormGroup;
  mouldTypes: MouldTypeList[] = [];
  constructor(
    public dialogRef: MatDialogRef<SelectIntervalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private mouldTypeService: MouldTypeService
    
    ) {
      if (data) {
        this.minValue = data.min;
        this.maxValue = data.max;
      }

      this.intervalsForm = this.fb.group({
        intervals: this.fb.array([])
      });
  
      this.initializeForm();

    }

    get intervals(): FormArray {
      return this.intervalsForm.get('intervals') as FormArray;
    }
    closeIntervalValue(): void {
      this.dialogRef.close();
    }

    initializeForm(): void {
      this.mouldTypeService.getMouldTypesList().subscribe(mouldTypes => {
        this.mouldTypes = mouldTypes;
        const existingIntervals = this.data && this.data.intervals ? this.data.intervals : [];
        console.log("existingIntervals",existingIntervals);
        console.log("this.mouldTypes",this.mouldTypes);
        this.mouldTypes.forEach(mouldType => {
          ['T1', 'T2'].forEach(variant => {
            const variantMouldType = `${mouldType.id} ${variant}`;
            const variantNumber = variant.substring(1); // Extracts the number after 'T'
        
            // Find existing interval that matches both mouldTypeId and type
            const existingInterval = existingIntervals.find(interval => 
              interval.mouldTypeId === mouldType.id && interval.type.toString() === variantNumber
            );
        
            if (existingInterval) {
              // If existing interval found, push it with data

              this.intervals.push(this.createIntervalGroupWithData(existingInterval,mouldType.name,variantNumber));
            } else {

              // Otherwise, create a new interval group for the variant
              // Split variantMouldType to pass the mouldTypeId and variant separately
              this.intervals.push(this.createIntervalGroup(mouldType, variant.substring(1)));
            }
          });
        });
        
      });
    }
    
    createIntervalGroup(mouldType: any, variant: string): FormGroup {
      return this.fb.group({
        mouldTypeId: mouldType.id,
        testParameterId: [],
        minValue: [''],
        maxValue: [''],
        type: variant ,
        variant:mouldType.name + ' T' + variant
      });
    }

    
    createIntervalGroupWithData(intervalData: any, variant?: string,variantNumber?: string): FormGroup {
      return this.fb.group({
        mouldTypeId: intervalData.mouldTypeId,
        testParameterId: [this.data.testParameterId],
        minValue: [intervalData.minValue],
        maxValue: [intervalData.maxValue],
        type: intervalData.type || variantNumber,
        variant: variant + ' T' + variantNumber//  intervalData.variant || variant
      });
    }
    getMouldTypeName(mouldTypeId: number): string {
      const mouldType = this.mouldTypes.find(mt => mt.id === mouldTypeId);
      return mouldType ? mouldType.name : 'Unknown Type';
    }
  addIntervalValue(): void {

  
    if (this.intervalsForm.valid) {

      const intervalsData = this.intervals.controls
                          .filter(group => {
                            const min = group.get('minValue').value;
                            const max = group.get('maxValue').value;
                           
                            return min !== null && min !== '' && max !== null && max !== '';
                          })
                          .map(group => {
                           
                            return {
                              min: group.get('minValue').value,
                              max: group.get('maxValue').value,
                              mouldTypeId: group.get('mouldTypeId').value,
                              type : group.get('type').value
                            };
  });
      const dataToEmit = {
        intervals: intervalsData,
        testParameterId: this.data.testParameterId 
      };
  
      console.log("Emitting interval values", dataToEmit);
      this.intervalSelected.emit(dataToEmit);

      this.dialogRef.close(dataToEmit);
    }
  }
  checkIntervals(): boolean {
    return this.intervals.controls.some(group => {
      const min = group.get('minValue').value;
      const max = group.get('maxValue').value;
      return min !== null && min !== '' && max !== null && max !== '';
    });
  }
}

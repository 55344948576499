import { Component } from '@angular/core';
import { CustomizerSettingsService } from '../customizer-settings/customizer-settings.service';

//for tabs
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';


interface ControlType {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-unit-tabs-content-description',
    templateUrl: './unit-tabs-content-description.component.html',
    styleUrls: ['./unit-tabs-content-description.component.scss']
})
export class UnitTabsContentDescriptionComponent {
    title: string;
    FormData!: FormGroup
    ControlTypes: ControlType[] = [
        { value: ' Text-0', viewValue: 'Text' },
        { value: 'ToggleButton-1', viewValue: 'Toggle Button' },
        { value: 'Date-2', viewValue: 'Date' },
        { value: 'ListBox-2', viewValue: 'List Box' },
    ];

    defaultValue: string = "Text";
    categories: string[];
    countInputs: number = 0;

    defaultCategorie: string = "categorie 1";

    displayInputDeleteBtn: boolean = false;
    lengthValue: number;
    addDisabledButtonInput: boolean = true;
    addDisabledTypeInput: boolean = false;

    counttab: number = 0;
    lotsOfTabs = new Array(3).fill(0).map((_, index) => `Tab ${index}`);
    indexDeleteBtn: string = null;

    constructor(
        public themeService: CustomizerSettingsService,
        private builder: FormBuilder) {
        this.title = "Unit Description";
        this.defaultValue = "Text";
        this.displayInputDeleteBtn = false;
        this.addDisabledButtonInput = true;
        this.lengthValue = 3;
        this.addDisabledTypeInput = false;

    }

    ngOnInit() {
        this.FormData = this.builder.group({
            inputsDescription: this.builder.array([
                this.builder.control(null)
            ])
        })

    }

    addItem() {
        if (this.addDisabledButtonInput != true) {
            const arraylengthr: any = (this.FormData.get('inputsDescription') as FormArray).controls[this.countInputs].disable();
            this.countInputs++;
            (this.FormData.get('inputsDescription') as FormArray).push(this.builder.control(null));
            console.log('array lenghth', arraylengthr);
            this.addDisabledButtonInput = true;
        } else {
            this.addDisabledTypeInput = true;
        }
    }

    getInputDescription(): AbstractControl[] {
        return (<FormArray>this.FormData.get('inputsDescription')).controls;
    }

    deleteItem(index: any) {
        this.countInputs--;
        (this.FormData.get('inputsDescription') as FormArray).removeAt(index);
    }

    onSubmit(FormData: any) {
        console.log(FormData, "FormData")
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    dislayDeletBtn(i) {
        if (i != null || i != "") {
            this.indexDeleteBtn = i;
            this.displayInputDeleteBtn = true;
        }

    }

    NodislayDeletBtn(i) {
        if (i != null || i != "") {
            this.indexDeleteBtn = i;
            this.displayInputDeleteBtn = false;
        }
    }

    onFocus(i) {
        this.dislayDeletBtn(i);
    }

    getInputId(event: any, i): void {
        const inputId = (event.target as HTMLInputElement).id;
        const inputValue = (event.target as HTMLInputElement).value;

        let splittedId = inputId.split('_');
        //last value in string
        let splitLastIdVal = splittedId.pop();
        if (inputId != null && inputValue != null && inputValue.length >= this.lengthValue && i == splitLastIdVal) {
            this.addDisabledButtonInput = false;
        }

    }
}


import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    FormArray,
    FormControl,
    Form,
    NgModel,
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Validators } from 'ngx-editor';
import { DescriptionService } from 'src/app/services/description.service';
import { CustomizerSettingsService } from '../../customizer-settings/customizer-settings.service';
import { UnitService } from 'src/app/services/unit.service';
import { ActivatedRoute } from '@angular/router';
import { MouldService } from 'src/app/services/mouldServices/mould.service';
import { EntityType, MouldStatus } from 'src/app/models/enums';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { FileEndpointService } from 'src/app/services/file-endpoint.service';
import { WorkplaceService } from 'src/app/services/workplace.service';
import { DescriptionValueService } from 'src/app/services/description-value.service';
import { WorkspaceService } from 'src/app/services/workspace.service';
import { DatePipe, formatDate } from '@angular/common';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ToastrService } from 'ngx-toastr';
import { DescriptionValueDuplicate, ForbiddenNameValidators } from 'src/app/shared/custom-validators';
import { FactoryService } from 'src/app/services/factory.service';
import { ThemePalette } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';
import { DeleteConfirmationDialogComponent } from '../../unit-description/tabs-content/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { WebcamImage, WebcamInitError, WebcamComponent as NgxWebcamComponent } from 'ngx-webcam';
import { UnitRequest } from '../../../models/unit-request.model';
import {
    NgxMatDateFormats,
    NGX_MAT_DATE_FORMATS
} from '@angular-material-components/datetime-picker';
const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'DD-MM-YYYY HH:mm'
    },
    display: {
        dateInput: 'DD-MM-YYYY HH:mm',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};
@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }]
})
export class ContentComponent implements OnInit {
    @Input() catId: number;
    @Input() index: number;
    @Input() mould: any
    @Input() checkIsFormValid: string
    @Output() dataEvent = new EventEmitter<number>();
    @ViewChild(SignaturePadComponent, { static: true }) signaturePad!: SignaturePadComponent;
    signaturePadOptions: NgSignaturePadOptions = {
        minWidth: 5,
        canvasWidth: 795,
        canvasHeight: 500,
        backgroundColor: 'white',
    };
    @ViewChild('picker') picker: any;

    public date: moment.Moment;
    public disabled = false;
    public showSpinners = true;
    public showSeconds = false;
    public touchUi = false;
    public enableMeridian = false;
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    public color: ThemePalette = 'primary';

    public dateControl = new FormControl(new Date());
    public dateControlMinMax = new FormControl(new Date());

    public options = [
        { value: true, label: 'True' },
        { value: false, label: 'False' }
    ];

    public listColors = ['primary', 'accent', 'warn'];

    public stepHours = [1, 2, 3, 4, 5];
    public stepMinutes = [1, 5, 10, 15, 20, 25];
    public stepSeconds = [1, 5, 10, 15, 20, 25];

    public controlNameArray: any[] = []
    public controlPushed: boolean = false
    defaultTtabCategorie: any = 'categorie';
    selectedControlType: any;
    descriptions: any[] = [];
    additionalDataForm: FormGroup;
    ControlTypes: any[] = [];
    checkboxStatus: boolean[] = [];
    additionalDataCheckboxStatus: boolean = false;
    inputTypeRequiredError: string = 'Please select an Input Type.';
    iconHoverStatus: boolean[] = [];
    @Input() createdUnitId: number
    name: FormControl;
    selectedControlTypeEdit: any[] = [];
    isUnitCreated: boolean = false;
    objDetails: any[] = [];
    inputTypeValues: any[] = [];
    descriptionValues: any[] = [];
    descriptionId = 0;
    popName = 'Signature';
    unitpopName = 'Unit Reference';
    discriptionValueList: any[] = [];
    signatureSelectedValue: any;
    handWritingSelectedValue: any;
    imageSelectedValue: any;
    unitReferenceSelectedValue = 'Enter UnitReference';
    unitForm: FormGroup;
    public HallName: string = ''

    trigger: Subject<void> = new Subject<void>();
    public allowCameraSwitch = true;
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
    };
    public errors: WebcamInitError[] = [];
    public webcamImage: WebcamImage = null;
    isOpenCamera = false;
    controlName: any;

    previewImage: any;
    confirmDeleteValue: any;

    types: string[] = ['1', '2', 'L', 'R'];
    pieces: any;
    tunnels: any = [
        {
            name: 'Greatworth',
            enabled: false
        },
        {
            name: 'Wendover',
            enabled: false
        },
        {
            name: 'Chipping',
            enabled: false
        },
        {
            name: 'Zero Cover',
            enabled: false
        },
        {
            name: 'Porous Portal',
            enabled: false
        }];

    elements: any = [
        {
            name: 'Wing',
            enabled: true
        },
        {
            name: 'Tunnel',
            enabled: false
        },
        {
            name: 'Portal',
            enabled: false
        }];

    specificityValues: string[] = [
        ' ',
        'A',
        'B',
        'C',
        'D',
        'E',
        'RIBBED ROOF',
        'JE',
        'UPSTAND',
        'UPSTAND ZERO cover',
        'RECESS',
        'DUCTING X',
        'DUCTING Y',
        'DOUBLE DUCTING X',
        'DOUBLE DUCTING Y',
        'ED A',
        'ED B',
        'ED C',
        'ED D1/2',
        'ED D3',
        'ED D4/5',
        'ED D6',
        '0000',
        '0000D',
        '0109',
        '0219',
        '0219D',
        '0328',
        '0438',
        '0547',
        '0656',
        '0656D',
        '0766',
        '0766D',
        '0875',
        '0875D',
        '0984',
        '1094',
        '1203',
        '1312',
        '1312D',
        '1422',
        '1531',
        '1531D',
        '1640',
        '1750',
        '1750D',
        '1859',
        '1969',
        '1969D',
        '2078',
        '2187',
        '2187D',
        '2297',
        '2297D',
        '2406',
        '2515',
        '2625',
        '2625D',
        '0429T',
        '0438T',
        '0596',
        '0623',
        '0763',
        '0806',
        '0929',
        '0989',
        '1096',
        '1174',
        '1263',
        '1346',
        '1346E',
        '1358',
        '1451',
        '1451E',
        '2734',
        '2734D',
        '2844',
        '2953',
        '2953D',
        '3062',
        '3172',
        '3172D',
        '3281',
        '3281D',
        '3391',
        '3391D',
        '3500',
        '3609',
        '3609D',
        '3719',
        '3719D',
        '3828',
        '3828D',
        '3937',
        '4047',
        '4047D',
        '4156',
        '4156D',
        '4266',
        '4266D',
        '4375',
        '4375E',
        '4484',
        '4594D',
        '4703D',
        '4812E'
    ];

    specificityKeyValues: any = [
        { name: ' ', value: '' },
        { name: 'A', value: 'A' },
        { name: 'B', value: 'B' },
        { name: 'C', value: 'C' },
        { name: 'D', value: 'D' },
        { name: 'E', value: 'E' },
        { name: 'RIBBED ROOF', value: 'RI' },
        { name: 'JE', value: 'JE' },
        { name: 'UPSTAND', value: 'UP' },
        { name: 'UPSTAND ZERO cover', value: 'UPZE' },
        { name: 'RECESS', value: 'RE' },
        { name: 'DUCTING X', value: 'DX' },
        { name: 'DUCTING Y', value: 'DY' },
        { name: 'DOUBLE DUCTING X', value: 'DXD' },
        { name: 'DOUBLE DUCTING Y', value: 'DYD' },
        { name: 'ED A', value: '' },
        { name: 'ED B', value: '' },
        { name: 'ED C', value: '' },
        { name: 'ED D1/2', value: '' },
        { name: 'ED D3', value: '' },
        { name: 'ED D4/5', value: '' },
        { name: 'ED D6', value: '' },
        { name: '0000', value: '0000' },
        { name: '0000D', value: '0000D' },
        { name: '0109', value: '0109' },
        { name: '0219', value: '0219' },
        { name: '0219D', value: '0219D' },
        { name: '0328', value: '0328' },
        { name: '0438', value: '0438' },
        { name: '0547', value: '0547' },
        { name: '0656', value: '0656' },
        { name: '0656D', value: '0656D' },
        { name: '0766', value: '0766' },
        { name: '0766D', value: '0766D' },
        { name: '0875', value: '0875' },
        { name: '0875D', value: '0875D' },
        { name: '0984', value: '0984' },
        { name: '1094', value: '1094' },
        { name: '1203', value: '1203' },
        { name: '1312', value: '1312' },
        { name: '1312D', value: '1312D' },
        { name: '1422', value: '1422' },
        { name: '1531', value: '1531' },
        { name: '1531D', value: '1531D' },
        { name: '1640', value: '1640' },
        { name: '1750', value: '1750' },
        { name: '1750D', value: '1750D' },
        { name: '1859', value: '1859' },
        { name: '1969', value: '1969' },
        { name: '1969D', value: '1969D' },
        { name: '2078', value: '2078' },
        { name: '2187', value: '2187' },
        { name: '2187D', value: '2187D' },
        { name: '2297', value: '2297' },
        { name: '2297D', value: '2297D' },
        { name: '2406', value: '2406' },
        { name: '2515', value: '2515' },
        { name: '2625', value: '2625' },
        { name: '2625D', value: '2625D' },
        { name: '0429T', value: '0429T' },
        { name: '0438T', value: '0438T' },
        { name: '0596', value: '0596' },
        { name: '0623', value: '0623' },
        { name: '0763', value: '0763' },
        { name: '0806', value: '0806' },
        { name: '0929', value: '0929' },
        { name: '0989', value: '0989' },
        { name: '1096', value: '1096' },
        { name: '1174', value: '1174' },
        { name: '1263', value: '1263' },
        { name: '1346', value: '1346' },
        { name: '1346E', value: '1346E' },
        { name: '1358', value: '1358' },
        { name: '1451', value: '1451' },
        { name: '1451E', value: '1451E' },
        { name: '2734', value: '2734' },
        { name: '2734D', value: '2734D' },
        { name: '2844', value: '2844' },
        { name: '2953', value: '2953' },
        { name: '2953D', value: '2953D' },
        { name: '3062', value: '3062' },
        { name: '3172', value: '3172' },
        { name: '3172D', value: '3172D' },
        { name: '3281', value: '3281' },
        { name: '3281D', value: '3281D' },
        { name: '3391', value: '3391' },
        { name: '3391D', value: '3391D' },
        { name: '3500', value: '3500' },
        { name: '3609', value: '3609' },
        { name: '3609D', value: '3609D' },
        { name: '3719', value: '3719' },
        { name: '3719D', value: '3719D' },
        { name: '3828', value: '3828' },
        { name: '3828D', value: '3828D' },
        { name: '3937', value: '3937' },
        { name: '4047', value: '4047' },
        { name: '4047D', value: '4047D' },
        { name: '4156', value: '4156' },
        { name: '4156D', value: '4156D' },
        { name: '4266', value: '4266' },
        { name: '4266D', value: '4266D' },
        { name: '4375', value: '4375' },
        { name: '4375E', value: '4375E' },
        { name: '4484', value: '4484' },
        { name: '4594D', value: '4594D' },
        { name: '4703D', value: '4703D' },
        { name: '4812E', value: '4812E' },
    ];

    unitName: string;

    type: string = '';
    tunnel: string = '';
    piece: string = '';
    element: string = '';
    specificity: string = '';
    public loading: boolean = false
    previousValues: { [key: string]: any } = {};
    isDropdownDisabled = false;

    showIcons(index: number) {
        this.initializeIcons();
        this.iconHoverStatus[index] = true;
    }

    handleToggleChange(checked: boolean, inputId: number, index: number) {
        const controlName = `inputsDescriptionValue.${index}`;
        this.FormData.get(controlName).setValue(checked);
    }

    hideIcons(index: number) {
        this.initializeIcons();
        // Only set to false if the mouse leaves the area.
        // Adjust this logic if needed to handle other scenarios.
        this.iconHoverStatus[index] = false;
    }

    initializeIcons() {
        this.iconHoverStatus = new Array(this.descriptions.length).fill(false);
    }

    getInputType(inputTypeId: number): string {
        switch (inputTypeId) {
            case 1:
                return 'UnitReference';
            case 2:
                return 'Name';
            case 3:
                return 'Text';
            case 4:
                return 'Number';
            case 5:
                return 'Text';
            case 6:
                return 'Text';
            case 7:
                return 'Text';
            case 8:
                return 'Text';
            case 9:
                return 'Text';
            case 10:
                return 'Text';
            case 11:
                return 'Date';
            case 12:
                return 'text';
            default:
                return 'text';
        }
    }

    inputTypeIconMapping: { [key: number]: string } = {
        1: 'ri-character-recognition-line',
        2: 'ri-settings-6-line',
        3: 'ri-file-text-line',
        4: 'ri-hashtag',
        5: 'ri-file-list-line',
        6: 'ri-chat-upload-line',
        7: 'ri-sketching',
        8: 'ri-pass-valid-line',
        9: 'ri-hand-coin-line',
        10: 'ri-door-lock-box-line',
        11: 'ri-list-ordered-2',
        12: 'ri-calendar-line',
        13: 'ri-toggle-line',
        14: 'ri-time-line',
    };

    getIconClass(inputTypeId: number): string {
        return this.inputTypeIconMapping[inputTypeId] || 'ri-keyboard-line'; // use a general icon class as fallback
    }

    constructor(
        private _factoryService: FactoryService,
        private descriptionService: DescriptionService,
        private descriptionvalueService: DescriptionValueService,
        public themeService: CustomizerSettingsService,
        private builder: FormBuilder,
        private route: ActivatedRoute,
        private unitsService: UnitService,
        private mouldStatusService: MouldService,
        private _workplaceService: WorkplaceService,
        private _workspaceService: WorkspaceService,
        private ngZone: NgZone,
        private fileEndpoint: FileEndpointService,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef,
        private modalService: NgbModal,
        public activeModal: NgbActiveModal
    ) {
        this.additionalDataForm = this.builder.group({
            additionalInput: [''],
            additionalCategory: [''],
        });
        this.FormData = this.builder.group({
            inputsDescription: this.builder.array([]),
        });
        this.unitForm = this.builder.group({
            elementType: ['', Validators.required],
            tunnelName: ['', Validators.required],
            specificity: ['', Validators.required],
            piece: ['', Validators.required],
            type: ['', Validators.required],
        });
    }

    FormData: FormGroup;

    private mouldName: string = ''
    private workplace: any
    private mouldUnitId: number

    ngOnInit() {
        this.loadMould()
        if (this.catId != undefined && this.catId > 0) {
            this.loadDescriptions(this.catId);
        }

        this.pieces = [
            {
                name: 'Centeral Wall',
                enabled: this.mould.mouldTypeName === 'CW' ? false : true,
            },
            {
                name: 'Arch Wall',
                enabled: this.mould.mouldTypeName === 'AW' ? false : true,
            },
            {
                name: 'Arch Roof',
                enabled: this.mould.mouldTypeName === 'AR' ? false : true,
            },
            {
                name: 'Emergency Door',
                enabled: false
            },
            {
                name: 'Ribbed Roof',
                enabled: false
            }
        ];
    }
    @ViewChild('signaturePad', { static: true }) signaturePadElement!: ElementRef;

    private originalCompositeOperation: any


    setBackgroundImage() {
        const canvas = this.signaturePad.getCanvas();
        const context = canvas.getContext('2d');

        // Replace 'your-image-url.jpg' with the actual URL or path to your image
        const img = new Image();

        switch (this.mould.mouldTypeName) {
            case 'CW':
                img.src = 'assets\\img\\covercheck_cw.jpg';
                break;
            case 'AW':
                img.src = 'assets\\img\\covercheck_aw.jpg';
                break;
            case 'AR':
                img.src = 'assets\\img\\covercheck_ar.jpg';
                break;
        }

        // Draw the image on the canvas as a background
        img.onload = () => {
            // Set composite operation to "destination-over" to draw new content behind the existing content
            context?.drawImage(img, 0, 0, canvas.width, canvas.height);
            context.globalCompositeOperation = this.originalCompositeOperation;
        };
    }
    private mouldId: number

    loadMould() {
        this.mouldId = this.mould.id;
        this.mouldName = this.mould.name
        this._workspaceService.getWorkspace(this.mould.workspaceId).subscribe((res) => {
            this.workplace = res
            this.HallName = this.HallName == '' ? this.workplace == null ? '' : this.workplace.workplaceName : this.HallName
        })

    }

    private resetForm() {
        this.FormData.reset();
    }

    isInputDisabled(index: number): boolean {
        const inputControl = this.FormData.get(`inputsDescription.${index}`);
        return inputControl ? inputControl.disabled : false;
    }

    isFieldRequired(index: number): boolean {
        return this.descriptions[index]?.required == true;
    }

    onEnterKeyPressed(event: KeyboardEvent, oldValue: any, controlName: string): void {

        event.preventDefault(); // Prevent default behavior (form submission, etc.)
        const newvalue = (event.target as HTMLInputElement).value; // Get the input value

        // Check if the value is not empty
        if (newvalue.trim() !== '') {
            this.saveValue(newvalue, oldValue, controlName); // Invoke saveValue function with the value and controlName
        }
    }

    public valueToSave: string
    public colorChange: any[] = []

    getColor(controllName): string {
        let obj = this.colorChange.find(x => x.control == controllName)
        if (obj != undefined) {
            return obj.color
        } else return 'red'
    }

    setColor(controlName, controlValue): void {

        let color
        if (controlValue == '') {
            color = 'red'
        } else {
            color = 'green'
        }
        this.colorChange.push({
            color: color,
            value: controlValue,
            control: controlName
        })
    }

    toggleSaveValue($event: MatSlideToggleChange, controlName: string) {
        this.valueToSave = ($event.checked).toString();
        if (this.valueToSave != "" && this.valueToSave != null && controlName != undefined) {

            const descriptionValueModel = {
                Value: this.valueToSave,
                UnitId: this.createdUnitId,
                descriptionId: Number(controlName.split('_').pop()),
            }
            this.descriptionvalueService
                .createDescriptionValue(descriptionValueModel)
                .subscribe((res) => {
                    this.loadDescriptionValues(this.catId)
                })
        }
    }

    saveTimeValue(value: string, controlName: string) {

        this.valueToSave = value
        if (this.valueToSave != "" && this.valueToSave != null && controlName != undefined) {

            const descriptionValueModel = {
                Value: this.valueToSave,
                UnitId: this.createdUnitId,
                descriptionId: Number(controlName.split('_').pop()),
            }
            this.descriptionvalueService
                .createDescriptionValue(descriptionValueModel)
                .subscribe((res) => {
                    this.loadDescriptionValues(this.catId)
                })
        }
    }

    saveDateValue(value: string, controlName: string) {
        this.valueToSave = value
        if (this.valueToSave != "" && this.valueToSave != null && controlName != undefined) {

            const descriptionValueModel = {
                Value: this.valueToSave,
                UnitId: this.createdUnitId,
                descriptionId: Number(controlName.split('_').pop()),
            }
            this.descriptionvalueService
                .createDescriptionValue(descriptionValueModel)
                .subscribe((res) => {
                    this.loadDescriptionValues(this.catId)
                })
        }
    }

    saveValue(newValue: any, oldValue: any, controlName: string) {
        if (newValue == oldValue) {
            return
        }

        if (this.inputDescriptionArray[0].get(controlName).hasError('nameDuplicate') == false) {

            this.valueToSave = newValue;

            if (this.valueToSave != "" && this.valueToSave != null && controlName != undefined) {

                const descriptionValueModel = {
                    Value: this.valueToSave,
                    UnitId: this.createdUnitId,
                    descriptionId: Number(controlName.split('_').pop()),
                }
                this.controlNameArray.find(x => x.name == controlName).loading = true
                this.descriptionvalueService
                    .createDescriptionValue(descriptionValueModel)
                    .subscribe((res) => {
                        this.controlNameArray.find(x => x.name == controlName).loading = false
                        if (res.statusCode == 200 && res.errorMessage == null) {
                            this.updateMould();
                            this.previousValues[controlName] = newValue;
                            this.toastr.success('Successfully Saved!', controlName.split('_')[0]);
                            this.loadDescriptionValues(this.catId);

                            if (controlName.split(' ')[0]) {
                                const unit = this.unitsService.getUnit(this.createdUnitId.toString()).subscribe((res) => {
                                    const unitRequest = new UnitRequest();
                                    unitRequest.model.id = +res.id;
                                    unitRequest.model.mouldId = res.mouldId;
                                    unitRequest.model.name = res.name;
                                    unitRequest.model.progress = res.progress;

                                    if (unit !== null) {
                                        if (controlName.split('_').length <= 2 && controlName.split('_')[0] === 'RFID 1') {
                                            unitRequest.model.rfId1 = this.valueToSave;
                                            unitRequest.model.rfId2 = res.rfId2;

                                            this.unitsService.updateUnit(unitRequest, this.mouldId).subscribe();
                                        }
                                        else if (controlName.split('_').length <= 2 && controlName.split('_')[0] === 'RFID 2') {
                                            unitRequest.model.rfId2 = this.valueToSave;
                                            unitRequest.model.rfId1 = res.rfId1;

                                            this.unitsService.updateUnit(unitRequest, this.mouldId).subscribe();
                                        }
                                    }
                                }
                                );
                            }
                        } else {
                            this.toastr.error(res.errorMessage, controlName.split('_')[0])
                        }
                    })
            }
        }

    }

    updateMould() {
        let _mouldStatus
        if (this.index == 0) {
            _mouldStatus = MouldStatus.Initial
        } else if (this.index == 1) {
            _mouldStatus = MouldStatus.Open
        } else if (this.index == 2) {
            _mouldStatus = MouldStatus.Closed
        } else if (this.index == 3) {
            _mouldStatus = MouldStatus.Curing
        } else {
            _mouldStatus = MouldStatus.Curing
        }

        this.mouldStatusService
            .updateMouldStatus(this.mouldId, _mouldStatus)
            .subscribe()
    }

    saveHallName(inputType: string): void {
        let element = this.descriptions.find(x => x.inputTypeName == inputType)
        if (element != undefined) {
            const concatenatedValue = `${element.name}_${element.id}`;
            let value = this.inputDescriptionArray[0].get(concatenatedValue).value
            if (value != undefined || value != "") {
                if (this.createdUnitId > 0) {

                    const descriptionValueModel = {
                        Value: inputType == "Hall" ? this.HallName : this.mouldName,
                        UnitId: this.createdUnitId,
                        descriptionId: element.id,
                    };

                    this.descriptionvalueService
                        .createDescriptionValue(descriptionValueModel)
                        .subscribe((res) => {
                        })
                }
            }
        }

    }

    saveMouldName(): void {
        let item = this.objDetails.find(x => x.name == "MouldName")
        if (item != undefined) {
            let value = this.discriptionValueList.find(x => x.descriptionId == item.id)
            if (value == undefined || value == "") {
                if (this.createdUnitId > 0) {

                    const descriptionValueModel = {
                        Value: this.mouldName,
                        UnitId: this.createdUnitId,
                        descriptionId: item.id,
                    };

                    this.descriptionvalueService
                        .createDescriptionValue(descriptionValueModel)
                        .subscribe((res) => {

                        })
                }
            }
        }
    }

    createUnitAndGetId(descriptionValue: any, controlName: string) {

        const unitData = {
            name: descriptionValue,
            mouldId: this.mouldId,
        };

        this.inputDescriptionArray[0].get(controlName).disable()

        this.unitsService.createUnit(unitData).subscribe(
            (response) => {

                this.createdUnitId = response.id;
                this.dataEvent.emit(this.createdUnitId);
                this.mouldStatusService.updateMouldUnitId(this.mouldId, this.createdUnitId).subscribe()

                let _mouldStatus
                if (this.catId == 1) {
                    _mouldStatus = MouldStatus.Initial
                } else if (this.catId == 2) {
                    _mouldStatus = MouldStatus.Open
                } else if (this.catId == 3) {
                    _mouldStatus = MouldStatus.Closed
                } else if (this.catId == 4) {
                    _mouldStatus = MouldStatus.Curing
                } else {
                    _mouldStatus = MouldStatus.Curing
                }
                this.mouldStatusService
                    .updateMouldStatus(this.mouldId, MouldStatus.Initial)
                    .subscribe(
                        (updateStatusResponse) => {
                        },
                        (updateStatusError) => {
                            console.error(
                                'Error updating mould status:',
                                updateStatusError
                            );
                        }
                    );


                if (this.createdUnitId > 0) {
                    const descriptionValueModel = {
                        Value: descriptionValue,
                        UnitId: this.createdUnitId,
                        descriptionId: controlName.split('_').pop(),
                    };

                    this.descriptionvalueService
                        .createDescriptionValue(descriptionValueModel)
                        .subscribe(
                            (descriptionValueResponse) => {
                                this.saveHallName("Hall")
                                this.saveHallName("MouldNumber")
                                // this.saveMouldName()
                                this.loadDescriptionValues(this.catId);


                            },
                            (descriptionValueError) => {
                                console.error(
                                    'Error creating description value:',
                                    descriptionValueError
                                );
                            }
                        );
                }

            },

            (error) => {
                console.error('Error creating unit:', error);
            }
        );

    }


    createNewUnit(descriptionValue: any, i: number, descriptionId: number) {

        const mouldId = this.route.snapshot.queryParams['id'];
        localStorage.setItem(
            `FormData_${mouldId}`,
            JSON.stringify(this.FormData.value)
        );

        if (!mouldId) {
            console.error('mouldId is missing in URL parameters');
            return;
        }
        const label = this.objDetails[i].inputTypeName;

        if (label === 'UnitReference') {
            const unitData = {
                name: descriptionValue,
                mouldId: mouldId,
            };

            const control = this.FormData.get('inputsDescriptionValue.' + i);

            if (control) {
                control.disable();
            }

            this.unitsService.createUnit(unitData).subscribe(
                (response) => {

                    this.createdUnitId = response.id;
                    this.dataEvent.emit(this.createdUnitId);
                    this.mouldStatusService.updateMouldUnitId(Number(mouldId), this.createdUnitId).subscribe()

                    let _mouldStatus
                    if (this.catId == 1) {
                        _mouldStatus = MouldStatus.Initial
                    } else if (this.catId == 2) {
                        _mouldStatus = MouldStatus.Open
                    } else if (this.catId == 3) {
                        _mouldStatus = MouldStatus.Closed
                    } else if (this.catId == 4) {
                        _mouldStatus = MouldStatus.Curing
                    } else {
                        _mouldStatus = MouldStatus.Curing
                    }
                    this.mouldStatusService
                        .updateMouldStatus(Number(mouldId), MouldStatus.Initial)
                        .subscribe(
                            (updateStatusResponse) => {
                                const inputsDescriptionArray =
                                    this.FormData.get(
                                        'inputsDescription'
                                    ) as FormArray;
                                const inputControl = inputsDescriptionArray.at(
                                    i
                                ) as FormControl;

                                if (inputControl) {
                                    inputControl.disable();
                                }
                            },
                            (updateStatusError) => {
                                console.error(
                                    'Error updating mould status:',
                                    updateStatusError
                                );
                            }
                        );


                    if (this.createdUnitId > 0) {


                        const descriptionValueModel = {
                            Value: descriptionValue,
                            UnitId: this.createdUnitId,
                            descriptionId: descriptionId,
                        };

                        this.descriptionvalueService
                            .createDescriptionValue(descriptionValueModel)
                            .subscribe(
                                (descriptionValueResponse) => {
                                    this.loadDescriptionValues(this.catId);

                                    const inputControl = this.FormData.get(
                                        `inputsDescription.${i}`
                                    ) as FormControl;
                                    if (inputControl) {
                                        inputControl.disable(); // Disable the control
                                    } else {
                                        ;
                                    }
                                },
                                (descriptionValueError) => {
                                    console.error(
                                        'Error creating description value:',
                                        descriptionValueError
                                    );
                                }
                            );
                    }

                },

                (error) => {
                    console.error('Error creating unit:', error);
                }
            );
        } else {

            if (this.createdUnitId > 0) {

                const descriptionValueModel = {
                    Value: descriptionValue,
                    UnitId: this.createdUnitId,
                    descriptionId: descriptionId,
                };

                this.descriptionvalueService
                    .createDescriptionValue(descriptionValueModel)
                    .subscribe(
                        (descriptionValueResponse) => {
                            this.loadDescriptionValues(this.catId);

                            const inputControl = this.FormData.get(
                                `inputsDescription.${i}`
                            ) as FormControl;
                            if (inputControl) {
                                inputControl.disable(); // Disable the control
                            } else {
                                ;
                            }
                            let _mouldStatus
                            if (this.catId == 1) {
                                _mouldStatus = MouldStatus.Initial
                            } else if (this.catId == 2) {
                                _mouldStatus = MouldStatus.Open
                            } else if (this.catId == 3) {
                                _mouldStatus = MouldStatus.Closed
                            } else if (this.catId == 4) {
                                _mouldStatus = MouldStatus.Curing
                            }
                            this.mouldStatusService
                                .updateMouldStatus(
                                    Number(mouldId),
                                    _mouldStatus
                                )
                                .subscribe(
                                    (updateStatusResponse) => {
                                    },
                                    (updateStatusError) => {
                                        console.error(
                                            'Error updating mould status:',
                                            updateStatusError
                                        );
                                    }
                                );
                        },
                        (descriptionValueError) => {
                            console.error(
                                'Error creating description value:',
                                descriptionValueError
                            );
                        }
                    );
            }

        }
    }

    get itemsControls() {
        return (this.FormData.get('items') as FormArray).controls;
    }

    get items() {
        return this.FormData.get('items') as FormArray;
    }

    fetchControlTypes() {
        this.descriptionService.getAllInputTypes().subscribe(
            (types: any[]) => {

                this.ControlTypes = types;
                this.FormData.get('inputType')?.setValue(
                    this.selectedControlType
                );
            },
            (error) => {
                console.error('Error fetching input types:', error);
            }
        );
    }

    ontestcall(): boolean {

        if (this.inputDescriptionArray[0].invalid) {
            return true
        } else {
            return false
        }
    }

    public RFID1Control
    public RFID2Control
    // Helper method to create a combined form group for all items in the array
    createCombinedFormGroup(items: any[]): FormGroup {
        const formGroup = this.builder.group({});
        items.forEach(item => {
            const concatenatedValue = `${item.name}_${item.id}`; // Adjust the concatenation as needed
            this.setColor(concatenatedValue, '')
            this.controlNameArray.push({
                name: concatenatedValue,
                inputTypeId: item.inputTypeId,
                inputType: item.inputTypeName,
                isRequired: item.isRequired,
                inputTypeValues: item.inputTypeValues,
                loading: false,
                descriptionId: item.id,
                descriptionValueId: 0,
            })
            var control
            if (item.inputTypeName == "ToggleButton" || item.inputTypeName == "PartialValidation") {
                control = this.builder.control(false);
            } else if (item.inputTypeName == "Hall") {

                control = this.builder.control(this.HallName);
            } else if (item.inputTypeName == "MouldNumber") {
                control = this.builder.control(this.mouldName);
            } else if (item.inputTypeName == "UnitReference") {
                control = this.builder.control('')
            } else if (item.inputTypeName == "Date") {
                control = this.builder.control(new Date())
            } else if (item.inputTypeName == "Time") {
                const currentTime = new Date();
                //this.formattedTime = this.datePipe.transform(currentTime, 'HH:mm');
                control = this.builder.control(this.datePipe.transform(currentTime, 'HH:mm'))
                //control.disable()
            } else {
                control = this.builder.control('');
            }

            formGroup.addControl(concatenatedValue, control);

            if (item.name == "RFID 1") {
                this.RFID1Control = concatenatedValue
                let asyncValidatorSet = false;
                formGroup.get(concatenatedValue).valueChanges.subscribe((value) => {

                    if (value === null) {
                        if (asyncValidatorSet) {
                            formGroup.clearAsyncValidators();
                            asyncValidatorSet = false;
                        }
                    } else {
                        this.inputDescriptionArray[0].get(this.RFID1Control).setAsyncValidators(
                            DescriptionValueDuplicate(this.descriptionvalueService, value, this.RFID1Control, this.createdUnitId)
                        );

                        asyncValidatorSet = true;
                    }
                });
            }

            if (item.name == "RFID 2") {
                this.RFID2Control = concatenatedValue
                let asyncValidatorSet = false;
                formGroup.get(concatenatedValue).valueChanges.subscribe((value) => {

                    if (value === null) {
                        // If 'workplaceId' is null and the async validator is set, remove it
                        if (asyncValidatorSet) {
                            formGroup.clearAsyncValidators();
                            asyncValidatorSet = false;
                        }
                    } else {
                        // If 'workplaceId' has a value and the async validator is not set, add it
                        this.inputDescriptionArray[0].get(this.RFID2Control).setAsyncValidators(
                            DescriptionValueDuplicate(this.descriptionvalueService, value, this.RFID2Control, this.createdUnitId)
                        );
                        asyncValidatorSet = true;
                    }
                });
            }
        });

        return formGroup;
    }

    get inputDescriptionArray() {
        return (this.FormData.get('inputsDescription') as FormArray).controls
    }

    loadDescriptions(categoryId: number) {
        this.descriptionService
            .getDescriptionsByCategoryId(categoryId)
            .subscribe((descriptions: any[]) => {
                if (descriptions && descriptions.length > 0) {
                    this.descriptions = descriptions
                    this.inputDescriptionArray.push(this.createCombinedFormGroup(descriptions))

                    // Create a flag to track whether the async validator is set

                }
                this.loadDescriptionValues(this.catId);
            });
    }

    loadDescriptionValues(categoryId: number) {

        this.descriptionvalueService
            .getDescriptionValues(categoryId, this.createdUnitId)
            .subscribe((descriptionValues: any[]) => {

                if (descriptionValues && descriptionValues.length > 0) {
                    this.discriptionValueList = descriptionValues;
                    this.colorChange.length = 0
                    this.discriptionValueList.forEach((item: any) => {

                        let inputTypeName = item.description.inputType.name
                        let Value
                        const concatenatedValue = `${item.description.name}_${item.description.id}`;
                        this.setColor(concatenatedValue, item.value)
                        if (inputTypeName == "Number") {
                            Value = Number(item.value)
                        } else if (inputTypeName == "ToggleButton" || inputTypeName == "PartialValidation") {
                            Value = item.value
                        } else if (inputTypeName == "Date") {
                            Value = new Date(item.value)
                        } else {
                            Value = item.value
                        }
                        if (this.controlNameArray.length > 0) {
                            if (inputTypeName == "UnitReference") {
                                this.inputDescriptionArray[0]?.get(concatenatedValue)?.disable()
                            }
                            this.inputDescriptionArray[0]?.get(concatenatedValue)?.setValue(Value)

                            const matchingItem = this.controlNameArray.find((controlItem: any) => controlItem.descriptionId === item.description.id);

                            if (matchingItem) {
                                matchingItem.descriptionValueId = item.id;
                            }
                        }
                    })

                } else {

                }
            });
    }

    setupFormControls() {
        const inputsDescriptionArray = this.FormData.controls[
            'inputsDescription'
        ] as FormArray;
        inputsDescriptionArray.clear();
        this.descriptions.forEach((description) => {
            inputsDescriptionArray.push(this.builder.control(''));
        });
    }

    toggleTheme() {
        this.themeService.toggleTheme();
    }

    toggleRTLEnabledTheme() {
        this.themeService.toggleRTLEnabledTheme();
    }

    selectedFiles: any;

    selectFile(event, controlName: string) {
        this.controlNameArray.find(x => x.name == controlName).loading = true;
        this.descriptionId = Number(controlName.split('_').pop());
        this.selectedFiles = event.target.files[0] as File;
        this.fileEndpoint.uploadImage(this.selectedFiles)
            .subscribe((response: any) => {
                this.controlNameArray.find(x => x.name == controlName).loading = true;

                this.imageSelectedValue = response.value;
                this.saveValue(this.imageSelectedValue, '', controlName);
            },
                (error) => {
                    this.controlNameArray.find(x => x.name == controlName).loading = true;

                    console.error('', error)
                });
    }

    indexvalue = 0;

    signaturePadPopup(controllName: string) {
        if (this.inputDescriptionArray[0]?.get(controllName).value != '') {
            return
        } else {
            this.signaturePad.clear()
            this.UnitRefernceControlName = controllName
            this.descriptionId = Number(controllName.split('_')[1]),
                document.getElementById('menuModal')?.click();

            if (this.controlNameArray.find(c => c.name == controllName).inputType == "Handwriting") {
                this.setBackgroundImage()
            }
        }
    }

    unitReferencePadPopup(index: number, controlName: string) {
        if (this.inputDescriptionArray[0].get(controlName).value != '') {
            return
        } else {
            this.descriptionId = Number(controlName.split('_').pop())
            this.UnitRefernceControlName = controlName
            document.getElementById('unitModal')?.click();
        }
    }

    checkIndex(descriptionId: number, index: number): number {
        const foundIndex = this.discriptionValueList.findIndex(
            (x) => x.descriptionId === descriptionId
        );
        return foundIndex !== -1 ? foundIndex : -1;
    }

    drawComplete(event: MouseEvent | Touch) {
    }

    drawStart(event: MouseEvent | Touch) {
    }

    signatureImage: string | ArrayBuffer | null = null;

    saveSignatureAsImage(): void {
        this.controlNameArray.find(x => x.name == this.UnitRefernceControlName).loading = true
        if (this.signaturePad.isEmpty()) {
            return;
        }

        const canvas = this.signaturePad.getCanvas();
        const imageData = canvas.toDataURL('image/jpg');
        this.signatureImage = imageData;

        function dataURItoBlob(dataURI: string): Blob {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        }

        const blob = dataURItoBlob(imageData);
        const file = new File([blob], 'signature.jpg', { type: 'image/jpeg' });
        this.fileEndpoint.uploadImage(file)
            .subscribe((response: any) => {
                this.controlNameArray.find(x => x.name == this.UnitRefernceControlName).loading = true

                this.signatureSelectedValue = response.value;
                this.saveValue(response.value, '', this.UnitRefernceControlName);
            },
                (error) => {

                    console.error('', error)
                });


    }

    handleRadio(evt) {
        if (evt.value.name == 'Wing')
            this.type = 'W';

        if (evt.value.name == 'Tunnel')
            this.type = 'T';

        if (evt.value.name == 'Portal')
            this.type = 'P';

        if (evt.value.name == 'Chipping')
            this.tunnel = 'C';

        if (evt.value.name == 'Greatworth')
            this.tunnel = 'G';

        if (evt.value.name == 'Wendover')
            this.tunnel = 'W';

        if (evt.value.name == 'Zero Cover')
            this.tunnel = 'Z';

        if (evt.value.name == 'Porous Portal')
            this.tunnel = 'P';

        if (evt.value.name == 'Centeral Wall')
            this.piece = 'CW';

        if (evt.value.name == 'Arch Wall')
            this.piece = 'AW';

        if (evt.value.name == 'Arch Roof')
            this.piece = 'AR';

        if (evt.value.name == 'Emergency Door')
            this.piece = 'ED';

        if (evt.value.name == 'Ribbed Roof')
            this.piece = 'RR';


        if (this.specificityKeyValues.find(x => x.name == evt.value)) {
            this.specificity = ' ' + this.specificityKeyValues.find(x => x.name == evt.value).value;
        }

        if (evt.value == '1')
            this.element = '1';

        if (evt.value == '2')
            this.element = '2';

        if (evt.value == 'L')
            this.element = 'L';

        if (evt.value == 'R')
            this.element = 'R';

        let name = this.type + this.tunnel + this.piece + this.element + this.specificity;

        this.unitName = name;

        if (this.type !== '' && this.tunnel !== '' && this.piece !== '' && this.element !== '') {
            this.unitsService.generateUnitName(name).subscribe(
                data => this.unitName = data,
                error => console.log('Error while getting Unit Name', error)
            );
        }
    }

    private UnitRefernceControlName: string

    saveUnitReference() {
        this.createUnitAndGetId(this.unitName, this.UnitRefernceControlName)
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    openCamera(controlName: any) {
        this.controlName = null;
        this.controlName = controlName;
        this.isOpenCamera = true;
        this.webcamImage = null;

        document.getElementById('cameraOpen')?.click();
    }

    handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;

    }
    nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }
    cameraWasSwitched(deviceId: string): void {
        this.deviceId = deviceId;
    }

    handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
    }

    takePicture(): void {
        this.trigger.next();
    }

    closeCamera() {
        this.isOpenCamera = false;
    }

    savePicture() {
        this.isOpenCamera = false;
        function dataURItoBlob(dataURI: string): Blob {
            const byteString = atob(dataURI.split(',')[1]);
            const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: mimeString });
        }

        const blob = dataURItoBlob(this.webcamImage.imageAsDataUrl);
        const file = new File([blob], 'picture.jpg', { type: 'image/jpeg' });

        this.controlNameArray.find(x => x.name == this.controlName).loading = true;

        this.fileEndpoint.uploadImage(file)
            .subscribe((response: any) => {
                this.controlNameArray.find(x => x.name == this.controlName).loading = true;

                this.imageSelectedValue = response.value;
                this.saveValue(this.imageSelectedValue, '', this.controlName);
            },
                (error) => {
                    this.controlNameArray.find(x => x.name == this.controlName).loading = true;

                    console.error('', error)
                });
    }

    getLiveTime(controlName: any): void {
        const obj = this.colorChange.find(x => x.control === controlName);

        if (obj === undefined) {
            const dateControl = this.builder.control(new Date());
            const inputDescription = this.inputDescriptionArray[0];

            if (inputDescription) {

                inputDescription.get(controlName).setValue(dateControl.value);

            }
        }

    }

    confirm() {
        this.inputDescriptionArray[0].get(this.confirmDeleteValue.name).enable()
        this.descriptionvalueService
            .deleteDescriptionValue(this.confirmDeleteValue.descriptionValueId)
            .subscribe((res) => {
                if (res.statusCode == 200 && res.errorMessage == null) {

                    this.inputDescriptionArray[0]?.get(this.confirmDeleteValue.name)?.setValue(null)
                    this.isDropdownDisabled = false;
                    this.updateMould();
                    this.toastr.warning('Successfully Deleted!', this.confirmDeleteValue.name.split('_')[0])
                    this.loadDescriptionValues(this.catId)
                } else {

                    this.toastr.error(res.errorMessage, this.confirmDeleteValue.split('_')[0])
                }
            });
    }

    deleteDescriptionValue(descriptionObj: any) {

        this.confirmDeleteValue = descriptionObj;
        const previewOpenElement = document.getElementById('confirmOpen');
        if (previewOpenElement) {
            previewOpenElement.click();
        } else {
            console.error("Element with id 'preViewOpen' not found");
        }

    }

    showPreview(obj: any) {
        const value = this.inputDescriptionArray[0]?.get(obj.name)?.value;
        if (value) {
            this.previewImage = `https://satebagreentunnelstorqa.blob.core.windows.net/images/${value}`;
        } else {
            console.error(`Value not found for ${obj.name}`);
            return;
        }
        const previewOpenElement = document.getElementById('preViewOpen');
        if (previewOpenElement) {
            previewOpenElement.click();
        } else {
            console.error("Element with id 'preViewOpen' not found");
        }
    }

    decline() {
        this.isDropdownDisabled = false;
        this.activeModal.close();
    }

    capturePreviousValue(value: any, controlName: string) {
        this.previousValues[controlName] = value;
    }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { CustomizerSettingsService } from '../customizer-settings/customizer-settings.service';
import { MouldService } from 'src/app/services/mouldServices/mould.service';
import { ValidateContentComponent } from './validate-content/validate-content.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toast, ToastrService } from 'ngx-toastr';
export class Tab {
    constructor(
        public id: number,
        public label: string,
        public content: string
    ) { }
}
@Component({
    selector: 'app-unit-visualisation',
    templateUrl: './unit-visualisation.component.html',
    styleUrls: ['./unit-visualisation.component.scss']
})
export class UnitVisualisationComponent {
    title: string;
    disableInputTab: boolean = true;
    mould: any = null;
    inc = 1;
    lastIndex: number;
    mouldId: number
    tabs: { id: number; title: string; content: string; isEditing: boolean }[] =
        [
            {
                id: 0,
                title: 'Category 0',
                content: 'Tab Content 0',
                isEditing: false,
            },
        ];
    activeTab: number = 0;
    uniId: number = 0
    constructor(
        public themeService: CustomizerSettingsService,
        private mouldService: MouldService,
        private categoryService: CategoryService,
        private route: ActivatedRoute,
        private _router: Router,
        private modalService: NgbModal,
        private toaster: ToastrService,
    ) { }
    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.mouldId = +params['id'];
            this.getMouldById(this.mouldId);
        });
        this.inc = this.tabs.length;
        this.lastIndex = this.tabs.length - 1;
    }


    handleDataFromChild(data: any) {
        this.uniId = data
    }

    public mouldStatusId: number
    getMouldStatusIdById(): void {
        this.mouldService.getMouldById(this.mouldId).subscribe(
            (mould: any) => {

                this.mouldStatusId = mould.statusId
            })
    }
    getMouldById(mouldId: number): void {
        this.mouldService.getMouldById(mouldId).subscribe(
            (mould: any) => {
                if (!mould) {
                    this._router.navigate(['common/not-found'])
                }
                else {

                    this.mould = mould;
                    this.uniId = mould.unitId
                    this.loadCategoriesByMouldType(mould.mouldTypeId);
                }
            },
            (error) => {
                console.error('Error fetching mould by ID:', error);
                // Handle error scenarios
            }
        );
    }

    loadCategoriesByMouldType(mouldTypeId: number) {
        this.categoryService.getCategoriesByMouldTypeId(mouldTypeId).subscribe(
            (categories: any[]) => {

                this.tabs = categories.map((category) => {
                    return {
                        id: category.id,
                        title: category.name,
                        content: category.content,
                        isEditing: false,
                    };
                });
                this.activeTab = this.tabs.length > 0 ? 0 : -1;
            },
            (error) => {
                console.error('Error fetching categories by mouldId:', error);
            }
        );
    }


    selectTab(index: number) {
        this.activeTab = index;
    }

    next() {
        if (this.tabs.length >= this.activeTab) {
            this.activeTab = this.activeTab + 1
        }
    }

    previous() {
        if (this.activeTab > 0) {
            this.activeTab = this.activeTab - 1;
        }
    }



    validate() {
        this.categoryService.validateFormByMouldTypeId(this.mould.id).subscribe((res: boolean) => {
            if (res) {
                const modalRef = this.modalService.open(
                    ValidateContentComponent,
                    {
                        size: 'lg',
                        backdrop: 'static'
                    }
                );
                modalRef.componentInstance.mouldId = this.mouldId;
                modalRef.componentInstance.deleteChanged.subscribe((data) => {
                    if (data) {

                        this.modalService.dismissAll();
                        this._router.navigate(['/view-unit'], { queryParams: { id: this.uniId } });
                    }
                })
            }
            else {
                this.toaster.error('Fill Unit Reference !', 'Field Required')
            }
        })

    }

}
